import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { NavLink } from 'react-router-dom'

interface IProps {
    pageTitle: string;
}

interface IState {
    pageTitle: string;
}


export class HealthSubnav extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            pageTitle: this.props.pageTitle
        } 
        
    }
    
    componentDidMount() {

    }



    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <h2>{this.props.pageTitle}</h2>
                    </Col>
                    <Col className="text-right">
                        <div className="btn-group">
                            <NavLink to="/health-general" className="btn btn-secondary" >Overview</NavLink>
                            <NavLink to="/health-aftertreatment" className="btn btn-secondary" >Aftertreatment</NavLink>
                           {/* <NavLink to="/health/battery" className="btn btn-secondary" >Battery Health</NavLink>*/}
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
