import * as React from 'react';
import { NavTop } from '../Nav'
import { BsCheckCircle } from "react-icons/bs"; // good
import { AiFillCheckCircle } from "react-icons/ai"; // good
import { MdCancel } from "react-icons/md"; //critical
import { RiErrorWarningFill } from "react-icons/ri"; //warning
import { BsFillSlashCircleFill } from "react-icons/bs";
import { GoTriangleRight } from "react-icons/go";
import { Link } from "react-router-dom";
import { InovexDropDown } from "../InovexDropDown";
import { DropDownFilterBar } from '../DropDownFilterBar';

import {
    Row,
    Col,
    Card,
    CardDeck,
    CardTitle,
    CardText,
    CardBody,
    CardGroup,
} from 'reactstrap';
import {
//     PieChart,
     SparkLineChart,
     ColumnChart,
     StackedColumnChart,
     LineChart,
     DivergentStackBarChart
} from '../Charts';





/*import { PieChart } from '@amcharts/amcharts4/charts';*/

// --- vvvvv --- This must be included in every component for exception tracking. Any uncaught Exception will be logged in Sentry.io
// import { Integrations } from "@sentry/tracing";
// import * as Sentry from "@sentry/react";
// Sentry.init({
//     dsn: "https://f9eb950a178d4b65b25c3cdf5eb67f55@o358502.ingest.sentry.io/5964791",
//     integrations: [new Integrations.BrowserTracing()],
//
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
// });
// --- ^^^^^ --- This must be included in every component

interface IDataPoint {
    dateTimeUtc: Date;
    value: number;
}

interface IOverviewProps {
}

interface IOverviewState {
    loading: boolean;
    healthChartData: Array<{ dateTimeUtc: Date; value: number; }>;
    safetyChartData: Array<{ dateTimeUtc: Date; value: number; }>;
    fuelChartData: Array<{ dateTimeUtc: Date; value: number; }>;
    aftertreatmentChartData: Array<{ dateTimeUtc: Date; value: number; }>;
    usageChartData: Array<{ dateTimeUtc: Date; value: number; }>;
    
    healthChart: SparkLineChart | null;
    safetyChart: SparkLineChart | null;
    fuelChart: SparkLineChart | null;
    usageChart: SparkLineChart | null;
    aftertreatmentChart: SparkLineChart | null;
    dutyCycleChart: StackedColumnChart | null;
    reusableLineChart: LineChart | null;
    contributingFactorsChart: DivergentStackBarChart | null;

    isTrendViewOpen: boolean;
    selectedTrendViewValue: string;
    totalFuelUsedInPeriod: string;
    totalExpectedFuelUsedInPeriod: string;
    totalFuelWasted: string;
    fleetHealthScore: number;
    avgFuelEcon: number;
    totalFuelWastedString: string;
}

export class Overview extends React.Component<IOverviewProps, IOverviewState> {
    constructor(props: IOverviewProps) {
        super(props);
        // this.toggleTrendView = this.toggleTrendView.bind(this);
        this.changeTrendValue = this.changeTrendValue.bind(this);

        // this.state = { pageTitle: 'Overview', parentPage: '' };
        // this.setState({ pageTitle: 'Overview', parentPage: '' })
        // this.createLoadHistogramChart = this.createLoadHistogramChart.bind(this);

        let initialTrendViewValue = "Average Fuel Economy";

        this.state =
        {
            healthChartData: [],
            safetyChartData: [],
            fuelChartData: [],
            aftertreatmentChartData: [],
            usageChartData: [],
            fleetHealthScore: 0,
            loading: false,
            healthChart: null,
            safetyChart: null,
            fuelChart: null,
            usageChart: null,
            aftertreatmentChart: null,
            dutyCycleChart: null,
            reusableLineChart: null,
            contributingFactorsChart: null,
            avgFuelEcon: 0,
            isTrendViewOpen: false,
            selectedTrendViewValue: initialTrendViewValue,
            totalFuelUsedInPeriod: '0 gal',
            totalExpectedFuelUsedInPeriod: '0 gal',
            totalFuelWasted: '0 gal',
            totalFuelWastedString: "0"
        };

    }

    //function to handle toggle of the trends view dropdown
    toggleTrendView() {
        this.setState(prevState => ({
            isTrendViewOpen: !prevState.isTrendViewOpen
        }));
    }
    changeTrendValue(chartName: string) {//function to handle on click
        this.setState({ selectedTrendViewValue: chartName }); //set the selected value to the text content of the clicked element

        let rangeValues: Array<{ value: number; endValue: number; colour: string; }>;

        switch (chartName) {
            case 'Fuel Economy':

                this.state.reusableLineChart.ReDraw("", "MPG", this.state.fuelChartData, rangeValues, 6, 10);
                break;

            case 'Safety':
                this.state.reusableLineChart.ReDraw("", "%", this.state.safetyChartData, rangeValues, 70, 100);
                break;
            case 'Aftertreatment':
                this.state.reusableLineChart.ReDraw("", "%", this.state.aftertreatmentChartData, rangeValues, 70, 100);
                break;
            case 'Fleet Health':
                this.state.reusableLineChart.ReDraw("", "%", this.state.healthChartData, rangeValues, 70, 100);
                break;

            case 'Usage':
                this.state.reusableLineChart.ReDraw("", "Days", this.state.usageChartData, rangeValues, 0, 7);
                break;

        }

    }

    componentDidMount() {
        this.fetchData();
    }






    async fetchData() {
        this.fetchFleetHealthScore();

        /*
        public enum HourlyDailyWeeklyMonthly {
            Hourly = 1,
            Daily = 2,
            Weekly = 3,
            Monthly = 4
        }

        public enum RandomSeriesBehaviour {
            Rising = 1,
            Falling = 2,
            Fluctuating = 3
        }

        public enum RandomSeriesDataType {
            Integer = 1,
            Double = 2
        }

        {
            qty: 10,
            min: 75,
            max: 99,
            name: 'Fuel Level',
            metricName: '%',
            hourlyDailyWeeklyMonthly: 1,
            randomSeriesBehaviour: 3,
            randomSeriesDataType: 1
        }
        [Route("FakeData/{qty}/{min}/{max}/{name}/{metricName}/{hourlyDailyWeeklyMonthly}/{randomSeriesBehaviour}/{randomSeriesDataType}")]

        */

        let tempDutyCycleChartData = [{
            "dutycycle": "abc123",
            "longHaul": 60,
            "shortHaul": 30,
            "city": 10
        }];

        

        let series =
            [
                { valueAxisName: 'longHaul', label: 'Long Haul', color: "#6B469C" },
                { valueAxisName: 'shortHaul', label: 'Short Haul', color: "#BE5928" },
                { valueAxisName: 'city', label: 'City', color: "#0092CD" }
            ];

        let chartProperties = {
            useCursor: false,
            useBullets: true,
            useTooltip: false,
            orientation: 'horizontal',
            barThickness: 50,
            categoryAxisName: 'dutycycle',
            height: 300,
            hideLabels: true,
            valueAxisMinValue: 0,
            valueAxisMaxValue: 100
            // yAxisTitle: { text: "Avg. Miles Driven at Load", fontSize: "10px" },
            // xAxisTitle: { text: "LBS", fontSize: "10px" }
        };



        let chart = new StackedColumnChart({ chartData: tempDutyCycleChartData, chartContainer: 'dutycyclechartdiv', chartProperties: chartProperties });
        chart.Create(series);
        
        this.setState({ loading: false });


        this.fetchFuelEconChart();


        fetch('/api/Vehicle/FakeData/11/75/96/Safety/%25/3/1/1')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                this.setState({ safetyChartData: data }, () => this.populateSafetyChart(data));
            })
            .catch((error: Error) => {
                throw error
            });

        fetch('/api/Vehicle/FakeData/85/2/7/Usage/Days/2/3/1')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                this.setState({ usageChartData: data }, () => this.populateUsageChart(data));
            })
            .catch((error: Error) => {
                throw error
            });

        // fetch('/api/Fuel/OverallAverage/2021-06-01/2021-08-30')
        //     .then(response => {
        //         if (!response.ok) {
        //             throw new Error(response.statusText)
        //         }
        //         return response.json()
        //     })
        //     .then(data => {
        // 
        //         // calculate the overall average
        //         const sum = data.reduce(function (acc, obj) { return acc + obj.value; }, 0);
        //         let fuelEconAvg = (sum / data.length) || 0;
        // 
        //         fuelEconAvg = Math.round(fuelEconAvg * 10) / 10 // round to one decimal place
        // 
        //         this.populateReusableLineChart(data);
        //         this.populateFuelEconChart(data);
        //         this.setState({ fuelChartData: data, avgFuelEcon: fuelEconAvg });
        //     })
        //     .catch((error: Error) => {
        //         throw error
        //     });

        fetch('/api/Vehicle/AftertreatmentChartData')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                console.log(data);
                this.setState({ aftertreatmentChartData: data }, () => this.populateAftertreatmentChart(data));
            })
            .catch((error: Error) => {
                throw error
            });



        fetch('api/Fuel/ContributingFactorsFuelUse/2021-07-01/2021-07-14/b6D8A/1')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                this.populateFuelUsage(data)
            })
            .catch((error: Error) => {
                throw error
            });

        this.loadFactorsChart()
            .then(([factors, totals]) => {
                // both have loaded!
                //console.log(factors, this.state.totalFuelWastedNum);
                this.populateContributingFactors(factors);
            })

    }

    public getFuelEconFactorsTotals() {
        return fetch('api/Fuel/FuelEconFactorsTotals/TOTAL')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {

                this.setState({
                    totalFuelUsedInPeriod: data[0].value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) + ' gal',
                    totalExpectedFuelUsedInPeriod: data[1].value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) + ' gal',
                    totalFuelWasted: data[2].value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) + ' gal',
                    totalFuelWastedString: data[2].value.toString()
                }, () => this.render())
            })
            .catch((error: Error) => {
                throw error
            });
    }

    public getContributingFactors() {
        return fetch('api/Fuel/ContributingFactors/TOTAL')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .catch((error: Error) => {
                throw error
            });
    }




    public fetchFleetHealthScore() {
        fetch('api/Vehicle/FleetHealthScore')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {


                this.setState(
                    {
                        fleetHealthScore: data,
                    },
                    () => this.fetchFleetHealthScoreChart(data));
                ;


            })
            .catch((error: Error) => {
                throw error
            });
    }

    public fetchFleetHealthScoreChart(finalValue: number) {
        let start: number = finalValue - 1;
        let minimum: number = finalValue - 5;
        let maximum: number = finalValue + 5;

        fetch('/api/Vehicle/FakeData/10/' + minimum + '/' + maximum + '/Health/%25/3/3/1')  // 10: items, 3: weekly, 3: fluctuating, 1: integers
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                this.setState({ healthChartData: data }, () => this.populateHealtChart(data));

            })
            .catch((error: Error) => {
                throw error
            });
    }


    public fetchFuelEconChart() {
        let fuelEconAvg: number = 7.1;
        let start: number = fuelEconAvg - 1;
        let minimum: number = fuelEconAvg - 0.5;
        let maximum: number = fuelEconAvg + 0.5;

        fetch('/api/Vehicle/FakeData/10/' + minimum + '/' + maximum + '/FuelEcon/mpg/3/3/2')  // 10: items, 3: weekly, 3: fluctuating, 2: double
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {

                
                this.setState({ fuelChartData: data, avgFuelEcon: fuelEconAvg },
                    () => this.populateFuelEconChart(data)
                );


                //this.setState({ healthChartData: data }, () => this.populateFuelEconChart(data));

            })
            .catch((error: Error) => {
                throw error
            });
    }

    public loadFactorsChart() {
        return Promise.all([this.getContributingFactors(), this.getFuelEconFactorsTotals()])
    }

    public populateHealtChart(json: any) {

        //const healthDataLength = json.length;

        console.log('populateHealtChart');
        //const HealthValue = this.state.fleetHealthScore + '%';

        let initialDate: Date = new Date(json[0].dateTimeUtc);
        initialDate.setHours(initialDate.getHours() - 7 * 24)  // one week prior


        let finalDate: Date = new Date(json[json.length-1].dateTimeUtc);
        finalDate.setHours(finalDate.getHours() + 7*24)      // and one week after

        let finalDatapoint: any = { name: 'Health', metricName: '%', value: this.state.fleetHealthScore, dateTimeUtc: finalDate };
        let initialDatapoint: any = { name: 'Health', metricName: '%', value: this.state.fleetHealthScore - 5, dateTimeUtc: initialDate };

        // console.log(initialDatapoint);
        // console.log(finalDatapoint);
        // console.log(json);


        json.unshift(initialDatapoint);
        json.push(finalDatapoint);
        console.log(json);

        const FuelValue = "7.9";

        const SafetyValue = "96%";
        const UsageValue = "4.2";

        //(document.getElementById('lblHealthValue') as HTMLSpanElement).innerText = HealthValue;
        // (document.getElementById('lblFuelValue') as HTMLSpanElement).innerText = FuelValue;
        (document.getElementById('lblSafetyValue') as HTMLSpanElement).innerText = SafetyValue;
        (document.getElementById('lblUsageValue') as HTMLSpanElement).innerText = UsageValue;

        var chart = new SparkLineChart({ chartData: json, chartContainer: 'healthchartdiv', strokeColor: '#1EA080', bulletColor: '#000000', minGridDistance: 25 });
        chart.Create('Health');
        this.setState({ healthChart: chart });
    }

    public populateSafetyChart(json: any) {
        var chart = new ColumnChart({ chartData: json, chartContainer: 'safetychartdiv', orientation: 'vertical', useCursor: false, hideValueAxisLabels: true, columnColour: "purple", roundedTopCorners: true, showGrid: true, columnWidth: 30, minGridDistance: 25 });
        this.setState({ safetyChart: chart });
        chart.Create();
    }

    public populateUsageChart(json: any) {
        var chart = new ColumnChart({ chartData: json, chartContainer: 'usagechartdiv', orientation: 'vertical', useCursor: false, hideValueAxisLabels: true, columnColour: "black", roundedTopCorners: false, showGrid: false, columnWidth:90, toolTipFormat:"X:Y" });
        chart.Create();
        this.setState({ usageChart: chart });
    }

    public populateFuelEconChart(json: any) {

        console.log('populateFuelEconChart');


        let initialDate: Date = new Date(json[0].dateTimeUtc);
        initialDate.setHours(initialDate.getHours() - 7 * 24)  // one week prior


        let finalDate: Date = new Date(json[json.length - 1].dateTimeUtc);
        finalDate.setHours(finalDate.getHours() + 7 * 24)      // and one week after

        let finalDatapoint: any = { name: 'FuelEcon', metricName: 'mpg', value: this.state.avgFuelEcon, dateTimeUtc: finalDate };
        let initialDatapoint: any = { name: 'FuelEcon', metricName: 'mpg', value: this.state.avgFuelEcon - 0.5, dateTimeUtc: initialDate };


        json.unshift(initialDatapoint);
        json.push(finalDatapoint);

        console.log(json);


        this.populateReusableLineChart(json);



        var chart = new SparkLineChart({ chartData: json, chartContainer: 'fuelchartdiv', strokeColor: '#1EA080', bulletColor: '#000000', minGridDistance: 25 });
        chart.Create('Fuel');
        this.setState({ fuelChart: chart });
    }
    public populateAftertreatmentChart(json: any) {
        var chart = new SparkLineChart({ chartData: json, chartContainer: 'aftertreatmentchartdiv', strokeColor: '#1EA080', bulletColor: '#000000', minGridDistance: 20 });
        chart.Create('aftertreatment');
        this.setState({ aftertreatmentChart: chart });
    }
    public populateReusableLineChart(json: any) {
        var chart = new LineChart({ useCursor: true, chartData: json, chartContainer: 'reusablechartdiv', strokeColor: '#1EA080', bulletColor: '#1D181D'});
        console.log("line chart data");
        //console.log(json);
        chart.Create("", "MPG", "0.0", 3, 10,null,true);
        this.setState({ reusableLineChart: chart });
    }

    public populateContributingFactors(json: any) {

        var fuelString = this.state.totalFuelWastedString;
        var fuelNum = parseFloat(fuelString)

        if (fuelNum !== 0) {
            json.forEach(function (obj) {
                let percent = parseInt(obj.value) / fuelNum * 100;
                obj.name += " (" + percent.toLocaleString(navigator.language, { maximumFractionDigits: 0 }) + "%)";
                //console.log(obj.name);
            });
        }

        var chart = new DivergentStackBarChart({
            chartData: json,
            chartContainer: "contributingFactorschartdiv",
            showLegend: false,
            orientation: "vertical",
            colorNegativeValues: "#3C6EB1", // #3C6EB1(blue) is used for negative values as it indicates fuel preserved from savvy driving practices.
            colorPositiveValues: "#A4282A", // #A4282A (red) is used for positive values because it indicates fuel wasted (a net loss for Aptiv)
            hideValueAxisLabels: true,
            hideCategoryAxisLabels: false,
            hideGridLines: true,
            useCursor: true,
            title: "+/- Predicted gal",
            valueAxisTitle: "",
            showSavedWasted: true
        });
        chart.Create();
        this.setState({ contributingFactorsChart: chart });
    }
    public populateFuelUsage(json: any) {

        //console.log("fuel usage data");
        //console.log(json);
        //console.log(json.length);
        //console.log(json[0]);

    }

    componentWillUnmount() {

        // TODO: fix this -> dispose is not a property of healthChart
        // if (this.state.healthChart)
        //     this.state.healthChart.dispose();
        // if (this.state.safetyChart)
        //     this.state.safetyChart.dispose();
        // if (this.state.fuelChart)
        //     this.state.fuelChart.dispose();
        // if (this.state.usageChart)
        //     this.state.usageChart.dispose();

    }



    render() {


        //this gives an error....have to leave it hard coded for now even though the random numbers are loading from the api
        //console.log(this.state.fuelUsageData[0].value);


        return (
            <React.Fragment>
                <DropDownFilterBar />
                <Row className="mb-0 ml-2 mr-2 ">
                    <Col xs="5" className="pl-0 pr-2 mb-3 pb-0 overview-highlight-col" >
                        <Row style={{ height: "100%" }}>
                            <Col className="pl-0 pr-2 mb-0" style={{ height: "100%" }}>
                                <Card className="card-highlight" >
                                    <CardBody>
                                        <CardTitle tag="h5">
                                            <Link to="/health-general" className="text-white text-link">Health</Link> <GoTriangleRight className="text-white mt-0 p-0 mb-1" fontSize="18" />

                                            <div style={{ float: "right", marginRight: "30px" }}>
                                                <small>+5%</small>
                                            </div>

                                        </CardTitle>

                                        <CardText tag="div">
                                            <h5>{this.state.fleetHealthScore}% <small style={{ fontSize: "0.8vw" }}>Average Total Health</small></h5>
                                            <div id="healthchartdiv" style={{ height: "80px" }}></div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="pl-0 mb-0 pr-3" style={{ height: "100%" }}>
                                <Card className="card-highlight">
                                    <CardBody>
                                        <CardTitle tag="h5">
                                            <Link to="./fuel" className="text-white text-link">Fuel</Link> <GoTriangleRight className="text-white mt-0 p-0 mb-1" fontSize="18" />
                                            <div style={{ float: "right", marginRight: "30px" }}>
                                                <small>+0.5</small>
                                            </div>
                                        </CardTitle>
                                        <CardText tag="div">
                                            <h5>{ this.state.avgFuelEcon } <small style={{ fontSize: "0.8vw" }}>Average MPG</small></h5>
                                            <div id="fuelchartdiv" style={{ height: "80px" }}></div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>
                     </Col>
                    <Col xs="7" className="pl-2 pr-0 mb-3 pb-0 overview-highlight-col">
                        <Row style={{ height: "100%" }}>
                            <Col className="pl-2 pr-2 mb-0" style={{ height: "100%" }}>
                                <Card className="card-highlight">
                                    <CardBody>
                                        <CardTitle tag="h5">
                                            <Link to="./fleet-safety" className="text-white text-link">Safety</Link> <GoTriangleRight className="text-white mt-0 p-0 mb-1" fontSize="18" />
                                            <div style={{ float: "right", marginRight: "30px" }}>
                                                <small>+17%</small>
                                            </div>
                                        </CardTitle>
                                        <CardText tag="div">
                                            <div className="card-text-container"><div className="card-text-value" id="lblSafetyValue"/></div>
                                            <div id="safetychartdiv" style={{ height: "80px" }}></div>
                                        </CardText>
                                    </CardBody>
                                </Card>

                            </Col>

                            <Col className="pl-0 pr-2 mb-0" style={{ height: "100%" }}>
                                <Card className="card-highlight">
                                    <CardBody>
                                        <CardTitle tag="h5">
                                            <Link to="./asset-utlization" className="text-white text-link">Usage</Link> <GoTriangleRight className="text-white mt-0 p-0 mb-1" fontSize="18" />
                                            <div style={{ float: "right", marginRight: "30px" }}>
                                                <small>+0.3</small>
                                            </div>
                                        </CardTitle>
                                        <CardText tag="div">
                                            <div className="card-text-container"><div className="card-text-value" id="lblUsageValue" /><div className="card-text-title">Average Days<br />Out of Service</div></div>
                                            <div id="usagechartdiv" style={{ height: "80px" }}></div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col className="pl-0 pr-1 mb-0" style={{ height: "100%" }}>
                                <Card className="card-highlight">
                                    <CardBody>
                                        <CardTitle tag="h5">
                                            <Link to="./duty-cycle" className="text-white text-link">Duty Cycle</Link> <GoTriangleRight className="text-white mt-0 p-0 mb-1" fontSize="18" />
                                        </CardTitle>
                                        <CardText tag="div">
                                            <div id="dutycyclechartdiv" className="mt-0" style={{ height: "100px" }}></div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>


                        </Row>
                    </Col>

                 </Row>


                <Row className="mt-0 mb-0  ml-2 mr-2">
                    <Col xs="5" className="pl-0 pr-2 mb-0 pb-0 pt-0 mt-0">
                        <Row>
                            <Col className="pl-0 pr-2 mb-0 mt-0">
                                <Card className="card-secondary">
                                    <CardBody>
                                        <CardTitle tag="h5" className="font-weight-bold">Vehicle Diagnostics</CardTitle>
                                        <CardText tag="div">
                                            <Row>
                                                <Col xs="1"><MdCancel className="MdCancel mt-0 ml-0" fontSize="20" /></Col>
                                                <Col>CRITICAL</Col>
                                                <Col style={{ textAlign: "right" }}><strong>4</strong></Col>
                                            </Row>
                                            <Row>
                                                <Col xs="1"><RiErrorWarningFill className="RiErrorWarningFill mt-0 ml-0" fontSize="20" /></Col>
                                                <Col>WARNING</Col>
                                                <Col style={{ textAlign: "right" }}><strong>23</strong></Col>
                                            </Row>
                                            <Row className="pb-3 border-bottom">
                                                <Col xs="1"><AiFillCheckCircle className="AiFillCheckCircle mt-0 ml-0" fontSize="20" /></Col>
                                                <Col >READY</Col>
                                                <Col style={{ textAlign: "right"}}><strong>219</strong></Col>
                                            </Row>
                                            <Row>
                                                <Col xs="1"></Col>
                                                <Col>TOTAL</Col>
                                                <Col style={{ textAlign: "right" }}><strong>253</strong></Col>
                                            </Row>

                                        </CardText>
                                        <CardTitle tag="h5" className="font-weight-bold">
                                            Emission System Health
                                            <div style={{ float: "right", marginRight: "10px" }}>
                                                <small>+9%</small>
                                            </div>

                                        </CardTitle>
                                        <CardText tag="div">
                                            <div className="card-text-container"><div className="card-text-value grey"> 90%</div><div className="card-text-title">Average<br /> Per Vehicle</div></div>
                                            <div id="aftertreatmentchartdiv" style={{ height: "100%" }}></div>

                                        </CardText>


                                    </CardBody>

                                </Card>
                            </Col>
                            <Col className="pl-0 mb-0">
                                <Card className="card-secondary">
                                    <CardBody>
                                        <CardTitle tag="h5" className="font-weight-bold">Factors</CardTitle>
                                        <CardText tag="div">
                                            <div id="contributingFactorschartdiv" style={{ height: "300px" }} className="border-bottom border-default"></div>
                                            <div style={{ marginLeft: 5, marginRight: 5, marginTop: 8 }}>
                                                <Row>
                                                    <Col xs="2" md="5" lg="6">
                                                        <div>
                                                            <h6><small>Sub-Total Vol:</small></h6>
                                                        </div>

                                                    </Col>
                                                    <Col xs="2" md="5" lg="6">
                                                        <div className="float-left">
                                                            <h6><small>{this.state.totalFuelUsedInPeriod}</small></h6>
                                                        </div>

                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col xs="2" md="4" lg="6">
                                                        <h6><small>Expected Vol:</small></h6>
                                                    </Col>
                                                    <Col xs="2" md="4" lg="6">
                                                        <div className="float-left">
                                                            <h6><small>{ this.state.totalExpectedFuelUsedInPeriod }</small></h6>
                                                        </div>

                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col xs="2" md="4" lg="6">
                                                        <h6><small>Wasted Vol:</small></h6>
                                                    </Col>
                                                    <Col xs="2" md="4" lg="6">
                                                        <div className="float-left">
                                                            <h6><small>{ this.state.totalFuelWasted }</small></h6>
                                                        </div>

                                                    </Col>

                                                </Row>
                                            </div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>


                    </Col>
                    <Col xs="7" className="pl-2 pr-0 mb-0 pb-0">
                        <Row>
                            <Col className="pl-2 pr-1 mb-0">

                                <Card className="card-secondary">
                                    <CardBody>
                                        <CardTitle tag="h5">
                                            <span style={{ float: "left" }} className="font-weight-bold">Select Trend to View</span>

                                            <div style={{ float: "left", marginLeft: "15px", backgroundColor: "#E0DDD6", paddingRight: "20px", paddingTop: "0", marginTop: "0", width:"60%" }}>
                                                <InovexDropDown dropDownId="dropDownTrend"
                                                    dropDownToggleId="dropdownTrendToggleID"
                                                    initialValue="Fuel Economy"
                                                    onValueSelected={(trendValue: any) => this.changeTrendValue(trendValue)}
                                                    dropDownStyle={{ padding: "0.1rem 0.7rem", width: "100%" }}
                                                    omitSelected={false} >
                                                    <span>Fuel Economy</span>
                                                    <span>Fleet Health</span>
                                                    <span>Usage</span>
                                                    <span>Aftertreatment</span>
                                                    <span>Duty Cycle</span>
                                                </InovexDropDown>

                                            </div>
                                        </CardTitle>
                                        <CardText tag="div">
                                            <div id="reusablechartdiv" style={{ width: "100%", height: "439px" }}>chart will be here</div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>

                    </Col>

                </Row>



            </React.Fragment>
        );
    }
}
