import * as React from 'react';
import { Link } from 'react-router-dom';
import '../../DataTable/Shared/styles.css'


export default (props) => {

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    let text = '';
    let output;
    let outputDiv;
    // console.log('fuelTotalTractiveNumberCellRenderer: ' + props.colDef.field);
    // console.log(props);
    if (props.value !== undefined) {
        // props.value.decimalPoints = 1; //props.value.decimalPoints === undefined ? 1 : props.value.decimalPoints;
        text = numberWithCommas(props.value.total.toFixed(1))

        
        if (props.value.isHeader) {
            output = <div className="pt-3 dt-vals-total" style={{  }} ><strong>{text}</strong></div>
        } else {
            output = <div className="dt-vals-total" style={{ marginTop: '0px' }}>{text}</div>
        }


        if (props.colDef.field === "mileage" || props.colDef.field === "fuelConsumed") {
            outputDiv = <div style={{ textAlign: "left" }} >{output}</div>
        }
        else {
            outputDiv = <div style={{ textAlign: "left" }} >{output}</div>
        }
    }

    return (
        <React.Fragment>
            {outputDiv}
        </React.Fragment>
    );
};