import * as React from 'react';
import { Row, Col, Spinner, Breadcrumb, BreadcrumbItem, Card, CardBody, CardTitle, CardText, CardDeck, ButtonGroup, Button } from 'reactstrap';

import { DropDownFilterBar } from '../../DropDownFilterBar'
import { Link } from 'react-router-dom';
import { DataTable } from '../../DataTable/FuelTotalTractive';
import { TotalAndTractiveFuelEconomySubnav } from '../TotalAndTractiveSubNav'
import { InovexDropDown } from '../../InovexDropDown';
import {
    AreaChartWithTrend

} from '../../Charts/';

interface IDashboardProps {

}

interface IDashboardState {
    totalFuelUsedInPeriod: string;
    totalExpectedFuelUsedInPeriod: string;
    totalFuelWasted: string;
    fleetRowData: any,     // 'any' becauase the structure of the objects in teh array will vary
    trendRowData: any,
    columns: Array<{ columnName: string, columnHeader: string, width: number }>
    reusableAreaChart: AreaChartWithTrend | null;
}

export class FuelTotalAndTractive extends React.Component<IDashboardProps, IDashboardState> {
    constructor(props: IDashboardProps) {
        super(props);
        this.state = {
            totalFuelUsedInPeriod: '',
            totalExpectedFuelUsedInPeriod: '',
            totalFuelWasted: '',
            fleetRowData: [],
            trendRowData: [],
            columns: [],
            reusableAreaChart: null,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {

        /*
        public enum HourlyDailyWeeklyMonthly {
            Hourly = 1,
            Daily = 2,
            Weekly = 3,
            Monthly = 4
        }

        public enum RandomSeriesBehaviour {
            Rising = 1,
            Falling = 2,
            Fluctuating = 3
        }

        public enum RandomSeriesDataType {
            Integer = 1,
            Double = 2
        }

        {
            qty: 10,
            min: 75,
            max: 99,
            name: 'Fuel Level',
            metricName: '%',
            hourlyDailyWeeklyMonthly: 1,
            randomSeriesBehaviour: 3,
            randomSeriesDataType: 1
        }
        [Route("FakeData/{qty}/{min}/{max}/{name}/{metricName}/{hourlyDailyWeeklyMonthly}/{randomSeriesBehaviour}/{randomSeriesDataType}")]
        */
        

        const fleetRowData = [
            {
                fleet: { name: "TOTAL", isHeader: true },
                mileage: { total: 2105500, isHeader: true, decimalPoints: 0 },
                fuelConsumed: { total: 650500, isHeader: true, decimalPoints: 0   },
                totalFuelEconomy: { total: 3.4, max: 5, barColor: "Black", isHeader: true, decimalPoints: 1   },
                tractiveFuel: { total: 2.25, isHeader: true, decimalPoints: 2   },
                idleFuel: { total: 18000, isHeader: true, decimalPoints: 0   },
                ptoFuel: { total: 29000, isHeader: true, decimalPoints: 0   },
                dutyCycle: {
                    percents: [
                        {
                            percent: 20,
                            color: "Purple"
                        },
                        {
                            percent: 42,
                            color: "Orange"
                        },
                        {
                            percent: 38,
                            color: "LightBlue"
                        }
                    ],
                    isStacked: true,
                    isDivergent: false,
                    isBar: true
                }
            },
            {
                fleet: { name: "Fleet 1", isHeader: false },
                mileage: { total: 550000, isHeader: false, decimalPoints: 0  },
                fuelConsumed: { total: 28000, isHeader: false, decimalPoints: 0  },
                totalFuelEconomy: { total: 3.0, max: 5, barColor: "Black", isHeader: false, decimalPoints: 1  },
                tractiveFuel: { total: 2.0, isHeader: false, decimalPoints: 2  },
                idleFuel: { total: 5000, isHeader: false, decimalPoints: 0  },
                ptoFuel: { total: 4000, isHeader: false, decimalPoints: 0  },
                dutyCycle: {
                    percents: [
                        {
                            percent: 28,
                            color: "Purple"
                        },
                        {
                            percent: 42,
                            color: "Orange"
                        },
                        {
                            percent: 30,
                            color: "LightBlue"
                        }
                    ],
                    isStacked: true,
                    isDivergent: false,
                    isBar: true
                }
            },
            {
                fleet: { name: "Fleet 2", isHeader: false },
                mileage: { total: 450000, isHeader: false, decimalPoints: 0  },
                fuelConsumed: { total: 27000, isHeader: false, decimalPoints: 0  },
                totalFuelEconomy: { total: 3.2, max: 5, barColor: "Black", isHeader: false, decimalPoints: 1  },
                tractiveFuel: { total: 2.35, isHeader: false, decimalPoints: 2  },
                idleFuel: { total: 1200, isHeader: false, decimalPoints: 0  },
                ptoFuel: { total: 6000, isHeader: false, decimalPoints: 0  },
                dutyCycle: {
                    percents: [
                        {
                            percent: 25,
                            color: "Purple"
                        },
                        {
                            percent: 40,
                            color: "Orange"
                        },
                        {
                            percent: 35,
                            color: "LightBlue"
                        }
                    ],
                    isStacked: true,
                    isDivergent: false,
                    isBar: true
                }
            },
            {
                fleet: { name: "Fleet 3", isHeader: false },
                mileage: { total: 449000, isHeader: false, decimalPoints: 0  },
                fuelConsumed: { total: 27800, isHeader: false, decimalPoints: 0  },
                totalFuelEconomy: { total: 5, max: 5, barColor: "Black", isHeader: false, decimalPoints: 1  },
                tractiveFuel: { total: 3.1, isHeader: false, decimalPoints: 2  },
                idleFuel: { total: 1100, isHeader: false, decimalPoints: 0  },
                ptoFuel: { total: 6200, isHeader: false, decimalPoints: 0  },
                dutyCycle: {
                    percents: [
                        {
                            percent: 36,
                            color: "Purple"
                        },
                        {
                            percent: 34,
                            color: "Orange"
                        },
                        {
                            percent: 30,
                            color: "LightBlue"
                        }
                    ],
                    isStacked: true,
                    isDivergent: false,
                    isBar: true
                }
            },
            {
                fleet: { name: "Fleet 4", isHeader: false },
                mileage: { total: 440000, isHeader: false, decimalPoints: 0 },
                fuelConsumed: { total: 27000, isHeader: false, decimalPoints: 0 },
                totalFuelEconomy: { total: 3.7, max: 5, barColor: "Black", isHeader: false, decimalPoints: 1 },
                tractiveFuel: { total: 1.2, isHeader: false, decimalPoints: 2 },
                idleFuel: { total: 3300, isHeader: false, decimalPoints: 0 },
                ptoFuel: { total: 5300, isHeader: false, decimalPoints: 0 },
                dutyCycle: {
                    percents: [
                        {
                            percent: 48,
                            color: "Purple"
                        },
                        {
                            percent: 32,
                            color: "Orange"
                        },
                        {
                            percent: 20,
                            color: "LightBlue"
                        }
                    ],
                    isStacked: true,
                    isDivergent: false,
                    isBar: true
                }
            },
            {
                fleet: { name: "Fleet 5", isHeader: false },
                mileage: { total: 390000, isHeader: false, decimalPoints: 0 },
                fuelConsumed: { total: 26000, isHeader: false, decimalPoints: 0 },
                totalFuelEconomy: { total: 2.0, max: 5, barColor: "Black", isHeader: false, decimalPoints: 1 },
                tractiveFuel: { total: 1.36, isHeader: false, decimalPoints: 2 },
                idleFuel: { total: 3110, isHeader: false, decimalPoints: 0 },
                ptoFuel: { total: 5100, isHeader: false, decimalPoints: 0 },
                dutyCycle: {
                    percents: [
                        {
                            percent: 25,
                            color: "Purple"
                        },
                        {
                            percent: 50,
                            color: "Orange"
                        },
                        {
                            percent: 25,
                            color: "LightBlue"
                        }
                    ],
                    isStacked: true,
                    isDivergent: false,
                    isBar: true
                }
            },
            {
                fleet: { name: "Fleet 6", isHeader: false },
                mileage: { total: 385000, isHeader: false, decimalPoints: 0 },
                fuelConsumed: { total: 24000, isHeader: false, decimalPoints: 0 },
                totalFuelEconomy: { total: 2.6, max: 5, barColor: "Black", isHeader: false, decimalPoints: 1 },
                tractiveFuel: { total: 2.6, isHeader: false, decimalPoints: 2 },
                idleFuel: { total: 3100, isHeader: false, decimalPoints: 0 },
                ptoFuel: { total: 3400, isHeader: false, decimalPoints: 0 },
                dutyCycle: {
                    percents: [
                        {
                            percent: 32,
                            color: "Purple"
                        },
                        {
                            percent: 22,
                            color: "Orange"
                        },
                        {
                            percent: 46,
                            color: "LightBlue"
                        }
                    ],
                    isStacked: true,
                    isDivergent: false,
                    isBar: true
                }
            },
            {
                fleet: { name: "Fleet 7", isHeader: false },
                mileage: { total: 300000, isHeader: false, decimalPoints: 0 },
                fuelConsumed: { total: 18000, isHeader: false, decimalPoints: 0 },
                totalFuelEconomy: { total: 2.4, max: 5, barColor: "Black", isHeader: false, decimalPoints: 1 },
                tractiveFuel: { total: 1.2, isHeader: false, decimalPoints: 2 },
                idleFuel: { total: 2000, isHeader: false, decimalPoints: 0 },
                ptoFuel: { total: 8000, isHeader: false, decimalPoints: 0 },
                dutyCycle: {
                    percents: [
                        {
                            percent: 18,
                            color: "Purple"
                        },
                        {
                            percent: 52,
                            color: "Orange"
                        },
                        {
                            percent: 30,
                            color: "LightBlue"
                        }
                    ],
                    isStacked: true,
                    isDivergent: false,
                    isBar: true
                }
            },
            {
                fleet: { name: "Fleet 8", isHeader: false },
                mileage: { total: 290000, isHeader: false, decimalPoints: 0 },
                fuelConsumed: { total: 17500, isHeader: false, decimalPoints: 0 },
                totalFuelEconomy: { total: 3.3, max: 5, barColor: "Black", isHeader: false, decimalPoints: 1 },
                tractiveFuel: { total: 2.4, isHeader: false, decimalPoints: 2 },
                idleFuel: { total: 1200, isHeader: false, decimalPoints: 0 },
                ptoFuel: { total: 2050, isHeader: false, decimalPoints: 0 },
                dutyCycle: {
                    percents: [
                        {
                            percent: 12,
                            color: "Purple"
                        },
                        {
                            percent: 42,
                            color: "Orange"
                        },
                        {
                            percent: 46,
                            color: "LightBlue"
                        }
                    ],
                    isStacked: true,
                    isDivergent: false,
                    isBar: true
                }
            },
            {
                fleet: { name: "Fleet 9", isHeader: false },
                mileage: { total: 274000, isHeader: false, decimalPoints: 0 },
                fuelConsumed: { total: 16000, isHeader: false, decimalPoints: 0 },
                totalFuelEconomy: { total: 3.6, max: 5, barColor: "Black", isHeader: false, decimalPoints: 1 },
                tractiveFuel: { total: 2.39, isHeader: false, decimalPoints: 2 },
                idleFuel: { total: 2300, isHeader: false, decimalPoints: 0 },
                ptoFuel: { total: 5450, isHeader: false, decimalPoints: 0 },
                dutyCycle: {
                    percents: [
                        {
                            percent: 22,
                            color: "Purple"
                        },
                        {
                            percent: 38,
                            color: "Orange"
                        },
                        {
                            percent: 40,
                            color: "LightBlue"
                        }
                    ],
                    isStacked: true,
                    isDivergent: false,
                    isBar: true
                }
            },
            {
                fleet: { name: "Fleet 10", isHeader: false },
                mileage: { total: 273900, isHeader: false, decimalPoints: 0 },
                fuelConsumed: { total: 15500, isHeader: false, decimalPoints: 0 },
                totalFuelEconomy: { total: 2.9, max: 5, barColor: "Black", isHeader: false, decimalPoints: 1 },
                tractiveFuel: { total: 2.56, isHeader: false, decimalPoints: 2 },
                idleFuel: { total: 2310, isHeader: false, decimalPoints: 0 },
                ptoFuel: { total: 5600, isHeader: false, decimalPoints: 0 },
                dutyCycle: {
                    percents: [
                        {
                            percent: 14,
                            color: "Purple"
                        },
                        {
                            percent: 24,
                            color: "Orange"
                        },
                        {
                            percent: 62,
                            color: "LightBlue"
                        }
                    ],
                    isStacked: true,
                    isDivergent: false,
                    isBar: true
                }
            },
            {
                fleet: { name: "Fleet 11", isHeader: false },
                mileage: { total: 272610, isHeader: false, decimalPoints: 0 },
                fuelConsumed: { total: 14726, isHeader: false, decimalPoints: 0 },
                totalFuelEconomy: { total: 2.8, max: 5, barColor: "Black", isHeader: false, decimalPoints: 1 },
                tractiveFuel: { total: 2.91, isHeader: false, decimalPoints: 2 },
                idleFuel: { total: 2800, isHeader: false, decimalPoints: 0 },
                ptoFuel: { total: 7200, isHeader: false, decimalPoints: 0 },
                dutyCycle: {
                    percents: [
                        {
                            percent: 44,
                            color: "Purple"
                        },
                        {
                            percent: 40,
                            color: "Orange"
                        },
                        {
                            percent: 16,
                            color: "LightBlue"
                        }
                    ],
                    isStacked: true,
                    isDivergent: false,
                    isBar: true
                }
            },
        ];

        const columns = [
            { columnName: 'fleet', columnHeader: 'Fleet', width: 220},
            { columnName: 'mileage', columnHeader: 'Mileage (mi)', width: 150 },
            { columnName: 'fuelConsumed', columnHeader: 'Fuel Cons. (gal)', width: 150 },
            { columnName: 'overallFuelEconomy', columnHeader: 'Total Fuel Economy (mpg)', width: 250 },
            { columnName: 'tractiveFuelEconomy', columnHeader: 'Tractive Fuel (mpg)', width: 130 },
            { columnName: 'idleFuel', columnHeader: 'Idle Fuel (gal)', width: 130 },
            { columnName: 'ptoFuel', columnHeader: 'PTO Fuel (gal)', width: 130 },
            { columnName: 'dutyCycle', columnHeader: 'Duty Cycle', width: 300 }
        ];

        
        fetch('/api/Fuel/FuelEconTractiveDataTable/2021-06-01/2021-06-30')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                this.setState({ fleetRowData: data }, () => this.render());

            })
            .catch((error: Error) => {
                throw error
            });

        const trendData = [
            {
                "name": "Health",
                "metricName": "%",
                "value": 406437,
                "dateTimeUtc": "2021-09-30T17:45:48.6003509-04:00",
                "companyValue": 478229,
                "trendValue": 300000
            },
            {
                "name": "Health",
                "metricName": "%",
                "value": 376831,
                "dateTimeUtc": "2021-10-01T17:45:48.6003594-04:00",
                "companyValue": 324229,
                "trendValue": 300000

            },
            {
                "name": "Health",
                "metricName": "%",
                "value": 369456,
                "dateTimeUtc": "2021-10-02T17:45:48.6003598-04:00",
                "companyValue": 370589,
                "trendValue": 300000
            },
            {
                "name": "Health",
                "metricName": "%",
                "value": 441077,
                "dateTimeUtc": "2021-10-03T17:45:48.6003601-04:00",
                "companyValue": 528964,
                "trendValue": 300000
            },
            {
                "name": "Health",
                "metricName": "%",
                "value": 508083,
                "dateTimeUtc": "2021-10-04T17:45:48.6003604-04:00",
                "companyValue": 325782,
                "trendValue": 300000
            },
            {
                "name": "Health",
                "metricName": "%",
                "value": 152259,
                "dateTimeUtc": "2021-10-05T17:45:48.6003621-04:00",
                "companyValue": 325875,
                "trendValue": 300000
            },
            {
                "name": "Health",
                "metricName": "%",
                "value": 308472,
                "dateTimeUtc": "2021-10-06T17:45:48.6003624-04:00",
                "companyValue": 392458,
                "trendValue": 300000
            }
            ,
            {
                "name": "Health",
                "metricName": "%",
                "value": 413208,
                "dateTimeUtc": "2021-10-07T17:45:48.6003624-04:00",
                "companyValue": 297166,
                "trendValue": 300000
            }
            ,
            {
                "name": "Health",
                "metricName": "%",
                "value": 398284,
                "dateTimeUtc": "2021-10-08T17:45:48.6003624-04:00",
                "companyValue": 392458,
                "trendValue": 300000
            },
            {
                "name": "Health",
                "metricName": "%",
                "value": 318526,
                "dateTimeUtc": "2021-10-09T17:45:48.6003624-04:00",
                "companyValue": 438458,
                "trendValue": 300000
            }
        ]

        
        this.populateTrendsChart(trendData);

        this.setState(
            {
                // fleetRowData: fleetRowData,  // fulling from the API now
                columns: columns,
                trendRowData: trendData,
            }, this.render);

    }

    public populateTrendsChart(json: any) {
        var chart = new AreaChartWithTrend({ useCursor: true, chartData: json, chartContainer: 'reusablechartdiv', legendContainer: 'reusablechartlegenddiv' });
        chart.Create("", "MPG", "0,000", 0, 1000000, null, true, true);
        this.setState({ reusableAreaChart: chart });
    }

    public prevDefaultClick(event) {
        event.preventDefault();
    }
   
    render() {
        let datatable;
        if (this.state.fleetRowData && this.state.columns)
            datatable = <DataTable rowData={this.state.fleetRowData} columns={this.state.columns} />

        
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <nav className="dashboardTitle" aria-label="breadcrumb">
                            <div className="breadcrumb">
                                <Link className="breadcrumb-item" to="/fuel" >FUEL</Link>
                                <Link className="breadcrumb-item" to="/fuel-totaltractive" >Total & Tractive Fuel Economy</Link>
                                
                            </div>
                        </nav>
                    </Col>
                </Row>
                <DropDownFilterBar />
                <Row>
                    <Col>
                        <h2>Total & Tractive Economy</h2>
                    </Col>

                </Row>
                
                
                <Row className="pb-0 mb-0">
                    <Col xs="2" className="tile p-0 mb-0">
                        {/*<div className="text-white pb-0 mb-0 pl-2" style={{ height: '30px', width: '275px', backgroundColor: "#006B63" }}>*/}
                        <div className="text-white pb-0 mb-0 pl-2 dropdownFleetHealthGroupByToggleLabel">
                            <div className="mt-0 pt-0 pb-0" style={{ float: "left", width: "33%", lineHeight: "30px" }}>
                                GROUP BY
                            </div>
                            <div className="mt-1 mb-1" style={{ display: "inline-block", width: "1px", backgroundColor: "white", height: "70%", float: "left" }} />
                            <div className="mt-0 pt-0" style={{ float: "left", width: "66%", height: "100%" }}>
                                <InovexDropDown dropDownId="dropDownGroupBy"
                                    dropDownToggleId="dropdownFleetHealthGroupByToggleID"
                                    initialValue="VIN"
                                    //onValueSelected={(trendValue: any) => this.changeTrendValue(trendValue)}
                                    dropDownStyle={{ width: "100%" }}
                                    omitSelected={false} >
                                    <span>VIN</span>
                                    <span>Company</span>

                                </InovexDropDown>

                            </div>

                        </div>

                    </Col>


                </Row>
                <Row className="pt-0 mt-0">
                    <Col className='tile pr-0 pb-0 pt-0'>
                        {datatable}
                    </Col>
                </Row>

                <Row className="pb-0 mb-0" color="#00000">
                    
                    <Col xs="12" className="tile p-0 mb-0 pr-0 mr-0" color="#00000">
                        <div className="text-white pb-0 mb-0 pl-2" style={{ height: '50px', width: '100%', backgroundColor: "#000000", verticalAlign:"middle" }}>
                            <div className="mt-2 pt-0 pb-0 mr-2 ml-5" style={{ float: "left", verticalAlign:"middle" }}>
                                SHOW TREND FOR
                            </div>

                            <div className="mt-1 pt-0" style={{ float: "left", width: "225px" }}>
                                <InovexDropDown dropDownId="dropDownGroupBy"
                                    dropDownToggleId="dropdownFuelTotalTractiveFuelEconomyGroupByToggleID"
                                    initialValue="Mileage"
                                    //onValueSelected={(trendValue: any) => this.changeTrendValue(trendValue)}
                                    dropDownStyle={{ padding: "0.1rem 0.7rem", width: "100%" }}
                                    omitSelected={false} >
                                    <span>Mileage</span>
                                    <span>Fuel Consumption</span>
                                    <span>Total Fuel Economy</span>
                                    <span>Tractive Fuel</span>
                                    <span>Idle Fuel</span>
                                    <span>PTO Fuel</span>

                                </InovexDropDown>

                            </div>

                            <div className="mt-2 pt-0 pb-0 mr-2 ml-5" style={{ float: "left", verticalAlign: "middle" }}>
                                COMPARE WITH
                            </div>

                            <div className="mt-1 pt-0" style={{ float: "left", width: "225px" }}>
                                <InovexDropDown dropDownId="dropDownGroupBy"
                                    dropDownToggleId="dropdownFuelTotalTractiveFuelEconomyGroupByToggleID"
                                    initialValue="Fleet 1"
                                    //onValueSelected={(trendValue: any) => this.changeTrendValue(trendValue)}
                                    dropDownStyle={{ padding: "0.1rem 0.7rem", width: "100%" }}
                                    omitSelected={false} >
                                    <span>Fleet 1</span>
                                    <span>Fleet 2</span>
                                    <span>Fleet 3</span>
                                    <span>Fleet 4</span>
                                    <span>Fleet 5</span>
                                    <span>Fleet 6</span>
                                    <span>Fleet 7</span>
                                    <span>Fleet 8</span>
                                    <span>Fleet 9</span>
                                    <span>Fleet 10</span>
                                    <span>Fleet 11</span>
                                    

                                </InovexDropDown>

                            </div>
                        </div>


                    </Col>


                </Row>

                <Row className="pt-0 mt-0 pb-0 mb-0">
                    <Col xs="12" className='tile pr-0 pb-0 pt-0'>
                        <div id="reusablechartlegenddiv"/>
                        <div id="reusablechartdiv"/>
                            
                    </Col>
                </Row>

                <Row className="pb-0 mb-0 pt-0 mt-0" color="#00000">

                    <Col xs="12" className="p-0 mb-0 pr-0 mr-0 pt-0" color="#00000">
                        <div className="text-white pb-0 mb-0 pl-2 pt-0 mt-0" style={{ height: '30px', width: '100%', backgroundColor: "#000000", verticalAlign: "middle" }}>
                            <div className="mt-0 pt-0 pb-0 mr-10 ml-5" style={{ float: "left", verticalAlign: "middle" }}>
                                <span className="totalAndTractiveFuelEconomyLabel">SHOW DATE FOR</span>
                            </div>
                            <div className="mt-0 pt-0 pl-3 mb-0" style={{ float: "left", width: "225px", verticalAlign: "middle"}}>
                                {/*<TotalAndTractiveFuelEconomySubnav pageTitle="Show Data For" />*/}
                                <Link to="#" onClick={this.prevDefaultClick} className="totalAndTractiveFuelEconomyLink-selected">Week</Link>  |
                                <Link to="#" onClick={this.prevDefaultClick} className="totalAndTractiveFuelEconomyLink-unselected">Month</Link>  |
                                <Link to="#" onClick={this.prevDefaultClick} className="totalAndTractiveFuelEconomyLink-unselected">Year</Link>
                            </div>

                            {/*<div className="mt-0 pt-0" style={{ float: "left", width: "225px" }}>*/}
                            {/*    <ButtonGroup>*/}
                            {/*        <Button className="text-white bg-dark" style={{ height: "28px" }}>Week</Button>*/}
                            {/*        <Button className="text-white bg-dark" style={{ height: "28px" }}>Month</Button>*/}
                            {/*        <Button className="text-white bg-dark" style={{ height: "28px" }}>Year</Button>*/}
                            {/*    </ButtonGroup>*/}

                               
                            {/*</div>*/}
                            
                        </div>


                    </Col>


                </Row>

            </React.Fragment>
        );
    }
}
