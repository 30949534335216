import * as React from 'react';
import { Row, Col, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { DataTable } from '../../DataTable/Telemetry'
import { DropDownFilterBar } from '../../DropDownFilterBar'
import { Link } from 'react-router-dom';
import { SparkLineChart } from '../../Charts';

import { AiFillCheckCircle } from "react-icons/ai"; // good
import { MdCancel } from "react-icons/md"; //critical
import { RiErrorWarningFill } from "react-icons/ri"; //warning
import { InovexDropDown } from '../../InovexDropDown';
import { HealthSubnav } from '../SubNav'

interface IAftertreatmentProps {

}

interface IAftertreatmentState {
    rowData: any,
    columns: Array<{ columnName: string, columnHeader: string, width: number }>
    healthChart: SparkLineChart | null;
    milLampCount: number,
    engineLampCount: number,
    stopLampCount: number,
    normalCount: number,
    warningCount: number,
    criticalCount: number,
    filteredData: any,
    filteredExpression: string,
    isFiltered: boolean,
    aftertreatmentHealthScore: number;
}

export class Aftertreatment extends React.Component<IAftertreatmentProps, IAftertreatmentState> {

    constructor(props: IAftertreatmentProps) {
        super(props);

        this.state =
        {
            rowData: [],
            columns: [],
            healthChart: null,
            milLampCount: 0,
            engineLampCount: 0,
            stopLampCount: 0,
            aftertreatmentHealthScore: 90,
            normalCount: 0,
            warningCount: 0,
            criticalCount: 0,
            filteredData: [],
            isFiltered: false,
            filteredExpression: ""
        }
    }

    componentDidMount() {
        this.fetchData();
        this.fetchAftertreatmentHealth();
    }

    public fetchAftertreatmentHealth() {
        fetch('api/Vehicle/AftertreatmentHealth')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                const columnsWithLamps = [
                    { columnName: 'generalHealth', columnHeader: 'Total Mileage (mi)', width: 150 },
                    { columnName: 'stopEngineLamp', columnHeader: 'Stop Engine', width: 100 },
                    { columnName: 'checkEngineLamp', columnHeader: 'Check Engine', width: 100 },
                    { columnName: 'milLamp', columnHeader: 'MIL Lamp', width: 100 },
                    { columnName: 'dpfLamp', columnHeader: 'DPF Lamp', width: 100 },
                    { columnName: 'defLevel', columnHeader: 'DEF Level', width: 100 },
                    { columnName: 'dpfHealth', columnHeader: 'DPF Health', width: 110 },
                    { columnName: 'fuelLevel', columnHeader: 'Fuel Level', width: 100 },
                    { columnName: 'dpfHealthSoot', columnHeader: 'Soot Level %', width: 100 }
                    //{ columnName: 'defLevel', columnHeader: 'DEF Level', width: 110 },
                ];

                var result = data.reduce((a, c) => ({
                    checkEngineLamp: a.checkEngineLamp + c.checkEngineLamp,
                    stopEngineLamp: a.stopEngineLamp + c.stopEngineLamp,
                    milLamp: a.milLamp + c.milLamp
                }))

                var nCount = data.reduce((prev, cur) => cur.status === "normal" ? ++prev : prev, 0);
                var wCount = data.reduce((prev, cur) => cur.status === "warning" ? ++prev : prev, 0);
                var cCount = data.reduce((prev, cur) => cur.status === "critical" ? ++prev : prev, 0);

                this.setState(
                    {
                        rowData: data,
                        filteredData: data,
                        columns: columnsWithLamps,
                        engineLampCount: result.checkEngineLamp,
                        stopLampCount: result.stopEngineLamp,
                        milLampCount: result.milLamp,
                        normalCount: nCount,
                        warningCount: wCount,
                        criticalCount: cCount
                    }, this.render);
            })
            .catch((error: Error) => {
                throw error
            });

    }



    async fetchData() {
        const rowData = [
            {
                vehicle: "Peterbilt A",
                totalMileage: { total: 700000, delta: 2032, deviceId: 'b123', data: [100, 150, 125, 137, 175, 162, 100, 150, 125, 137, 175, 162] },
                totalConsumed: { total: 15234, delta: -879, deviceId: 'b123', data: [5000, 5100, 5050, 4900, 5125, 5500, 5332, 5136] },
                avgConsumption: { total: 4507, delta: 231, deviceId: 'b123', data: [5000, 5100, 5050, 4900, 5125, 5500, 5332, 5136] },
                avgFuelEcon: { total: 12.7, delta: 0.4, deviceId: 'b123', data: [4.7, 3.8, 4.0, 6.8, 5.5, 5.9, 6.1, 6.5] },
                idleFuel: { total: 1507, delta: -18, deviceId: 'b123', data: [5000, 5100, 5050, 4900, 5125, 5500, 5332, 5136] },
                driverBehavior: { total: 9.7, delta: -768, deviceId: 'b123', data: [4.7, 3.8, 4.0, 6.8, 5.5, 5.9, 6.1, 6.5] },
                dutyCycle: { city: 12, longhaul: 60, shorthaul: 22 },
            },
            {
                vehicle: "Peterbilt B",
                totalMileage: { total: 700000, delta: 2032, deviceId: 'b124', data: [100, 150, 125, 137, 175, 162, 100, 150, 125, 137, 175, 162] },
                totalConsumed: { total: 15234, delta: -879, deviceId: 'b124', data: [5000, 5100, 5050, 4900, 5125, 5500, 5332, 5136] },
                avgConsumption: { total: 4507, delta: 231, deviceId: 'b124', data: [5000, 5100, 5050, 4900, 5125, 5500, 5332, 5136] },
                avgFuelEcon: { total: 12.7, delta: 0.4, deviceId: 'b124', data: [4.7, 3.8, 4.0, 6.8, 5.5, 5.9, 6.1, 6.5] },
                idleFuel: { total: 1507, delta: -18, deviceId: 'b124', data: [5000, 5100, 5050, 4900, 5125, 5500, 5332, 5136] },
                driverBehavior: { total: 9.7, delta: -768, deviceId: 'b124', data: [4.7, 3.8, 4.0, 6.8, 5.5, 5.9, 6.1, 6.5] },
                dutyCycle: { city: 12, longhaul: 60, shorthaul: 22 },
            },
            {
                vehicle: "Kenworth A",
                totalMileage: { total: 700000, delta: 2032, deviceId: 'b124', data: [100, 150, 125, 137, 175, 162, 100, 150, 125, 137, 175, 162] },
                totalConsumed: { total: 15234, delta: -879, deviceId: 'b124', data: [5000, 5100, 5050, 4900, 5125, 5500, 5332, 5136] },
                avgConsumption: { total: 4507, delta: 231, deviceId: 'b124', data: [5000, 5100, 5050, 4900, 5125, 5500, 5332, 5136] },
                avgFuelEcon: { total: 12.7, delta: 0.4, deviceId: 'b124', data: [4.7, 3.8, 4.0, 6.8, 5.5, 5.9, 6.1, 6.5] },
                idleFuel: { total: 1507, delta: -18, deviceId: 'b124', data: [5000, 5100, 5050, 4900, 5125, 5500, 5332, 5136] },
                driverBehavior: { total: 9.7, delta: -768, deviceId: 'b124', data: [4.7, 3.8, 4.0, 6.8, 5.5, 5.9, 6.1, 6.5] },
                dutyCycle: { city: 12, longhaul: 60, shorthaul: 22 },
            },
            {
                vehicle: "Kenworth B",
                totalMileage: { total: 700000, delta: 2032, deviceId: 'b124', data: [100, 150, 125, 137, 175, 162, 100, 150, 125, 137, 175, 162] },
                totalConsumed: { total: 15234, delta: -879, deviceId: 'b124', data: [5000, 5100, 5050, 4900, 5125, 5500, 5332, 5136] },
                avgConsumption: { total: 4507, delta: 231, deviceId: 'b124', data: [5000, 5100, 5050, 4900, 5125, 5500, 5332, 5136] },
                avgFuelEcon: { total: 12.7, delta: 0.4, deviceId: 'b124', data: [4.7, 3.8, 4.0, 6.8, 5.5, 5.9, 6.1, 6.5] },
                idleFuel: { total: 1507, delta: -18, deviceId: 'b124', data: [5000, 5100, 5050, 4900, 5125, 5500, 5332, 5136] },
                driverBehavior: { total: 9.7, delta: -768, deviceId: 'b124', data: [4.7, 3.8, 4.0, 6.8, 5.5, 5.9, 6.1, 6.5] },
                dutyCycle: { city: 12, longhaul: 60, shorthaul: 22 },
            },
        ];

        // new data
        const rowDataWithLamps = [
            //{
            //    vehicle: {
            //        name: "2019 International LT625", //C Make/Model
            //        deviceId: 'X99999', //F Vehicle Number
            //        vin: "XKYD49XXKJX99999" //A VIN
            //    },
            //    generalHealth: {
            //        health: 45, //Z vehicle health
            //        status: 'In-Repair', //no data
            //        odometer: 336370 //I Odometer Miles
            //    },
            //    stopEngine: { isLampOn: false }, //K Stop engine lamp
            //    checkEngine: { isLampOn: true }, //L Amber - CEL
            //    milLamp: { isLampOn: true }, // ODB LamP
            //    dpfLamp: { isLampOn: true}, // DPF Lamp
            //    criticalDTC: { active: 0, pending: 0 }, //O - Critical DTcs (active only)
            //    fuelLevel: { level: 49, secondaryData: '5g', status: 'good' }, //P - Fuel Level (level only)
            //    engineOilLevel: { level: 68, secondaryData: 'MIN', status: 'warning' }, //no data
            //    defLevel: { level: 73.6, secondaryData: 'MAX', status: 'good' }, //Q DEF LEvel % (level only)
            //    engineCoolantLevel: { level: 100, secondaryData: 'MAX', status: 'good' }, //S Eng Coolant Leval % (level only)
            //    engineCoolantTemp: { temp: 85, average: 97, status: 'good' }, //R Eng Coolant Temp
            //    dpfHealth: {
            //        level: 97,
            //        status: 'warning',
            //        odometer: 336370,

            //    }, //T - DPF Health (Soot%) //W - DPF Health
            //    dpfHealth2: { level: 97, secondaryData: '7g', status: 'warning' },
            //    dpfTemp: { temp: 1285, status: 'Hot'},
            //},
            {
                vehicle: {
                    name: "2017 Freightliner Cascadia", //C Make/Model
                    deviceId: 'X99999', //F Vehicle Number
                    vin: "XKYD49XXKJX99999"
                },
                generalHealth: {
                    health: 100, //Z vehicle health
                    status: 'Active', //no data
                    odometer: 469375 //I Odometer Miles
                },
                stopEngine: { isLampOn: false }, //K Stop engine lamp
                checkEngine: { isLampOn: false }, //L Amber - CEL
                milLamp: { isLampOn: false }, // ODB LamP
                dpfLamp: { isLampOn: false }, // DPF Lamp
                criticalDTC: { active: 0, pending: 0 }, //O - Critical DTcs (active only)
                fuelLevel: { level: 38, secondaryData: '5g', status: 'good' }, //P - Fuel Level (level only)
                engineOilLevel: { level: 75, secondaryData: 'MIN', status: 'warning' }, //no data
                defLevel: { level: 74, secondaryData: 'MAX', status: 'good' }, //Q DEF LEvel % (level only)
                engineCoolantLevel: { level: 100, secondaryData: 'MAX', status: 'good' }, //S Eng Coolant Leval % (level only)
                engineCoolantTemp: { temp: 88, average: 97, status: 'good' }, //R Eng Coolant Temp
                dpfHealth: {
                    level: -1,
                    status: 'normal',
                    odometer: 469375,
                }, //T - DPF Health (Soot%) //W - DPF Health
                dpfHealth2: { level: -1, secondaryData: '7g', status: 'normal' },
                dpfTemp: { temp: 590, status: 'Optimal' },
            },
            {
                vehicle: {
                    name: "2018 Freightliner Cascadia", //C Make/Model
                    deviceId: 'KB2555', //F Vehicle Number
                    vin: "AKJHHDRXJSXB2555"
                },
                generalHealth: {
                    health: 0, //Z vehicle health
                    status: 'In-Repair', //no data
                    odometer: 756502 //I Odometer Miles
                },
                stopEngine: { isLampOn: true }, //K Stop engine lamp
                checkEngine: { isLampOn: true }, //L Amber - CEL
                milLamp: { isLampOn: false }, // ODB LamP
                dpfLamp: { isLampOn: true }, // DPF Lamp
                criticalDTC: { active: 0, pending: 0 }, //O - Critical DTcs (active only)
                fuelLevel: { level: 33, secondaryData: '5g', status: 'good' }, //P - Fuel Level (level only)
                engineOilLevel: { level: 75, secondaryData: 'MIN', status: 'warning' }, //no data
                defLevel: { level: 50, secondaryData: 'MAX', status: 'good' }, //Q DEF LEvel % (level only)
                engineCoolantLevel: { level: 0, secondaryData: 'MAX', status: 'good' }, //S Eng Coolant Leval % (level only)
                engineCoolantTemp: { temp: 100, average: 97, status: 'good' }, //R Eng Coolant Temp
                dpfHealth: {
                    level: -1,
                    status: 'normal',
                    odometer: 756502,
                }, //T - DPF Health (Soot%) //W - DPF Health
                dpfHealth2: { level: -1, secondaryData: '7g', status: 'normal' },
                dpfTemp: { temp: 1200, status: 'Hot' },
            },
            
            {
                vehicle: {
                    name: "2019 Freightliner Cascadia", //C Make/Model
                    deviceId: 'KG2226', //F Vehicle Number
                    vin: "AKJGNDRXKDXG2226" //A VIN
                },
                generalHealth: {
                    health: 100, //Z vehicle health
                    status: 'Active', //no data
                    odometer: 157890 //I Odometer Miles
                },
                stopEngine: { isLampOn: false }, //K Stop engine lamp
                checkEngine: { isLampOn: false }, //L Amber - CEL
                milLamp: { isLampOn: false }, // ODB LamP
                dpfLamp: { isLampOn: false }, // DPF Lamp
                criticalDTC: { active: 0, pending: 0 }, //O - Critical DTcs (active only)
                fuelLevel: { level: 95, secondaryData: '5g', status: 'good' }, //P - Fuel Level (level only)
                engineOilLevel: { level: 75, secondaryData: 'MIN', status: 'warning' }, //no data
                defLevel: { level: 100, secondaryData: 'MAX', status: 'good' }, //Q DEF LEvel % (level only)
                engineCoolantLevel: { level: 100, secondaryData: 'MAX', status: 'good' }, //S Eng Coolant Leval % (level only)
                engineCoolantTemp: { temp: 87, average: 97, status: 'good' }, //R Eng Coolant Temp
                dpfHealth: {
                    level: -1,
                    status: 'normal',
                    odometer: 157890,
                }, //T - DPF Health (Soot%) //W - DPF Health
                dpfHealth2: { level: -1, secondaryData: '7g', status: 'normal' },
                dpfTemp: { temp: 590, status: 'Optimal' },
            },
            {
                vehicle: {
                    name: "2019 Freightliner Cascadia", //C Make/Model
                    deviceId: 'KN2496', //F Vehicle Number
                    vin: "AKJHHDRXKSXN2496" //A VIN
                },
                generalHealth: {
                    health: 100, //Z vehicle health
                    status: 'Active', //no data
                    odometer: 280782 //I Odometer Miles
                },
                stopEngine: { isLampOn: false }, //K Stop engine lamp
                checkEngine: { isLampOn: false }, //L Amber - CEL
                milLamp: { isLampOn: false }, // ODB LamP
                dpfLamp: { isLampOn: false }, // DPF Lamp
                criticalDTC: { active: 0, pending: 0 }, //O - Critical DTcs (active only)
                fuelLevel: { level: 63, secondaryData: '5g', status: 'good' }, //P - Fuel Level (level only)
                engineOilLevel: { level: 75, secondaryData: 'MIN', status: 'warning' }, //no data
                defLevel: { level: 63, secondaryData: 'MAX', status: 'good' }, //Q DEF LEvel % (level only)
                engineCoolantLevel: { level: 100, secondaryData: 'MAX', status: 'good' }, //S Eng Coolant Leval % (level only)
                engineCoolantTemp: { temp: 87, average: 97, status: 'good' }, //R Eng Coolant Temp
                dpfHealth: {
                    level: -1,
                    status: 'normal',
                    odometer: 280782,
                }, //T - DPF Health (Soot%) //W - DPF Health
                dpfHealth2: { level: -1, secondaryData: '7g', status: 'normal' },
                dpfTemp: { temp: 590, status: 'Optimal' },
            },
            {
                vehicle: {
                    name: "2019 Freightliner Cascadia", //C Make/Model
                    deviceId: 'KJ5400', //F Vehicle Number
                    vin: "AKJHHDRXKSXJ5400" //A VIN
                },
                generalHealth: {
                    health: 100, //Z vehicle health
                    status: 'Active', //no data
                    odometer: 355533 //I Odometer Miles
                },
                stopEngine: { isLampOn: false }, //K Stop engine lamp
                checkEngine: { isLampOn: false }, //L Amber - CEL
                milLamp: { isLampOn: false }, // ODB LamP
                dpfLamp: { isLampOn: false }, // DPF Lamp
                criticalDTC: { active: 0, pending: 0 }, //O - Critical DTcs (active only)
                fuelLevel: { level: 82, secondaryData: '5g', status: 'good' }, //P - Fuel Level (level only)
                engineOilLevel: { level: 75, secondaryData: 'MIN', status: 'warning' }, //no data
                defLevel: { level: 100, secondaryData: 'MAX', status: 'good' }, //Q DEF LEvel % (level only)
                engineCoolantLevel: { level: 100, secondaryData: 'MAX', status: 'good' }, //S Eng Coolant Leval % (level only)
                engineCoolantTemp: { temp: 75, average: 97, status: 'good' }, //R Eng Coolant Temp
                dpfHealth: {
                    level: -1,
                    status: 'normal',
                    odometer: 355533,
                }, //T - DPF Health (Soot%) //W - DPF Health
                dpfHealth2: { level: -1, secondaryData: '7g', status: 'normal' },
                dpfTemp: { temp: 590, status: 'Optimal' },
            },
            {
                vehicle: {
                    name: "2019 Freightliner Cascadia", //C Make/Model
                    deviceId: 'KJ5401', //F Vehicle Number
                    vin: "AKJHHDRXKSXJ5401" //A VIN
                },
                generalHealth: {
                    health: 100, //Z vehicle health
                    status: 'Active', //no data
                    odometer: 409209 //I Odometer Miles
                },
                stopEngine: { isLampOn: false }, //K Stop engine lamp
                checkEngine: { isLampOn: false }, //L Amber - CEL
                milLamp: { isLampOn: false }, // ODB LamP
                dpfLamp: { isLampOn: false }, // DPF Lamp
                criticalDTC: { active: 0, pending: 0 }, //O - Critical DTcs (active only)
                fuelLevel: { level: 72, secondaryData: '5g', status: 'good' }, //P - Fuel Level (level only)
                engineOilLevel: { level: 75, secondaryData: 'MIN', status: 'warning' }, //no data
                defLevel: { level: 89, secondaryData: 'MAX', status: 'good' }, //Q DEF LEvel % (level only)
                engineCoolantLevel: { level: 100, secondaryData: 'MAX', status: 'good' }, //S Eng Coolant Leval % (level only)
                engineCoolantTemp: { temp: 87, average: 97, status: 'good' }, //R Eng Coolant Temp
                dpfHealth: {
                    level: -1,
                    status: 'normal',
                    odometer: 409209,
                }, //T - DPF Health (Soot%) //W - DPF Health
                dpfHealth2: { level: -1, secondaryData: '7g', status: 'normal' },
                dpfTemp: { temp: 590, status: 'Optimal' },
            },
            {
                vehicle: {
                    name: "2016 Freightliner Cascadia", //C Make/Model
                    deviceId: 'GY2762', //F Vehicle Number
                    vin: "FUJGLD5XGLXY2762" //A VIN
                },
                generalHealth: {
                    health: 100, //Z vehicle health
                    status: 'Active', //no data
                    odometer: 486472 //I Odometer Miles
                },
                stopEngine: { isLampOn: false }, //K Stop engine lamp
                checkEngine: { isLampOn: false }, //L Amber - CEL
                milLamp: { isLampOn: false }, // ODB LamP
                dpfLamp: { isLampOn: false }, // DPF Lamp
                criticalDTC: { active: 0, pending: 0 }, //O - Critical DTcs (active only)
                fuelLevel: { level: 55, secondaryData: '5g', status: 'good' }, //P - Fuel Level (level only)
                engineOilLevel: { level: 75, secondaryData: 'MIN', status: 'warning' }, //no data
                defLevel: { level: 98.4, secondaryData: 'MAX', status: 'good' }, //Q DEF LEvel % (level only)
                engineCoolantLevel: { level: 100, secondaryData: 'MAX', status: 'good' }, //S Eng Coolant Leval % (level only)
                engineCoolantTemp: { temp: 89, average: 97, status: 'good' }, //R Eng Coolant Temp
                dpfHealth: {
                    level: -1,
                    status: 'normal',
                    odometer: 486472,
                }, //T - DPF Health (Soot%) //W - DPF Health
                dpfHealth2: { level: -1, secondaryData: '7g', status: 'normal' },
                dpfTemp: { temp: 590, status: 'Optimal' },
            },
            {
                vehicle: {
                    name: "2016 Freightliner Cascadia", //C Make/Model
                    deviceId: 'GY2750', //F Vehicle Number
                    vin: "FUJGLD5XGLXY2750" //A VIN
                },
                generalHealth: {
                    health: 100, //Z vehicle health
                    status: 'Active', //no data
                    odometer: 222846 //I Odometer Miles
                },
                stopEngine: { isLampOn: false }, //K Stop engine lamp
                checkEngine: { isLampOn: false }, //L Amber - CEL
                milLamp: { isLampOn: false }, // ODB LamP
                dpfLamp: { isLampOn: false }, // DPF Lamp
                criticalDTC: { active: 0, pending: 0 }, //O - Critical DTcs (active only)
                fuelLevel: { level: 35, secondaryData: '5g', status: 'good' }, //P - Fuel Level (level only)
                engineOilLevel: { level: 75, secondaryData: 'MIN', status: 'warning' }, //no data
                defLevel: { level: 73.6, secondaryData: 'MAX', status: 'good' }, //Q DEF LEvel % (level only)
                engineCoolantLevel: { level: 100, secondaryData: 'MAX', status: 'good' }, //S Eng Coolant Leval % (level only)
                engineCoolantTemp: { temp: 94, average: 97, status: 'good' }, //R Eng Coolant Temp
                dpfHealth: {
                    level: -1,
                    status: 'normal',
                    odometer: 222846,
                }, //T - DPF Health (Soot%) //W - DPF Health
                dpfHealth2: { level: -1, secondaryData: '7g', status: 'normal' },
                dpfTemp: { temp: 590, status: 'Optimal' },
            },
            {
                vehicle: {
                    name: "2016 Kenworth T680", //C Make/Model
                    deviceId: '115046', //F Vehicle Number
                    vin: "WKAD49XXGFX15046" //A VIN
                },
                generalHealth: {
                    health: 100, //Z vehicle health
                    status: 'Active', //no data
                    odometer: 377806 //I Odometer Miles
                },
                stopEngine: { isLampOn: false }, //K Stop engine lamp
                checkEngine: { isLampOn: false }, //L Amber - CEL
                milLamp: { isLampOn: false }, // ODB LamP
                dpfLamp: { isLampOn: false }, // DPF Lamp
                criticalDTC: { active: 0, pending: 0 }, //O - Critical DTcs (active only)
                fuelLevel: { level: 35, secondaryData: '5g', status: 'good' }, //P - Fuel Level (level only)
                engineOilLevel: { level: 75, secondaryData: 'MIN', status: 'warning' }, //no data
                defLevel: { level: 72.4, secondaryData: 'MAX', status: 'good' }, //Q DEF LEvel % (level only)
                engineCoolantLevel: { level: 100, secondaryData: 'MAX', status: 'good' }, //S Eng Coolant Leval % (level only)
                engineCoolantTemp: { temp: 84, average: 97, status: 'good' }, //R Eng Coolant Temp
                dpfHealth: {
                    level: 25,
                    status: 'normal',
                    odometer: 377806,
                }, //T - DPF Health (Soot%) //W - DPF Health
                dpfHealth2: { level: 25, secondaryData: '7g', status: 'normal' },
                dpfTemp: { temp: 590, status: 'Optimal' },
            },
            {
                vehicle: {
                    name: "2016 Kenworth T680", //C Make/Model
                    deviceId: '115040', //F Vehicle Number
                    vin: "WKAD49XXGFX15040" //A VIN
                },
                generalHealth: {
                    health: 100, //Z vehicle health
                    status: 'Active', //no data
                    odometer: 213916 //I Odometer Miles
                },
                stopEngine: { isLampOn: false }, //K Stop engine lamp
                checkEngine: { isLampOn: false }, //L Amber - CEL
                milLamp: { isLampOn: false }, // ODB LamP
                dpfLamp: { isLampOn: false }, // DPF Lamp
                criticalDTC: { active: 0, pending: 0 }, //O - Critical DTcs (active only)
                fuelLevel: { level: 31, secondaryData: '5g', status: 'good' }, //P - Fuel Level (level only)
                engineOilLevel: { level: 75, secondaryData: 'MIN', status: 'warning' }, //no data
                defLevel: { level: 50, secondaryData: 'MAX', status: 'good' }, //Q DEF LEvel % (level only)
                engineCoolantLevel: { level: 100, secondaryData: 'MAX', status: 'good' }, //S Eng Coolant Leval % (level only)
                engineCoolantTemp: { temp: 85, average: 97, status: 'good' }, //R Eng Coolant Temp
                dpfHealth: {
                    level: 27,
                    status: 'normal',
                    odometer: 213916,
                }, //T - DPF Health (Soot%) //W - DPF Health
                dpfHealth2: { level: 27, secondaryData: '7g', status: 'normal' },
                dpfTemp: { temp: 590, status: 'Optimal' },
            },
            {
                vehicle: {
                    name: "2016 Kenworth T680", //C Make/Model
                    deviceId: '115048', //F Vehicle Number
                    vin: "WKAD49XXGFX15048" //A VIN
                },
                generalHealth: {
                    health: 100, //Z vehicle health
                    status: 'Active', //no data
                    odometer: 496332 //I Odometer Miles
                },
                stopEngine: { isLampOn: false }, //K Stop engine lamp
                checkEngine: { isLampOn: false }, //L Amber - CEL
                milLamp: { isLampOn: false }, // ODB LamP
                dpfLamp: { isLampOn: false }, // DPF Lamp
                criticalDTC: { active: 0, pending: 0 }, //O - Critical DTcs (active only)
                fuelLevel: { level: 90, secondaryData: '5g', status: 'good' }, //P - Fuel Level (level only)
                engineOilLevel: { level: 75, secondaryData: 'MIN', status: 'warning' }, //no data
                defLevel: { level: 76.8, secondaryData: 'MAX', status: 'good' }, //Q DEF LEvel % (level only)
                engineCoolantLevel: { level: 100, secondaryData: 'MAX', status: 'good' }, //S Eng Coolant Leval % (level only)
                engineCoolantTemp: { temp: 95, average: 97, status: 'good' }, //R Eng Coolant Temp
                dpfHealth: {
                    level: 13,
                    status: 'normal',
                    odometer: 496332,
                }, //T - DPF Health (Soot%) //W - DPF Health
                dpfHealth2: { level: 13, secondaryData: '7g', status: 'normal' },
                dpfTemp: { temp: 590, status: 'Optimal' },
            },
            {
                vehicle: {
                    name: "2016 Kenworth T680", //C Make/Model
                    deviceId: '115043', //F Vehicle Number
                    vin: "WKAD49XXGFX15043" //A VIN
                },
                generalHealth: {
                    health: 100, //Z vehicle health
                    status: 'Active', //no data
                    odometer: 380887 //I Odometer Miles
                },
                stopEngine: { isLampOn: false }, //K Stop engine lamp
                checkEngine: { isLampOn: false }, //L Amber - CEL
                milLamp: { isLampOn: false }, // ODB LamP
                dpfLamp: { isLampOn: false }, // DPF Lamp
                criticalDTC: { active: 0, pending: 0 }, //O - Critical DTcs (active only)
                fuelLevel: { level: 97, secondaryData: '5g', status: 'good' }, //P - Fuel Level (level only)
                engineOilLevel: { level: 75, secondaryData: 'MIN', status: 'warning' }, //no data
                defLevel: { level: 99.2, secondaryData: 'MAX', status: 'good' }, //Q DEF LEvel % (level only)
                engineCoolantLevel: { level: 100, secondaryData: 'MAX', status: 'good' }, //S Eng Coolant Leval % (level only)
                engineCoolantTemp: { temp: 50, average: 97, status: 'good' }, //R Eng Coolant Temp
                dpfHealth: {
                    level: 63,
                    status: 'normal',
                    odometer: 380887,
                }, //T - DPF Health (Soot%) //W - DPF Health
                dpfHealth2: { level: 63, secondaryData: '7g', status: 'normal' },
                dpfTemp: { temp: 590, status: 'Optimal' },
            },
            {
                vehicle: {
                    name: "2016 Freightliner Cascadia", //C Make/Model
                    deviceId: 'GY2751', //F Vehicle Number
                    vin: "FUJGLD5XGLXY2751" //A VIN
                },
                generalHealth: {
                    health: 100, //Z vehicle health
                    status: 'Active', //no data
                    odometer: 551151 //I Odometer Miles
                },
                stopEngine: { isLampOn: false }, //K Stop engine lamp
                checkEngine: { isLampOn: false }, //L Amber - CEL
                milLamp: { isLampOn: false }, // ODB LamP
                dpfLamp: { isLampOn: false }, // DPF Lamp
                criticalDTC: { active: 0, pending: 0 }, //O - Critical DTcs (active only)
                fuelLevel: { level: 58, secondaryData: '5g', status: 'good' }, //P - Fuel Level (level only)
                engineOilLevel: { level: 75, secondaryData: 'MIN', status: 'warning' }, //no data
                defLevel: { level: 89.2, secondaryData: 'MAX', status: 'good' }, //Q DEF LEvel % (level only)
                engineCoolantLevel: { level: 100, secondaryData: 'MAX', status: 'good' }, //S Eng Coolant Leval % (level only)
                engineCoolantTemp: { temp: 94, average: 97, status: 'good' }, //R Eng Coolant Temp
                dpfHealth: {
                    level: -1,
                    status: 'normal',
                    odometer: 551151,
                }, //T - DPF Health (Soot%) //W - DPF Health
                dpfHealth2: { level: -1, secondaryData: '7g', status: 'normal' },
                dpfTemp: { temp: 590, status: 'Optimal' },

            },
            {
                vehicle: {
                    name: "2016 Freightliner Cascadia", //C Make/Model
                    deviceId: 'GY2761', //F Vehicle Number
                    vin: "FUJGLD5XGLXY2761" //A VIN
                },
                generalHealth: {
                    health: 80, //Z vehicle health
                    status: 'Active', //no data
                    odometer: 523032 //I Odometer Miles
                },
                stopEngine: { isLampOn: false }, //K Stop engine lamp
                checkEngine: { isLampOn: false }, //L Amber - CEL
                milLamp: { isLampOn: true }, // ODB LamP
                dpfLamp: { isLampOn: false }, // DPF Lamp
                criticalDTC: { active: 2, pending: 0 }, //O - Critical DTcs (active only)
                fuelLevel: { level: 48, secondaryData: '5g', status: 'good' }, //P - Fuel Level (level only)
                engineOilLevel: { level: 75, secondaryData: 'MIN', status: 'warning' }, //no data
                defLevel: { level: 94.4, secondaryData: 'MAX', status: 'good' }, //Q DEF LEvel % (level only)
                engineCoolantLevel: { level: 100, secondaryData: 'MAX', status: 'good' }, //S Eng Coolant Leval % (level only)
                engineCoolantTemp: { temp: 86, average: 97, status: 'good' }, //R Eng Coolant Temp
                dpfHealth: {
                    level: -1,
                    status: 'normal',
                    odometer: 523032,
                }, //T - DPF Health (Soot%) //W - DPF Health
                dpfHealth2: { level: -1, secondaryData: '7g', status: 'normal' },
                dpfTemp: { temp: 590, status: 'Optimal' },
            },
            {
                vehicle: {
                    name: "2016 Freightliner Cascadia", //C Make/Model
                    deviceId: 'GY2760', //F Vehicle Number
                    vin: "FUJGLD5XGLXY2760" //A VIN
                },
                generalHealth: {
                    health: 100, //Z vehicle health
                    status: 'Active', //no data
                    odometer: 603373 //I Odometer Miles
                },
                stopEngine: { isLampOn: false }, //K Stop engine lamp
                checkEngine: { isLampOn: false }, //L Amber - CEL
                milLamp: { isLampOn: true }, // ODB LamP
                dpfLamp: { isLampOn: false }, // DPF Lamp
                criticalDTC: { active: 0, pending: 0 }, //O - Critical DTcs (active only)
                fuelLevel: { level: 70, secondaryData: '5g', status: 'good' }, //P - Fuel Level (level only)
                engineOilLevel: { level: 75, secondaryData: 'MIN', status: 'warning' }, //no data
                defLevel: { level: 82, secondaryData: 'MAX', status: 'good' }, //Q DEF LEvel % (level only)
                engineCoolantLevel: { level: 100, secondaryData: 'MAX', status: 'good' }, //S Eng Coolant Leval % (level only)
                engineCoolantTemp: { temp: 87, average: 97, status: 'good' }, //R Eng Coolant Temp
                dpfHealth: {
                    level: -1,
                    status: 'normal',
                    odometer: 603373,
                }, //T - DPF Health (Soot%) //W - DPF Health
                dpfHealth2: { level: -1, secondaryData: '7g', status: 'normal' },
                dpfTemp: { temp: 590, status: 'Optimal' },
            },
            {
                vehicle: {
                    name: "2019 International LT625", //C Make/Model
                    deviceId: '010346', //F Vehicle Number
                    vin: "HCDZAPRXKLX10346" //A VIN
                },
                generalHealth: {
                    health: 100, //Z vehicle health
                    status: 'Active', //no data
                    odometer: 286901 //I Odometer Miles
                },
                stopEngine: { isLampOn: false }, //K Stop engine lamp
                checkEngine: { isLampOn: false }, //L Amber - CEL
                milLamp: { isLampOn: false }, // ODB LamP
                dpfLamp: { isLampOn: false }, // DPF Lamp
                criticalDTC: { active: 0, pending: 0 }, //O - Critical DTcs (active only)
                fuelLevel: { level: 40, secondaryData: '5g', status: 'good' }, //P - Fuel Level (level only)
                engineOilLevel: { level: 75, secondaryData: 'MIN', status: 'warning' }, //no data
                defLevel: { level: 64.4, secondaryData: 'MAX', status: 'good' }, //Q DEF LEvel % (level only)
                engineCoolantLevel: { level: 100, secondaryData: 'MAX', status: 'good' }, //S Eng Coolant Leval % (level only)
                engineCoolantTemp: { temp: 83, average: 97, status: 'good' }, //R Eng Coolant Temp
                dpfHealth: {
                    level: 12,
                    status: 'normal',
                    odometer: 286901,
                }, //T - DPF Health (Soot%) //W - DPF Health
                dpfHealth2: { level: 12, secondaryData: '7g', status: 'normal' },
                dpfTemp: { temp: 590, status: 'Optimal' },
            },
            {
                vehicle: {
                    name: "2019 International LT625", //C Make/Model
                    deviceId: '010637', //F Vehicle Number
                    vin: "HCDZAPRXKLX10637" //A VIN
                },
                generalHealth: {
                    health: 100, //Z vehicle health
                    status: 'Active', //no data
                    odometer: 139895 //I Odometer Miles
                },
                stopEngine: { isLampOn: false }, //K Stop engine lamp
                checkEngine: { isLampOn: false }, //L Amber - CEL
                milLamp: { isLampOn: false }, // ODB LamP
                dpfLamp: { isLampOn: false }, // DPF Lamp
                criticalDTC: { active: 0, pending: 0 }, //O - Critical DTcs (active only)
                fuelLevel: { level: 71, secondaryData: '5g', status: 'good' }, //P - Fuel Level (level only)
                engineOilLevel: { level: 75, secondaryData: 'MIN', status: 'warning' }, //no data
                defLevel: { level: 85.6, secondaryData: 'MAX', status: 'good' }, //Q DEF LEvel % (level only)
                engineCoolantLevel: { level: 100, secondaryData: 'MAX', status: 'good' }, //S Eng Coolant Leval % (level only)
                engineCoolantTemp: { temp: 37, average: 97, status: 'good' }, //R Eng Coolant Temp
                dpfHealth: {
                    level: 28,
                    status: 'normal',
                    odometer: 139895,
                }, //T - DPF Health (Soot%) //W - DPF Health
                dpfHealth2: { level: 28, secondaryData: '7g', status: 'normal' },
                dpfTemp: { temp: 590, status: 'Optimal' },
            },
            {
                vehicle: {
                    name: "2019 International LT625", //C Make/Model
                    deviceId: '009584', //F Vehicle Number
                    vin: "HCDZAPRXKLX09584" //A VIN
                },
                generalHealth: {
                    health: 100, //Z vehicle health
                    status: 'Active', //no data
                    odometer: 178164 //I Odometer Miles
                },
                stopEngine: { isLampOn: false }, //K Stop engine lamp
                checkEngine: { isLampOn: false }, //L Amber - CEL
                milLamp: { isLampOn: false }, // ODB LamP
                dpfLamp: { isLampOn: false }, // DPF Lamp
                criticalDTC: { active: 0, pending: 0 }, //O - Critical DTcs (active only)
                fuelLevel: { level: 55, secondaryData: '5g', status: 'good' }, //P - Fuel Level (level only)
                engineOilLevel: { level: 75, secondaryData: 'MIN', status: 'warning' }, //no data
                defLevel: { level: 88.4, secondaryData: 'MAX', status: 'good' }, //Q DEF LEvel % (level only)
                engineCoolantLevel: { level: 100, secondaryData: 'MAX', status: 'good' }, //S Eng Coolant Leval % (level only)
                engineCoolantTemp: { temp: 91, average: 97, status: 'good' }, //R Eng Coolant Temp
                dpfHealth: {
                    level: 28,
                    status: 'normal',
                    odometer: 178164,
                }, //T - DPF Health (Soot%) //W - DPF Health
                dpfHealth2: { level: 28, secondaryData: '7g', status: 'normal' },
                dpfTemp: { temp: 590, status: 'Optimal' },
            },
            {
                vehicle: {
                    name: "2020 International LT625", //C Make/Model
                    deviceId: '139509', //F Vehicle Number
                    vin: "HCDZAPRXLLX39509" //A VIN
                },
                generalHealth: {
                    health: 100, //Z vehicle health
                    status: 'Active', //no data
                    odometer: 54114 //I Odometer Miles
                },
                stopEngine: { isLampOn: false }, //K Stop engine lamp
                checkEngine: { isLampOn: false }, //L Amber - CEL
                milLamp: { isLampOn: false }, // ODB LamP
                dpfLamp: { isLampOn: false }, // DPF Lamp
                criticalDTC: { active: 0, pending: 0 }, //O - Critical DTcs (active only)
                fuelLevel: { level: 49, secondaryData: '5g', status: 'good' }, //P - Fuel Level (level only)
                engineOilLevel: { level: 75, secondaryData: 'MIN', status: 'warning' }, //no data
                defLevel: { level: 58, secondaryData: 'MAX', status: 'good' }, //Q DEF LEvel % (level only)
                engineCoolantLevel: { level: 100, secondaryData: 'MAX', status: 'good' }, //S Eng Coolant Leval % (level only)
                engineCoolantTemp: { temp: 82, average: 97, status: 'good' }, //R Eng Coolant Temp
                dpfHealth: {
                    level: 9,
                    status: 'normal',
                    odometer: 54114,
                }, //T - DPF Health (Soot%) //W - DPF Health
                dpfHealth2: { level: 9, secondaryData: '7g', status: 'normal' },
                dpfTemp: { temp: 590, status: 'Optimal' },
            },
        ];



        const columns = [
            { columnName: 'totalMileage' },
            { columnName: 'totalConsumed' },
            { columnName: 'avgConsumption' },
            { columnName: 'avgFuelEcon' },
            { columnName: 'idleFuel' },
            { columnName: 'driverBehavior' },
            { columnName: 'dutyCycle' }
        ];

        const columnsWithLamps = [
            { columnName: 'dpfHealth', columnHeader: 'DPF Health / Total Mileage', width: 150 },
            { columnName: 'stopEngine', columnHeader: 'Stop Engine', width: 100 },
            { columnName: 'checkEngine', columnHeader: 'Check Engine', width: 100 },
            { columnName: 'milLamp', columnHeader: 'MIL Lamp', width: 100 },
            { columnName: 'dpfLamp', columnHeader: 'DPF Lamp', width: 100 },
            { columnName: 'dpfHealth2', columnHeader: 'DPF Health', width: 130 },
            { columnName: 'dpfTemp', columnHeader: 'DPF Temp', width: 130 },
            { columnName: 'fuelLevel', columnHeader: 'Fuel Level', width: 130 },
            { columnName: 'defLevel', columnHeader: 'DEF Level', width: 130 },
        ];

        /*
       public enum HourlyDailyWeeklyMonthly {
           Hourly = 1,
           Daily = 2,
           Weekly = 3,
           Monthly = 4
       }

       public enum RandomSeriesBehaviour {
           Rising = 1,
           Falling = 2,
           Fluctuating = 3
       }

       public enum RandomSeriesDataType {
           Integer = 1,
           Double = 2
       }

       {
           qty: 10,
           min: 75,
           max: 99,
           name: 'Fuel Level',
           metricName: '%',
           hourlyDailyWeeklyMonthly: 1,
           randomSeriesBehaviour: 3,
           randomSeriesDataType: 1
       }
       [Route("FakeData/{qty}/{min}/{max}/{name}/{metricName}/{hourlyDailyWeeklyMonthly}/{randomSeriesBehaviour}/{randomSeriesDataType}")]

       */

        fetch('/api/Vehicle/AftertreatmentChartData')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                this.populateFleetAfterTreatmentHealthChart(data)
            })
            .catch((error: Error) => {
                throw error
            });

        //this.setState(
        //    {
        //        rowData: rowDataWithLamps,
        //        columns: columnsWithLamps
        //    }, this.render);

    }

    public populateFleetAfterTreatmentHealthChart(json: any) {

        var chart = new SparkLineChart({ chartData: json, chartContainer: 'fleethealthchartdiv', strokeColor: '#3885BB', bulletColor: '#000000' });
        chart.Create('Health', true, true);
        this.setState({ healthChart: chart });
    }

    onFilterClickHandler(filterVal, event) {
        var element = event.currentTarget;

        //Clear active from all the buttons
        document.querySelectorAll('.buttons button').forEach(function (el) {
            el.classList.remove('active');
        });

        if (this.state.filteredExpression === filterVal) { //if the same filter is clicked, reset the table
            element.classList.remove("active");
            var data = this.state.rowData;
            this.setState({ filteredData: data, isFiltered: false, filteredExpression: "" });
        }
        else { //if a different filter is clicked, filter the data
            element.classList.toggle("active");
            var data = this.state.rowData.filter((obj) => obj.status === filterVal);
            this.setState({ filteredData: data, isFiltered: true, filteredExpression: filterVal });
        }
    }

    render() {
        let datatable;
        if (this.state.rowData && this.state.columns)
            datatable = <DataTable rowData={this.state.filteredData} columns={this.state.columns} pinTopRow={false} />

        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <nav className="dashboardTitle" aria-label="breadcrumb">
                            <div className="breadcrumb">
                                <Link className="breadcrumb-item" to="/health-general" >HEALTH</Link>
                                <a className="breadcrumb-item" >Aftertreament Health</a>
                            </div>
                        </nav>
                    </Col>
                </Row>
                <DropDownFilterBar />
                
                <HealthSubnav pageTitle="Aftertreatment" />

                <Row className="mb-0  ml-2 mr-2 ">
                    <Col className="pl-0 pr-2 mb-0">
                        <Card className="card-highlight">
                            <CardBody>
                                <CardTitle tag="h5">
                                    TOTAL AFTERTREATMENT
                                </CardTitle>

                                <CardText tag="div">
                                    <h2>{this.state.aftertreatmentHealthScore}% <small className="very-small">Average Total Fleet Health</small></h2>

                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="pl-0 pr-2 mb-0">
                        <Card className="card-highlight">
                            <CardBody>
                                <CardTitle tag="h5">
                                    AFTERTREATMENT HEALTH OVER PERIOD

                                </CardTitle>

                                <CardText tag="div">
                                    <div id="fleethealthchartdiv" style={{ height: "100px" }}></div>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="pl-0 pr-2 mb-0">
                        <Card className="card-highlight">
                            <CardBody>
                                <CardTitle tag="h5">
                                    DASHBOARD LIGHTS
                                </CardTitle>

                                
                                <CardText tag="div">
                                    <Row className="mb-0">
                                        <Col xs="auto"></Col>
                                        <Col xs="7" className="border-bottom border-default ml-0 pl-0">
                                            STOP ENGINE LIGHTS
                                        </Col>
                                        <Col className="text-right border-bottom border-default">
                                            <strong>{this.state.stopLampCount}</strong>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                    <Row className="mb-0">
                                        <Col xs="auto"></Col>
                                        <Col xs="7" className="border-bottom border-default ml-0 pl-0">
                                            CHECK ENGINE LIGHTS
                                        </Col>
                                        <Col className="text-right border-bottom border-default">
                                            <strong>{this.state.engineLampCount}</strong>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                    <Row className="mb-0">
                                        <Col xs="auto"></Col>
                                        <Col xs="7" className="ml-0 pl-0">
                                            MIL LAMP
                                        </Col>
                                        <Col className="text-right">
                                            <strong>{this.state.milLampCount}</strong>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
                <Row className="pt-3 pb-3 align-items-center justify-content-center">
                    <div className="filterButtons round buttons">
                        <button style={{ width: "215px" }} onClick={(e) => this.onFilterClickHandler("critical", e)} >
                            <MdCancel className="MdCancel" fontSize="25" />
                            CRITICAL
                            <div className="box pr-5" style={{ float: "right", textAlign: "right" }}><strong>{this.state.criticalCount}</strong></div>
                        </button>
                        <button style={{ width: "215px" }} onClick={(e) => this.onFilterClickHandler("warning", e)}>
                            <RiErrorWarningFill className="RiErrorWarningFill" fontSize="25" />
                            WARNING
                            <div className="box pr-5" style={{ float: "right", textAlign: "right" }}><strong>{this.state.warningCount}</strong></div>
                        </button>
                        <button style={{ width: "215px" }} onClick={(e) => this.onFilterClickHandler("normal", e)}>
                            <AiFillCheckCircle className="AiFillCheckCircle" fontSize="25" />
                            LOW
                            <div className="pr-5" style={{ float: "right", textAlign: "right" }}><strong>{this.state.normalCount}</strong></div>
                        </button>
                    </div>
                </Row>
                <Row className="pb-0 mb-0">
                    <Col xs="2" className="tile p-0 mb-0">
                        <div className="text-white pb-0 mb-0 pl-2 dropdownFleetHealthGroupByToggleLabel">
                            <div className="mt-0 pt-0 pb-0" style={{ float: "left", width: "33%", lineHeight: "30px" }}>
                                GROUP BY
                            </div>
                            <div className="mt-1 mb-1" style={{ display: "inline-block", width: "1px", backgroundColor: "white", height: "70%", float: "left" }} />

                            <div className="mt-0 pt-0" style={{ float: "left", width: "66%", height: "100%" }}>
                                <InovexDropDown dropDownId="dropDownGroupBy"
                                    dropDownToggleId="dropdownFleetHealthGroupByToggleID"
                                    initialValue="VIN"
                                    //onValueSelected={(trendValue: any) => this.changeTrendValue(trendValue)}
                                    dropDownStyle={{ width: "100%" }}
                                    omitSelected={false} >
                                    <span>VIN</span>
                                    <span>Stop Engine</span>
                                    <span>Check Engine</span>
                                    <span>MIL Lamp</span>
                                </InovexDropDown>

                            </div>

                        </div>

                    </Col>


                </Row>
                <Row className="pt-0 mt-0">
                    <Col className='tile pr-0 pb-0 pt-0'>

                        {datatable}
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
};

