import * as React from 'react';

import '../../DataTable/Shared/styles.css'

import { AiFillCheckCircle } from "react-icons/ai"; // good
import { MdCancel } from "react-icons/md"; //critical
import { RiErrorWarningFill } from "react-icons/ri"; //warning

export default (props) => {

    let statusIcon;
    let level = "";
    let status = "";
    let suffix = "";

    if (props.value !== undefined) {
        level = props.value;
        //console.log(props);

        switch (props.colDef.field) {
            case 'dpfHealth':
                switch (props.value) {
                    case 'NORMAL': status = 'good'; break;
                    case 'WARNING': status = 'warning'; break;
                    case 'DANGER': status = 'danger'; break;
                }
                break;
            case 'dpfHealthSoot':
                suffix = props.value === null ? 'n/a' : '%';
                if (props.value != null && props.value >= 0 && props.value <= 50)
                    status = 'danger';
                else if (props.value >= 51 && props.value <= 90)
                    status = 'warning';
                else if (props.value > 90)
                    status = 'good';
                break;
            case 'fuelLevel':
            case 'defLevel':
                suffix = props.value === 'n/a' ? '' : '%';
                if (props.value >= 0 && props.value <= 9)
                    status = 'danger';
                else if (props.value >= 10 && props.value <= 60)
                    status = 'warning';
                else if (props.value > 61)
                    status = 'good';
                break;
            case 'engCoolantLevel':
                suffix = '%';
                if (props.value >= 0 && props.value <= 79)
                    status = 'danger';
                else if (props.value >= 80 && props.value <= 90)
                    status = 'warning';
                else if (props.value > 90)
                    status = 'good';
                break;
            case 'engCoolantTemp':
            case 'oilTemp':
                suffix = ' C';
                if (props.value <= 104)
                    status = 'good';
                else if (props.value >= 105 && props.value <= 120)
                    status = 'warning';
                if (props.value >= 121)
                    status = 'danger';

                break;

        }




        // if (props.value.level !== undefined)
        //     level = props.value.level == -1 ? "n/a" : props.value.level + "%";
        // else
        //     level = props.value == -1 ? "n/a" : props.value + "%";

        switch (status) {
            case "warning":
                statusIcon = <RiErrorWarningFill className='RiErrorWarningFill' fontSize='25' />
                break;
            case "good":
                statusIcon = <AiFillCheckCircle className='AiFillCheckCircle' fontSize='25' />
                break;
            case "danger":
                statusIcon = <MdCancel className='MdCancel' fontSize='25' />
                break;
            default:
        }


    }
    return (
        <div className="dt-vals-total">
            {level}{suffix}{statusIcon}
        </div>

    );
};


