import * as React from 'react';
import { Row, Col, Card, CardDeck, CardTitle, CardText, CardBody, } from 'reactstrap';
import { DataTable } from '../../DataTable/FuelEconByDriverBehavior'
import { DropDownFilterBar } from '../../DropDownFilterBar'
import { Link } from 'react-router-dom';
import { FuelSubnav } from '../SubNav'
import { InovexDropDown } from '../../InovexDropDown';

interface IDashboardProps {

}

interface IDashboardState {
    rowData: any,     // 'any' becauase the structure of the objects in teh array will vary
    columns: Array<{ columnName: string, columnHeader: string, width: number }>
}

export class FuelByDriverBehaviour extends React.Component<IDashboardProps, IDashboardState> {
    constructor(props: IDashboardProps) {
        super(props);
        this.state = {
            rowData: [],
            columns: []
        }

    }
    componentDidMount() {
        this.fetchData();
    }

    public getfuelBehaviourData() {

        fetch('api/Fuel/FuelEconByBehaviourDataTable/2021-07-01/2021-07-14')  // Currently this enpoint returns an empty list. tempdata is being used
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                this.populateFuelByBehaviour(data)
            })
            .catch((error: Error) => {
                throw error
            });

    }

    async fetchData() {
        this.getfuelBehaviourData();
    }


    public populateFuelByBehaviour(data: any) {
        const columns = [
            // { columnName: 'totalConsumption', columnHeader: 'Total Cons. (gal)', width: 100  },
            { columnName: 'idleGallons', columnHeader: 'Idle Gallons (gal)', width: 150 },
            { columnName: 'idleTimePercentage', columnHeader: 'Idle Waste (%)', width: 100 },
            { columnName: 'accelerationImpact', columnHeader: 'Vehicle Acceleration (+/- predicated gal)', width: 200 },
            //{ columnName: 'acceleration', columnHeader: 'Average Accel. (g)', width: 90}, //APT-587
            { columnName: 'highwaySpeedImpact', columnHeader: 'Highway Speed (+/- predicated gal)', width: 200 },
            { columnName: 'avgHighwaySpeed', columnHeader: 'Average Hwy Speed (mph)', width: 120 },
        ];


        this.setState(
            {
                rowData: data,
                columns: columns
            }, () => this.render);
    }


    render() {
        let datatable;
        if (this.state.rowData && this.state.columns)
            datatable = <DataTable rowData={this.state.rowData} columns={this.state.columns} />

        console.log(this.state.rowData);
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <nav className="dashboardTitle" aria-label="breadcrumb">
                            <div className="breadcrumb">
                                <Link className="breadcrumb-item" to="/fuel" >FUEL</Link>
                                <Link className="breadcrumb-item" to="/fuel-weight" >Fuel Efficiency</Link>
                                <a className="breadcrumb-item" >Fuel Efficiency By Driver Behavior</a>
                            </div>
                        </nav>
                    </Col>
                </Row>
                <DropDownFilterBar />
                <FuelSubnav pageTitle="Fuel Economy By Driver Behavior" />

                <Row className="pb-0 mb-0">
                    <Col xs="2" className="tile p-0 mb-0">
                        
                        <div className="text-white pb-0 mb-0 pl-2 dropdownFleetHealthGroupByToggleLabel">
                            <div className="mt-0 pt-0 pb-0" style={{ float: "left", width: "33%", lineHeight: "30px" }}>
                                GROUP BY
                            </div>
                            <div className="mt-1 mb-1" style={{ display: "inline-block", width: "1px", backgroundColor: "white", height: "70%", float: "left" }} />
                            <div className="mt-0 pt-0" style={{ float: "left", width: "66%", height:"100%" }}>
                                <InovexDropDown dropDownId="dropDownGroupBy"
                                    dropDownToggleId="dropdownFleetHealthGroupByToggleID"
                                    initialValue="VIN"
                                    //onValueSelected={(trendValue: any) => this.changeTrendValue(trendValue)}
                                    dropDownStyle={{ width: "100%" }}
                                    omitSelected={false} >
                                    <span>VIN</span>
                                    <span>Company</span>

                                </InovexDropDown>

                            </div>

                        </div>

                    </Col>


                </Row>
                <Row className="pt-0 mt-0">
                    <Col className='tile pb-0 pt-0' style={{ marginRight: 0 }}>
                        {datatable}
                    </Col>
                </Row>


            </React.Fragment>
        );
    }
}
