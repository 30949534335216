import * as React from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import VehicleCellRenderer from './CellRenderers/vehicleHealthCellRenderer'
import BarCellRenderer from './CellRenderers/barCellRenderer'
import GeneralNumberCellRenderer from './CellRenderers/generalNumberCellRenderer'

import '../DataTable/Shared/styles.css'
import '../DataTable/Shared/ag-theme-alpine.css';
import '../DataTable/Shared/ag-grid.css';
import { isNullOrUndefined } from 'util';


interface IDataTableProps {
    columns: Array<{ columnName: string, headerName: string, width: number, hide?: boolean }>
    rowData: any;
    wrapText: boolean;
    autoHeight: boolean;
    showCheckboxes?: boolean;
    onRowSelected?: Function; //passed function when a row checkbox is selected
    externalOnGridReady?: Function; //passed function when a row checkbox is selected
    pushPinnedData?: Function; //passed function when the pinned row data is ready
    min?: number;
    max?: number;
}

interface IDataTableState {
    loading: boolean;
    columns: Array<{ columnName: string, headerName: string, width: number, hide?: boolean }>;
    rowData: any;
    pinnedRowData: any;
    gridApi: any;
    initialLoad: boolean;
}

// All the docs are here: 
// https://www.ag-grid.com/react-data-grid

export class DataTable extends React.Component<IDataTableProps, IDataTableState> {
    constructor(props: IDataTableProps) {
        super(props);
        this.state =
        {
            gridApi: null,
            loading: true,
            columns: [],
            rowData: null,
            pinnedRowData: [this.props.rowData[0]],
            initialLoad: false,
        };

    }

    componentDidMount() {
        //const script = document.createElement("script");
        //script.src = "../../assets/js/sparkline.min.js";
        //script.async = true;
        // document.body.appendChild(script);
    }


    public getRowData() {
        this.props.rowData.shift(); // drop the first element because it will be pinned
        return this.props.rowData as any[];
    };

    public getPinnedRowData() {
        // return [this.props.rowData[0]];
        return this.state.pinnedRowData;
    };

    public isFirstColumn(params) {
        var displayedColumns = params.columnApi.getAllDisplayedColumns();
        var thisIsFirstColumn = displayedColumns[0] === params.column;
        return thisIsFirstColumn;
    }
    
    /*onSelectionChanged = () => {
        console.log('onSelectionChanged');
        console.log(this.state.gridApi.getSelectedRows());
        console.log(this.state.gridApi);

        let selectedRows = this.state.gridApi.getSelectedRows();

        if (selectedRows.length > 3) {
            // selectedRows[3]

            // un-select
            // node.setSelected(false);
            return null;
        }
        var rowSelectedFunction = this.props.onRowSelected;
        if (rowSelectedFunction) { // if click function provided
            if (rowSelectedFunction.length === 0) { //if no args just run
                rowSelectedFunction();
            } else { //if 1 or more args pass element
                rowSelectedFunction(this.state.gridApi.getSelectedRows());
            }
        }
    };*/
    
    onRowSelected = (event) => {
        var currentSelectedNode = event.node;
        // console.log('onSelectionChanged', currentSelectedNode);
        // console.log(event);

        let selectedRows = this.state.gridApi.getSelectedRows();

        if ((selectedRows.length + (this.props.pushPinnedData ? 1 : 0)) > (this.props.max ? this.props.max : 3)) {
            // un-select
            currentSelectedNode.setSelected(false);
            return null;
        }
        var rowSelectedFunction = this.props.onRowSelected;
        var pushPinnedDataFunction = this.props.pushPinnedData;
        if (rowSelectedFunction) { // if click function provided
            if (rowSelectedFunction.length === 0) { //if no args just run
                rowSelectedFunction();
            } else { //if 1 or more args pass element

                // we need to add te pinned row to the selected Rows
                let pinnedData = null;
                if (pushPinnedDataFunction)
                    pinnedData = this.state.gridApi.pinnedRowModel.getPinnedTopRowData()[0].data;

                let data: any = [pinnedData, ...this.state.gridApi.getSelectedRows()];
                console.log("showme", data);
                rowSelectedFunction(data);
            }
        }
    };

    initTable(params: any) {
        // console.log('initTable');
        params.api.sizeColumnsToFit();
        
        // params.api.selectIndex(0, false, false);
        // params.api.sizeColumnsToFit();
    }

    render() {
        const defaultColDef = {
            // set every column width
            width: 100,
            // make every column editable
            editable: true,
            // make every column use 'text' filter by default
            filter: 'agTextColumnFilter',
        };


        const dynamicCellStyle = params => {
            // console.log(params)

            let style = { width: '', height: '', paddingTop: '', paddingLeft: '', display: '', alignItmes:'' };
            switch (params.colDef.field) {
                case 'vehicle':
                    //style.paddingTop = params.node.rowPinned === 'top' ? '1.3rem' : '0rem';
                    style.paddingLeft = params.node.rowPinned === 'top' ? '2.9rem' : null;
                    break;
                case 'dutyCycle':
                    style.paddingTop = params.node.rowPinned === 'top' ? '0.2rem' : '0rem';
                    style.display = 'block';
                    style.width = '100%';
                    break;
                case 'overallFuelEconomyAvg':
                case 'weightImpactAvgMpg':
                    style.display = 'block';
                    break;
                case 'totalConsumption':
                    style.width = '15%';
                    break;
                default:
                    // style.paddingTop = params.node.rowPinned === 'top' ? '1.5rem' : '1rem';
                    break;
            }
            if (params.colDef.field != 'dutyCycle') {
                style.alignItmes = 'center';
                style.display = 'flex';
            }
            return style;
        };

        const getRowHeight = params => {
            // console.log('getRowHeight');
            // console.log(params);
            // console.log(params.rowIndex === 0);
            return params.node.rowPinned === "top" ? 60 : 50
        };

        const getRowClass = params => {
            if (params.node.rowPinned === "top") {
                return 'borderBottomSilver';
            }
        };

        const onFirstDataRendered = (params) => {
            // console.log('onFirstDataRendered');
            // console.log(params);
            // params.api.selectIndex(0, false, false);
            params.api.sizeColumnsToFit();
            // console.log(params.api.pinnedRowModel.getPinnedTopRowData()[0].data);

            if (this.props.pushPinnedData)
                this.props.pushPinnedData([params.api.pinnedRowModel.getPinnedTopRowData()[0].data]);
        };

        const onGridSizeChanged = (params) => {
            params.api.sizeColumnsToFit();
        };

        const valueComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
            //console.log(valueA);
            if (isNullOrUndefined(valueA) || isNullOrUndefined(valueB)) return 0;
            if (valueA == valueB) return 0;
            //Since they're all hard strings use the above. When we get data identical to invision use something like below
            //if (isNullOrUndefined(valueA.total) || isNullOrUndefined(valueB.total)) return 0;
            //if (valueA.total == valueB.total) return 0;
            return (valueA > valueB) ? 1 : -1;
        };

        const vehicleNameCompare = (valueA, valueB, nodeA, nodeB, isInverted) => {
            if (isNullOrUndefined(valueA) || isNullOrUndefined(valueB)) return 0;
            if (valueA.name == valueB.name) return 0;
            return (valueA.name > valueB.name) ? 1 : -1;
        };

        if (!this.state.initialLoad && this.state.gridApi) {
            if (this.props.pushPinnedData && this.props.onRowSelected && this.state.gridApi.pinnedRowModel.getPinnedTopRowData()[0].data) {
                console.log(!this.state.initialLoad, this.state.gridApi, this.props.pushPinnedData, this.props.onRowSelected, this.state.gridApi.pinnedRowModel.getPinnedTopRowData()[0].data)
                this.props.onRowSelected([this.state.gridApi.pinnedRowModel.getPinnedTopRowData()[0].data]);
                this.setState({ initialLoad:true })
            }
        }

        return (
            <React.Fragment>
                <div
                    id="myGrid"
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                    className="ag-theme-alpine"
                >

                    <AgGridReact
                        onGridReady={(params) => { this.setState({ gridApi: params.api }, () => { this.initTable(params); }) }}

                        frameworkComponents={{
                            barCellRenderer: BarCellRenderer,
                            generalNumberCellRenderer: GeneralNumberCellRenderer,
                            vehicleCellRenderer: VehicleCellRenderer
                        }}
                        rowClass="datatable-row"

                        // suppressRowClickSelection={true} // bcc was here originally.  i left it commented out
                        // onGridReady={onFirstDataRendered} // bcc did not make a difference
                        // rowHeight={rowHeight}
                        // rowStyle={rowStyle}
                        onFirstDataRendered={onFirstDataRendered}
                        onGridSizeChanged={onGridSizeChanged}
                        getRowHeight={getRowHeight}
                        getRowClass={getRowClass}
                        pinnedTopRowData={this.getPinnedRowData()}
                        rowData={this.getRowData()}
                        pagination={true}
                        paginationPageSize={10}

                        // Selection options
                        // suppressRowClickSelection={true}
                        rowSelection={'multiple'}
                        //onSelectionChanged={this.onSelectionChanged}
                        rowMultiSelectWithClick={true}
                        onRowSelected={this.onRowSelected}
                        // defaultColDef={{
                        //     // headerCheckboxSelection: this.isFirstColumn,
                        //     checkboxSelection: this.isFirstColumn
                        //     
                        // }}
                        defaultColDef={{
                            comparator: valueComparator
                        }}
                    >
                        {this.props.columns.map(col =>
                            <AgGridColumn
                                checkboxSelection={col.columnName === "vehicle" && this.props.showCheckboxes}
                                headerCheckboxSelection={false}
                                wrapText={this.props.wrapText}
                                headerName={col.headerName}
                                hide={col.hide === null ? false : col.hide}
                                width={col.width}

                                // maxWidth={col.width}
                                cellRenderer=
                                {
                                    col.columnName === "dutyCycle" ? "barCellRenderer" :
                                        col.columnName === "vehicle" ? "vehicleCellRenderer"
                                            : "generalNumberCellRenderer"
                                }
                                field={col.columnName}
                                cellStyle={dynamicCellStyle}
                                sortable={col.columnName === "dutyCycle" ? false : true}
                                comparator={col.columnName === "vehicle" ? vehicleNameCompare : valueComparator}
                            >

                            </AgGridColumn>)}
                    </AgGridReact>
                </div>
            </React.Fragment>
        )
    }


}
