import * as React from 'react';

import {
    Row, Col, Spinner, Breadcrumb, BreadcrumbItem, Card, CardBody, CardTitle, CardText,
    CardDeck, ButtonGroup, Button, Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    InputGroup, InputGroupAddon, InputGroupText, InputGroupButtonDropdown
} from 'reactstrap';
import { LineChart2, LineChart, ColumnChart, DivergentStackBarChart, StackedColumnChart, SparkLineChart } from '../../Charts';
import { DropDownFilterBar } from '../../DropDownFilterBar'
import { Link } from 'react-router-dom';
import { DataTable } from '../../DataTable'
import { string } from '@amcharts/amcharts4/core';
import { DutyCycleSubnav } from '../SubNav'
import { InovexDropDown } from '../../InovexDropDown';

import '../Shared/styles.css';


interface IDashboardProps {

}

interface IDashboardState {
    tripHistory: any;
    dutyCycleSummary: any;
    driverBehavior: any;
    dutyCycleTable: any;
    dropdownOpen: boolean;
    splitButtonOpen: boolean;
}

interface ITripStat {
    Value: string;
    Units: string;
    Title: string;
}
export class DutyCycle extends React.Component<IDashboardProps, IDashboardState> {
    constructor(props: IDashboardProps) {
        super(props);

        this.toggleDropDown = this.toggleDropDown.bind(this);
        this.toggleSplit = this.toggleSplit.bind(this);

        this.state = {
            dropdownOpen: false,
            splitButtonOpen: false,
            tripHistory: <div style={{ width: "100%", textAlign: "center" }}>Loading...</div>,
            dutyCycleSummary: <div style={{ width: "100%", textAlign: "center" }}>Loading...</div>,
            driverBehavior: <div style={{ width: "100%", textAlign: "center" }}>Loading...</div>,
            dutyCycleTable: <p style={{ color: "white", width: "100%", textAlign: "center" }}>Loading...</p>
        }
    }

    toggleDropDown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    toggleSplit() {
        this.setState({
            splitButtonOpen: !this.state.splitButtonOpen
        });
    }
    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {

        //this.populateTripHistory(null);
        this.populateDutyCycleSummary();
        this.populateDriverBehavior(null);
        this.populateDutyCycleTable();
    }




    public populateTripHistory(json: any) {
        fetch('api/DutyCycle/GetTripHistory')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {

                let navitem = function (stat: ITripStat) {
                    return <div className="mb-4 ml-2" key={Math.random()}>
                        <span className="">{stat.Title}</span><br />
                        <span className="stat-large">{stat.Value}</span><strong>{stat.Units}</strong>
                    </div>
                };
                let tripHistory: any = <div style={{ width: "100%" }}>{data.map((stat: ITripStat, index) => navitem(stat))}</div>
                this.setState(
                    {
                        tripHistory: tripHistory
                    });


            })
            .catch((error: Error) => {
                throw error
            });

    }

    public populateDutyCycleSummary() {

        fetch('api/DutyCycle/GetDutyCycleTotal')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                console.log(data);
                let series =
                    [
                        { valueAxisName: 'shortHaul', label: 'Short Haul', color: "#6b469c" },
                        { valueAxisName: 'longHaul', label: 'Long Haul', color: "#bf5928" },
                        { valueAxisName: 'city', label: 'City', color: "#2a85c7" }
                    ];

                let chartProperties = {
                    useCursor: false,
                    useBullets: true,
                    useTooltip: false,
                    orientation: 'horizontal',
                    barThickness: 50,
                    categoryAxisName: 'dutycycle',
                    height: 300,
                    hideLabels: true,
                    valueAxisMinValue: 0,
                    valueAxisMaxValue: 100,
                    labelTextTemplateSizeOverride: 'large',
                    chartPadding:0,
                    numberFormat: '.#'
                    // yAxisTitle: { text: "Avg. Miles Driven at Load", fontSize: "10px" },
                    // xAxisTitle: { text: "LBS", fontSize: "10px" }
                };



                let chart = new StackedColumnChart({ chartData: data, chartContainer: 'dutyCycleChartContainer', chartProperties: chartProperties });
                chart.Create(series);


            })
            .catch((error: Error) => {
                throw error
            });


    }

    //public populateDriverBehavior(json: any) {
    //    let chart = am4core.create("chartdiv", am4charts.XYChart);

    //}

    public populateDriverBehavior(json: any) {
        let series =
            [
                { xAxis: "xAxis", yAxis: 'bar1', name: 'Harsh Acceleration', color: "#FD614A" },
                { xAxis: "xAxis", yAxis: 'bar2', name: 'Severe Breaking', color: "#2A85C7" }
            ];

        let chartData = [
            { xAxis: new Date(2018, 5, 10), bar1: "8", bar2: "5" },
            { xAxis: new Date(2018, 5, 30), bar1: "5.5", bar2: "7" },
            { xAxis: new Date(2018, 6, 15), bar1: "4.5", bar2: "9" },
            { xAxis: new Date(2018, 6, 31), bar1: "5", bar2: "7" },
            { xAxis: new Date(2018, 7, 15), bar1: "7.5", bar2: "8" },
            { xAxis: new Date(2018, 7, 25), bar1: "6.5", bar2: "5" }];

        let format = {
            series: {
                tensionX: 0.77
            },
            legend: {
                position: "bottom"
            }
        };

       // let highwaySpeedLineChart = new LineChart({ useCursor: true, chartData: json.highwaySpeed, chartContainer: 'driverBehaviorChartContainer', strokeColor: "#5F8993" });
       // 
       // highwaySpeedLineChart.Create('', 'Count of Events', '', null, null);

        var chart = new LineChart2({
            useCursor: true,
            chartContainer: 'driverBehaviorChartContainer'
        });
        chart.Create("Count of Events", chartData, series, format);
    }

    public populateDutyCycleTable() {
        fetch('api/DutyCycle/OverviewDataTable')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(rowData => {

                let columns: Array<{ columnName: string, headerName: string, width: number }> = [
                    { columnName: 'vehicle', headerName: 'Vehicle', width: 260 },
                    { columnName: 'totalMileage', headerName: 'Total Mileage (mi)', width: 120 },
                    { columnName: 'engineHours', headerName: 'Engine Hours (h)', width: 110 },
                    { columnName: 'idleHours', headerName: 'Idle Hours (h)', width: 100 },
                    { columnName: 'ptoHours', headerName: 'PTO Hours (h)', width: 100 },
                    { columnName: 'avgTripsPerDay', headerName: 'Avg. # of Trips', width: 100 },
                    { columnName: 'avgTripDistance', headerName: 'Avg. Trip Distance', width: 100 },
                    { columnName: 'dutyCycle', headerName: 'Duty Cycle', width: 300 },
                ];


                let table: any = <DataTable rowData={rowData} columns={columns} wrapText={true} autoHeight={true} showCheckboxes={true} min={1} max={2} onRowSelected={(params) => { this.tableSelectionFunction(params) }} pushPinnedData={(params) => { }} />

                this.setState(
                    {
                        dutyCycleTable: table
                    }, () => this.tableSelectionFunction([{ 'vehicle': {'name':'TOTAL'}}]));   // load TOTAL by default

            })
            .catch((error: Error) => {
                throw error
            });
    }

    tempDTO(data: any) {
        console.log(data);
        return [[
            { name: 'ROUTE (' + data.route.total + ')', metricName: '/fuel/route', value: data.route.total, dateTimeUtc: '2021-11-12T10:03:55.9567326-05:00' },
            { name: 'OTHER (' + data.driverBehavior.delta + ')', metricName: '', value: data.driverBehavior.delta, dateTimeUtc: '2021-11-12T10:03:55.9567355-05:00' },
            { name: 'DRIVER (' + data.driverBehavior.total + ')', metricName: '/fuel/behaviour', value: data.driverBehavior.total, dateTimeUtc: '2021-11-12T10:03:55.956737-05:00' },
            { name: 'VEHICLE (' + data.vehicleAttribute.total + ')', metricName: '', value: data.vehicleAttribute.total, dateTimeUtc: '2021-11-12T10:03:55.9567372-05:00' },
            { name: 'WEIGHT (' + data.weight.total + ')', metricName: '/fuel/weight', value: data.weight.total, dateTimeUtc: '2021-11-12T10:03:55.9567375-05:00' },
            { name: 'IDLE (' + data.fuelIdleSum.total + ')', metricName: '', value: data.fuelIdleSum.total, dateTimeUtc: '2021-11-12T10:03:55.9567377-05:00' },
        ],
        { title: data.vehicle.name, totalFuelUsedInPeriod: (Math.floor(Math.random() * 42561) + 94421).toLocaleString() + ' gal', totalExpectedFuelUsedInPeriod: (Math.floor(Math.random() * 42561) + 94421).toLocaleString() + ' gal', totalFuelWasted: '- ' + (Math.floor(Math.random() * 200) + 150).toLocaleString() + ' gal' }
        ]
    }

    tableSelectionFunction(data: any[]) {
        if (data.length == 0) {
            return;
        }
        var passedValues = []
        for (var i = 0; i < data.length; i++) {
            if (data[i].vehicle.name.toLowerCase() == "total") {
                passedValues.push("TOTAL");
            }
            passedValues.push(data[i].vehicle.vin);
        }
        console.log('api/DutyCycle/GetTripHistoryByItems?vehicles=' + passedValues.join('_'));
        fetch('api/DutyCycle/GetTripHistoryByItems?vehicles=' + passedValues.join('_'))
            //console.log('api/DutyCycle/GetTripHistoryByItems');
            // fetch('api/DutyCycle/GetTripHistoryByItems')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                console.log("data", data);
                var columns = [];

                // load the titles
                columns.push(
                    <Col>
                        <Row className='vehicleName'>
                        
                        </Row>
                        <Row className='pr-3 label' >
                            Avg. Miles per vehicle (mi)
                        </Row>
                        <Row className='pr-3 label'>
                            Avg. Distance per trip (mi)
                        </Row>
                        <Row className='pr-3 label'>
                            Avg. Trips per day
                        </Row>
                    </Col>
                );






                for (var i = 0; i < data.length; i++) {
                    columns.push(

                        <Col>
                            <Row className='vehicleName'>
                                <strong>{data[i][0].Name}</strong>
                            </Row>
                            <Row className='trip-value'>
                                <h5>{data[i][1].Value.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</h5>
                            </Row>
                            <Row className='trip-value'>
                                <h5>{data[i][2].Value.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</h5>
                            </Row>
                            <Row className='trip-value'>
                                <h5>{data[i][3].Value.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</h5>
                            </Row>
                        </Col>
                    );
                }

                /* <div className="mb-4 ml-2" key={Math.random()}>
                                <span className="">{stat.Title}</span><br />
                                <span className="stat-large">{stat.Value}</span><strong>{stat.Units}</strong>
                            </div>*/

                var tripHistory =
                    <div style={{ width: "100%" }}>
                        <Row className='m-1' id='trip-history-comparison'>
                            {columns}
                        </Row>
                    </div>;
                this.setState(
                    {
                        tripHistory: tripHistory
                    });


            })
            .catch((error: Error) => {
                throw error
            });
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <nav className="dashboardTitle" aria-label="breadcrumb">
                            <div className="breadcrumb">
                                <Link className="breadcrumb-item" to="/duty-cycle" >Duty Cycle</Link>
                                <a className="breadcrumb-item" >Overview</a>
                            </div>
                        </nav>
                    </Col>
                </Row>
                <DropDownFilterBar />
                <DutyCycleSubnav pageTitle="Duty Cycle" />

                <Row>
                    <Col>
                        <CardDeck>
                            <Card md="4" className="card-highlight">
                                <CardBody>
                                    <CardTitle tag="h5">Trip History</CardTitle>
                                    <CardText tag="div">
                                        {this.state.tripHistory}
                                    </CardText>
                                </CardBody>
                            </Card>

                            <Card md="4" className="card-highlight">
                                <CardBody>
                                    <CardTitle tag="h5">Duty Cycle Summary</CardTitle>
                                    <CardText tag="div" id="dutyCycleChartContainer">
                                        {this.state.dutyCycleSummary}
                                    </CardText>
                                </CardBody>
                            </Card>

                            <Card md="4" className="card-highlight">
                                <CardBody>
                                    <CardTitle tag="h5">Driver Behavior</CardTitle>
                                    <CardText tag="div" id="driverBehaviorChartContainer" style={{ height: "105%" }}>
                                        {this.state.driverBehavior}
                                    </CardText>
                                    <div id="chartLegendDiv" style={{ marginTop: "-80px" }}></div>
                                </CardBody>
                            </Card>
                        </CardDeck>
                    </Col>
                </Row>
                <Row className="pb-0 mb-0">
                    <Col xs="2" className="tile p-0 mb-0">
                        <div className="text-white pb-0 mb-0 pl-2 dropdownFleetHealthGroupByToggleLabel">
                            <div className="mt-0 pt-0 pb-0" style={{ float: "left", width: "33%", lineHeight: "30px" }}>
                                GROUP BY
                            </div>
                            <div className="mt-1 mb-1" style={{ display: "inline-block", width: "1px", backgroundColor: "white", height: "70%", float: "left" }} />
                            <div className="mt-0 pt-0" style={{ float: "left", width: "66%", height:"100%" }}>
                                <InovexDropDown dropDownId="dropDownGroupBy"
                                    dropDownToggleId="dropdownFleetHealthGroupByToggleID"
                                    initialValue="VIN"
                                    //onValueSelected={(trendValue: any) => this.changeTrendValue(trendValue)}
                                    dropDownStyle={{ width: "100%" }}
                                    omitSelected={false} >
                                    <span>VIN</span>
                                    <span>Region</span>
                                    <span>Total Mileage</span>
                                    <span>Engine Hours</span>
                                </InovexDropDown>

                            </div>

                        </div>

                    </Col>
                </Row>
                <Row className="pt-0 mt-0">
                    <Col className='tile pr-0 pb-0 pt-0'>
                        {this.state.dutyCycleTable}
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}
