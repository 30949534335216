import * as React from 'react';
import { Row, Col, Breadcrumb, BreadcrumbItem, ListGroup, ListGroupItem, Card, CardGroup, CardDeck, CardTitle, CardText, CardSubtitle, CardBody, Nav, NavItem, NavLink, Badge, Table, CardFooter } from 'reactstrap';
import { LineChart, ColumnChart, DivergentStackBarChart, StackedColumnChart, SparkLineChart } from '../../Charts';
import { DropDownFilterBar } from '../../DropDownFilterBar'
import { Link } from 'react-router-dom';
import '../Shared/styles.css';
import { FaTimesCircle, FaEllipsisH, FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { MdContentCopy } from 'react-icons/md';
import { FiMapPin } from 'react-icons/fi';

import { DataTable } from '../../DataTable/VehicleDiagnostics'
import { width } from '@amcharts/amcharts4/.internal/core/utils/Utils';
import { Button, number } from '@amcharts/amcharts4/core';
import { MdCancel } from "react-icons/md"; //critical

import ReactDOM from 'react-dom';

interface IVehicleHealthProps {
    vid: string;
}


interface IVehicleHealthState {
    loading: boolean;
    // VehicleParametersChart: CCVehicleParameters | null;
    diagnosticsRowData: any,     // 'any' becauase the structure of the objects in teh array will vary
    columns: Array<{ columnName: string }>,
    coolantLevelData: Array<{ Name: string, MetricName: string, DateTimeUTC: Date, Value: number }>,
    coolantTempData: Array<{ Name: string, MetricName: string, DateTimeUTC: Date, Value: number }>,
    defLevelData: Array<{ Name: string, MetricName: string, DateTimeUTC: Date, Value: number }>,
    oilTempData: Array<{ Name: string, MetricName: string, DateTimeUTC: Date, Value: number }>,
    sootLoadData: Array<{ Name: string, MetricName: string, DateTimeUTC: Date, Value: number }>,
    crankVoltageData: Array<{ Name: string, MetricName: string, DateTimeUTC: Date, Value: number }>,
    parametersChart: LineChart,
    vehicleHealthScore: number,
    vehicle:
    {
        makeModel: string,
        year: number,
        odometerMiles: number,
        location: string,
        fleet: string,
        vin: string,
        vehicleId: string,
        odometerKm: number,
        engineStatus: boolean,
        stopEngineLamp: boolean,
        amberCEL: boolean,
        obdLamp: boolean,
        dPFLamp: boolean,
        criticalDTCs: number,
        fuelLevel: number,
        defLevel: number,
        engCoolantTemp: number,
        engCoolantLevel: number,
        dpfHealthSoot: number,
        aBS: boolean,
        oilTemp: number,
        dpfHealth: string,
        timetoStart: number,
        crankVolts: number,
        vehicleHealth: number,
        milesSinceLastRegen: number,
        defFuelRatio: number,
        avgMilesSinceLastRegen: number,
        sootLoadDangerZone: number,
        milLampCounts: number
    }
}


export class VehicleHealth extends React.Component<IVehicleHealthProps, IVehicleHealthState> {
    constructor(props: IVehicleHealthProps) {
        super(props);
        //console.log(props);
        this.state = {
            loading: false,
            vehicleHealthScore: 0,
            // VehicleParametersChart: null,
            diagnosticsRowData: [],
            columns: [],
            coolantLevelData: [],
            coolantTempData: [],
            defLevelData: [],
            oilTempData: [],
            sootLoadData: [],
            crankVoltageData: [],
            parametersChart: null,
            vehicle:
            {
                makeModel: '',
                year: 0,
                odometerMiles: 0,
                location: '',
                fleet: '',
                vin: (props as any).match.params.vid,    // AH 20211104: I don't like having to cast props as any. Maybe we can find a better way to get the "vehicle id" from the URL
                vehicleId: '',
                odometerKm: 0,
                engineStatus: false,
                stopEngineLamp: false,
                amberCEL: false,
                obdLamp: false,
                dPFLamp: false,
                criticalDTCs: 0,
                fuelLevel: 0,
                defLevel: 0,
                engCoolantTemp: 0,
                engCoolantLevel: 0,
                dpfHealthSoot: 0,
                aBS: false,
                oilTemp: 0,
                dpfHealth: '',
                timetoStart: 0,
                crankVolts: 0,
                vehicleHealth: 0,
                milesSinceLastRegen: 0,
                defFuelRatio: 0,
                avgMilesSinceLastRegen: 0,
                sootLoadDangerZone: 80,
                milLampCounts: 0
            }

            //coolantTempChartData: [],
            //fuelLevelChartData: [],
            //crankingVoltageChartData: [],
            //chart: null,
            ////contributingFactorsChartData: [],
            ////contributingFactorsChart: null,
            //diagnostics:
            //    { diagnosticEngineCoolantTemperature: 0, diagnosticCoolantLevel: 0, diagnosticFuelLevel: 0, diagnosticOilPressure: 0, diagnosticDieselExhaustFluid: 0, diagnosticCrankingVoltage: 0, diagnosticEngineWarningLightIsOn: false, diagnosticGeneralVehicleWarningLampIsOn: false, diagnosticOilLampIsOn: false, diagnosticDieselParticulateFilterLampIsOn: false, diagnosticDashWarningLightRedLampIsOn: false}
        };
    }

    componentDidMount() {
        this.initialiseDashboard();
    }

    public initialiseRegenChart() {


        let milesSinceLastRegenPercentage = (this.state.vehicle.milesSinceLastRegen / this.state.vehicle.avgMilesSinceLastRegen) * 100;

        // Regen
        let lastRegenData = [
            {
                'distance': milesSinceLastRegenPercentage,
                'average': (100 - milesSinceLastRegenPercentage)
            }
        ];

        let lastRegenSeries =
            [
                { valueAxisName: 'distance', label: 'Long Haul', color: "#541C89" },
                { valueAxisName: 'average', label: 'Short Haul', color: "#e0e0e0" }
            ];

        let chartProperties = {
            useCursor: false,
            useTooltip: false,
            useBullets: false,
            orientation: 'horizontal',
            barThickness: 15,
            categoryAxisName: 'distance',
            height: 20,
            hideLabels: true,
            valueAxisMinValue: 0,
            valueAxisMaxValue: 100
        };

        let chart = new StackedColumnChart({ chartData: lastRegenData, chartContainer: 'dpfRegenChart', chartProperties: chartProperties });
        chart.Create(lastRegenSeries);

    }

    public initialiseSootLoadChart() {

        let sootLoadTotal: number = 100;
        let sootLoad: number = this.state.vehicle.dpfHealthSoot;
        if (sootLoad === null || sootLoad === undefined)
            sootLoad = 0;

        sootLoadTotal = 100 - sootLoad;

        // Soot Load
        let sootLoadData = [
            {
                'sootload': 'abc',
                'soot': sootLoad,
                'total': sootLoadTotal
            }
        ];

        let sootLoadColor: string = '#31A688';

        if (sootLoad >= 50 && sootLoad < 80)
            sootLoadColor = '#ff6a00';
        else if (sootLoad >= 80)
            sootLoadColor = '#ff0000';

        let sootLoadSeries =
            [
                { valueAxisName: 'soot', label: 'Long Haul', color: sootLoadColor },
                { valueAxisName: 'total', label: 'Short Haul', color: '#e0e0e0' }
            ];

        let chartProperties = {
            useCursor: false,
            useTooltip: false,
            useBullets: false,
            orientation: 'horizontal',
            barThickness: 15,
            categoryAxisName: 'soot',
            height: 20,
            hideLabels: true,
            valueAxisMinValue: 0,
            valueAxisMaxValue: 100

        };



        let chart = new StackedColumnChart({ chartData: sootLoadData, chartContainer: 'sootLoadChart', chartProperties: chartProperties });
        chart.Create(sootLoadSeries);

    }

    public initialiseDEFCharts() {
        let defLevelTotal: number = 100;
        let defLevel: number = this.state.vehicle.defLevel;
        if (defLevel === null || defLevel === undefined)
            defLevel = 0;

        defLevelTotal = 100 - defLevel;

        // Soot Load
        let defLevelChartData = [
            {
                'defLevelChart': 'abc',
                'defLevel': defLevel,
                'total': defLevelTotal
            }
        ];

        let defLevelSeries =
            [
                { valueAxisName: 'defLevel', label: 'Long Haul', color: "#541C89" },
                { valueAxisName: 'total', label: 'Short Haul', color: "#e0e0e0" }
            ];

        let defLevelChartProperties = {
            useCursor: false,
            useTooltip: false,
            useBullets: false,
            orientation: 'horizontal',
            barThickness: 15,
            categoryAxisName: 'defLevel',
            height: 20,
            hideLabels: true,
            valueAxisMinValue: 0,
            valueAxisMaxValue: 100

        };

        let defLevelChart = new StackedColumnChart({ chartData: defLevelChartData, chartContainer: 'defLevelChart', chartProperties: defLevelChartProperties });
        defLevelChart.Create(defLevelSeries);



        let defFuelChartData = [{
            "defFuelChart": "abc123",
            "leftside": 50,
            "middle": 35,
            "rightside": 15
        }];

        let defFuelSeries =
            [
                { valueAxisName: 'leftside', label: 'Long Haul', color: "#e0e0e0" },
                { valueAxisName: 'middle', label: 'Short Haul', color: "#080808" },
                { valueAxisName: 'rightside', label: 'Short Haul', color: "#e0e0e0" }
            ];

        let defFuelChartProperties = {
            useCursor: false,
            useTooltip: false,
            useBullets: false,
            orientation: 'horizontal',
            barThickness: 15,
            categoryAxisName: 'middle',
            height: 20,
            hideLabels: true,
            valueAxisMinValue: 0,
            valueAxisMaxValue: 100

        };

        let defFuelChart = new StackedColumnChart({ chartData: defFuelChartData, chartContainer: 'defFuelChart', chartProperties: defFuelChartProperties });
        defFuelChart.Create(defFuelSeries);

        // original code
        //let defFuelChart = new StackedColumnChart({ chartData: defFuelChartData, chartContainer: 'defFuelChart', orientation: 'horizontal', useCursor: true, barThickness: 15 });
        //defFuelChart.Create('defFuelChart', defFuelSeries, false);

        /* vvvv THIS IS THE CORRECT FORMAT vvv
        
               let series =
                   [
                       { valueAxisName: 'longHaul', label: 'Long Haul', color: "#6B469C" },
                       { valueAxisName: 'shortHaul', label: 'Short Haul', color: "#BE5928" },
                       { valueAxisName: 'city', label: 'City', color: "#0092CD" }
                   ];
        
               let chartProperties = {
                   useCursor: true,
                   useBullets: true,
                   orientation: 'horizontal',
                   barThickness: 50,
                   categoryAxisName: 'dutycycle',
                   height: 300,
                   hideLabels: true,
                   valueAxisMinValue: 0,
                   valueAxisMaxValue: 100
                   // yAxisTitle: { text: "Avg. Miles Driven at Load", fontSize: "10px" },
                   // xAxisTitle: { text: "LBS", fontSize: "10px" }
               };
        
        
        
               let chart = new StackedColumnChart({ chartData: tempDutyCycleChartData, chartContainer: 'dutycyclechartdiv', chartProperties: chartProperties });
               chart.Create(series);
               */

    }

    public initialiseFuelEconCharts(data: any) {

        var chart = new DivergentStackBarChart({
            chartData: data,
            chartContainer: "fuelEconChart",
            showLegend: false,
            orientation: "vertical",
            colorNegativeValues: "#3C6EB1",
            colorPositiveValues: "#A4282A",
            hideValueAxisLabels: true,
            hideCategoryAxisLabels: false,
            hideGridLines: true,
            useCursor: true,
            title: "+/- Predicated Gal",
            showSavedWasted: true
        });
        chart.Create();
    }

    public initialiseDutyCycleCharts() {
        let tempDutyCycleChartData = [{
            "dutycycle": "abc123",
            "longHaul": 60,
            "shortHaul": 30,
            "city": 10
        }];



        let series =
            [
                { valueAxisName: 'longHaul', label: 'Long Haul', color: "#6B469C" },
                { valueAxisName: 'shortHaul', label: 'Short Haul', color: "#BE5928" },
                { valueAxisName: 'city', label: 'City', color: "#0092CD" }
            ];

        let chartProperties = {
            useCursor: true,
            useBullets: true,
            orientation: 'horizontal',
            barThickness: 50,
            categoryAxisName: 'dutycycle',
            height: 500,
            hideLabels: true,
            valueAxisMinValue: 0,
            valueAxisMaxValue: 100,
            labelTextTemplateSizeOverride: 'large',
            useTooltip: false
            // yAxisTitle: { text: "Avg. Miles Driven at Load", fontSize: "10px" },
            // xAxisTitle: { text: "LBS", fontSize: "10px" }
        };

        let chart = new StackedColumnChart({ chartData: tempDutyCycleChartData, chartContainer: 'dutyCycleChart', chartProperties: chartProperties });
        chart.Create(series);

        // original code
        //let chart = new StackedColumnChart({ chartData: tempDutyCycleChartData, chartContainer: 'dutyCycleChart', orientation: 'horizontal', useCursor: true, barThickness: 50 });
        //chart.Create('dutycycle', series, true, 'xlarge');

    }

    public initialiseParametersChart() {
        this.state.parametersChart.Create('Coolant Level', '%', 'a', 0, 110, []);
    }

    async initialiseDashboard() {

        this.setState({ loading: true });

        // DATA RETRIEVAL
        this.fetchVehicleInfo();
        this.fetchCoolantLevel();  // The first call populates the graph when the data comes back. The subsequnt calls just put the data into the state   
        this.fetchCoolantTemp();
        this.fetchDefLevel();
        this.fetchOilTemp();
        this.fetchOilLevel();
        this.fetchSootLoad();
        this.fetchCrankVoltage();
        // this.fetchCrankVoltage();
        this.fetchFuelEconData(); // calls initialiseFuelEconCharts(data) on callback
        this.fetchHealthScore();


    }

    public initCharts() {
        this.initialiseRegenChart();
        this.initialiseSootLoadChart();
        this.initialiseDEFCharts();
        this.initialiseDutyCycleCharts();
        this.fetchDTCs();
    }

    public fetchDTCs() {
        fetch('api/Vehicle/DTC/' + this.state.vehicle.vin)
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(vehicleDiagnosticsData => {
                const columns = [
                    { columnName: 'dtc', headerName: 'DTC OEM/ASPN/FMI', width: 150 },
                    { columnName: 'description', headerName: 'Description', width: 110 },
                    { columnName: 'severity', headerName: 'Severity', width: 110 },
                    { columnName: 'module', headerName: 'Module', width: 110 },
                    { columnName: 'lampOn', headerName: 'Lamp On', width: 100 },
                    { columnName: 'lampOff', headerName: 'Lamp Off', width: 100 },
                    { columnName: 'first', headerName: 'First', width: 130 },
                    { columnName: 'last', headerName: 'Last', width: 130 }
                ];


                this.setState(
                    {
                        diagnosticsRowData: vehicleDiagnosticsData,
                        columns: columns,
                    }, this.render);

            })
            .catch((error: Error) => {
                throw error
            });
    }

    public fetchCoolantLevel() {
        fetch('api/Vehicle/SeriesData/' + this.state.vehicle.vin + '/engine_coolant_level/degC/2021-06-01/2021-08-30/Min')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                this.setState(
                    {
                        coolantLevelData: data,
                        parametersChart: new LineChart({ useCursor: true, chartData: data, chartContainer: 'VehicleParametersChart' }),
                    }, () => this.initialiseParametersChart());

            })
            .catch((error: Error) => {
                throw error
            });
    }

    public fetchHealthScore() {
        fetch('api/Vehicle/VehicleHealthScore/' + this.state.vehicle.vin)
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                this.setState({ vehicleHealthScore: data });

            })
            .catch((error: Error) => {
                throw error
            });
    }

    public fetchCoolantTemp() {
        fetch('api/Vehicle/SeriesData/' + this.state.vehicle.vin + '/engine_coolant_temp/degC/2021-06-01/2021-08-30/Max')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                console.log(data);
                this.setState({ coolantTempData: data });

                //                 this.setState(
                //                     {
                //                         coolantTempData: data,
                //                         parametersChart: new LineChart({ useCursor: true, chartData: data, chartContainer: 'VehicleParametersChart' }),
                //                     }, () => this.initialiseParametersChart());
                // 
            })
            .catch((error: Error) => {
                throw error
            });
    }

    public fetchDefLevel() {
        fetch('api/Vehicle/SeriesData/' + this.state.vehicle.vin + '/aftrtmnt_1_scr_cat_tank_level/percent/2021-06-01/2021-08-30/Min')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                this.setState({ defLevelData: data });

            })
            .catch((error: Error) => {
                throw error
            });

    }

    public fetchFuelEconData() {
        fetch('api/Fuel/ContributingFactors/' + this.state.vehicle.vin)
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                this.initialiseFuelEconCharts(data)
            })
            .catch((error: Error) => {
                throw error
            });

    }

    public fetchOilTemp() {
        fetch('api/Vehicle/SeriesData/' + this.state.vehicle.vin + '/ctv_ds_oil_temp/degC/2021-06-01/2021-08-30/Max')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                this.setState({ oilTempData: data });

            })
            .catch((error: Error) => {
                throw error
            });
    }

    public fetchOilLevel() {
    }

    public fetchSootLoad() {
        fetch('api/Vehicle/SeriesData/' + this.state.vehicle.vin + '/ctv_dpf_soot_load/percent/2021-06-01/2021-08-30/Max')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                this.setState({ sootLoadData: data });

            })
            .catch((error: Error) => {
                throw error
            });
    }

    public fetchCrankVoltage() {
        fetch('api/Vehicle/SeriesData/' + this.state.vehicle.vin + '/ctv_batt_volt_min/V/2021-06-01/2021-08-30/Min')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                this.setState({ crankVoltageData: data });

            })
            .catch((error: Error) => {
                throw error
            });

    }

    public fetchVehicleInfo() {
        fetch('api/Vehicle/Info/' + this.state.vehicle.vin)
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                this.setVehicleInfo(data)
            })
            .catch((error: Error) => {
                throw error
            });
    }

    public setVehicleInfo(vehicle: any) {
        // console.log(vehicle);
        this.setState({
            vehicle:
            {
                makeModel: vehicle.makeModel,
                year: vehicle.year,
                odometerMiles: vehicle.odometerMiles,
                location: vehicle.location,
                fleet: vehicle.fleet,
                vin: vehicle.vin,
                vehicleId: vehicle.vehicleId,
                odometerKm: vehicle.odometerKm,
                engineStatus: vehicle.engineStatus,
                stopEngineLamp: vehicle.stopEngineLamp,
                amberCEL: vehicle.amberCEL,
                obdLamp: vehicle.obdLamp,
                dPFLamp: vehicle.dPFLamp,
                criticalDTCs: vehicle.criticalDTCs,
                fuelLevel: vehicle.fuelLevel,
                defLevel: vehicle.defLevel,
                engCoolantTemp: vehicle.engCoolantTemp,
                engCoolantLevel: vehicle.engCoolantLevel,
                dpfHealthSoot: vehicle.dpfHealthSoot,
                aBS: vehicle.aBS,
                oilTemp: vehicle.oilTemp,
                dpfHealth: vehicle.dpfHealth,
                timetoStart: vehicle.timetoStart,
                crankVolts: vehicle.crankVolts,
                vehicleHealth: vehicle.health,
                defFuelRatio: 2.3,
                milesSinceLastRegen: 652,
                sootLoadDangerZone: 80,
                avgMilesSinceLastRegen: 1251,
                milLampCounts: 4
            }
        }, () => this.initCharts());

    }
    // END: DATA RETRIEVAL 


    // VEHICLE HEALTH RENDERING
    public vehicleHealthTable() {
        return <React.Fragment>
            <Row>
                <Col xs="12" className="ml-3 pl-3">
                    <div style={{ float: "left" }}>
                        <h1>{this.state.vehicleHealthScore}%</h1>
                    </div>
                    <div style={{ float: "left" }} className="pl-2 ml-2 mt-3">

                        <p id='vehicleHealthLabel'>Vehicle Health</p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs="2" className="ml-3 pl-3 pr-0 mr-0">
                    <img src='/images/stop-engine-on.png' style={{ height: "50px", width: "76px" }} />
                </Col>
                <Col xs="3" className="ml-5 pl-0 mr-0">
                    <strong>
                        Stop<br />
                        Engine
                    </strong>
                </Col>
                <Col xs="4" className="ml-2 pl-0">
                    {this.state.vehicle.stopEngineLamp === true &&
                        <h1><span style={{ color: '#ffffff', backgroundColor: '#b31323', borderRadius: '1rem' }} className="badge badge-light">ON</span></h1>}
                    {this.state.vehicle.stopEngineLamp === false &&
                        <h1><span style={{ color: '#ffffff', backgroundColor: '#e7e7e7', borderRadius: '1rem' }} className="badge badge-light">OFF</span></h1>}
                </Col>

            </Row>
            <Row>
                <Col xs="2" className="ml-3 pl-3 pr-0 mr-0">
                    <img src='/images/check-engine-on.png' style={{ height: "50px", width: "76px" }} />
                </Col>
                <Col xs="3" className="ml-5 pl-0 mr-0">
                    <strong>
                        Check<br />
                        Engine
                    </strong>
                </Col>
                <Col xs="4" className="ml-2 pl-0">
                    {this.state.vehicle.amberCEL === true &&
                        <h1><span style={{ color: '#ffffff', backgroundColor: '#FF8E07', borderRadius: '1rem' }} className="badge badge-light">ON</span></h1>}
                    {this.state.vehicle.amberCEL === false &&
                        <h1><span style={{ color: '#ffffff', backgroundColor: '#e7e7e7', borderRadius: '1rem' }} className="badge badge-light">OFF</span></h1>}
                </Col>

            </Row>
            <Row>
                <Col xs="2" className="ml-3 pl-3 pr-0 mr-0">
                    <img src='/images/mil-on.png' style={{ height: "50px", width: "76px" }} />
                </Col>
                <Col xs="3" className="ml-5 pl-0 mr-0">
                    <strong>
                        Check<br />
                        Engine
                    </strong>
                </Col>
                <Col xs="4" className="ml-2 pl-0">
                    {this.state.vehicle.obdLamp === true &&
                        <h1><span style={{ color: '#ffffff', backgroundColor: '#F1C21B', borderRadius: '1rem' }} className="badge badge-light">ON</span></h1>}
                    {this.state.vehicle.obdLamp === false &&
                        <h1><span style={{ color: '#ffffff', backgroundColor: '#e7e7e7', borderRadius: '1rem' }} className="badge badge-light">OFF</span></h1>}
                </Col>

            </Row>
            <Row className="border-top" style={{ justifyContent: 'space-evenly' }}>
                <Col xs="3" className="ml-4 pl-0 border-right pt-2" align="center">
                    <h1 className="vehiclehealth-healthpanel-summary-value">0</h1>
                    <h3 className="vehiclehealth-healthpanel-summary-label">STOP ENGINE COUNTS</h3>
                </Col>

                <Col xs="3" className="pl-4 pt-2 pr-3" align="center">
                    <h1 className="vehiclehealth-healthpanel-summary-value">{this.state.vehicle.milLampCounts}</h1>
                    <h3 className="vehiclehealth-healthpanel-summary-label">MIL LAMP COUNT</h3>
                </Col>

                <Col xs="3" className="ml-2 pl-4 pt-2 border-left" align="center">
                    <h1 className="vehiclehealth-healthpanel-summary-value">{this.state.vehicle.criticalDTCs}</h1>
                    <h3 className="vehiclehealth-healthpanel-summary-label">CEL COUNT</h3>
                </Col>

            </Row>


        </React.Fragment>
    };

    public getIcon(signal: string, signalValue: number) {
        let iconName: string = 'good';

        console.log(signal + ': ' + signalValue);

        switch (signal) {
            case 'crankVolts':
                if (signalValue === 0 || signalValue === undefined || signalValue === null)
                    return null;
                else if (signalValue > 0 && signalValue <= 7.5)
                    iconName = 'danger';
                else if (signalValue >= 7.5 && signalValue <= 8.0)
                    iconName = 'warning';
                else if (signalValue >= 8.1)
                    iconName = 'good';
                break;
            case 'fuelLevel':
                if (signalValue >= 0 && signalValue <= 9)
                    iconName = 'danger';
                else if (signalValue >= 10 && signalValue <= 60)
                    iconName = 'warning';
                else if (signalValue >= 61)
                    iconName = 'good';
                break;
            case 'engCoolantLevel':
                if (signalValue >= 0 && signalValue <= 79)
                    iconName = 'danger';
                else if (signalValue >= 80 && signalValue <= 90)
                    iconName = 'warning';
                else if (signalValue >= 91)
                    iconName = 'good';
                break;
            case 'defLevel':
                if (signalValue >= 0 && signalValue <= 29)
                    iconName = 'danger';
                else if (signalValue >= 30 && signalValue <= 60)
                    iconName = 'warning';
                else if (signalValue >= 61)
                    iconName = 'good';
                break;
            case 'engOilTemp':
            case 'engCoolantTemp':
                // if (signalValue >= 0 && signalValue <= 59)
                //     iconName = 'danger';
                // else if (signalValue >= 60 && signalValue <= 80)
                //     iconName = 'warning';
                // else if (signalValue >= 81 && signalValue <= 110)
                if (signalValue <= 104)
                    iconName = 'good';
                else if (signalValue >= 105 && signalValue <= 120)
                    iconName = 'warning';
                if (signalValue >= 121)
                    iconName = 'danger';
                break;
            case 'dpfHealthSoot':
                if (signalValue >= 0 && signalValue <= 49)
                    iconName = 'good';
                else if (signalValue >= 75 && signalValue <= 90)
                    iconName = 'warning';
                else if (signalValue >= 91)
                    iconName = 'danger';
                break;
        }

        return <span>
            {iconName === "danger" && <FaTimesCircle color="red" />}
            {iconName === "good" && <FaCheckCircle color="green" />}
            {iconName === "warning" && <FaExclamationCircle color="orange" />}
        </span>



    }

    public vehicleVitalsTable() {
        let vehicle = this.state.vehicle;
        let isLightDuty: boolean = vehicle.makeModel == 'Ford Transit' || vehicle.makeModel == 'Ford F150';
        let crankingVolts: string = vehicle.crankVolts > 0 ? vehicle.crankVolts + 'v' : 'n/a';
        let dpfHealthSoot: string = vehicle.dpfHealthSoot > 0 ? vehicle.dpfHealthSoot + '%' : 'n/a';

        let dpfAshPack: string = isLightDuty ? 'n/a' : '33%';
        let dpfDeltaPressure: string = isLightDuty ? 'n/a' : '23psi';

        let dpfHealthSootIcon: any = isLightDuty || dpfHealthSoot === 'n/a' ? null : this.getIcon('dpfHealthSoot', vehicle.dpfHealthSoot);
        let dpfDeltaPressureIcon: any = isLightDuty ? null : this.drawIcon("good");
        let dpfAshPackIcon: any = isLightDuty ? null : this.drawIcon("good");


        return <div>
            <table className="stats-table">
                <tbody>
                    <tr>
                        <td>Engine Crank Voltage</td>
                        <td>{crankingVolts}</td>
                        <td>{this.getIcon('crankVolts', vehicle.crankVolts)}</td>
                    </tr>
                    <tr>
                        <td>Engine Start Time</td>
                        <td>{vehicle.timetoStart}s</td>
                        <td>{this.drawIcon("good")}</td>
                    </tr>
                    <tr>
                        <td>Engine Coolant Temp</td>
                        <td>{vehicle.engCoolantTemp}&deg;C</td>
                        <td>{this.getIcon('engCoolantTemp', vehicle.engCoolantTemp)}</td>
                    </tr>
                    <tr>
                        <td>Engine Oil Temp</td>
                        <td>{vehicle.oilTemp}&deg;C</td>
                        <td>{this.getIcon('engOilTemp', vehicle.oilTemp)}</td>
                    </tr>
                    <tr>
                        <td>Engine Oil Pressure Cold Start</td>
                        <td>23psi</td>
                        <td>{this.drawIcon("good")}</td>
                    </tr>
                    <tr>
                        <td>Transmission Fluid Temp</td>
                        <td>53&deg;C</td>
                        <td>{this.drawIcon("good")}</td>
                    </tr>
                    <tr>
                        <td>DPF Soot Level %</td>
                        <td>{dpfHealthSoot}</td>
                        <td>{dpfHealthSootIcon}</td>
                    </tr>
                    <tr>
                        <td>DPF Ash Pack %</td>
                        <td>{dpfAshPack}</td>
                        <td>{dpfAshPackIcon}</td>
                    </tr>
                    <tr>
                        <td>DPF Delta Pressure</td>
                        <td>{dpfDeltaPressure}</td>
                        <td>{dpfDeltaPressureIcon}</td>
                    </tr>

                </tbody>
            </table>
        </div>
    };

    public vehicleVConsumablesTable() {
        let vehicle = this.state.vehicle;

        let isLightDuty: boolean = vehicle.makeModel == 'Ford Transit' || vehicle.makeModel == 'Ford F150';
        let defLevel: string = isLightDuty ? 'n/a' : vehicle.defLevel + '%';
        let defLevelIcon: any = isLightDuty ? null : this.getIcon('defLevelIcon', vehicle.defLevel);

        return <div>
            <table className="stats-table">
                <tbody>
                    <tr>
                        <td>Fuel Level</td>
                        <td>{vehicle.fuelLevel}%</td>
                        <td>{this.getIcon('fuelLevel', vehicle.fuelLevel)}</td>
                    </tr>
                    <tr>
                        <td>DEF Level</td>
                        <td>{defLevel}</td>
                        <td>{defLevelIcon}</td>
                    </tr>
                    <tr>
                        <td>Engine Coolant Level</td>
                        <td>{vehicle.engCoolantLevel}%</td>
                        <td>{this.getIcon('engCoolantLevel', vehicle.engCoolantLevel)}</td>
                    </tr>
                    <tr>
                        <td>Wiper Fluid Level</td>
                        <td>66%</td>
                        <td>{this.drawIcon("good")}</td>
                    </tr>
                    <tr>
                        <td>Engine Oil Level</td>
                        <td>97%</td>
                        <td>{this.drawIcon("good")}</td>
                    </tr>
                    <tr>
                        <td>Transmission Fluid Level</td>
                        <td>67%</td>
                        <td>{this.drawIcon("good")}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    };
    // END VEHICLE HEALTH RENDERING



    // PARAMETERS CHART
    public ParametersNavClick(nav: any, item: any) {
        this.state.parametersChart.ReDraw(item.name, item.unit, item.data, [], item.min, item.max)
    }

    public ParametersNav() {



        let parameters = [{
            section: 'ENGINE',
            items: [
                // {
                //     name: 'Oil Level',
                //     data: this.state.coolantLevelData,
                //     signal: 'engine_coolant_level',
                //     unit: '%',
                //     min: 0,
                //     max: 110
                // },
                {
                    name: 'Coolant Level',
                    data: this.state.coolantLevelData,
                    signal: 'engine_coolant_level',
                    unit: '%',
                    min: 0,
                    max: 110,
                    status: ''
                },
                {
                    name: 'Coolant Temperature',
                    data: this.state.coolantTempData,
                    signal: 'engine_coolant_temp',
                    unit: 'deg C',
                    min: 30,
                    max: 120
                },
                {
                    name: 'Oil Temp',
                    data: this.state.oilTempData,
                    signal: 'ctv_ds_oil_temp',
                    unit: 'deg C',
                    min: 30,
                    max: 120
                },
                // {
                //     name: 'Oil Pressure Cold Start',
                //     data: this.state.oilTempData,
                //     signal: 'ctv_ds_oil_temp',
                //     unit: 'psi',
                //     min: 30,
                //     max: 120
                // },
                // {
                //     name: 'Start Time',
                //     data: this.state.oilTempData,
                //     signal: 'ctv_ds_oil_temp',
                //     unit: 'Time',
                //     min: 30,
                //     max: 120
                // },
                {
                    name: 'Crank Voltage',
                    data: this.state.crankVoltageData,
                    signal: 'ctv_batt_volt_min',
                    unit: 'volts',
                    min: 0,
                    max: 15,
                    status: ''
                }
            ]
        },
        {
            section: 'AFTERTREATMENT',
            items: [
                {
                    name: 'DEF Level',
                    data: this.state.defLevelData,
                    signal: 'aftrtmnt_1_scr_cat_tank_level',
                    unit: '%',
                    min: 0,
                    max: 110
                },
                {
                    name: 'Soot Load',
                    data: this.state.sootLoadData,
                    signal: 'ctv_dpf_soot_load',
                    unit: '%',
                    min: 0,
                    max: 110
                }
            ],
        }
        ];



        return <div>
            <Nav vertical id="VehicleParametersChartNav" className="vehicleParametersNav">

                {parameters.map((param, index) => (
                    <div key={index}>
                        <NavLink href="#" value={param.section} disabled >{param.section}</NavLink>

                        {param.items.map((i, ind) => (
                            <div>
                                <div style={{ float: "left", width: "90%", height: "30px" }}>
                                    <NavLink href="#" onClick={(nav) => this.ParametersNavClick(nav, i)} value={i.signal} >
                                        {i.name}
                                    </NavLink>
                                </div>
                                <div style={{ float: "right", height: "22px" }}>
                                    {(i.status === "warning"
                                        ? <div>
                                            <MdCancel className="text-danger" fontSize="20px" />
                                        </div>
                                        : <div style={{ height: "21px" }}></div>)}
                                </div>

                            </div>

                        ))}
                    </div>
                ))}

            </Nav>
        </div>
    }
    // END: PARAMETERS CHART

    public drawIcon(status: string) {
        return <span>
            {status === "danger" && <FaTimesCircle color="red" />}
            {status === "good" && <FaCheckCircle color="green" />}
            {status === "warning" && <FaExclamationCircle color="orange" />}
        </span>
    };

    render() {
        let diagnosticsDataTable;
        if (this.state.diagnosticsRowData && this.state.columns)
            diagnosticsDataTable = <DataTable rowData={this.state.diagnosticsRowData} columns={this.state.columns} />

        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <Breadcrumb className="dashboardTitle">
                            <BreadcrumbItem><Link to="/health">HEALTH</Link></BreadcrumbItem>
                            <BreadcrumbItem><Link to="/health-general">Fleet Health</Link></BreadcrumbItem>
                            <BreadcrumbItem active>Vehicle Health</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                </Row>

                <DropDownFilterBar />

                <Row>
                    <Col>
                        <h2>{this.state.vehicle.makeModel} {this.state.vehicle.year}</h2>
                    </Col>
                </Row>

                <Row>
                    <Col md="12">
                        <CardDeck>
                            <Card className="card-highlight">
                                <CardBody>
                                    <CardTitle tag="h5">
                                        VIN
                                        <div style={{ float: "right" }} className="mr-3 mt-0 pt-0">
                                            <MdContentCopy className="text-white pb-1" />

                                        </div>
                                    </CardTitle>
                                    <div className="card-text" id="lblVin">
                                        {this.state.vehicle.vin}
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-highlight">
                                <CardBody>
                                    <CardTitle tag="h5">
                                        VEHICLE ID
                                        <div style={{ float: "right" }} className="mr-3 mt-0 pt-0">
                                            <MdContentCopy className="text-white pb-1" />

                                        </div>
                                    </CardTitle>
                                    <div className="card-text" id="lblvin">
                                        {this.state.vehicle.vehicleId}
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-highlight">
                                <CardBody>
                                    <CardTitle tag="h5">
                                        FLEET
                                        <div style={{ float: "right" }} className="mr-3 mt-0 pt-0">
                                            <MdContentCopy className="text-white pb-1" />

                                        </div>
                                    </CardTitle>
                                    <div className="card-text" id="lblFleet">
                                        {this.state.vehicle.fleet}
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-highlight">
                                <CardBody>
                                    <CardTitle tag="h5">
                                        ODOMETER
                                        <div style={{ float: "right" }} className="mr-3 mt-0 pt-0">
                                            <MdContentCopy className="text-white pb-1" />

                                        </div>
                                    </CardTitle>
                                    <div className="card-text" id="lblOdometer">
                                        {this.state.vehicle.odometerMiles.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}mi
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-highlight">
                                <CardBody>
                                    <CardTitle tag="h5">
                                        LOCATION
                                        <div style={{ float: "right" }} className="mr-3 mt-0 pt-0">
                                            <FiMapPin className="text-white pb-1" />

                                        </div>

                                    </CardTitle>
                                    <div className="card-text" id="lblLocation">
                                        {this.state.vehicle.location}
                                    </div>
                                </CardBody>
                            </Card>
                        </CardDeck>
                    </Col>
                </Row>

                <Row className="vehicleHealthRow">
                    <Col>
                        <CardGroup className="dark-background">
                            <Card className="card-secondary-dark">
                                <CardBody>
                                    <CardTitle tag="h5">
                                        HEALTH
                                        <div style={{ float: "right", fontWeight: "normal" }} className="mr-3 pr-3">
                                            -7.1%
                                        </div>

                                    </CardTitle>
                                    <div className="card-text" id="VehicleHealthTable">
                                        {this.vehicleHealthTable()}
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-secondary-dark">
                                <CardBody>
                                    <CardTitle tag="h5">LAST REPORTED</CardTitle>
                                    <div className="card-text" id="VehicleVitalsTable">
                                        {this.vehicleVitalsTable()}
                                    </div>
                                </CardBody>
                            </Card>
                            <Card className="card-secondary-dark">
                                <CardBody>
                                    <CardTitle tag="h5">CONSUMABLES</CardTitle>
                                    <div className="card-text" id="VehicleConsumablesTable">
                                        {this.vehicleVConsumablesTable()}
                                    </div>
                                </CardBody>
                            </Card>
                        </CardGroup>
                    </Col>
                </Row>

                <Row className="panel-group">
                    <Col>
                        <Card className="card-highlight disagnostics" >
                            <CardBody>
                                <CardText tag="div">
                                    <div className="padding10">
                                        <div className="dateRangeText">Date Range: 2021/06/01 - 2021/08/31</div>
                                        <h3>Diagnostics </h3>
                                        {diagnosticsDataTable}
                                    </div>
                                </CardText>
                            </CardBody >
                        </Card >
                    </Col >
                </Row >

                <Row className="panel-group">
                    <Col>
                        <Card className="card-highlight">
                            <CardBody>
                                <CardText tag="div">
                                    <div className="padding10">
                                        <div className="dateRangeText">Date Range: 2021/06/01 - 2021/08/31</div>
                                        <h3>Parameters </h3>
                                    </div>
                                    <Row>
                                        <Col md="3" xs="12">
                                            <div className="bgWhite height-100-percent">
                                                {this.ParametersNav()}
                                            </div>
                                        </Col>
                                        <Col md="9" xs="12" className="bgWhite">
                                            <div className="bgWhite">
                                                <div id="VehicleParametersChart" style={{ width: "100%", height: "500px" }}>Loading...</div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row className="panel-group" id="Aftertreatment">
                    <Col>
                        <Row>
                            <Col>
                                <div className="padding10">
                                    <div className="dateRangeText">Date Range: 2021/06/01 - 2021/08/31</div>
                                    <h3>Aftertreatment</h3>
                                </div>
                            </Col>
                        </Row>

                        <Row className="row-eq-height">
                            <Col md="6">
                                <Card className="card-highlight bgWhite">
                                    <CardBody>
                                        <CardTitle tag="h5">DIESEL PARTICULATE FILTER HEALTH</CardTitle>
                                        <CardText tag="div">
                                            <p><strong>DPF Health Status:</strong></p>
                                            <h2 className={this.state.vehicle.dpfHealth}> {this.state.vehicle.dpfHealth}</h2>

                                            <div className="regenChartContainer overflow-hidden">
                                                <p><strong>Miles since last regen</strong> {this.state.vehicle.milesSinceLastRegen}mi</p>
                                                <div className="dpfRegenChart stackedMiniChart"></div>
                                                <p className="margin-top-minus15">Average - {this.state.vehicle.avgMilesSinceLastRegen}mi</p>
                                            </div>
                                            <div className="sootLoadChartContainer overflow-hidden">
                                                <p><strong>Soot Load</strong> {this.state.vehicle.dpfHealthSoot}%</p>
                                                <div className="sootLoadChart stackedMiniChart"></div>
                                                <p className="margin-top-minus15">Danger Zone - {this.state.vehicle.sootLoadDangerZone}%</p>
                                            </div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md="6">
                                <Card className="card-highlight bgWhite">
                                    <CardBody>
                                        <CardTitle tag="h5">EMISSION SYSTEM HEALTH</CardTitle>
                                        <CardText tag="div">
                                            <h2>{this.state.vehicle.defLevel}%</h2>
                                            <div className="regenChartContainer overflow-hidden">
                                                <p><strong>DEF Level</strong> {this.state.vehicle.defLevel}%</p>
                                                <div className="defLevelChart stackedMiniChart"></div>
                                                <p>Diesel Emissions Fluid DTE - 3952mi</p>
                                            </div>
                                            <div className="defFuelChartContainer overflow-hidden">
                                                <p><strong>DEF/Fuel Ratio</strong> {this.state.vehicle.defFuelRatio}%</p>
                                                <div className="defFuelChart stackedMiniChart"></div>
                                                <p>Optimal Ratio 1.9% - 4%</p>
                                            </div>
                                        </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        {/*<Col>*/}
                        {/*    <button className="btn btn-seasick">View Aftertreatment Analysis</button>*/}
                        {/*</Col>*/}
                    </Row>
                </Col>
            </Row >


                <Row className="panel-group">
                    <Col>
                        <Row>
                            <Col>
                                <div className="padding10">
                                    <div className="dateRangeText">Date Range: 2021/06/01 - 2021/08/31</div>
                                    <h3>Fuel Efficiency</h3>
                                </div>
                            </Col>
                        </Row>

                        <Row className="row-eq-height">
                            <Col md="6">
                                <Card className="card-highlight bgWhite" style={{ height: "100%" }}>
                                    <CardBody>
                                        <CardTitle tag="h5">
                                            AVERAGE FUEL ECONOMY
                                            <div className="mr-3 pt-0 mt-0" style={{ float: "right", fontWeight: "normal" }}>+1.0</div>
                                        </CardTitle>
                                        <CardText tag="div">
                                            <Row>
                                                <Col md="7">
                                                    <div className="fuelEconChart" style={{ height: "300px" }}></div>
                                                </Col>
                                                <Col md="5" id="AvgFuelEcoPanel_Totals">
                                                    <table className='fuelEconSummaryTable'>
                                                        <tbody>
                                                            <tr>
                                                                <td className='width-33-percent border-left-bottom'>
                                                                    <h3>-492 </h3>
                                                                </td>
                                                                <td className='width-66-percent border-bottom'>
                                                                    <h4><small>Sub-Total Volume</small></h4>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='width-33-percent border-left-bottom' >
                                                                    <h4>-3,319</h4>
                                                                </td>
                                                                <td className='width-66-percent border-bottom' >
                                                                    <h4><small>Expected Volume</small></h4>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='width-33-percent border-left'>
                                                                    <h4>-3,697</h4>
                                                                </td>
                                                                <td className='width-66-percent'>
                                                                    <h4><small>Total Volume</small></h4>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </Col>
                                            </Row>

                                            <button className="btn btn-seasick">Go to Deep Analysis</button>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6">
                                <Card className="card-highlight bgWhite" style={{ height: "100%" }}>
                                    <CardBody>
                                        <CardTitle tag="h5">DUTY CYCLE</CardTitle>
                                        <CardText tag="div" >
                                            <Row>
                                                <Col style={{ height: "300px" }}>
                                                    <div className="dutyCycleChart" ></div>
                                                </Col>
                                            </Row>
                                            <button className="btn btn-seasick" style={{ verticalAlign: "bottom" }}>Compare to Fleet</button>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}


