import * as React from 'react';

import {
    Row, Col, Spinner, Breadcrumb, BreadcrumbItem, Card, CardBody, CardTitle, CardText,
    CardDeck, ButtonGroup, Button, Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    InputGroup, InputGroupAddon, InputGroupText, InputGroupButtonDropdown
} from 'reactstrap';
import { StackedColumnChart } from '../../Charts';
import { DropDownFilterBar } from '../../DropDownFilterBar'
import { Link } from 'react-router-dom';
import { DataTable } from '../../DataTable'
import { InovexDropDown } from '../../InovexDropDown'
import { DutyCycleSubnav } from '../SubNav'

interface IDashboardProps {

}

interface IDashboardState {
    weightAnalysisChart: any;
    dutyCycleTable: any;
    dropdownOpen: boolean;
    splitButtonOpen: boolean;
    weightBinsChart: StackedColumnChart;
}

interface ITripStat {
    Value: string;
    Units: string;
    Title: string;
}
export class WeightAnalysis extends React.Component<IDashboardProps, IDashboardState> {
    constructor(props: IDashboardProps) {
        super(props);

        this.toggleDropDown = this.toggleDropDown.bind(this);
        this.toggleSplit = this.toggleSplit.bind(this);

        this.state = {
            weightBinsChart: null,
            dropdownOpen: false,
            splitButtonOpen: false,
            weightAnalysisChart: <div style={{ width: "100%", textAlign: "center" }}>Loading...</div>,
            dutyCycleTable: <h1 style={{ color: "white", width: "100%", textAlign: "center" }}>Loading...</h1>
        }
    }

    toggleDropDown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    toggleSplit() {
        this.setState({
            splitButtonOpen: !this.state.splitButtonOpen
        });
    }
    componentDidMount() {
        this.fetchData();
        this.populateData()
    }

    async populateData() {
        // add any other methods that will populate data in the UI
        this.populateDutyCycleTable();
    }




    async fetchData() {


    }
    pushPinnedData(data: any[]) {
        this.updateChart(data);
    }

    updateChart(data: any[]) {

        let colors = ['#2A85C7', '#BE5928', '#6B469C', '#a4282a']



        console.log('updateChart');
        console.log(data);
        let weightAnalysisSeries = [];
        let newData = [
            { 'weightBin': '0' },
            { 'weightBin': '5k' },
            { 'weightBin': '10k' },
            { 'weightBin': '15k' },
            { 'weightBin': '20k' },
            { 'weightBin': '25k' },
            { 'weightBin': '30k' },
            { 'weightBin': '35k' },
            { 'weightBin': '40k' },
            { 'weightBin': '45k' },
            { 'weightBin': '50k' },
            { 'weightBin': '55k' },
            { 'weightBin': '60k' },
            { 'weightBin': '65k' },
            { 'weightBin': '70k' },
            { 'weightBin': '75k' },
            { 'weightBin': '80k' },
            { 'weightBin': '85k' },
            { 'weightBin': '90k' },
            { 'weightBin': '95k' },
            { 'weightBin': '100k' }
        ];

        for (let d = 0; d < data.length; d++) {

            newData[0][data[d].vehicle.vin] = data[d].weight0kTo5kPounds
            newData[1][data[d].vehicle.vin] = data[d].weight5kTo10kPounds
            newData[2][data[d].vehicle.vin] = data[d].weight10kTo15kPounds
            newData[3][data[d].vehicle.vin] = data[d].weight15kTo20kPounds
            newData[4][data[d].vehicle.vin] = data[d].weight20kTo25kPounds
            newData[5][data[d].vehicle.vin] = data[d].weight25kTo30kPounds
            newData[6][data[d].vehicle.vin] = data[d].weight30kTo35kPounds
            newData[7][data[d].vehicle.vin] = data[d].weight35kTo40kPounds
            newData[8][data[d].vehicle.vin] = data[d].weight40kTo45kPounds
            newData[9][data[d].vehicle.vin] = data[d].weight45kTo50kPounds
            newData[10][data[d].vehicle.vin] = data[d].weight50kTo55kPounds
            newData[11][data[d].vehicle.vin] = data[d].weight55kTo60kPounds
            newData[12][data[d].vehicle.vin] = data[d].weight60kTo65kPounds
            newData[13][data[d].vehicle.vin] = data[d].weight65kTo70kPounds
            newData[14][data[d].vehicle.vin] = data[d].weight70kTo75kPounds
            newData[15][data[d].vehicle.vin] = data[d].weight75kTo80kPounds
            newData[16][data[d].vehicle.vin] = data[d].weight80kTo85kPounds
            newData[17][data[d].vehicle.vin] = data[d].weight85kTo90kPounds
            newData[18][data[d].vehicle.vin] = data[d].weight90kTo95kPounds
            newData[19][data[d].vehicle.vin] = data[d].weight95kTo100kPounds
            newData[19][data[d].vehicle.vin] = data[d].weightOver100kPounds

            weightAnalysisSeries.push({ valueAxisName: data[d].vehicle.vin, label: data[d].vehicle.vin, color: colors[d] })
        }

        let chartProperties = {
            useCursor: false,
            useTooltip: true,
            useBullets: false,
            orientation: 'vertical',
            barThickness: 30,
            categoryAxisName: 'weightBin',
            numberFormat: '#,###.',
            labelTextTemplateOverride: '{categoryX} lbs - {name}: {valueY}mi ',
            valueAxisRenderInside: false,
            height: 600,
            legend: {
                //legendContainer: "reusablechartlegenddiv",
                position: 'bottom'
            },
            stacked: false,
            rangeFill: {
                startCategory: "80k",
                endCategory: "100k",
                color: "#FF8389"
            },
            flagProps: {
                labelText: "Limit - 80k",
                XYaxis: "Y", // acceptable values are 'X' or 'Y'
                startValue: "75k",
                endValue: "80k",
                //fontSize?: number,
                horizontalCenter: "left",
                pole: {
                    color: "#FF8389",
                    strokeWidth: 2,
                    height: 235,
                    dash: "8 4"
                },
                background: {
                    waveLength: 0,
                    color: "#FF8389"
                }
            },
            yAxisTitle: { text: "Avg. Miles Driven at weight", fontSize: "10px" },
            xAxisTitle: { text: "Weight", fontSize: "10px" }
        };

        let chart = new StackedColumnChart({ chartData: newData, chartContainer: 'weightAnalysisChartContainer', chartProperties: chartProperties });
        console.log("populateWeightAnalysisChart");
        this.setState({ weightBinsChart: chart }, () => this.state.weightBinsChart.Create(weightAnalysisSeries))

    }

    public populateDutyCycleTable() {
        fetch('api/DutyCycle/WeightDataTable')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(rowData => {

                let columns: Array<{ columnName: string, headerName: string, width: number, hide?: boolean }> = [
                    { columnName: 'vehicle', headerName: 'Vehicle', width: 300 },
                    { columnName: 'totalMileage', headerName: 'Total Mileage (mi)', width: null },
                    { columnName: 'minPounds', headerName: 'Min Pounds', width: null },
                    { columnName: 'maxPounds', headerName: 'Max Pounds', width: null },
                    { columnName: 'meanPounds', headerName: 'Mean Pounds', width: null },
                    // { columnName: 'percentHistory', headerName: 'Percent History', width: null },
                    { columnName: 'weight0kTo5kPounds', headerName: 'empty header', width: 0, hide: true },
                    { columnName: 'weight5kTo10kPounds', headerName: 'empty header', width: 0, hide: true },
                    { columnName: 'weight10kTo15kPounds', headerName: 'empty header', width: 0, hide: true },
                    { columnName: 'weight15kTo20kPounds', headerName: 'empty header', width: 0, hide: true },
                    { columnName: 'weight20kTo25kPounds', headerName: 'empty header', width: 0, hide: true },
                    { columnName: 'weight25kTo30kPounds', headerName: 'empty header', width: 0, hide: true },
                    { columnName: 'weight30kTo35kPounds', headerName: 'empty header', width: 0, hide: true },
                    { columnName: 'weight35kTo40kPounds', headerName: 'empty header', width: 0, hide: true },
                    { columnName: 'weight40kTo45kPounds', headerName: 'empty header', width: 0, hide: true },
                    { columnName: 'weight45kTo50kPounds', headerName: 'empty header', width: 0, hide: true },
                    { columnName: 'weight50kTo55kPounds', headerName: 'empty header', width: 0, hide: true },
                    { columnName: 'weight55kTo60kPounds', headerName: 'empty header', width: 0, hide: true },
                    { columnName: 'weight60kTo65kPounds', headerName: 'empty header', width: 0, hide: true },
                    { columnName: 'weight65kTo70kPounds', headerName: 'empty header', width: 0, hide: true },
                    { columnName: 'weight70kTo75kPounds', headerName: 'empty header', width: 0, hide: true },
                    { columnName: 'weight75kTo80kPounds', headerName: 'empty header', width: 0, hide: true },
                    { columnName: 'weight80kTo85kPounds', headerName: 'empty header', width: 0, hide: true },
                    { columnName: 'weight85kTo90kPounds', headerName: 'empty header', width: 0, hide: true },
                    { columnName: 'weight90kTo95kPounds', headerName: 'empty header', width: 0, hide: true },
                    { columnName: 'weight95kTo100kPounds', headerName: 'empty header', width: 0, hide: true },
                    { columnName: 'weightOver100kPounds', headerName: 'empty header', width: 0, hide: true },

                ];


                let table: any = <DataTable rowData={rowData} columns={columns} pushPinnedData={(data) => { this.updateChart(data); }} onRowSelected={(data) => { this.updateChart(data); }} wrapText={true} autoHeight={true} showCheckboxes={true} />

                this.setState(
                    {
                        dutyCycleTable: table
                    });


            })
            .catch((error: Error) => {
                throw error
            });
    }



    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <nav className="dashboardTitle" aria-label="breadcrumb">
                            <div className="breadcrumb">
                                <Link className="breadcrumb-item" to="/duty-cycle" >Duty Cycle</Link>
                                <a className="breadcrumb-item" >Weight Analysis</a>
                            </div>
                        </nav>
                    </Col>
                </Row>
                <DropDownFilterBar />

                <DutyCycleSubnav pageTitle="Weight Analysis" />

                <Row className="mb-5">
                    <Col>
                        <CardDeck>
                            <Card xs="12" className="card-highlight">
                                <CardBody>
                                    <CardTitle tag="h5">GROSS VEHICLE WEIGHT ANALYSIS</CardTitle>
                                    <CardText tag="div" id="weightAnalysisChartContainer" style={{ height: '400px' }}>
                                        {this.state.weightAnalysisChart}
                                    </CardText>
                                </CardBody>
                            </Card>
                        </CardDeck>
                    </Col>
                </Row>
                <Row className="pb-0 mb-0">
                    <Col xs="2" className="tile p-0 mb-0">
                        <div className="text-white pb-0 mb-0 pl-2 dropdownFleetHealthGroupByToggleLabel">
                            <div className="mt-0 pt-0 pb-0" style={{ float: "left", width: "33%", lineHeight: "30px" }}>
                                GROUP BY
                            </div>
                            <div className="mt-1 mb-1" style={{ display: "inline-block", width: "1px", backgroundColor: "white", height: "70%", float: "left" }} />
                            <div className="mt-0 pt-0" style={{ float: "left", width: "66%", height:"100%" }}>
                                <InovexDropDown dropDownId="dropDownGroupBy"
                                    dropDownToggleId="dropdownFleetHealthGroupByToggleID"
                                    initialValue="VIN"
                                    //onValueSelected={(trendValue: any) => this.changeTrendValue(trendValue)}
                                    dropDownStyle={{ width: "100%" }}
                                    omitSelected={false} >
                                    <span>VIN</span>
                                    <span>Region</span>
                                    <span>Total Mileage</span>
                                    <span>Engine Hours</span>
                                </InovexDropDown>

                            </div>

                        </div>

                    </Col>
                </Row>
                <Row className="pt-0 mt-0">
                    <Col className='tile pr-0 pb-0 pt-0'>
                        {this.state.dutyCycleTable}
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
