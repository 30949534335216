// import React, { Component } from 'react';
import * as React from 'react';

import { Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Container, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { NavTop } from '../Nav'
import { FaChartArea, FaTools, FaMapMarked, FaTruckMoving, FaGasPump, FaStethoscope, FaCogs } from 'react-icons/fa';
// import ScriptTag from 'react-script-tag';
import { Route, Redirect } from "react-router-dom";
import Login from '../../components/Login';


interface ILayoutProps {
    pageTitle?: string;
    parentPage?: string;
}

interface ILayoutState {
    pageTitle: string;
    parentPage: string;
}

export class Layout extends React.Component<ILayoutProps, ILayoutState> {

    constructor(props: ILayoutProps) {
        super(props);
        this.state = { pageTitle: '', parentPage: '' };

    }

    render() {
        // console.log(this);
        // console.log(window);
        // console.log(localStorage.getItem("token") === 'T' && window.location.pathname !== '/login');
        return (
            <React.Fragment>
                <Route
                    render={({ location }) =>
                        localStorage.getItem("token") ==='true' && window.location.pathname !== '/login' ? (
                            <Container className="themed-container" fluid={false}>
                                <Row>
                                    <Col><NavTop /></Col>
                                </Row>
                                <Row>
                                    <Col className="dashboard">{this.props.children}</Col>
                                </Row>
                            </Container>
                        ) : (
                            localStorage.getItem("token") !== 'true' &&
                            <Login />
                        )
                    }
                />


            </React.Fragment>
        );
    }
}
