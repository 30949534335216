import * as React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

interface IChartState {
    chartData: Array<{ dateTimeUtc: Date; value: number; }>;
    chart: am4charts.XYChart;
    chartContainer: string;
    useCursor: boolean;
}

interface IChartProps {
    chartData: Array<{ dateTimeUtc: Date; value: number; }>;
    chartContainer: string;
    useCursor: boolean;
    strokeColor?: string;
    bulletColor?: string;
    // rangeValues: Array<{ value: number; endValue: number; colour: string;  }>;

        // range.value = 0;
        // range.endValue = 33;
        // range.axisFill.fill = am4core.color("red");
        // range.axisFill.fillOpacity = 0.15;

}

function MassageData(rawData: Array<{ dateTimeUtc: Date; value: number; }>) {

    // convert the input data and massage it to be what is needed for the labels
    let massagedData = rawData.map((data) => {
        return {
            dateTimeUtc: data.dateTimeUtc,
            value: data.value,
            disabled: true
        }
    })

    // set the first and last elements disabled to false
    if (massagedData.length === 0) {
        console.log("data is empty")
    }
    else {
        //console.log("massageddata");
        //console.log(massagedData);
        if (massagedData.length) {
            massagedData[0].disabled = false;
            massagedData[massagedData.length - 1].disabled = false;
        }
    }
    return massagedData;
}

function CreateBulletLabels(series: am4charts.LineSeries) {
    // have the value show as a label on the first and last data points
    let labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{value}";
    labelBullet.disabled = true;
    labelBullet.propertyFields.disabled = "disabled";
    labelBullet.label.dy = -20;
    labelBullet.label.fontSize = "12";

}

export default class LineChart extends React.Component<IChartProps, IChartState> {

    constructor(props: IChartProps) {
        super(props);
        this.state =
        {
            useCursor: props.useCursor,
            chartData: props.chartData,
            chartContainer: props.chartContainer,
            chart: am4core.create(props.chartContainer, am4charts.XYChart),
        }
    }


    public Create(labelValue: string, valueAxisTitle: string, numberFormat: string, min?: number, max?: number, rangeValues?: Array<{ value: number; endValue: number; colour: string; }>, allLabels: boolean = false, addFill: boolean = false)
    {
        am4core.useTheme(am4themes_animated);
        am4core.addLicense("CH308822591");
        // Set input format for the dates
        this.state.chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

        if (numberFormat !== '')
            this.state.chart.numberFormatter.numberFormat = '#'+numberFormat;

        // Color set
        let colors = new am4core.ColorSet();
        let color = colors.getIndex(0);

        // massage the data for what is needed for the chart
        let massagedData = MassageData(this.state.chartData);


        this.state.chart.data = massagedData; 
        


        // console.log(this.state.chartData);
        // Create axes
        let dateAxis = this.state.chart.xAxes.push(new am4charts.DateAxis());


        function updateRangeLabels() {
            minRange.value = dateAxis.min + dateAxis.start * (dateAxis.max - dateAxis.min);
            minRange.label.text = dateAxis.dateFormatter.format(minRange.value, "MMM");

            maxRange.value = dateAxis.min + dateAxis.end * (dateAxis.max - dateAxis.min);
            maxRange.label.text = dateAxis.dateFormatter.format(maxRange.value, "MMM");
        }

        if (allLabels) {
            dateAxis.renderer.labels.template.disabled = false;
            dateAxis.renderer.minGridDistance = 50;
        }
        else {
            dateAxis.renderer.labels.template.disabled = true;
            var minRange = dateAxis.axisRanges.create();
            minRange.maxPosition = 1;
            minRange.minPosition = 0;
            minRange.label.horizontalCenter = "left"
            minRange.label.paddingLeft = 0;

            var maxRange = dateAxis.axisRanges.create();
            // this overrides minLabelPosition/maxLabelPosition so that the range labels would be visible
            maxRange.maxPosition = 1;
            maxRange.minPosition = 0;
            maxRange.label.horizontalCenter = "right"
            maxRange.label.paddingRight = 0;

            dateAxis.events.on("startendchanged", updateRangeLabels)
            dateAxis.events.on("extremeschanged", updateRangeLabels)
        }


        let valueAxis = this.state.chart.yAxes.push(new am4charts.ValueAxis());
        if (min !== null)
            valueAxis.min = min;
        if (max !== null)
            valueAxis.max = max;
        if (valueAxisTitle !== '')
        valueAxis.title.text = valueAxisTitle;


        // Create series
        let series = this.state.chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "value";
        series.dataFields.dateX = "dateTimeUtc";
        series.tooltipText = "[bold]{name}[/]\n[font-size:14px]{valueY}";
        series.strokeWidth = 2;
        series.minBulletDistance = 15;
        series.tensionX = 0.9;

        if (this.props.strokeColor) {
            series.stroke = am4core.color(this.props.strokeColor);
            series.fill = am4core.color("rgba(0, 175, 158, 0.5)");
        }

        if (addFill) {
            //console.log(this.props.addFill);
            series.fillOpacity = 0.3;
        }

        // render data points as bullets
        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.opacity = 1;
        bullet.circle.propertyFields.opacity = "opacity";
        bullet.circle.radius = 3;

        if (this.props.bulletColor) {
            bullet.circle.fill = am4core.color(this.props.bulletColor);//color taken from props
            bullet.circle.stroke = am4core.color(this.props.bulletColor);
        }
        else
            bullet.circle.fill = color;//color taken from default color set
        
        CreateBulletLabels(series);

       
        let label = this.state.chart.chartContainer.createChild(am4core.Label);
        label.text = labelValue;
        label.align = "center";

        // Drop-shaped tooltips
        series.tooltip.background.cornerRadius = 20;
        series.tooltip.background.strokeOpacity = 0;
        series.tooltip.pointerOrientation = "vertical";
        series.tooltip.label.minWidth = 40;
        series.tooltip.label.minHeight = 40;
        series.tooltip.label.textAlign = "middle";
        series.tooltip.label.textValign = "middle";

        // TODO: we need to find a way to make these dynamic and dependant on the metric. Nice and easy :D

        // Create ranges
        if (rangeValues !== null) {
            for (var index in rangeValues) {
                let range = valueAxis.axisRanges.create();
                range.value = rangeValues[index].value;
                range.endValue = rangeValues[index].endValue;
                range.axisFill.fill = am4core.color(rangeValues[index].colour);
                range.axisFill.fillOpacity = 0.15;
            }

        }


        

        if (this.state.useCursor) {
            // Make a panning cursor
            this.state.chart.cursor = new am4charts.XYCursor();
            this.state.chart.cursor.behavior = "panXY";
            this.state.chart.cursor.xAxis = dateAxis;
            this.state.chart.cursor.snapToSeries = series;
        }

    }


    
    public ReDraw(labelValue: string, valueAxisTitle: string, newData: Array<{ dateTimeUtc: Date; value: number; }>, rangeValues: Array<{ value: number; endValue: number; colour: string; }>, min: number, max: number) {

        let massagedData = MassageData(newData);

        this.state.chart.data = massagedData;

        

        // TODO: figure out how to target the label more intelligently and not just by index
        if (this.state.chart.chartContainer.children.values.length > 3) {
            (this.state.chart.chartContainer.children.values[3] as any).text = labelValue;
        }




        if (this.state.chart.yAxes.values.length > 0) {
            (this.state.chart.yAxes.values[0] as any).min = min;
            (this.state.chart.yAxes.values[0] as any).max = max;
            (this.state.chart.yAxes.values[0] as any).title.text = valueAxisTitle;
        }

        // Create ranges
        if (rangeValues !== null) {
            for (var index in rangeValues) {
                let x = 1;
                // let range = this.state.chart.valueAxis.axisRanges.create();
                // range.value = rangeValues[index].value;
                // range.endValue = rangeValues[index].endValue;
                // range.axisFill.fill = am4core.color(rangeValues[index].colour);
                // range.axisFill.fillOpacity = 0.15;
            }

        }


    }

}
