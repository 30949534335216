import * as React from 'react';
import '../../DataTable/Shared/styles.css'

export default (props) => {

    //console.log('bar cell renderer: ' + props.colDef.field);
    //console.log(props);


    let total: number = 0;
    let maxValue: number = 0;
    let actual: string = '';
    let formattedNumber: string = '';

    let leftWidth: number = 0;
    let rightWidth: number = 0;
    let leftWidthPercentage: string = '';
    let rightWidthPercentage: string = '';
    let positivePercentage: number = 0;
    let negativePercentage: number = 0;
    let isPositiveDisplay: string = 'none';
    let isNegativeDisplay: string = 'none';
    let fontSize: string = '12px'
    let divergentLeftWidth: string = '50%';
    let divergentRightWidth: string = '50%';
    let divergentTertiaryWidth: string = '10%';
    let stackedLeftWidth: string = '50%';
    let stackedRightWidth: string = '50%';
    let paddingtop: string = '10px';
    let marginTop: string = '4px';
    let lineHeight: string = '35px';
    let isBar: boolean = false;
    let isBarOnly: boolean = false;
    let isDivergent: boolean = false;
    let isStacked: boolean = false;
    let isPaddingonTopRow: boolean = true;
    let showActual: boolean = false;
    let minBarPercentDisplay: number = 0;
    let showValueOnRight: boolean = false;
    let valueOnRightDisplay: string = 'none';
    let barOnlyStyle: any = {}; // this will be used to contain CSS properies
    let barWidth: string = '80%';
    let barHeight: string = '50px';
    let stackedBar: any[] = [];
    let barLabel: any[] = [];
    let actuals: any;
    let valueOutside: JSX.Element = null;
    let valueInside: JSX.Element = null;
    let negativeValueOutside: JSX.Element = null;
    let positiveValueOutside: JSX.Element = null;
    let isPinned: boolean = false;
    let isRowIndexZero: boolean = false;
    let isTopRow: boolean = false;
    let divergePinnedLeftLabel: string = "Saved";
    let divergePinnedRightLabel: string = "Wasted";
    let classNameNegative: string = 'bar Blue';
    let classNamePositive: string = 'bar Red';

    if (props.node.rowPinned === "top")
        isPinned = true;

    if (props.rowIndex == 0)
        isRowIndexZero = true;

    isTopRow = isPinned; //isRowIndexZero || ;


    fontSize = isTopRow ? '14px' : fontSize;
    lineHeight = isTopRow ? '50px' : lineHeight;

    //console.log('getRowHeight');
    //console.log(props);

    if (props.value !== undefined) {

        if (props.value.isBar !== undefined)
            isBar = props.value.isBar;
        if (props.value.isDivergent !== undefined)
            isDivergent = props.value.isDivergent;
        if (props.value.isStacked !== undefined)
            isStacked = props.value.isStacked;
        if (props.colDef.cellRendererParams)
            minBarPercentDisplay = props.colDef.cellRendererParams;

        if (props.value.paddingOnTopRow != undefined)
            isPaddingonTopRow = props.value.paddingOnTopRow;

        if (isPaddingonTopRow) {
            paddingtop = isTopRow ? '2px' : paddingtop;
        }


        if (props.colDef.field === "weightImpactAvgMpg") {
            divergePinnedLeftLabel = "Lost";
            divergePinnedRightLabel = "Gained";
        }

        if (isBar && !isDivergent && !isStacked)
            isBarOnly = true;

        if (props.value.actual !== undefined)
            actual = props.value.actual
        if (props.value.total !== undefined)
            total = props.value.total;
        if (props.value.maxValue !== undefined)
            maxValue = props.value.maxValue;

        if (props.value.leftWidth !== undefined && props.value.rightWidth !== undefined) {
            leftWidth = props.value.leftWidth;
            rightWidth = props.value.rightWidth;
        }

        if (props.value.showActual !== undefined) {
            showActual = props.value.showActual;
        }


        //console.log("showActual" + showActual);

        if (showActual) {
            let unit: string = '';

            if (props.colDef.field === "idleGallons") {
                unit = "%";
            }

            barWidth = "80%";
            actuals = <div style={{ width: '20%', display: 'inline-block', textAlign: 'right', verticalAlign: 'top', paddingTop: '4px', height: '30px', fontSize: fontSize, float: 'right', top: '10px' }}>
                {actual}{unit}
            </div>

            //console.log(barWidth);
            //console.log(actuals);
        }
        else {
            barWidth = "100%";
        }


        if (isBar) {
            if (total !== undefined && total !== null) {

                marginTop = isTopRow ? '10px' : marginTop;
                barHeight = isTopRow ? '70px' : barHeight;
                if (isPaddingonTopRow) {
                    paddingtop = isTopRow ? '10px' : paddingtop;
                }

                if (isDivergent) {
                    // console.log(props.colDef.field + ': total ' + total);
                    paddingtop = isTopRow ? '10px' : paddingtop;

                    if (props.colDef.field === "weightImpactAvgMpg") {
                        classNameNegative = 'bar Red';
                        classNamePositive = 'bar Blue';
                    }




                    if (total > 0) {
                        positivePercentage = Math.round((total / maxValue) * 100)
                        isPositiveDisplay = 'inline-block';
                    }
                    else {
                        negativePercentage = Math.round((total / maxValue) * 100);
                        isNegativeDisplay = 'inline-block';
                    }

                    // console.log('positivePercentage: ' + positivePercentage + ' | maxValue: ' + maxValue + ' | total: ' + total);
                    // console.log('negativePercentage: ' + negativePercentage + ' | maxValue: ' + maxValue + ' | total: ' + total);

                    if (total >= 0 && ((positivePercentage <= 15 && total.toString().length >= 1)
                        || (positivePercentage <= 20 && total.toString().length > 2)
                        || (positivePercentage <= 30 && total.toString().length > 3))) {
                        positiveValueOutside = <div style={{ width: (100 - positivePercentage) + '%', position: 'absolute', right: '0px', textAlign: 'left', paddingLeft: '5px', paddingTop: '2px' }} >{total}</div>

                    }
                    else if (total <= 0 && ((negativePercentage <= 15 && total.toString().length >= 1)
                        || (negativePercentage <= 20 && total.toString().length > 2)
                        || (negativePercentage <= 30 && total.toString().length > 3))) {
                        negativeValueOutside = <div style={{ width: (100 - negativePercentage) + '%', position: 'absolute', left: '0px', textAlign: 'right', paddingRight: '5px', paddingTop: '2px' }} >{total}</div>

                    }
                    else
                        valueInside = <p>{total}</p>

                }
                else if (isStacked) {
                    paddingtop = '15px';
                    paddingtop = isTopRow ? '17px' : paddingtop;

                    // console.log("paddingTop" + paddingtop);

                    //paddingtop = props.rowIndex == 0 ? '19px' : paddingtop;
                    stackedLeftWidth = leftWidth + '%';
                    stackedRightWidth = rightWidth + '%';

                    leftWidthPercentage = leftWidth <= 5 ? '' : leftWidth + '%';
                    rightWidthPercentage = rightWidth <= 5 ? '' : rightWidth + '%';

                    let subTotal = 0;

                    for (let i = 0; i < props.value.percents.length; i++) {
                        let percentage = Math.round(props.value.percents[i].percent)

                        if (props.rowIndex === 0 && isPinned) {   // top row only
                            //if (percentage > 0) {
                            let classNameStyle: string = "border-left-1 " + props.value.percents[i].color;
                            let leftPercent = subTotal + "%";
                            let title = props.value.percents[i].name + ': ' + percentage + "%"
                            let label = <div className={classNameStyle}
                                title={title}
                                style={{
                                    width: percentage + "%",
                                    position: 'absolute',
                                    left: leftPercent,
                                    paddingBottom: '0px',
                                    paddingLeft: '2px',
                                    height: '25px',
                                    fontSize: '8px'
                                }}
                            >
                                {props.value.percents[i].name}
                            </div>;
                            barLabel.push(label);
                            // console.log(label)
                            // console.log(barLabel)
                            //}
                        }


                        if (percentage > 0) {

                            let classNameStyle = "bar";
                            let leftPercent = subTotal + '%';
                            let percentageString = percentage + '%';
                            let title = props.value.percents[i].name + ': ' + percentage + '%';

                            percentageString = percentage <= 12 ? '' : percentageString;  // don't show the value inline if it's small
                            let temp = <div className={classNameStyle}
                                key={Math.random()}
                                title={title}
                                style={{
                                    width: percentage + "%",
                                    position: 'absolute',
                                    left: leftPercent,
                                    paddingBottom: '0px',
                                    height: '25px',
                                    fontSize: '12px',
                                    backgroundColor: props.value.percents[i].color,
                                    color: 'white'
                                }}
                            >
                                {percentageString}
                            </div>
                            stackedBar.push(temp);

                            // console.log(temp)
                            // console.log(stackedBar)
                        }


                        subTotal = subTotal + props.value.percents[i].percent;
                    }


                }

                else if (isBarOnly) {

                    positivePercentage = Math.round((total / maxValue) * 100);
                    // console.log(props.colDef.field + ': ' + total + ' / ' + maxValue + ' = ' + positivePercentage + ' | (100-positivePercentage)=' + (100 - positivePercentage))
                    if (positivePercentage <= 10
                        || (positivePercentage <= 14 && total.toString().length > 2)
                        || (positivePercentage <= 20 && total.toString().length > 3)
                        || (positivePercentage <= 30 && total.toString().length > 4)
                    ) {
                        valueOutside = <div style={{ width: (100 - positivePercentage) + '%', position: 'absolute', right: '0px', textAlign: 'left', paddingLeft: '2px', paddingTop: '2px' }} >{total}</div>

                    }
                    else
                        valueInside = <p>{total}</p>
                }

                showValueOnRight = actual !== '';
                if (showValueOnRight)
                    valueOnRightDisplay = 'inline-block';
            }
        }
        else if (!isBar) {
            //console.log(total);
            if (total !== undefined && total !== null) {
                if (props.colDef.field === "milesDriven" ||
                    props.colDef.field === "totalMileage" ||
                    props.colDef.field === "totalConsumption" ||
                    props.colDef.field === "overallFuelEconomy" ||
                    props.colDef.field === "overallFuelEconomy" ||
                    props.colDef.field === "fuelEconomy" ||
                    props.colDef.field === "weightAvg" ||
                    props.colDef.field === "weightImpactSum" ||
                    props.colDef.field === "weightImpactAvgMpg" ||
                    props.colDef.field === "citySpeed" ||
                    props.colDef.field === "citySpeedAvg" ||
                    props.colDef.field === "highwaySpeed" ||
                    props.colDef.field === "highwaySpeedAvg" ||
                    props.colDef.field === "accelerationAvg" ||
                    props.colDef.field === "acceleration" ||
                    props.colDef.field === "accelerationImpact" ||
                    props.colDef.field === "highwaySpeedImpact" ||
                    props.colDef.field === "avgHighwaySpeed" ||
                    props.colDef.field === "idleTimePercentage"
                    && total > 0) {

                    formattedNumber = total.toLocaleString(navigator.language, { minimumFractionDigits: 0 });
                }

                if (props.colDef.field === "gallonsSavedFromWeight" && total > 0)
                    formattedNumber = "+" + total.toLocaleString(navigator.language, { minimumFractionDigits: 0 });
                else if (props.colDef.field === "gallonsSavedFromWeight" && total < 0)
                    formattedNumber = total.toLocaleString(navigator.language, { minimumFractionDigits: 0 });
            }
            else
                formattedNumber = 'n/a'



        }


    }
    else {


    }

    return (
        <div>
            { isDivergent === true &&
                <React.Fragment>
                    <div style={{ width: barWidth, height: barHeight, display: 'inline-block', position: 'relative', fontSize: fontSize }}>
                        <div style={{ width: '50%', borderRight: '1px solid silver', position: 'absolute', right: '50%', paddingTop: paddingtop, paddingBottom: '0px', height: barHeight }}>

                            <div style={{ width: negativePercentage + '%', display: isNegativeDisplay, right: '0' }} className={classNameNegative}>
                                {valueInside}
                            </div>
                            {negativeValueOutside}

                        </div>
                        <div style={{ width: '50%', borderLeft: '0px solid silver', position: 'absolute', left: '50%', paddingTop: paddingtop, paddingBottom: '0px', height: barHeight }}>
                            <div style={{ width: positivePercentage + '%', display: isPositiveDisplay, left: '0' }} className={classNamePositive}>
                                {valueInside}
                            </div>
                            {positiveValueOutside}

                        </div>
                    </div>
                    {isPinned && <div>
                        <div style={{ position: 'absolute', top: '34px', fontSize: '9px' }}>{divergePinnedLeftLabel}</div>
                        <div style={{ position: 'absolute', top: '34px', right: '15px', fontSize: '9px' }}>{divergePinnedRightLabel}</div>
                    </div>}
                    {/* bcc commented out  as new screens showed the actuals now being shown in their own column*/}
                    {/*<div style={{ width: '20%', display: valueOnRightDisplay, textAlign: 'right', verticalAlign: 'top', marginTop:'3px', paddingTop: paddingtop, height: '30px', fontSize: fontSize }}>*/}
                    {/*    {actual} */}
                    {/*</div> */}
                </React.Fragment>
            }

            {isStacked === true &&
                <React.Fragment>
                    {(props.rowIndex === 0 && isPinned) && <div style={{ display: 'block', position: 'relative' }}>
                        {barLabel}
                    </div>
                    }
                    <div style={{ display: 'block', height: barHeight, position: 'relative', fontSize: fontSize, paddingTop: paddingtop }}>
                        {stackedBar}
                    </div>
                </React.Fragment>
            }

            {isBarOnly === true &&
                <div className="margin-left-minus-16" style={{ width: '100%', marginTop: marginTop }}>

                    <div style={{ width: '100%', display: 'inline-block', position: 'relative', height: '50px', fontSize: fontSize, float: 'left' }}>
                        <div style={{ width: positivePercentage + '%', display: 'inline-block', left: '0' }} className="bar DarkGrey" >
                            {valueInside}
                        </div>
                        {valueOutside}
                    </div>
                    {actuals}


                </div>
            }

            { !isBar &&
                <p className="tableText" style={{ lineHeight: lineHeight, paddingTop: paddingtop }}>
                    {formattedNumber}
                </p>

            }

        </div>
    );
};
