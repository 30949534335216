import * as React from 'react';
import { CardText, Col, Row } from 'reactstrap';
import { DivergentStackBarChart } from "../../../Charts";

interface IDashboardProps {
    data: any
}

interface IDashboardState {
    contributingFactorsChart: DivergentStackBarChart | null;
    totalFuelUsedInPeriod: string;
    totalExpectedFuelUsedInPeriod: string;
    totalFuelWasted: string;
    vehicleData: any;
}


export class FuelComparison extends React.Component<IDashboardProps, IDashboardState> {
    constructor(props: IDashboardProps) {
        super(props);
        this.state = {
            contributingFactorsChart: null,
            totalFuelUsedInPeriod: '',
            totalExpectedFuelUsedInPeriod: '',
            totalFuelWasted: '',
            vehicleData: this.props.data
        };
    }

    componentDidMount() {
        this.fetchFuelEconData();
    }

     fetchFuelEconData() {
        let url = "api/Fuel/FuelEconFactorsTotals/";
        url += this.state.vehicleData.vehicle.vin;

         fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
             .then(data => {
                this.setState(
                    {
                        totalFuelUsedInPeriod: data[0].value.toString(),
                        totalExpectedFuelUsedInPeriod: data[1].value.toString(),
                        totalFuelWasted: data[2].value.toString()
                    });

                this.fuelComparisonChart();
            })
            .catch((error: Error) => {
                throw error;
            });
    }

    fuelComparisonChart() {
        let convertedChartData = this.convertChartData();

        var chart = new DivergentStackBarChart({
            chartData: convertedChartData,
            chartContainer: this.state.vehicleData.vehicle.vin,
            showLegend: false,
            orientation: "vertical",
            colorNegativeValues: "#3C6EB1", // #3C6EB1(blue) is used for negative values as it indicates fuel preserved from savvy driving practices.
            colorPositiveValues: "#A4282A", // #A4282A (red) is used for positive values because it indicates fuel wasted (a net loss for Aptiv)
            hideValueAxisLabels: true,
            hideCategoryAxisLabels: false,
            hideGridLines: true,
            useCursor: true,
            title: "+/- Predicted",
        });
        chart.Create();

        this.setState({
            contributingFactorsChart: chart
        });
    }

    convertChartData() {
        let route = this.state.vehicleData.route.total;
        let other = this.state.vehicleData.driverBehavior.delta;
        let driver = this.state.vehicleData.driverBehavior.total;
        let vehicle = this.state.vehicleData.vehicleAttribute.total;
        let weight = this.state.vehicleData.weight.total;
        let idle = this.state.vehicleData.fuelIdleSum.total;

        let routePercent = this.getPercentage(route);
        let otherPercent = this.getPercentage(other);
        let driverPercent = this.getPercentage(driver);
        let vehiclePercent = this.getPercentage(vehicle);
        let weightPercent = this.getPercentage(weight);
        let idlePercent = this.getPercentage(idle);

        return [
            { name: 'ROUTE (' + routePercent + ')', metricName: '/fuel/route', value: route, dateTimeUtc: '2021-11-12T10:03:55.9567326-05:00' },
            { name: 'WEIGHT (' + weightPercent + ')', metricName: '/fuel/weight', value: weight, dateTimeUtc: '2021-11-12T10:03:55.9567375-05:00' },
            { name: 'IDLE (' + idlePercent + ')', metricName: '', value: idle, dateTimeUtc: '2021-11-12T10:03:55.9567377-05:00' },
            { name: 'VEHICLE (' + vehiclePercent + ')', metricName: '', value: vehicle, dateTimeUtc: '2021-11-12T10:03:55.9567372-05:00' },
            { name: 'MISC (' + otherPercent + ')', metricName: '', value: other, dateTimeUtc: '2021-11-12T10:03:55.9567355-05:00' },
            { name: 'DRIVER (' + driverPercent + ')', metricName: '/fuel/behaviour', value: driver, dateTimeUtc: '2021-11-12T10:03:55.956737-05:00' }
        ]
    }

    getPercentage(value: any) {
        let wastedVol = parseFloat(this.state.totalFuelUsedInPeriod);

        if (wastedVol !== 0) {
            return (parseFloat(value) / wastedVol * 100).toFixed(0) + "%";
        }
        else {
            return "0%";
        }
    }

    render() {
        return (
            <React.Fragment>
                <Col>
                    <CardText tag="div" style={{ width: "310px" }}>
                        <h4>{this.state.vehicleData.vehicle.name}</h4>
                        <h6 style={{ color: this.state.vehicleData.vehicle.vin === 'TOTAL' ? '#fff' : '#000' }}>{this.state.vehicleData.vehicle.vin}</h6>
                        <div key={this.state.vehicleData.vehicle.vin} id={this.state.vehicleData.vehicle.vin} style={{ height: "300px", width: "310px" }}></div>
                        <div style={{ borderTopWidth: "5px", borderTopStyle: "solid", borderTopColor: "#fff ", marginLeft: 20, marginRight: 20, marginTop: 5 }}>
                            <Row>
                                <Col xs="2" md="4" lg="5">
                                    <h6 className="float-left"><small>Sub-Total Vol:</small></h6>

                                </Col>
                                <Col xs="2" md="4" lg="5">
                                    <div className="float-right">
                                        <h6><small>{this.state.totalFuelUsedInPeriod} gal</small></h6>
                                    </div>

                                </Col>

                            </Row>
                            <Row >
                                <Col xs="2" md="4" lg="5">
                                    <h6 className="float-left"><small>Expected Vol:</small></h6>
                                </Col>
                                <Col xs="2" md="4" lg="5">
                                    <div className="float-right">
                                        <h6><small>{this.state.totalExpectedFuelUsedInPeriod} gal</small></h6>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="2" md="4" lg="5">
                                    <h6 className="float-left"><small>Wasted Vol:</small></h6>
                                </Col>
                                <Col xs="2" md="4" lg="5">
                                    <div className="float-right">
                                        <h6><small>{this.state.totalFuelWasted} gal</small></h6>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </CardText>
                </Col>
               
            </React.Fragment>
        )
    }
}