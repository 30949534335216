import * as React from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { BsBell } from 'react-icons/bs';
import { MdCancel } from 'react-icons/md';
import { RiErrorWarningFill } from 'react-icons/ri';

interface IAlertBarProps {
    iconClassName?: string, //css classes to be applied to icon
    onItemSelected?: Function, //click function called when any element clicked
    notifications?: string, //number of pending notifications
}

interface IAlertBarState {
    toggle: boolean,
}

export class AlertBar extends React.Component<IAlertBarProps, IAlertBarState> {
    constructor(props: IAlertBarProps) {
        super(props);
        this.state = {
            toggle: false,
        }
    }

    renderHeader(headerText: string) {
        return (
            <div style={{ float: "left", fontSize: "12px", backgroundColor: "#2F2E36", color: "#D0D0D2", height: "25px", width: "100%", borderWidth: "0px", margin: "0px", padding: "3px", paddingLeft: "10px" }}>{headerText}</div>
        );
    }

    renderMain(mainText: string, subText: string, time: string, image: string, oddEven: boolean) {
        let icon = null
        switch (image) {
            case "warning":
                icon = <RiErrorWarningFill className='RiErrorWarningFill' fontSize='25' />
                break;

            case "good":
                icon = <AiFillCheckCircle className='AiFillCheckCircle' fontSize='25' />
                break;

            case "danger":
                icon = <MdCancel className='MdCancel' fontSize='25' />
                break;
            default:

        }
        return (
            <div style={{ float: "left", backgroundColor: (oddEven ? "#FFFFFF" : "#E8E9ED"), color: "#D0D0D2", height: "85px", width: "100%", borderWidth: "0px", margin: "0px", padding: "5px", paddingLeft: "10px", }} onClick={(value) => this.onClick(value)}>
                <div style={{ display: "inline-block", height: "100%", width:"15%" }}>
                    {icon}
                </div>
                <div style={{ display: "inline-block", height: "100%", width: "85%", verticalAlign:"top" }}>
                    <div style={{ fontSize: "16px", color: "#686868", wordWrap: "break-word" }}>{mainText}</div>
                    <div style={{ fontSize: "11px", color: "#94969C", wordWrap: "break-word" }}>{subText}</div>
                    <div style={{ fontSize: "11px", color: "#94969C", wordWrap: "break-word" }}>{time}</div>

                </div>
            </div>
        );
    }

    onClick(e: any) {//function to handle on click
        var valueSelectedFunction = this.props.onItemSelected;
        var textContent = e.currentTarget.textContent;
        if (valueSelectedFunction) { // if click function provided
            if (valueSelectedFunction.length === 0) { //if no args just run
                valueSelectedFunction!();
            } else { //if 1 or more args pass element
                valueSelectedFunction!(textContent);
            }
        }
    }

    render() {
        return (
            <span style={{ display: "inline-block" }}>
                <div className={this.props.iconClassName} onClick={() => { this.setState({ toggle: !this.state.toggle }) }}>
                    <BsBell size="25px" />
                    <div style={{ borderRadius: "50px", fontSize: "8px", backgroundColor: "#FF1D00", color: "FFFFFF", width: "10px", height: "10px", padding: "0px", position: "absolute", top: "22px", right: "90px", alignContent: "center" }}>
                        <span style={{ position: "relative", top: "-1px", margin: "2px", paddingLeft: "1px", paddingBottom: "2px" }}>2</span>
                    </div>
                </div>
                {this.state.toggle ?
                    <div style={{ backgroundColor: "#2F2E36", position: "absolute", top: "70px", right: "3px", zIndex: 99999, color: "black", padding: "0px", width: "250px", border: "1px solid #2F2E36" }}>
                        <div style={{ float: "left", fontSize: "22px", backgroundColor: "#000000", color: "#FBFBFB", height: "45px", width: "100%", borderWidth: "0px", margin: "0px", padding: "2px", paddingLeft: "10px" }}>Recent alerts</div>
                        {this.renderHeader("Today")}
                        {this.renderMain("Pressure in coolant system is low: 2.1 psi", "3AJKTDV1MSMZ1488(WEST)", "2:13PM", "danger", true)}
                        {this.renderMain("Low crank voltage", "3AJKTDV1MSMZ1488(WEST)", "9:00AM", "warning", false)}
                        {this.renderHeader("Yesterday")}
                        {this.renderMain("Coolant pressure is low", "3AJKTDV1MSMZ1488(WEST)", "11:45PM", "danger", true)}
                        {this.renderMain("Low crank voltage", "3AJKTDV1MSMZ1488(WEST)", "9:00AM", "warning", false)}
                        {this.renderMain("Fuel Theft identified", "3AJKTDV1MSMZ1488(WEST)", "6:00PM", "danger", true)}
                        {this.renderMain("Low crank voltage", "3AJKTDV1MSMZ1488(WEST)", "4:00AM", "warning", false)}
                        {this.renderMain("Hard Acceleration Event", "3AJKTDV1MSMZ1488(WEST)", "9:00AM", "warning", true)}
                        <div style={{ float: "left", backgroundColor: "#2F2E36", color: "#D0D0D2", height: "45px", width: "100%", borderWidth: "0px", margin: "0px", padding: "5px", paddingLeft: "3px", paddingRight:"3px" }}>
                            <div style={{ fontSize: "18px", backgroundColor: "#006D63", color: "#DFE3DE", height: "100%", width: "100%", borderWidth: "0px", margin: "0px", padding: "5px", paddingBottom:"7px", borderRadius: "2px" }}>
                                View all alerts
                            </div>
                        </div>
                    </div>

                    : null}
            </span>
        );
    }
}