import * as React from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import VehicleCellRenderer from '../CellRenderers/vehicleHealthCellRenderer'
import BarCellRenderer from '../CellRenderers/barCellRenderer'
import { LineChart } from '../../Charts';

import '../../DataTable/Shared/styles.css'
import '../../DataTable/Shared/ag-theme-alpine.css';
import '../../DataTable/Shared/ag-grid.css';
import { isNullOrUndefined } from 'util';


interface IDataTableProps {
    columns: Array<{ columnName: string, columnHeader: string, width: number }>
    rowData: any[];
    pushPinnedData?: Function; //passed function when the pinned row data is ready
}

interface IDataTableState {
    loading: boolean;
    columns: Array<{ columnName: string, columnHeader: string, width: number }>;
    rowData: any;
    pinnedRowData: any[]
    totalConsumptionLineChart: LineChart | null;
    idleGallonsLineChart: LineChart | null;
    vehicleAccelerationLineChart: LineChart | null;
    highwaySpeedLineChart: LineChart | null;
}

// All the docs are here: 
// https://www.ag-grid.com/react-data-grid

export class DataTable extends React.Component<IDataTableProps, IDataTableState> {
    constructor(props: IDataTableProps) {
        super(props);
        this.state =
        {
            loading: true,
            columns: [],
            rowData: this.props.rowData,
            pinnedRowData: this.props.rowData[0],
            totalConsumptionLineChart: null,
            idleGallonsLineChart: null,
            vehicleAccelerationLineChart: null,
            highwaySpeedLineChart: null
        };

    }

    getChartData() {
        console.log('componentDidMount');
        fetch('/api/Fuel/FuelEconByDriverBehaviourCharts/2021-07-01/2021-07-31')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                this.populateCharts(data)
            })
            .catch((error: Error) => {
                throw error
            });

    }

    public populateCharts(json: any) {
        //let totalConsumptionLineChart = new LineChart({ useCursor: true, chartData: json.totalConsumption, chartContainer: 'TotalConsumptionChart', allLabels: false });
        let idleGallonsLineChart = new LineChart({ useCursor: true, chartData: json.idleGallons, chartContainer: 'IdleGallonsChart', strokeColor: "#5F8993" });
        let vehicleAccelerationLineChart = new LineChart({ useCursor: true, chartData: json.vehicleAcceleration, chartContainer: 'VehicleAccelerationChart', strokeColor: "#5F8993" });
        let highwaySpeedLineChart = new LineChart({ useCursor: true, chartData: json.highwaySpeed, chartContainer: 'HighwaySpeedChart', strokeColor: "#5F8993" });

        //totalConsumptionLineChart.Create('', '', 'a', null, null);
        idleGallonsLineChart.Create('', 'idle %', '', null, null,null,false,true);
        vehicleAccelerationLineChart.Create('', 'gal/vehicle', '', null, null);
        highwaySpeedLineChart.Create('', 'gal/vehicle', '', null, null);

        // this.setState(
        //     {
        //         //totalConsumptionLineChart: totalConsumptionLineChart,
        //         idleGallonsLineChart: idleGallonsLineChart,
        //         vehicleAccelerationLineChart: vehicleAccelerationLineChart,
        //         highwaySpeedLineChart: highwaySpeedLineChart
        //     }, () => { /* empty callback function */});

    }


    public getRowData() {
        this.props.rowData.shift(); // drop the first element because it will be pinned
        return this.props.rowData as any[];
    };

    public getPinnedRowData() {
        return [this.props.rowData[0]];
    };


    render() {
        // console.log(this.props.rowData);

        const dynamicCellStyle = params => {
            let style = { width: '', height: '', paddingTop: '', display: '', alignItems: '' };
            console.log('dynamicCellStyle', params.colDef.field);
            switch (params.colDef.field) {
                case 'vehicle':
                    style.paddingTop = '4px';
                    style.width = '10%';
                    break;
                case 'totalConsumption':
                    style.width = '15%'; break;
                case 'accelerationImpact':
                case 'highwaySpeedImpact':
                    style.display = 'block';
                    break;
                case 'idleGallons':
                    style.display = 'block';
                    style.paddingTop = '5px';
                   break;
                default:
                    style.paddingTop = '4px';
                    style.width = '25%';
                    break;
            }

            return style;
        };
                

        const totalConsumptionCellRenderer = params => {
            let fontSize = params.node.rowPinned === "top"  ? '16px' : '14px';
            let lineHeight = params.node.rowPinned === "top"  ? '47px' : '35px';
            let paddingTop = params.node.rowPinned === "top" ? '0' : '0';
            let returnValue = '';

            if (params.value !== undefined) {
                let totalCons = params.value.total == null || params.value.total  == undefined ? 0 : params.value.total.toLocaleString(navigator.language, { minimumFractionDigits: 0 })

                // console.log('totalConsumptionCellRenderer');
                // console.log(params);
                returnValue = '<div style="line-height:' + lineHeight + '; font-size:' + fontSize + '; padding-top:' + paddingTop + ';">' + totalCons;
                if (params.value.delta !== undefined && params.value.delta !== null) {
                    returnValue += '<br />(' + params.value.delta + ' gal)';
                }
                returnValue += '</div>'
            }

            return returnValue;
        };


            
        const onFirstDataRendered = (params) => {
            console.log('onFirstDataRendered');

            if (this.props.pushPinnedData)
                this.props.pushPinnedData([params.api.pinnedRowModel.getPinnedTopRowData()[0].data]);

            sizeColumnsToFit(params);
            // console.log(params);
            this.getChartData();
        };

        const sizeColumnsToFit = (params) => {
            params.api.sizeColumnsToFit();
            let bodyWidth = params.columnApi.columnModel.bodyWidth;
            bodyWidth -= 300;
            let chartColumnWidth = bodyWidth / 3;
            //document.getElementById('IdleGallonsChartContainer').style.width = chartColumnWidth + 'px';
            //document.getElementById('VehicleAccelerationChartContainer').style.width = chartColumnWidth + 'px';;
            //document.getElementById('HighwaySpeedChartContainer').style.width = chartColumnWidth + 'px';;
        };
        const getRowHeight = params => {
            //console.log(params);
            return params.node.rowPinned === "top" ? 60 : 50
        };
        const getRowClass = params => {
            if (params.node.rowPinned === "top") {
                return 'borderBottomSilver';
            }
        };

        const valueComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
            //console.log(valueA);
            if (isNullOrUndefined(valueA) || isNullOrUndefined(valueB)) return 0;

            if (!isNullOrUndefined(valueA.total) && !isNullOrUndefined(valueB.total)) {
                if (valueA.total == valueB.total) return 0;
                return (valueA.total > valueB.total) ? 1 : -1;
            }
            else {
                if (valueA == valueB) return 0;
                return (valueA > valueB) ? 1 : -1;
            }
        };

        const fleetNameCompare = (valueA, valueB, nodeA, nodeB, isInverted) => {
            if (isNullOrUndefined(valueA) || isNullOrUndefined(valueB)) return 0;
            if (valueA.name == valueB.name) return 0;
            return (valueA.name > valueB.name) ? 1 : -1;
        };

        return (
            <React.Fragment>
                <div
                    id="myGrid"
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                    className="ag-theme-alpine cellsWithLeftBorder"
                >
                    <AgGridReact
                        frameworkComponents={{
                            vehicleCellRenderer: VehicleCellRenderer,
                            barCellRenderer: BarCellRenderer
                        }}
                        rowClass="datatable-row"
                        getRowHeight={getRowHeight}
                        getRowClass={getRowClass}
                        onFirstDataRendered={onFirstDataRendered}
                        onGridSizeChanged={sizeColumnsToFit}
                        //onSortChanged={onFirstDataRendered}
                        pinnedTopRowData={this.getPinnedRowData()}
                        rowData={this.getRowData()}
                        pagination={true}
                        paginationPageSize={10}
                        defaultColDef={{ sortable: true }}
                    >
                        <AgGridColumn minWidth={240} field="vehicle" headerName="Fleet" cellRenderer="vehicleCellRenderer" headerClass='FuelEconDataTableHeader' cellStyle={dynamicCellStyle} comparator={fleetNameCompare}/>
                        <AgGridColumn maxWidth={130} field="totalConsumption" headerName="Total Cons. (gal)" cellRenderer={totalConsumptionCellRenderer} headerClass='FuelEconDataTableHeader' cellStyle={dynamicCellStyle} comparator={valueComparator} />
                        {this.props.columns.map(col =>
                            <AgGridColumn
                                cellRenderer="barCellRenderer"
                                field={col.columnName}
                                headerName={col.columnHeader}
                                maxWidth={col.width}
                                headerClass={col.columnName === 'acceleration'
                                    || col.columnName === 'idleTimePercentage'
                                    || col.columnName === 'avgHighwaySpeed'
                                    || col.columnName === 'highwaySpeedvg'
                                    || col.columnName === 'citySpeedAvg' ? 'FuelEconDataTableHeaderSmall' : 'FuelEconDataTableHeader'}
                                cellStyle={dynamicCellStyle}
                                comparator={valueComparator}
                            />)}
                    </AgGridReact>
                </div>
                {/*<div className='miniChartsContainer'>*/}
                {/*    <div id='emptyCell'>*/}

                {/*    </div>*/}
                {/*    <div id='TotalConsumptionChartContainer'>*/}
                {/*        <div id='TotalConsumptionChart'></div>*/}
                {/*    </div>*/}
                {/*    <div id='IdleGallonsChartContainer' >*/}
                {/*        <div id='IdleGallonsChart'></div>*/}
                {/*    </div>*/}
                {/*    <div id='VehicleAccelerationChartContainer' >*/}
                {/*        <div id='VehicleAccelerationChart'></div>*/}
                {/*    </div>*/}
                {/*    <div id='HighwaySpeedChartContainer' >*/}
                {/*        <div id='HighwaySpeedChart'></div>*/}
                {/*    </div>*/}
                {/*</div>*/}

            </React.Fragment>
        )
    }


}
