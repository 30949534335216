import * as React from 'react';
import { createRef } from 'react';

interface IOutsideClickHandlerProps {
    onOutsideClick: () => void; // this is the function to handle the outside click
}
interface IOutsideClickHandlerState {
}


export class OutsideClickHandler extends React.Component<IOutsideClickHandlerProps, IOutsideClickHandlerState>{
    constructor(props: IOutsideClickHandlerProps) {
        super(props)
    }
    wrapperRef = createRef<HTMLDivElement>();

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }
    handleClickOutside = (event) => {
        if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.props.onOutsideClick();
        }
    };
    render() {
        return <div ref={this.wrapperRef}>{this.props.children}</div>
    }
}