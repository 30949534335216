﻿import * as React from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import VehicleCellRenderer from '../CellRenderers/vehicleHealthCellRenderer'
import BarCellRenderer from '../CellRenderers/barCellRenderer'
import FuelTotalTractiveCellRenderer from '../CellRenderers/fuelTotalTractiveFleetCellRenderer';
import NumberCellRenderer from '../CellRenderers/fuelTotalTractiveNumberCellRenderer';
import TotalFuelEconomyFuelRenderer from '../CellRenderers/fuelTotalTractiveFuelEconomyCellRenderer';
import { LineChart } from '../../Charts';

import '../../DataTable/Shared/styles.css'
import '../../DataTable/Shared/ag-theme-alpine.css';
import '../../DataTable/Shared/ag-grid.css';
import { isNullOrUndefined } from 'util';


interface IDataTableProps {
    columns: Array<{ columnName: string, columnHeader: string, width: number }>
    rowData: any;
}

interface IDataTableState {
    loading: boolean;

    columns: Array<{ columnName: string, columnHeader: string, width: number }>;
    rowData: any;

    totalConsumptionLineChart: LineChart | null;
    idleGallonsLineChart: LineChart | null;
    vehicleAccelerationLineChart: LineChart | null;
    highwaySpeedLineChart: LineChart | null;
}

// All the docs are here: 
// https://www.ag-grid.com/react-data-grid

export class DataTable extends React.Component<IDataTableProps, IDataTableState> {
    constructor(props: IDataTableProps) {
        super(props);
        this.state =
        {
            loading: true,
            columns: [],
            rowData: [],
            totalConsumptionLineChart: null,
            idleGallonsLineChart: null,
            vehicleAccelerationLineChart: null,
            highwaySpeedLineChart: null
        };

    }

    componentDidMount() {
        // fetch('/api/Fuel/FuelEconByBehaviour/2021-07-01/2021-07-31')
        //     .then(response => {
        //         if (!response.ok) {
        //             throw new Error(response.statusText)
        //         }
        //         return response.json()
        //     })
        //     .then(data => {
        //         this.populateCharts(data)
        //     })
        //     .catch((error: Error) => {
        //         throw error
        //     });
    }

    /*
    public populateCharts(json: any) {
        let totalConsumptionLineChart = new LineChart({ useCursor: true, chartData: json.totalConsumption, chartContainer: 'TotalConsumptionChart' });
        let idleGallonsLineChart = new LineChart({ useCursor: true, chartData: json.idleGallons, chartContainer: 'IdleGallonsChart' });
        let vehicleAccelerationLineChart = new LineChart({ useCursor: true, chartData: json.vehicleAcceleration, chartContainer: 'VehicleAccelerationChart' });
        let highwaySpeedLineChart = new LineChart({ useCursor: true, chartData: json.highwaySpeed, chartContainer: 'HighwaySpeedChart' });

        totalConsumptionLineChart.Create('', '', 'a', null, null);
        idleGallonsLineChart.Create('', 'idle gal', '', null, null);
        vehicleAccelerationLineChart.Create('', 'accel impact %', '', null, null);
        highwaySpeedLineChart.Create('', 'hgwy_frac_impact_mpg', '', null, null);

        this.setState(
            {
                totalConsumptionLineChart: totalConsumptionLineChart,
                idleGallonsLineChart: idleGallonsLineChart,
                vehicleAccelerationLineChart: vehicleAccelerationLineChart,
                highwaySpeedLineChart: highwaySpeedLineChart
        });

    }
    */
    public getRowData () {
        this.props.rowData.shift(); // drop the first element because it will be pinned
        return this.props.rowData as any[];
    };


    render() {
        // console.log(this.props.rowData);

        const dynamicCellStyle = params => {
            let style = { width: '', height: '', display: '', alignItems: '', paddingTop: ''};
            switch (params.colDef.field) {
                case 'vehicle':
                case 'fleet':
                    style.width = '10%';
                    style.display = 'flex';
                    style.alignItems = 'center';
                    break;
                case 'overallFuelEconomyAvg':
                case 'overallFuelEconomy':
                case 'dutyCycle':
                    style.paddingTop = params.node.rowPinned === 'top' ? '0.2rem' : '0rem';
                    style.display = 'block';
                    style.width = '100%';
                    break;
                case 'totalConsumption': style.width = '15%'; break;
                default: style.width = '25%'; break;
            }
            return style;
        };


        const onFirstDataRendered = (params) => {
            sizeColumnsToFit(params);
        };

        const sizeColumnsToFit = (params) => {
            params.api.sizeColumnsToFit();
        };

        function getPinnedTopRowData(params){
            // onsole.log(params);
            var result = [];
            result.push(this.props.rowData[0]);
            return result;
        }



        const getRowHeight = params => {
            //console.log(params);
            return params.node.rowPinned === "top" ? 60 : 50
        };
        const getRowClass = params => {
            if (params.node.rowPinned === "top") {
                return 'borderBottomSilver';
            }
        };

        const valueComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
            //console.log(this.props.columns);
            if (isNullOrUndefined(valueA) || isNullOrUndefined(valueB)) return 0;

            if (!isNullOrUndefined(valueA.total) && !isNullOrUndefined(valueB.total)) {
                if (valueA.total == valueB.total) return 0;
                return (valueA.total > valueB.total) ? 1 : -1;
            }
            else {
                if (valueA == valueB) return 0;
                return (valueA > valueB) ? 1 : -1;
            }
        };

        const fleetNameCompare = (valueA, valueB, nodeA, nodeB, isInverted) => {
            if (isNullOrUndefined(valueA) || isNullOrUndefined(valueB)) return 0;
            if (valueA.name == valueB.name) return 0;
            return (valueA.name > valueB.name) ? 1 : -1;
        };

        return (
            <React.Fragment>
                <div
                    id="myGrid"
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                    className="ag-theme-alpine cellsWithLeftBorder"
                >
                    <AgGridReact
                        frameworkComponents={{
                            vehicleCellRenderer: VehicleCellRenderer,
                            barCellRenderer: BarCellRenderer,
                            fleetCellRenderer: FuelTotalTractiveCellRenderer,
                            numberCellRenderer: NumberCellRenderer,
                            totalFuelEconCellRenderer: TotalFuelEconomyFuelRenderer,

                        }}
                        rowClass="datatable-row"
                        getRowHeight={getRowHeight}
                        getRowClass={getRowClass}
                        onFirstDataRendered={onFirstDataRendered}
                        onGridSizeChanged={sizeColumnsToFit}
                        onSortChanged={sizeColumnsToFit}
                        pinnedTopRowData={[this.props.rowData[0]]}
                        rowData={this.getRowData()}
                        pagination={true}
                        paginationPageSize={15}
                        defaultColDef={{ sortable:true }}

                    >
                          {this.props.columns.map(col =>
                              <AgGridColumn
                                  width={col.width}
                                  headerName={col.columnHeader}
                                  field={col.columnName}
                                  cellStyle={dynamicCellStyle}
                                  headerClass='FuelEconDataTableHeader'
                                  comparator={col.columnName === "fleet" ? fleetNameCompare : valueComparator}
                                  sortable={col.columnName === "dutyCycle" ? false : true}
                                  minWidth={ col.columnName === "fleet" ? 240 : 100}
                                  cellRenderer={
                                      col.columnName === "fleet"
                                          ? "vehicleCellRenderer"
                                          : col.columnName === "mileage" || col.columnName === "fuelConsumed" || col.columnName === "idleFuel" || col.columnName === "ptoFuel" || col.columnName === "tractiveFuelEconomy"
                                            ? "numberCellRenderer"
                                              : col.columnName === "dutyCycle"
                                                  ? "barCellRenderer"
                                                  : col.columnName === "overallFuelEconomy"
                                                      ? "totalFuelEconCellRenderer"
                                                      : null
                                  }
                              >
                            </AgGridColumn>)}

                        {/*{columnName: 'fleet', columnHeader: 'Fleet', width: 150},*/}
                        {/*{columnName: 'mileage', columnHeader: 'Mileage (mi)', width: 150 },*/}
                        {/*{columnName: 'fuelConsumed', columnHeader: 'Fuel Consumed (gal)', width: 150 },*/}
                        {/*{columnName: 'totalFuelEconomy', columnHeader: 'Total Fuel Economy (mpeg)', width: 250 },*/}
                        {/*{columnName: 'tractiveFuel', columnHeader: 'Tractive Fuel (mpg)', width: 150 },*/}
                        {/*{columnName: 'idleFuel', columnHeader: 'Idle Fuel (gal)', width: 150 },*/}
                        {/*{columnName: 'ptoFuel', columnHeader: 'PTO Fuel (gal)', width: 150 },*/}
                        {/*{columnName: 'dutyCycle', columnHeader: 'Duty Cycle', width: 300 }*/}



                    </AgGridReact>
                </div>

            </React.Fragment>
        )
    }


}