import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { NavLink } from 'react-router-dom'

interface IProps {
    pageTitle: string;
}

interface IState {
    pageTitle: string;
}


export class FuelSubnav extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            pageTitle: this.props.pageTitle
        } 
        
    }
    
    componentDidMount() {

    }
        
    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <h2>{this.props.pageTitle}</h2>
                    </Col>
                    <Col className="text-right">
                        <div className="btn-group">
                            <NavLink to="/fuel-weight" className="btn btn-secondary" >Weight</NavLink>
                            <NavLink to="/fuel-behavior" className="btn btn-secondary" >Driver Behavior</NavLink>
                            <NavLink to="/fuel-route" className="btn btn-secondary" >Route</NavLink>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
