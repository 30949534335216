import * as React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { isNullOrUndefined } from 'util';

interface IChartState {
    chartData: any;
    chart: am4charts.XYChart;
    chartContainer: string;
}

interface IChartProps {
    chartData: any;
    chartContainer: string;
    yAxisTitleRotation?: number;
    bulletColor?: string;
    alternatingGridColors?: {
        isEnabled?: boolean,
        alternateColor?: string,
        opacity?: number
    }
}

export default class ScatterPlotChart extends React.Component<IChartProps, IChartState> {
    constructor(props: IChartProps) {
        super(props);
        this.state =
        {
            chartData: props.chartData,
            // chartData: tempData,
            chartContainer: props.chartContainer,
            chart: am4core.create(props.chartContainer, am4charts.XYChart),
        }

    }


    public Create(xAxisName: string, yAxisName: string, xAxisField: string, yAxisField: string, numberFormat: string, trendlines: Array<{ valueX: string; valueY: string, data:any }>, schowScrollbars: boolean) {
        am4core.useTheme(am4themes_animated);
        am4core.addLicense("CH308822591");
        this.state.chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
        this.state.chart.data = this.state.chartData;

        // this.state.chart.legend = new am4charts.Legend();
        // this.state.chart.legend.position = "bottom";

        if (numberFormat !== '')
            this.state.chart.numberFormatter.numberFormat = '#' + numberFormat;


        // Create axes
        var valueAxisX = this.state.chart.xAxes.push(new am4charts.ValueAxis());
        valueAxisX.title.text = xAxisName;
        valueAxisX.renderer.minGridDistance = 40;

        // Create value axis
        var valueAxisY = this.state.chart.yAxes.push(new am4charts.ValueAxis());
        valueAxisY.title.text = yAxisName;

        if (!isNullOrUndefined(this.props.yAxisTitleRotation)) {
            valueAxisY.title.rotation = this.props.yAxisTitleRotation;
            valueAxisY.title.wrap = true;
            valueAxisY.title.maxWidth = 120;
        }

        if (this.props.alternatingGridColors.isEnabled) {
            valueAxisY.renderer.axisFills.template.disabled = false;
            valueAxisY.renderer.axisFills.template.fillOpacity = 0.5;
            valueAxisY.renderer.axisFills.template.fill = am4core.color(this.props.alternatingGridColors.alternateColor);
        }

        // Create series
        var lineSeries = this.state.chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.valueY = yAxisField;
        lineSeries.dataFields.valueX = xAxisField;
        lineSeries.strokeOpacity = 0;
        /*
        var lineSeries2 = this.state.chart.series.push(new am4charts.LineSeries());
        lineSeries2.dataFields.valueY = "by";
        lineSeries2.dataFields.valueX = "bx";
        lineSeries2.strokeOpacity = 0;
        */

        // Add a bullet
        var bullet = lineSeries.bullets.push(new am4charts.Bullet());

        // Add a triangle to act as am arrow
        var arrow = bullet.createChild(am4core.Circle);
        arrow.horizontalCenter = "middle";
        arrow.verticalCenter = "middle";
        arrow.strokeWidth = 0;
        arrow.fill = (this.props.bulletColor ? am4core.color(this.props.bulletColor) : this.state.chart.colors.getIndex(0));
        // arrow.direction = "top";
        arrow.width = 6;
        arrow.height = 6;
        /*
        // Add a bullet
        var bullet2 = lineSeries2.bullets.push(new am4charts.Bullet());

        // Add a triangle to act as an arrow
        var arrow2 = bullet2.createChild(am4core.Triangle);
        arrow2.horizontalCenter = "middle";
        arrow2.verticalCenter = "middle";
        arrow2.rotation = 180;
        arrow2.strokeWidth = 0;
        arrow2.fill = this.state.chart.colors.getIndex(3);
        arrow2.direction = "top";
        arrow2.width = 12;
        arrow2.height = 12;
        */
            //add the trendlines
        for (let x = 0; x < trendlines.length; x++) {
            var trend = this.state.chart.series.push(new am4charts.LineSeries());
            trend.dataFields.valueY = trendlines[x].valueY;
            trend.dataFields.valueX = trendlines[x].valueX;
            trend.strokeWidth = 2
            trend.stroke = this.state.chart.colors.getIndex(0);
            trend.strokeOpacity = 0.7;
            trend.data = trendlines[x].data;

        }

        if (schowScrollbars) {
            //scrollbars
            this.state.chart.scrollbarX = new am4core.Scrollbar();
            this.state.chart.scrollbarY = new am4core.Scrollbar();

        }

    }

    public ReDraw(newdata: Array<{ dateTimeUtc: Date; value: number; }>) {
        this.state.chart.data = newdata;
    }

}
