import * as React from 'react';
import { Row, Col, Card, CardDeck, CardTitle, CardText, CardBody, } from 'reactstrap';
import { DropDownFilterBar } from '../../DropDownFilterBar'
import { Link } from 'react-router-dom';
import { FuelSubnav } from '../SubNav'
import { DataTable } from '../../DataTable/FuelEconByRoute'  // This might need to be changed to a new DataTable: one specifically by route
import { InovexDropDown } from '../../InovexDropDown';

interface IDashboardProps {

}

interface IDashboardState {
    rowData: any,     // 'any' becauase the structure of the objects in teh array will vary
    columns: Array<{ columnName: string, columnHeader: string, width: number, minBarPercentDisplay?: number }>
}

export class FuelByRoute extends React.Component<IDashboardProps, IDashboardState> {
    constructor(props: IDashboardProps) {
        super(props);
        this.state = {
            rowData: [],
            columns: []
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    public getFuelRouteData() {

        fetch('api/Fuel/FuelEconRouteDataTable')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                this.populateFuelByRoute(data)
            })
            .catch((error: Error) => {
                throw error
            });

    }

    public populateFuelByRoute(data: any) {
        const columns = [
            { columnName: 'milesDriven', columnHeader: 'Mileage (mpg)', width: 70 },
            { columnName: 'totalConsumption', columnHeader: 'Fuel Cons. (gal)', width: 70 },
            { columnName: 'fuelEconomy', columnHeader: 'Fuel Economy (mpg)', width: 90 },
            { columnName: 'citySpeed', columnHeader: 'City Speed (+/- predicated gal)', width: 115 },
            { columnName: 'citySpeedAvg', columnHeader: 'Speed (mph)', width: 60 },
            { columnName: 'highwaySpeed', columnHeader: 'Highway Speed (+/- predicated gal)', width: 125 },
            { columnName: 'highwaySpeedAvg', columnHeader: 'Speed (mpg)', width: 60 },
            { columnName: 'dutyCycle', columnHeader: '% of Driving', width: 180, minBarPercentDisplay: 15 }
        ];

        console.log(data);
        this.setState(
            {
                rowData: data,
                columns: columns
            }, () => this.render);
    }


    async fetchData() {
        this.getFuelRouteData();
    }


    render() {
        let datatable;
        if (this.state.rowData && this.state.columns)
            datatable = <DataTable rowData={this.state.rowData} columns={this.state.columns} />

        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <nav className="dashboardTitle" aria-label="breadcrumb">
                            <div className="breadcrumb">
                                <Link className="breadcrumb-item" to="/health-general" >FUEL</Link>
                                <Link className="breadcrumb-item" to="/health-general" >Fuel Efficiency</Link>
                                <a className="breadcrumb-item" >Fuel Efficiency By Driver Behavior</a>
                            </div>
                        </nav>
                    </Col>
                </Row>
                <DropDownFilterBar />
                <FuelSubnav pageTitle="Fuel Economy By Route" />
                <Row className="pb-0 mb-0">
                    <Col xs="2" className="tile p-0 mb-0">
                        <div className="text-white pb-0 mb-0 pl-2 dropdownFleetHealthGroupByToggleLabel">
                            <div className="mt-0 pt-0 pb-0" style={{ float: "left", width: "33%", lineHeight: "30px" }}>
                                GROUP BY
                            </div>
                            <div className="mt-1 mb-1" style={{ display: "inline-block", width: "1px", backgroundColor: "white", height: "70%", float: "left" }} />
                            <div className="mt-0 pt-0" style={{ float: "left", width: "66%", height: "100%" }}>
                                <InovexDropDown dropDownId="dropDownGroupBy"
                                    dropDownToggleId="dropdownFleetHealthGroupByToggleID"
                                    initialValue="VIN"
                                    //onValueSelected={(trendValue: any) => this.changeTrendValue(trendValue)}
                                    dropDownStyle={{ width: "100%" }}
                                    omitSelected={false} >
                                    <span>VIN</span>
                                    <span>Company</span>

                                </InovexDropDown>

                            </div>

                        </div>

                    </Col>


                </Row>
                <Row className="pt-0 mt-0">
                    <Col className='tile pr-0 pb-0 pt-0'>

                        {datatable}
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}
