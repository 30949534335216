import * as React from 'react';
import { Link } from 'react-router-dom';
import '../../DataTable/Shared/styles.css'


export default (props) => {
    let to = '';
    let linkText = '';
    let linkVINText = ''
    if (props.value !== undefined) {
        to = '/health-vehicle-' + props.value.vin
        linkText = props.value.name

        if (props.value.vin !== undefined) {
            linkVINText = props.value.vin;
        }

        if (props.value.vin === "TOTAL") {
            linkVINText = "";
        }
            
    }

    return (
        <div>
            <strong><Link to={to} className="dataTableLink">{linkText}</Link></strong>
            <br />
            <small><Link to={to} className="dataTableLinkSub">{linkVINText}</Link></small>
        </div>
        
    );
};