import * as React from 'react';
import '../../DataTable/Shared/styles.css'

export default (props) => {
    // console.log('totalFuelEconCellRenderer: ' + props.colDef.field);
    // console.log(props);

    let totalFuelEconomy: number = 0;
    let total: number = 0;
    let positivePercentage: number = 0;
    let maxValue: number = 0;
    let output;
    let classNameStyle;
    let paddingTop: number = 0;

    if (props.value !== undefined) {
        totalFuelEconomy = Number(props.value.total.toLocaleString(navigator.language, { minimumFractionDigits: props.value.decimalPoints }))

        total = props.value.total;
        maxValue = props.value.maxValue;
        positivePercentage = (total / maxValue) * 100

        if (props.value.isHeader) {
            output = <div style={{ float: "right", marginTop: '12px' }} ><strong>{totalFuelEconomy.toFixed(1)}</strong></div>
            paddingTop = 6
        } else {
            output = <div style={{ float: "right", marginTop: '9px' }}>{totalFuelEconomy.toFixed(1)}</div>
            paddingTop = 5
        }

        classNameStyle = props.value.barColor == undefined ? "bar DarkGrey" : "bar " + props.value.barColor;
    }

        
  
    return (
        <React.Fragment>
            <div className="margin-left-minus-16" style={{marginTop: '5px'}}>
               
                <div style={{ width: '83%', verticalAlign: "middle", display: 'inline-block', position: 'relative', height: '50px', left: '0', paddingBottom: '0px', paddingTop: paddingTop}}>
                    <div style={{ left: "0", verticalAlign:"middle", width: positivePercentage + '%', display: 'inline-block', height: "30px" }} className={classNameStyle}>
                        
                    </div>
                </div>
                {output}
            </div>
        </React.Fragment>
    );
};