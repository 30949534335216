import * as React from 'react';
import { Row, Container, Col } from 'reactstrap';
import { DropDownFilterBar } from '../../DropDownFilterBar'
import { Link } from 'react-router-dom';

interface IBatteryHealthProps {

}

interface IBatteryHealthState {
    pageTitle: string,
    parentPage: string,
}

export class BatteryHealth extends React.Component<IBatteryHealthProps, IBatteryHealthState> {
    constructor(props: IBatteryHealthProps) {
        super(props);
        this.setState({ pageTitle: 'Battery Health', parentPage: '' });
    }


    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <nav className="dashboardTitle" aria-label="breadcrumb">
                            <div className="breadcrumb">
                                <Link className="breadcrumb-item" to="/health-general" >HEALTH</Link>
                                <a className="breadcrumb-item" >Battery Health</a>
                            </div>
                        </nav>
                    </Col>
                </Row>
                <DropDownFilterBar />
                <Row>
                    <Col>this is where the Battery Health charts will be</Col>
                </Row>
            </React.Fragment>
        );
    }
}