import * as React from 'react';
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import * as ReactDOM from 'react-dom';
import { BiChevronDown } from 'react-icons/bi';

interface IInovexDropDownProps {
    onValueSelected?: Function, //click function called when any element clicked
    values?: string[], //simple array of strings to populate dropdown
    dropDownToggleId?: string, //passable id for the dropdown if needed to be called
    dropDownId?: string, //passable id for the dropdown itself if needed to be called
    staticTitle?: string, //give the dropdown a title that does not change
    omitSelected?: boolean, //optionally remove the selected element from the list
    initialValue?: string, //give initial value. if is a text content of the selectable will be selected
    children?: any, //this.props.children. can pass elements like filling a div
    dropDownStyle?: any, //styles for DropDown component
    dropDownToggleStyle?: any, //styles for DropDownToggle component
    activeEnabled?: boolean, //enable the showing of a selected item
    itemStyles?: React.CSSProperties[], //array of styles to apply to items
    oldDropDownFormat?: boolean //use the old style dropdown
    hover?: boolean, //over the toggle access element when hovering instead of click
    dropDownClassName?: string, //class names for DropDown
    dropDownToggleClassName?: string //class names for DropDown Toggle
    hideCaret?: boolean //hide the caret(arrow). works only in old format rn
    onToggleClicked?: Function //click function for when the toggle is clicked
}

interface IInovexDropDownState {
    isOpen: boolean, //state of if dropdown open or not
    selectedValue: string, //state holding header/selected value
    content: any, //to be displayed
    elements: any | null, //from props.children the plain html of the components for reading changes
}

export class InovexDropDown extends React.Component<IInovexDropDownProps, IInovexDropDownState> {
    constructor(props: IInovexDropDownProps) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.toggleClick = this.toggleClick.bind(this);
        this.initialize = this.initialize.bind(this);
        this.mouseEnter = this.mouseEnter.bind(this);
        this.mouseLeave = this.mouseLeave.bind(this);

        var initValues = this.initialize();
        this.state = { //set the state
            isOpen: false,
            selectedValue: initValues.selectedValue,
            content: initValues.content,
            elements: initValues.elements,
        };
    }

    componentDidUpdate(prevprops: any) {
        if (prevprops.values != this.props.values) {
            var initValues = this.initialize();
            this.setState({
                selectedValue: initValues.selectedValue,
                content: initValues.content,
                elements: initValues.elements,
            });
        }
    }

    initialize() {
        //initial value of dropdown if no content
        let dropDownItems =
            <DropdownMenu>
                <DropdownItem header>Header</DropdownItem>
                <DropdownItem disabled>Action</DropdownItem>
                <DropdownItem>Another Action</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Another Action</DropdownItem>
            </DropdownMenu>;
        let selectedValue = "";

        let elements: any[] = [];
        if (this.props.values && this.props.values.length >= 1) { //if passed array 
            selectedValue = (this.props.initialValue ? this.props.initialValue : this.props.values[0]); // set header to first element unless initial
        } else if (this.props.children) {//if passed props
            for (let i = 0; i < React.Children.count(this.props.children); i++) {//convert all children to html to get the text content later
                elements.push(document.createElement('div'));
                if (i == 0) { // if first element callback set initial valye to first element unless initial
                    ReactDOM.render(this.props.children[i], elements[i], () => { this.setState({ selectedValue: (this.props.initialValue ? this.props.initialValue : elements[0].textContent) }); });
                    continue;
                }
                ReactDOM.render(this.props.children[i], elements[i]);
            }
        } else { //set header if nothing entered
            console.log("Dropdown has no values");
            selectedValue = (this.props.initialValue ? this.props.initialValue : "Not Set");
        }
        return {
            selectedValue: selectedValue,
            content: dropDownItems,
            elements: elements,
        }

    }

    toggle() { //function to toggle dropdown
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    }

    mouseEnter() {
        this.setState(prevState => ({
            isOpen: true
        }));
    }

    mouseLeave() {
        this.setState(prevState => ({
            isOpen: false
        }));
    }

    changeValue(e: any) {//function to handle on click
        var valueSelectedFunction = this.props.onValueSelected;
        var textContent = e.currentTarget.textContent;
        if (valueSelectedFunction) { // if click function provided
            if (valueSelectedFunction.length === 0) { //if no args just run
                this.setState({ selectedValue: e.currentTarget.textContent }, () => { valueSelectedFunction!() });
            } else { //if 1 or more args pass element
                this.setState({ selectedValue: e.currentTarget.textContent }, () => { valueSelectedFunction!(textContent) });
            }
        } else {
            this.setState({ selectedValue: e.currentTarget.textContent }); //set the selected value to the text content of the clicked element
        }
    }

    toggleClick() {//function to handle on click
        if (this.props.onToggleClicked) { // if click function provided
            this.props.onToggleClicked();
        }
    }

    render() {
        let content = this.state.content;
        if (this.props.values && this.props.values.length >= 1) { //if values passed
            let items = [];
            for (let i = 0; i < this.props.values.length; i++) { //render each item
                if (this.props.values[i] == this.state.selectedValue) { //if the value[i] is the selected value
                    if (!this.props.omitSelected || this.props.staticTitle) { //if not omitting or having a title
                        items.push(<DropdownItem style={this.props.itemStyles ? this.props.itemStyles[i] : {}} onClick={this.changeValue} active={this.props.activeEnabled || typeof (this.props.activeEnabled) === "undefined"}>{this.props.values[i]}</DropdownItem>);//render the selected value set to active
                    }
                    continue;
                }
                items.push(<DropdownItem style={this.props.itemStyles ? this.props.itemStyles[i] : {}} onClick={this.changeValue}>{this.props.values[i]}</DropdownItem>); //render normally
            }
            //push the elemernts into a menu
            content =
                <DropdownMenu id="dmenu">
                    {items}
                </DropdownMenu>;

        } else if (this.props.children) { //if children passed
            let items = [];
            for (let i = 0; i < React.Children.count(this.props.children); i++) {//render each item
                if (this.state.elements[i].textContent == this.state.selectedValue) {//if the value[i] is the selected value
                    if (!this.props.omitSelected || this.props.staticTitle) {//if not omitting or having a title
                        items.push(<DropdownItem style={this.props.itemStyles ? this.props.itemStyles[i] : {}} onClick={this.changeValue} active={this.props.activeEnabled || typeof (this.props.activeEnabled) === "undefined"}>{this.props.children[i]}</DropdownItem>);//render the selected value set to active
                    }
                    continue;
                }
                items.push(<DropdownItem style={this.props.itemStyles ? this.props.itemStyles[i] : {}} onClick={this.changeValue}>{this.props.children[i]}</DropdownItem>);//render normally
            }
            //push the elemernts into a menu
            content =
                <DropdownMenu>
                    {items}
                </DropdownMenu>;

        }
        //header = statictitle if provided otherwise the selectedvalue
        let caret = this.props.staticTitle ? this.props.staticTitle : this.state.selectedValue;

        //render with above information
        return (
            <Dropdown id={this.props.dropDownId} className={this.props.dropDownClassName ? this.props.dropDownClassName : ""} isOpen={this.state.isOpen} toggle={!this.props.hover ? this.toggle : () => { }} style={this.props.dropDownStyle} onMouseEnter={this.props.hover ? this.mouseEnter : () => { }} onMouseLeave={this.props.hover ? this.mouseLeave : () => { }}>
                <DropdownToggle id={this.props.dropDownToggleId} className={this.props.dropDownToggleClassName ? this.props.dropDownToggleClassName : ""} variant="success" style={this.props.dropDownToggleStyle} caret={this.props.oldDropDownFormat && !this.props.hideCaret} onClick={this.toggleClick}>
                    {this.props.oldDropDownFormat ?
                        <span>{caret}</span> :
                        <span>
                            <span style={{ float: "left" }}>{caret}</span>
                            <BiChevronDown size="20px" style={{ position: "relative", top: "3px", float: "right" }} />
                        </span>}
                </DropdownToggle>
                {content}
            </Dropdown>
        );
    }
}