import * as React from 'react';
import { Row, Col, Label } from 'reactstrap';
import { InovexDropDown } from '../InovexDropDown'
import { TiExportOutline } from "react-icons/ti";
import { AiOutlineCalendar } from "react-icons/ai"; // calendar
import { OutsideClickHandler } from "../OutsideClickHandler"

// react-date-range
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRangePicker } from 'react-date-range';
import { addDays, addMonths, format } from 'date-fns';


interface IProps {
}

interface IState {

    dateRange: any;
    companies: string[],
    selectedCompany: string,
    fleets: string[],
    selectedFleet: string,
    subFleets: string[],
    selectedSubFleet: string,
    calendar: boolean
}


export class DropDownFilterBar extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.calculateDropDown = this.calculateDropDown.bind(this);
        this.updateCompany = this.updateCompany.bind(this);
        this.updateFleet = this.updateFleet.bind(this);
        this.updateSubFleet = this.updateSubFleet.bind(this);
        this.showOrHideCalendar = this.showOrHideCalendar.bind(this);
        this.hideCalendar = this.hideCalendar.bind(this)

        let companies: string[] = [];
        let selectedCompanyIndex = -1;
        for (let i = 0; i < this.testCompanies.length; i++) {
            companies.push(this.testCompanies[i].Company);
        }
        selectedCompanyIndex = 0;
        let fleets: string[] = [];
        let selectedFleetIndex = -1;
        for (let i = 0; i < this.testCompanies[selectedCompanyIndex].Fleets.length; i++) {
            fleets.push(this.testCompanies[selectedCompanyIndex].Fleets[i].Fleet);
        }
        selectedFleetIndex = 0;

        let subFleets: string[] = [];
        let selectedSubFleetIndex = -1;
        for (let i = 0; i < this.testCompanies[selectedCompanyIndex].Fleets[selectedFleetIndex].SubFleets.length; i++) {
            subFleets.push(this.testCompanies[selectedCompanyIndex].Fleets[selectedFleetIndex].SubFleets[i].SubFleet);
        }
        selectedSubFleetIndex = 0;

        this.state = {
            dateRange: {
                startDate: new Date(2021, 5, 1),
                endDate: new Date(2021, 7, 31),
                key: 'selection',
            },
            companies: companies,
            selectedCompany: companies[selectedCompanyIndex],
            fleets: fleets,
            selectedFleet: fleets[selectedFleetIndex],
            subFleets: subFleets,
            selectedSubFleet: subFleets[selectedSubFleetIndex],
            calendar: false
        };
    }

    testCompanies = [{
        Company: "North America Shipping",
        Fleets: [{
            Fleet: "Canada Coast to Coast",
            SubFleets: [{
                SubFleet: "Ontario"
            },
            {
                SubFleet: "Quebec"
            },
            {
                SubFleet: "Prairies"
            },
            {
                SubFleet: "British Columbia"
            },
            {
                SubFleet: "Atlantic"
            },
            ],
        }, {
            Fleet: "United States Shipping Service",
            SubFleets: [{
                SubFleet: "West"
            },
            {
                SubFleet: "Mid West"
            },
            {
                SubFleet: "North East"
            },
            {
                SubFleet: "South West"
            },
            {
                SubFleet: "South East"
            },
            ],
        }],
    },
    {
        Company: "Europe Delivery",
        Fleets: [{
            Fleet: "United Kingdom Fleet",
            SubFleets: [{
                SubFleet: "Scotland"
            },
            {
                SubFleet: "England"
            },
            {
                SubFleet: "Wales"
            },
            {
                SubFleet: "Northern Ireland"
            },
            ],
        }, {
            Fleet: "France Delivery",
            SubFleets: [{
                SubFleet: "North"
            },
            {
                SubFleet: "South"
            },
            {
                SubFleet: "East"
            },
            {
                SubFleet: "West"
            },
            {
                SubFleet: "Center"
            },
            ],
        }],
    }];
    
    //changeDateRange(newValue: string) {

    //}

    handleDateRangeSelect(ranges: any) {
        //console.log(ranges);

        var formattedStartDate = format(ranges[0].startDate, "MM/dd/yyyy");
        var formattedEndDate = format(ranges[0].endDate, "MM/dd/yyyy");

        var labelText = formattedStartDate + " - " + formattedEndDate;

        (document.getElementById('dateRangeLabel') as HTMLLabelElement).innerText = labelText;
    }
    
    showOrHideCalendar() {
        //console.log("show or hide calendar");
        var div = (document.getElementById('calendar') as HTMLDivElement);//document.getElementById("calendar");
        if (div.style.display == "block") {
            div.style.display = "none"

        } else {
            div.style.display = "block"
        }
    }
      
    hideCalendar() {
        (document.getElementById('calendar')).style.display="none"
    }

    updateCompany(value: string) {
        this.setState({ selectedCompany: value }, () => { this.calculateDropDown() });
    }

    updateFleet(value: string) {
        this.setState({ selectedFleet: value }, () => { this.calculateDropDown() });
    }

    updateSubFleet(value: string) {
        this.setState({ selectedSubFleet: value }, () => { this.calculateDropDown() });

    }

    calculateDropDown() {
        let companies: string[] = [];
        let selectedCompanyIndex = -1;
        for (let i = 0; i < this.testCompanies.length; i++) {
            companies.push(this.testCompanies[i].Company);
        }
        selectedCompanyIndex = companies.indexOf(this.state.selectedCompany) == -1 ? 0 : companies.indexOf(this.state.selectedCompany);
        let fleets: string[] = [];
        let selectedFleetIndex = -1;
        for (let i = 0; i < this.testCompanies[selectedCompanyIndex].Fleets.length; i++) {
            fleets.push(this.testCompanies[selectedCompanyIndex].Fleets[i].Fleet);
        }
        selectedFleetIndex = fleets.indexOf(this.state.selectedFleet) == -1 ? 0 : fleets.indexOf(this.state.selectedFleet);

        let subFleets: string[] = [];
        let selectedSubFleetIndex = -1;
        for (let i = 0; i < this.testCompanies[selectedCompanyIndex].Fleets[selectedFleetIndex].SubFleets.length; i++) {
            subFleets.push(this.testCompanies[selectedCompanyIndex].Fleets[selectedFleetIndex].SubFleets[i].SubFleet);
        }
        selectedSubFleetIndex = subFleets.indexOf(this.state.selectedSubFleet) == -1 ? 0 : subFleets.indexOf(this.state.selectedSubFleet);

        this.setState({
            companies: companies,
            selectedCompany: companies[selectedCompanyIndex],
            fleets: fleets,
            selectedFleet: fleets[selectedFleetIndex],
            subFleets: subFleets,
            selectedSubFleet: subFleets[selectedSubFleetIndex],
        });
    }

    componentDidMount() {
        //this.changeDateRange("Last 6 Months");
        this.handleDateRangeSelect([this.state.dateRange]);
    }

    render() {
        //const  selectionRangeOptions = {
        //    startDate: addMonths(new Date(), -6),
        //    endDate: new Date(),
        //    key: 'selection',
        //}




        const staticRangeOptions = [
            {
                label: "Last 7 Days",
                hasCustomerRendering: true,
                range: () => ({
                    startDate: addDays(new Date(), -7),
                    endDate: new Date()
                }),
                isSelected() {
                    return true;
                }
            },
            {
                label: "Last 30 Days",
                hasCustomerRendering: true,
                range: () => ({
                    startDate: addDays(new Date(), -30),
                    endDate: new Date()
                }),
                isSelected() {
                    return true;
                }
            },
            {
                label: "Last 6 Months",
                hasCustomerRendering: true,
                range: () => ({
                    startDate: addMonths(new Date(), -6),
                    endDate: new Date()
                }),
                isSelected() {
                    return true;
                }
            },
        ];

        return (
            <React.Fragment>
                <Row>
                    <Col xs="auto" style={{ width: "202px" }} className="pr-1 mr-0 pl-2">
                        <InovexDropDown
                            staticTitle="Company"
                            dropDownToggleId="dropdowntogglefilterCompany"
                            values={this.state.companies}
                            initialValue={this.state.selectedCompany}
                            onValueSelected={(value: any) => this.updateCompany(value)} />
                    </Col>
                    <Col xs="auto" style={{ width: "202px" }} className="pr-1 mr-0 pl-0">
                        <InovexDropDown staticTitle="Fleet"
                            dropDownToggleId="dropdowntogglefilterFleet"
                            values={this.state.fleets}
                            initialValue={this.state.selectedFleet}
                            onValueSelected={(value: any) => this.updateFleet(value)} />
                    </Col>
                    <Col xs="auto" style={{ width: "202px" }} className="pr-1 mr-0 pl-0">
                        <InovexDropDown staticTitle="Sub fleet"
                            dropDownToggleId="dropdowntogglefilterSubFleet"
                            values={this.state.subFleets}
                            initialValue={this.state.selectedSubFleet}
                            onValueSelected={(value: any) => this.updateSubFleet(value)} />
                    </Col>
                    
                    <Col xs="auto" className="text-black bg-white" /*style={{width:"292px"}}*/>
                        <OutsideClickHandler onOutsideClick={this.hideCalendar}>
                            <Row xs="auto"
                                style={{ height: "15px" }}
                                onClick={this.showOrHideCalendar}
                            >
                                <Col xs="auto">
                                    <Label
                                        className="text-black bg-white align-middle mt-0 pt-2"
                                        id="dateRangeLabel"
                                    />
                                </Col>
                                <Col xs="2">
                                    <AiOutlineCalendar
                                        className="text-black bg-white ml-0 pl-0 mt-2"
                                        size="20px"
                                    />
                                </Col>
                            </Row>
                                <div id="calendar" style={{ display: "none", position: "absolute", zIndex: 2, left: "-598px", top: "38px" }}>

                                    <DateRangePicker
                                        onChange={item => {
                                            this.handleDateRangeSelect([item.selection]);
                                            this.setState({ dateRange: item.selection });
                                        }}
                                        // showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={2} // shows 2 months for start date and end date
                                        ranges={[this.state.dateRange]} // sets the start date and end date
                                        direction="horizontal" // has the 2 calendars showing horizontally
                                        preventSnapRefocus={true}
                                        calendarFocus="forwards"
                                        staticRanges={staticRangeOptions} // sets the ranges on the left side of the control
                                        //shownDate={new Date()}
                                        maxDate={new Date()} // sets the maximum date shown to be today
                                    />

                                </div>
                        </OutsideClickHandler>
                    </Col>
                    <Col>
                        <div style={{ float: "right", width: "100px" }}>
                            <Label className="text-white align-middle mt-0 pt-0 mb-0 pb-0" style={{ fontSize: "12px", lineHeight: "15px" }} ><strong>Export<br />Report</strong></Label>
                            <TiExportOutline className="text-white p-0 ml-1 mb-1 mt-0" size="32px" />
                        </div>

                    </Col>

                </Row>
            </React.Fragment>
        );
    }
}
