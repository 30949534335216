import * as React from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import vehicleHealthCellRenderer from '../CellRenderers/vehicleHealthCellRenderer'
import BarCellRenderer from '../CellRenderers/barCellRenderer'
import { LineChart } from '../../Charts';

import '../../DataTable/Shared/styles.css'
import '../../DataTable/Shared/ag-theme-alpine.css';
import '../../DataTable/Shared/ag-grid.css';
import { isNullOrUndefined } from 'util';

/*
 * 
 * Trying to make a more generic data table with bars that can be used in
 * any dashboard.
 * Data must have the following properies in order for bars to render
   columnName: { total: 3.8, maxValue: 5.2, isStacked: false, isDivergent: false, isBar: true },

these are passed into barCellRender
   isBar: determines whether or not to even show a bar. If false, then just show the number
   isStacked: is it a stacked bar? this will show a 100% width bar that is split into two segments. This can/should be extended to allow multiple segments
   isDivergent: is it a divergent bar? If so, a vertical line will be drawn in the middle of the cell and the bar will go left or right depending on whether the value is positive or negative

*/ 

interface IDataTableProps {
    columns: Array<{ columnName: string, columnHeader: string, width: number }>
    rowData: any;
}

interface IDataTableState {
    loading: boolean;
    columns: Array<{ columnName: string, columnHeader: string, width: number }>
    rowData: any;
    pinnedRowData: any;
}

// All the docs are here: 
// https://www.ag-grid.com/react-data-grid

export class DataTable extends React.Component<IDataTableProps, IDataTableState> {
    constructor(props: IDataTableProps) {
        super(props);

        this.state =
        {
            loading: true,
            columns: [],
            rowData: this.props.rowData,
            pinnedRowData: [this.props.rowData[0]]
       };

    }

    componentDidMount() {

    }


    public getRowData() {
        // console.log('getRowData');;
        // console.log(this.props.rowData);;
        this.props.rowData.shift(); // drop the first element because it will be pinned
        // console.log(this.props.rowData);;
        return this.props.rowData as any[];
    };

    public getPinnedRowData() {
        // console.log('getPinnedRowData');;
        // console.log(this.props.rowData[0]);;
        // console.log(this.state.pinnedRowData);
        return [this.props.rowData[0]];
    };



    render() {
        // console.log(this.props.rowData);

        const dynamicCellStyle = params => {
            let style = { width: '', height: '', paddingTop: '', textAlign: '', display: '', alignItems: ''};

            if (params.node.rowPinned === 'top') {
                switch (params.colDef.field) {
                    case 'vehicle':
                        style.display = 'flex';
                        style.alignItems = 'center';
                        break;
                    case 'fuelEconomy':
                    case 'highwaySpeed':
                    case 'citySpeed':
                        style.display = 'block';
                        style.paddingTop =  '0.2rem';
                        break;
                    case 'weightImpactSum':
                        style.textAlign = 'center';
                        break;
                    case 'overallFuelEconomyAvg':
                        style.display = 'block';
                        style.paddingTop = '0.3rem';
                        break;
                    case 'dutyCycle':
                        style.display = 'block';
                        style.paddingTop = '0.2rem';
                        break;
                    case 'weightImpactAvgMpg':
                        style.display = 'block';
                        break;
                    // case 'totalConsumption':
                    //     style.width = '15%';
                    //     break;
                    // case 'milesDriven':
                    // case 'totalConsumption':
                    // case 'averageGrossWeightInHauling':
                    // case 'gallonsSavedFromWeight':
                    //     style.width = '10%';
                    //     style.paddingTop = '4px';
                    //     break;
                    default:
                        style.paddingTop = '0.5rem';
                        break;
                }
            }
            else {
                switch (params.colDef.field) {
                    case 'vehicle':
                        style.paddingTop = '0.3rem';
                        break;
                    case 'weightImpactSum':
                        style.textAlign = 'center';
                        break;
                    case 'fuelEconomy':
                    case 'highwaySpeed':
                    case 'citySpeed':
                        style.display = 'block';
                        style.paddingTop = '0rem';
                        break;
                    case 'overallFuelEconomyAvg':
                    case 'weightImpactAvgMpg':
                        style.paddingTop = '0.3rem';
                        style.display = 'block';
                        break;
                    case 'dutyCycle':
                        style.display = 'block';
                        break;
                    // case 'totalConsumption':
                    //     style.width = '15%';
                    //     break;
                    // case 'milesDriven':
                    // case 'totalConsumption':
                    // case 'averageGrossWeightInHauling':
                    // case 'gallonsSavedFromWeight':
                    //     style.width = '10%';
                    //     style.paddingTop = '4px';
                    //     break;
                    default:
                        style.paddingTop = '0rem';
                        break;
                }
            }

            return style;
        };

        const onFirstDataRendered = (params) => {
            params.api.sizeColumnsToFit();

        };

        const sizeColunmnsToFit = (params) => {
            params.api.sizeColumnsToFit();
        };

        const getRowHeight = params => {
            return params.node.rowPinned === "top" ? 60 : 50
        };

        const getRowClass = params => {
            if (params.node.rowPinned === "top") {
                return 'borderBottomSilver';
            }
        };

        const valueComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
            console.log(nodeA);
            if (isNullOrUndefined(valueA) || isNullOrUndefined(valueB)) return 0;

            if (!isNullOrUndefined(valueA.total) && !isNullOrUndefined(valueB.total)) {
                if (valueA.total == valueB.total) return 0;
                return (valueA.total > valueB.total) ? 1 : -1;
            }
            else {
                if (valueA == valueB) return 0;
                return (valueA > valueB) ? 1 : -1;
            }
        };

        const fleetNameCompare = (valueA, valueB, nodeA, nodeB, isInverted) => {
            if (isNullOrUndefined(valueA) || isNullOrUndefined(valueB)) return 0;
            if (valueA.name == valueB.name) return 0;
            return (valueA.name > valueB.name) ? 1 : -1;
        };

        return (
            <React.Fragment>
                <div
                    id="myGrid"
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                    className="ag-theme-alpine cellsWithLeftBorder"
                >
                    <AgGridReact
                        frameworkComponents={{
                            vehicleCellRenderer: vehicleHealthCellRenderer,
                            barCellRenderer: BarCellRenderer
                        }}
                        rowClass="datatable-row"
                        getRowHeight={getRowHeight}
                        getRowClass={getRowClass}
                        onFirstDataRendered={onFirstDataRendered}
                        onGridSizeChanged={sizeColunmnsToFit}
                        onSortChanged={sizeColunmnsToFit}
                        pinnedTopRowData={this.getPinnedRowData()}
                        rowData={this.getRowData()}
                        pagination={true}
                        paginationPageSize={10}
                        headerHeight={65}
                        defaultColDef={{ sortable: true, comparator:valueComparator }}
                    >
                        <AgGridColumn maxWidth={250} field="vehicle" cellRenderer="vehicleCellRenderer" headerClass='FuelEconDataTableHeader' cellStyle={dynamicCellStyle} comparator={fleetNameCompare} />
                        {this.props.columns.map(col => <AgGridColumn cellRenderer="barCellRenderer" field={col.columnName} headerName={col.columnHeader} width={col.width} headerClass='FuelEconDataTableHeader' cellStyle={dynamicCellStyle} />)}
                    </AgGridReact>
                </div>

            </React.Fragment>
        )
    }


}
