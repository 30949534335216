import * as React from 'react';
import '../../DataTable/Shared/styles.css'

export default (props) => {

    const value = props.value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) === null ? ""  : props.value.toLocaleString(navigator.language, { minimumFractionDigits: 0 });

    return (
        <div className="dt-vals-total">
            { value }
        </div>
    );
};


