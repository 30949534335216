import * as React from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import VehicleCellRenderer from './../CellRenderers/vehicleHealthCellRenderer';
import FleetHealthLevelPercentCellRenderer from '../CellRenderers/fleetHealthLevelPercentCellRenderer';
import FleetHealthTemperatureCellRenderer from '../CellRenderers/fleetHealthTemperatureCellRenderer';
import FleetHealthTemperatureWithAverageCellRenderer from '../CellRenderers/fleetHealthTemperatureWithAverageCellRenderer';
import FleetHealthNumberCellRenderer from '../CellRenderers/fleetHealthNumberCellRenderer';

import '../../DataTable/Shared/styles.css'
import '../../DataTable/Shared/ag-theme-alpine.css';
import '../../DataTable/Shared/ag-grid.css';
import { isNullOrUndefined } from 'util';
import { GridReadyEvent } from 'ag-grid-community';
import { Alert } from 'reactstrap';

interface IDataTableProps {
    columns: Array<{ columnName: string, columnHeader: string, width: number }>
    rowData: any;
    onRowSelected?: Function; //passed function when a row checkbox is selected
    onFilterSelected?: Function;
    showCheckboxes?: boolean;
    min?: number;
    max?: number;
    pushPinnedData?: Function; //passed function when the pinned row data is ready
    pinTopRow?: boolean;
}

interface IDataTableState {
    loading: boolean;
    columns: Array<{ columnName: string, columnHeader: string, width: number }>;
    gridApi: any;
    selectedIndexs: any[];
    initialRun: boolean;
    rowData: any;
    pinnedRowData: any;
    pinTopRow: boolean;
}

// All the docs are here:
// https://www.ag-grid.com/react-data-grid

export class DataTable extends React.Component<IDataTableProps, IDataTableState> {
    constructor(props: IDataTableProps) {
        super(props);

        this.state =
        {
            gridApi: null,
            loading: true,
            columns: [],
            rowData: this.props.rowData,
            pinnedRowData: [this.props.rowData[0]],
            selectedIndexs: [],
            initialRun: true,
            pinTopRow: this.props.pinTopRow ?? true
        };
    }

    componentDidMount() {
        //const script = document.createElement("script");
        //script.src = "../../assets/js/sparkline.min.js";
        //script.async = true;
        // document.body.appendChild(script);
    }

    /*componentDidUpdate() {
        if (this.state.gridApi) {
            if (this.state.gridApi.getSelectedRows().length == 0) {
                console.log("content", this.state.gridApi.getSelectedRows())
                this.onSelectionChanged();
            }
        }
    }*/


    public createGeneralCellRenderer() {
        function GeneralCellRenderer() { }
        GeneralCellRenderer.prototype.init = function (params) {
            //Cant return empty cell specifically for the lamp images logic
            //var cellBlank = !params.value;
            //if (cellBlank) {
            //    // console.log('!params.value');
            //   // console.log(params);
            //    return null;
            //}
            this.ui = document.createElement('div');
            let delta = '';
            //console.log("Field:", params.colDef.field);
            // console.log('GeneralCellRenderer');
            if (params.value !== undefined && params.value !== null) {
            switch (params.colDef.field) {
                case "totalMileage":
                case "totalConsumption":
                case "totalConsumed":
                case "avgConsumption":
                case "avgFuelEcon":
                case "idleFuel":
                case "driverBehavior":
                case "weight":
                case "route":
                case "fuelIdleSum":
                case "vehicleAttribute":
                case "fuelIdleSum":
                    {
                        //if (params.colDef.field === "idleFuel") {
                        //    console.log("Idle Fuel", params.value);
                        //}

                        if (!isNullOrUndefined(params.value.total) || !isNullOrUndefined(params.value.delta)) {
                            let primaryUnit = 'mi';
                            let secondaryUnit = 'mi';

                            switch (params.colDef.field) {
                                case "totalMileage":
                                    primaryUnit = 'mi'; secondaryUnit = 'mi'; break;
                                case "totalConsumption":
                                case "totalConsumed":
                                    primaryUnit = 'gal'; secondaryUnit = 'avg'; break;
                                case "avgConsumption":
                                    primaryUnit = 'gal'; secondaryUnit = 'gal b/w'; break;
                                case "avgFuelEcon":
                                    primaryUnit = 'mpg'; secondaryUnit = 'mpg b/w'; break;
                                case "driverBehavior":
                                case "weight":
                                case "route":
                                case "vehicleAttribute":
                                case "fuelIdleSum":
                                case "idleFuel":
                                case "fuelIdleSum":
                                    primaryUnit = 'gal'; secondaryUnit = 'gal'; break;
                            }
                            if (params.value.delta)
                                delta = params.value.delta > 0 ? '+' + params.value.delta : params.value.delta;

                            if (!params.value.total) //There are cases where total is null - causing 113 to error out
                                params.value.total = 0;

                            if (!params.value.delta) //do the same for safety?
                                params.value.delta = 0;


                            const decimalPlaces: number = params.value.total > 100 ? 0 : 1;


                            this.ui.innerHTML =
                                '<div class="dt-vals-total">' + params.value.total.toLocaleString(navigator.language, { minimumFractionDigits: decimalPlaces }) + ' ' + primaryUnit + '</div>' // +
                            // '<div class="dt-vals-delta">' + delta + ' ' + secondaryUnit + '</div>' +
                            // '<svg class="' + params.colDef.field + params.value.deviceId + ' sparkline red" width="100" height="30" stroke-width="2" ></svg>'

                            if (params.value.data) {
                                const script = document.createElement("script");
                                script.text = 'sparkline.sparkline(document.querySelector(".' + params.colDef.field + params.value.deviceId + '"), [' + params.value.data + ']);';
                                this.ui.appendChild(script);
                            }
                        }
                        else if (params.value.city || params.value.longhaul || params.value.shorthaul) {

                            this.ui.innerHTML = '<div class="show-name">city: ' + params.value.city + ' | longhaul: ' + params.value.longhaul + ' | shorthaul: ' + params.value.shorthaul + '</div>'
                        }

                        break;
                    }
                case 'generalHealth':
                    {
                        this.ui.innerHTML =
                            '<div class="dt-vals-total">' + params.value.health + '% – ' + params.value.status + '</div>' +
                            '<div class="dt-vals-delta">' + params.value.odometer.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) + ' mi ' + '</div>'

                        break;
                    }
                case 'dpfHealth':
                    {
                        let valueToShow = params.value == "n/a" ? "n/a" : params.value + "%";
                        this.ui.innerHTML =
                            '<div class="dt-vals-total">' + valueToShow + '</div>' +    //params.value.level+ '% – ' + params.value.status + '</div>' +
                            '<div class="dt-vals-delta">' + params.value.odometer.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) + ' mi ' + '</div>'

                        break;
                    }
                case 'stopEngineLamp':
                case 'checkEngineLamp':
                case 'milLamp':
                case 'dpfLamp':
                    {
                        console.log('EngineLamp', params.colDef.field);
                        console.log('value', params.value);
                        let className = '';
                        if (params.value) {  // handle lamp ON state
                            switch (params.colDef.field) { // which lamp is it?
                                case "stopEngineLamp": className = 'stop-engine-on'; break;
                                case "checkEngineLamp": className = 'check-engine-on'; break;
                                case "milLamp": className = 'mil-on'; break;
                                case "dpfLamp": className = 'dpf-on'; break;
                            }
                        }
                        else { // handle lamp OFF state
                            switch (params.colDef.field) { // which lamp is it?
                                case "stopEngineLamp": className = 'stop-engine-off'; break;
                                case "checkEngineLamp": className = 'check-engine-off'; break;
                                case "milLamp": className = 'mil-off'; break;
                                case "dpfLamp": className = 'dpf-off'; break;
                            }
                        }
                        this.ui.innerHTML = '<div class="' + className + '" />';
                        break;
                    }
                case 'criticalDTCs':
                    {
                        this.ui.innerHTML =
                            '<div class="dt-vals-total">' + params.value + '</div>' //+
                        //'<div class="dt-vals-delta">' + params.value.pending + ' pending' + '</div>'
                        break;
                    }
                case 'fuelLevel':
                    {
                        this.ui.innerHTML =
                            '<div class="dt-vals-total">' + params.value + '%</div>' //+
                        //'<div class="dt-vals-delta">' + params.value.pending + ' pending' + '</div>'
                        break;
                    }
                //case 'fuelLevel':
                //    //let statusIcon = '/images/' + params.value.status + '.png';
                //    let statusIcon = "";

                //    switch (params.value.status) {
                //        case "warning":
                //            statusIcon = "<RiErrorWarningFill className='text-warning' fontSize='25' />";
                //            break;

                //        case "good":
                //            statusIcon = "<AiFillCheckCircle className='text-success' fontSize='25' />";
                //            break;

                //        case "danger":
                //            statusIcon = "<MdCancel className='text-danger' fontSize='25' />";
                //            break;
                //        default:
                //    }


                //    this.ui.innerHTML =
                //        //'<div class="dt-vals-total">' + params.value.level + '% <img src="' + statusIcon + '" /></div>' //+
                //        '<div class="dt-vals-total">' + params.value.level + '% ' + statusIcon + '</div>' //+
                //        //'<div class="dt-vals-delta">' + params.value.secondaryData + '' + '</div>'
                //    break;

                //case 'dpfHealth2':
                //    let iconName = params.value.status === "normal" ? "good" : params.value.status;
                //    let dpfHealthStatusIcon = '/images/' + iconName + '.png';
                //    let dpfHealth2ValueToShow = params.value.level == -1 ? "n/a" : params.value.level + "%";


                //    this.ui.innerHTML =
                //        '<div class="dt-vals-total">' + dpfHealth2ValueToShow + ' <img src="' + dpfHealthStatusIcon + '" /></div>' //+
                //    //'<div class="dt-vals-delta">' + params.value.secondaryData + '' + '</div>'
                //    break;
                //case 'engineOilLevel':
                //case 'defLevel':
                //case 'engineCoolantLevel':
                //    {
                //        let statusIcon = '/images/' + params.value.status + '.png';

                //        this.ui.innerHTML =
                //            '<div class="dt-vals-total">' + params.value.level + '% <img src="' + statusIcon + '" /></div>' // +
                //            // '<div class="dt-vals-delta">' + params.value.secondaryData + '' + '</div>'
                //        break;
                //    }
                //case 'engineCoolantTemp':
                //    {
                //        let statusIcon = '/images/' + params.value.status + '.png';

                //        this.ui.innerHTML =
                //            '<div class="dt-vals-total">' + params.value.temp + 'C <img src="' + statusIcon + '" /></div>' +
                //            '<div class="dt-vals-delta">AVG:<br />' + params.value.average + 'C' + '</div>'
                //        break;

                //    }
                //case 'dpfTemp':
                //    {
                //        let iconName = params.value.status === "Optimal" ? "good" : params.value.status === "Hot" ? "warning" : params.value.status;

                //        let statusIcon = '/images/' + iconName + '.png';

                //        this.ui.innerHTML =
                //            '<div class="dt-vals-total">' + params.value.temp + 'C <img src="' + statusIcon + '" /></div>' //+
                //            //'<div class="dt-vals-delta"><small>' + params.value.status + '</small></div>'
                //        break;
                //    }
                default:
                    {
                        this.ui.innerHTML = params.value;
                        break;
                    }
            }

            }


        };
        GeneralCellRenderer.prototype.getGui = function () {
            return this.ui;
        };
        return GeneralCellRenderer;
    }

    public isFirstColumn(params) {
        var displayedColumns = params.columnApi.getAllDisplayedColumns();
        var thisIsFirstColumn = displayedColumns[0] === params.column;
        return thisIsFirstColumn;
    }

    onGridReady(params: GridReadyEvent) {
        this.setState({ gridApi: params.api });
    }

    fillSelectedIndexs = (nodes) => {
        let indexs = [];
        for (var i = 0; i < nodes.length; i++) {
            indexs.push(nodes[i].rowIndex);
        }
        return indexs;
    }

    //onSelectionChanged = () => {
    //    console.log("onSelectionChanged", this.props)
    //    var rowSelectedFunction = this.props.onRowSelected;
    //    var selectedRows = this.state.gridApi.getSelectedRows();

    //    if (this.props.min && selectedRows.length < this.props.min) {
    //        let firstIndexs = [];
    //        for (var i = 0; i < this.props.min; i++) {
    //            if (this.state.selectedIndexs.length > i) {
    //                firstIndexs.push(this.state.selectedIndexs[i]);
    //            } else {
    //                firstIndexs.push(i)
    //            }
    //        }
    //        this.state.gridApi.forEachNode(node => firstIndexs.indexOf(node.rowIndex) !== -1 ? node.setSelected(true) : node.setSelected(false));
    //        this.setState({ selectedIndexs: (this.fillSelectedIndexs(this.state.gridApi.getSelectedNodes())) });
    //        console.log("initialrun", this.state.initialRun)
    //        if (this.state.initialRun === false) {
    //            // alert("Cannot select less then " + this.props.min + " rows. Please select another row before deselecting this one");
    //        } else {
    //            if (this.state.gridApi.getSelectedNodes().length > 0) {
    //                this.setState({ initialRun: false });
    //            }
    //        }
    //        //return;
    //    } else if (this.props.max && selectedRows.length > this.props.max) {
    //        let nodes = this.state.gridApi.getSelectedNodes();
    //        for (var i = 0; i < nodes.length; i++) {
    //            if (this.state.selectedIndexs.indexOf(nodes[i].rowIndex) === -1) {
    //                this.state.gridApi.deselectIndex(nodes[i].rowIndex, true);
    //                // alert("Cannot select more then " + this.props.max + " rows. Please deselect a selection to compare another row.");
    //                return;
    //            }
    //        }
    //    } else if (rowSelectedFunction) { // if click function provided
    //        this.setState({ selectedIndexs: (this.fillSelectedIndexs(this.state.gridApi.getSelectedNodes())) });
    //        if (rowSelectedFunction.length === 0) { //if no args just run
    //            rowSelectedFunction();
    //        } else { //if 1 or more args pass element
    //            rowSelectedFunction(selectedRows);
    //        }
    //    }
    //};
    /*
    onSelectionChanged = () => {
        console.log('onSelectionChanged', this.state.gridApi.getSelectedRows());
        console.log(this.state.gridApi);

        let selectedRows = this.state.gridApi.getSelectedRows();

        if (selectedRows.length > 3) {
            // selectedRows[3]

            // un-select
            // node.setSelected(false);
            return null;
        }
        var rowSelectedFunction = this.props.onRowSelected;
        if (rowSelectedFunction) { // if click function provided
            if (rowSelectedFunction.length === 0) { //if no args just run
                rowSelectedFunction();
            } else { //if 1 or more args pass element
                rowSelectedFunction(this.state.gridApi.getSelectedRows());
            }
        }
    };
    */
    onRowSelected = (event) => {
        var currentSelectedNode = event.node;
        console.log('onSelectionChanged', currentSelectedNode);
        console.log(event);

        let selectedRows = this.state.gridApi.getSelectedRows();

        if (selectedRows.length > 2) {
            // un-select
            currentSelectedNode.setSelected(false);
            return null;
        }
        var rowSelectedFunction = this.props.onRowSelected;
        var pushPinnedDataFunction = this.props.pushPinnedData;
        if (rowSelectedFunction) { // if click function provided
            if (rowSelectedFunction.length === 0) { //if no args just run
                rowSelectedFunction();
            } else { //if 1 or more args pass element

                // we need to add te pinned row to the selected Rows
                let pinnedData = null;
                if (pushPinnedDataFunction)
                    pinnedData = this.state.gridApi.pinnedRowModel.getPinnedTopRowData()[0].data;

                let data: any = [pinnedData, ...this.state.gridApi.getSelectedRows()];
                rowSelectedFunction(data);
            }
        }
    };


    public getPinnedRowData() {
        // console.log('getPinnedRowData');;
        // console.log(this.props.rowData[0]);
        // console.log(this.state.pinnedRowData);
        if (this.state.pinTopRow)
            return [this.props.rowData[0]];
        else return [];
    };

    public getRowData() {
        // console.log('getRowData', this.props.rowData);;
        const rowData = [...this.props.rowData];

        if (this.state.pinTopRow)
            rowData.shift(); // drop the first element because it will be pinned
        // console.log('after the shift', rowData);;
        return rowData as any[];
    };

    render() {

        const valueComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
            //console.log(valueA);
            if (isNullOrUndefined(valueA) || isNullOrUndefined(valueB)) return 0;

            //If the total property exists but is null, treat it as 0
            if (valueA.total === null)
                valueA.total = 0;

            if (valueB.total === null)
                valueB.total = 0;

            //If we have both totals, compare them
            if (!isNullOrUndefined(valueA.total) && !isNullOrUndefined(valueB.total)) {
                if (valueA.total === valueB.total) return 0;
                return (valueA.total > valueB.total) ? 1 : -1;
            }
            else {
                if (valueA == valueB) return 0;
                return (valueA > valueB) ? 1 : -1;
            }
        };

        const fleetNameCompare = (valueA, valueB, nodeA, nodeB, isInverted) => {
            if (isNullOrUndefined(valueA) || isNullOrUndefined(valueB)) return 0;
            if (valueA.name == valueB.name) return 0;
            return (valueA.name > valueB.name) ? 1 : -1;
        };

        const healthComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
            //console.log("Odom",valueA);
            if (isNullOrUndefined(valueA) || isNullOrUndefined(valueB)) return 0;
            if (valueA.health == valueB.health) return 0;
            return (valueA.health > valueB.health) ? 1 : -1;
        };

        const compareStringsAsNums = (valueA, valueB, nodeA, nodeB, isInverted) => {
            if (valueA === 'n/a')
                valueA = 0;

            if (valueB === 'n/a')
                valueB = 0;

            if (isNullOrUndefined(valueA) || isNullOrUndefined(valueB)) return 0;
            if (parseFloat(valueA) === parseFloat(valueB)) return 0;
            return (parseFloat(valueA) > parseFloat(valueB)) ? 1 : -1;
        }

        const defaultColDef = {
            headerCheckboxSelection: this.isFirstColumn,
            checkboxSelection: this.isFirstColumn,
            sortable: true
        };

        const rowStyle = { height: '80px', overflow: 'inherit!important' };
        const rowHeight = 80;

        const showCheckbox = (!isNullOrUndefined(this.props.showCheckboxes) && this.props.showCheckboxes ? this.props.showCheckboxes : false);
        //console.log("Are we showin checkers", showCheckbox)

        const dynamicCellStyle = params => {
            let style = { width: '', height: '', paddingTop: '', paddingLeft: '', display: '', alignItmes: '' };
            switch (params.colDef.field) {
                case 'vehicle':
                    //style.paddingTop = params.node.rowPinned === 'top' ? '1.3rem' : '0rem';
                    style.paddingLeft = params.node.rowPinned === 'top' ? '2.9rem' : null;
                    break;
                case 'dutyCycle':
                    style.paddingTop = params.node.rowPinned === 'top' ? '0.2rem' : '0rem';
                    style.display = 'block';
                    style.width = '100%';
                    break;
                case 'overallFuelEconomyAvg':
                case 'weightImpactAvgMpg':
                    style.display = 'block';
                    break;
                case 'totalConsumption':
                    style.width = '15%';
                    break;
                default:
                    // style.paddingTop = params.node.rowPinned === 'top' ? '1.5rem' : '1rem';
                    break;
            }

            // if (params.colDef.field != 'dutyCycle') {
            //     style.alignItmes = 'center';
            //     style.display = 'flex';
            // }
            return style;
        };

        const sizeColumnsToFit = (params) => {
            params.api.sizeColumnsToFit();
        };

        // if (this.state.initialRun && this.state.gridApi && this.props.min) {
        //     if (this.state.gridApi.getSelectedNodes().length == 0 && this.props.rowData.length > 0) {
        //         this.state.gridApi.selectAll();
        //     }
        // }

        const onFirstDataRendered = (params) => {
            // console.log('onFirstDataRendered');
            // console.log(params);
            // params.api.selectIndex(0, false, false);
            params.api.sizeColumnsToFit();
            // console.log(params.api.pinnedRowModel.getPinnedTopRowData()[0].data);

            if (this.props.pushPinnedData)
                this.props.pushPinnedData([params.api.pinnedRowModel.getPinnedTopRowData()[0].data]);
        };


        return (
            <React.Fragment>
                <div
                    id="myGrid"
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                    className="ag-theme-alpine"
                >
                    <AgGridReact
                        onGridReady={(params) => { this.setState({ gridApi: params.api}, () => this.onGridReady(params)); }}
                        frameworkComponents={{
                            vehicleCellRenderer: VehicleCellRenderer,
                            // fluidLevelCellRenderer: FleetHealthLevelPercentCellRenderer,
                            // fuelTempCellRenderer: FleetHealthTemperatureCellRenderer,
                            // fuelTempWithAverageCellRenderer: FleetHealthTemperatureWithAverageCellRenderer,
                            fleetHealthNumberCellRenderer: FleetHealthNumberCellRenderer

                        }}
                        rowClass="datatable-row"
                        rowSelection={'multiple'}
                        // suppressRowClickSelection={true}
                        // onSelectionChanged={this.onSelectionChanged}
                        onFirstDataRendered={onFirstDataRendered}
                        onGridSizeChanged={sizeColumnsToFit}
                        onSortChanged={sizeColumnsToFit}
                        rowMultiSelectWithClick={true}
                        onRowDataChanged={sizeColumnsToFit}
                        rowHeight={rowHeight}
                        rowStyle={rowStyle}
                        pinnedTopRowData={this.getPinnedRowData()}
                        rowData={this.getRowData()}
                        components={{
                            generalCellRenderer: this.createGeneralCellRenderer(),
                        }}
                        pagination={true}
                        paginationPageSize={8}
                        onRowSelected={this.onRowSelected}
                        defaultColDef={{
                           // headerCheckboxSelection: this.isFirstColumn,
                           checkboxSelection: this.isFirstColumn
                        }}
                    >
                        <AgGridColumn sortable={true} width={300} cellRenderer="vehicleCellRenderer" field="vehicle" checkboxSelection={this.props.showCheckboxes} headerCheckboxSelection={this.props.showCheckboxes && !this.props.max} comparator={fleetNameCompare}/>

                        {this.props.columns.map(col =>
                            <AgGridColumn
                                key={Math.random()}
                                width={col.width}
                                sortable={true}
                                cellRenderer={
                                        col.columnName === "fuelLevel" ||
                                        col.columnName === "defLevel" ||
                                        col.columnName === "dpfHealth" ||
                                        col.columnName === "dpfHealthSoot" ||
                                        col.columnName === "engineOilLevel" ||
                                        col.columnName === "dpfTemp" ||
                                        col.columnName === "oilTemp" ||
                                        col.columnName === "engineCoolantLevel" ||
                                        col.columnName === "engineCoolantTemp" ||
                                        col.columnName === "engCoolantLevel" ||
                                        col.columnName === "engCoolantTemp"
                                        ? "fleetHealthNumberCellRenderer" : "generalCellRenderer"
                                }
                                headerName={col.columnHeader}
                                field={col.columnName}
                                comparator={col.columnName === "defLevel"
                                        ? compareStringsAsNums
                                        : col.columnName === "generalHealth"
                                            ? healthComparator
                                                    : valueComparator
                                }
                                cellStyle={dynamicCellStyle}>
                            </AgGridColumn>)}
                    </AgGridReact>
                </div>
            </React.Fragment>
        )
    }
}
