import * as React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

interface IChartState {
    chartData: any;
    chart: am4charts.PieChart;
    chartContainer: string;
}

interface IChartProps {
    chartData: any;
    chartContainer: string;
    displayType: string;
}

/*
 * https://www.amcharts.com/demos/pie-chart/
 */


export default class PieChart extends React.Component<IChartProps, IChartState> {
    constructor(props: IChartProps) {
        super(props);
        this.state =
        {
            chartData: props.chartData,
            chartContainer: props.chartContainer,
            chart: am4core.create(props.chartContainer, am4charts.PieChart)
        }
    }


    public Create() {
        am4core.useTheme(am4themes_animated);
        am4core.addLicense("CH308822591");
        // configure Series
        let pieSeries = this.state.chart.series.push(new am4charts.PieSeries());
        pieSeries.data = this.state.chartData;
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "name";

        switch (this.props.displayType) {
            case "standardPie":
            default:
                pieSeries.slices.template.stroke = am4core.color("#fff");
                pieSeries.slices.template.strokeOpacity = 1;

                pieSeries.hiddenState.properties.opacity = 1;
                pieSeries.hiddenState.properties.endAngle = -90;
                pieSeries.hiddenState.properties.startAngle = -90;

                this.state.chart.hiddenState.properties.radius = am4core.percent(0);
                break;
            case "standardDoughnut":
                pieSeries.innerRadius = am4core.percent(50);
                pieSeries.ticks.template.disabled = true;
                pieSeries.labels.template.disabled = true;

                let rgm = new am4core.RadialGradientModifier();
                rgm.brightnesses.push(-0.8, -0.8, -0.5, 0, - 0.5);
                pieSeries.slices.template.fillModifier = rgm;
                pieSeries.slices.template.strokeModifier = rgm;
                pieSeries.slices.template.strokeOpacity = 0.4;
                pieSeries.slices.template.strokeWidth = 0;

                this.state.chart.legend = new am4charts.Legend();
                this.state.chart.legend.position = "right";
                break;
        }

    }
}