import * as React from 'react';
import { Row, Col, Spinner, Breadcrumb, BreadcrumbItem, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { ScatterPlotChart, ScatterPlotChartWithHistogram } from '../../Charts';
import { DropDownFilterBar } from '../../DropDownFilterBar'
import { FuelSubnav } from '../SubNav'
import { Link } from 'react-router-dom';
import { DataTable } from '../../DataTable/WithBars'
import { InovexDropDown } from '../../InovexDropDown';
import { OutsideClickHandler } from '../../OutsideClickHandler';

interface IDashboardProps {

}

interface IDashboardState {
    fuelEconByWeightChart: ScatterPlotChart;
    //fuelConsumedByWeightChart: ScatterPlotChart;
    fuelEconByWeightChart2: ScatterPlotChartWithHistogram;
    rowData: any;     // 'any' becauase the structure of the objects in teh array will vary
    topChartData: any;
    rightChartData: any;
    columns: Array<{ columnName: string, columnHeader: string, width: number }>

}

export class FuelByWeight extends React.Component<IDashboardProps, IDashboardState> {
    constructor(props: IDashboardProps) {
        super(props);
        this.state = {
            fuelEconByWeightChart: null,
            //fuelConsumedByWeightChart: null,
            fuelEconByWeightChart2: null,
            rowData: [],
            topChartData: [],
            rightChartData: [],
            columns: []
        }

    }
    componentDidMount() {
        this.fetchData();
    }

    public getFuelDataTableData() {
        fetch('api/Fuel/FuelEconWeightDataTable')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {

                const columns = [
                    // { columnName: 'TotalConsumption' },
                    { columnHeader: 'Mileage (mi)', columnName: 'totalMileage', width: 90 },
                    { columnHeader: 'Fuel Cons. (gal)', columnName: 'totalConsumption', width: 100 },
                    { columnHeader: 'Fuel Economy (mpg)', columnName: 'overallFuelEconomyAvg', width: 200 },
                    { columnHeader: 'Avg. Gross Weight In Hauling (lbs)', columnName: 'weightAvg', width: 150 },
                    { columnHeader: 'Gallons (+/- predicted) from Weight', columnName: 'weightImpactSum', width: 120 },
                    { columnHeader: 'Fuel Efficiency Gain/Loss (mpg)', columnName: 'weightImpactAvgMpg', width: 240 }
                ];

                this.setState(
                    {
                        rowData: data,
                        columns: columns
                    }, this.render);


            })
            .catch((error: Error) => {
                throw error
            });

    }

    public getScatterPlotData() {
        fetch('api/Fuel/FuelEconByWeightScatter/2021-07-01/2021-08-31/1/1')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                this.CreateFuelEconByWeightChart(data);
            })
            .catch((error: Error) => {
                throw error
            });

    }


    async fetchData() {
        this.getFuelDataTableData();
        this.getScatterPlotData();
    }

    public getHistogramData(data: any) {

        let xBinCounts = [];
        let yBinCounts = [];

        type bin = {
            bName: string;
            bVal: number;
        }  

        //Need to determine what the scatterplot will use for the bins
        //Hardcoded FOR loop to match the current data in Fuel Weight chart
        //X Bins => All data points that fall within one grid column of the chart Eg: All points between 15k and 20k
        for (var i = 3; i < 16; i++) {
            let binMin = i * 5000; 
            let binMax = binMin + 5000;
            let binName = (binMin / 1000).toString() + "k - " + (binMax / 1000).toString() + "k";
            let binVal = data.reduce((prev, cur) => cur.x >= binMin && cur.x < binMax ? ++prev : prev, 0);
            let obj: bin = { bName: binName, bVal: binVal };
            xBinCounts.push(obj);
        }

        //Need to determine what the scatterplot will use for the bins
        //Hardcoded FOR loop to match the current data in Fuel Weight chart
        //Y Bins => All data points that fall within on grid ROW of the chart
        for (var i = 3; i < 12; i++) {
            let binName = i.toString() + " - " + (i + 1).toString();
            let binMin = i;
            let binMax = binMin + 1;
            let binVal = data.reduce((prev, cur) => cur.y >= binMin && cur.y < binMax ? ++prev : prev, 0);
            let obj: bin = { bName: binName, bVal: binVal };
            yBinCounts.push(obj);
        }

        return { topChartData: xBinCounts, rightChartData: yBinCounts }
    }


    public CreateFuelEconByWeightChart(data: any) {
        let trendlines = [
            {
                valueX: 'value',
                valueY: 'value2',
                data: [
                    { 'value': 1, 'value2': 2 },
                    { 'value': 12, 'value2': 11 }
                ]
            },
            {
                valueX: 'value',
                valueY: 'value2',
                data: [
                    { 'value': 1, 'value2': 1 },
                    { 'value': 12, 'value2': 19 }
                ]
            }];

        trendlines = []; // not going to use a trendline this time
        //var chart = new ScatterPlotChart({ chartData: data, chartContainer: 'fuelbyweightchartdiv' });
        //chart.Create('Weight (lbs)', 'MPG', 'x', 'y', 'a', trendlines, false);
        //console.log("Weight chart data", data);

        let histoData = this.getHistogramData(data);
        //console.log(histoData);

        var chart = new ScatterPlotChartWithHistogram({
            mainChartData: data,
            mainChartContainer: 'fuelbyweightchartdiv',
            topChartContainer: 'scattertop',
            topChartData: histoData.topChartData,
            rightChartContainer: 'scatterright',
            rightChartData: histoData.rightChartData
        });
        chart.Create();
    }
    /*
    public populateFuelConsumedByWeight(data: any) {
        this.setState(
            {
                fuelConsumedByWeightChart: new ScatterPlotChart({ chartData: data, chartContainer: 'fuelconsumedbyweightchartdiv' }),
            }, this.CreateFuelConsumedByWeightChart);
    }


    async CreateFuelEconByWeightChart(data: any) {
        // if trendlines are necessary
        // sample data
        let trendlines = [
            {
                valueX: 'value',
                valueY: 'value2',
                data: [
                    { 'value': 1, 'value2': 2 },
                    { 'value': 12, 'value2': 11 }
                ]
            },
            {
                valueX: 'value',
                valueY: 'value2',
                data: [
                    { 'value': 1, 'value2': 1 },
                    { 'value': 12, 'value2': 19 }
                ]
            }];

        trendlines = []; // not going to use a trendline this time

        let fuelEconByWeightChart = new ScatterPlotChart({ chartData: data, chartContainer: 'fuelbyweightchartdiv' });
        fuelEconByWeightChart.Create('Weight (lbs)', 'MPG', 'x', 'y', 'a', trendlines, false);
    }


        let rightHandData = [
            {
                'category': '1',
                'value': 3
            },
            {
                'category': '1.5',
                'value': 0
            },
            {
                'category': '2',
                'value': 6
            },
            {
                'category': '2.5',
                'value': 0
            },
            {
                'category': '3',
                'value': 5
            },
            {
                'category': '3.5',
                'value': 0
            },
            {
                'category': '4',
                'value': 2
            },
            {
                'category': '4.5',
                'value': 0
            },
            {
                'category': '5',
                'value': 2
            },
        ];

        let topData = [
            {
                'category': '1',
                'value': 1
            },
            {
                'category': '2',
                'value': 2
            },
            {
                'category': '3',
                'value': 3
            },
            {
                'category': '4',
                'value': 4
            },
            {
                'category': '5',
                'value': 3
            },
            {
                'category': '6',
                'value': 2
            },
            {
                'category': '7',
                'value': 1
            },
            {
                'category': '8',
                'value': 1
            },
            {
                'category': '9',
                'value': 1
            },
            {
                'category': '10',
                'value': 1
            },
        ];
        */


    render() {
        /*
         * Please do not remove this. This is an example of teh scatter plot with the histograms on the side (like the design)
                <Row>
                    <Col className="tile">
                        <h4>Fuel Econ By Weight</h4>
                        <div style={{position: "relative", width: "100%", height:"700px" }} >
                            <div id="scattertop" style={{ width: "675px", height: "200px", position:"absolute", top: "0px", left: "98px" }} ></div>
                            <div id="scattermain" style={{ width: "800px", height: "500px", position: "absolute", top: "171px", left: "0px" }} ></div>
                            <div id="scatterright" style={{ width: "200px", height: "394px", position: "absolute", top: "192px", left: "770px" }} ></div>
                        </div>
                    </Col>
                </Row>

*/
        let datatable;
        if (this.state.rowData && this.state.columns)
            datatable = <DataTable rowData={this.state.rowData} columns={this.state.columns} />

        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <nav className="dashboardTitle" aria-label="breadcrumb">
                            <div className="breadcrumb">
                                <Link className="breadcrumb-item" to="/health-general" >FUEL</Link>
                                <Link className="breadcrumb-item" to="/health-general" >Fuel Efficiency</Link>
                                <a className="breadcrumb-item" >Fuel Efficiency By Weight</a>
                            </div>
                        </nav>
                    </Col>
                </Row>
                <DropDownFilterBar />
                <FuelSubnav pageTitle="Fuel Economy By Weight" />
                <Row>
                    <Col>
                        <Card className="col-5of5 card-secondary">
                            <CardBody>
                                <CardTitle tag="h5">Fuel Economy By Weight</CardTitle>
                                <CardText tag="div">
                                    <div style={{ position: "relative", width: "100%", height: "700px" }} >
                                        {/*<div id="scattertop" style={{ width: "840px", height: "200px", position: "absolute", top: "0px", left: "158px", zIndex: 2 }} ></div>*/}
                                        {/*<div id="fuelbyweightchartdiv" style={{ width: "989px", height: "500px", position: "absolute", top: "171px", left: "10px" }} ></div>*/}
                                        {/*<div id="scatterright" style={{ width: "200px", height: "455px", position: "absolute", top: "151px", left: "969px", zIndex: 2 }} ></div>*/}
                                        <div id="scattertop" className="scattertop"></div>
                                        <div id="fuelbyweightchartdiv" className="fuelbyweightchartdiv"></div>
                                        <div id="scatterright" className="scatterright"></div>
                                    </div>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="pb-0 mb-0">
                    <Col xs="2" className="tile p-0 mb-0">
                        <div className="text-white pb-0 mb-0 pl-2" style={{ height: '30px', width: '275px', backgroundColor: "#4e7c88" }}>
                            <div className="mt-0 pt-0 pb-0" style={{ float: "left", width: "33%", lineHeight: "30px" }}>
                                GROUP BY
                            </div>
                            <div className="mt-1 mb-1" style={{ display: "inline-block", width: "1px", backgroundColor: "white", height: "70%", float: "left" }} />
                            <div className="mt-0 pt-0" style={{ float: "left", width: "66%", height: "100%" }}>
                                <InovexDropDown dropDownId="dropDownGroupBy"
                                    dropDownToggleId="dropdownFleetHealthGroupByToggleID"
                                    initialValue="VIN"
                                    //onValueSelected={(trendValue: any) => this.changeTrendValue(trendValue)}
                                    dropDownStyle={{ width: "100%" }}
                                    omitSelected={false} >
                                    <span>VIN</span>
                                    <span>Company</span>
                                    
                                </InovexDropDown>

                            </div>

                        </div>

                    </Col>


                </Row>
                <Row className="pt-0 mt-0">
                    <Col className='tile pr-0 pb-0 pt-0'>

                        {datatable}
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}
