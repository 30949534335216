import * as React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_bullets from "@amcharts/amcharts4/plugins/bullets";
/*import * as am4tooltip from "@amcharts/amcharts4/.internal/core/elements/Tooltip";*/
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

//interface IChartState {
//    chartData: Array<{ dateTimeUtc: Date; value: number; }>;
//    chart: am4charts.XYChart;
//    chartContainer: string;
//    orientation: am4core.PointerOrientation;
//    useCursor: boolean;
//    hideValueAxisLabels: boolean;
//    columnColour: string;
//    roundedTopCorners: boolean;
//    showGrid: boolean;
//    columnWidth: number;
//}

//interface IChartProps {
//    chartData: Array<{ dateTimeUtc: Date; value: number; }>;
//    chartContainer: string;
//    orientation: am4core.PointerOrientation;
//    useCursor: boolean;
//    hideValueAxisLabels: boolean;
//    columnColour: string;
//    roundedTopCorners: boolean;
//    showGrid: boolean;
//    columnWidth: number;
//}

interface IChartState {
    data?: Array<any>;
    series?: Array<IChartSeries>;
    range?: Array<IChartRange>;
    format?: IChartFormat;
    chart: am4charts.XYChart;
}

interface IChartProps {
    chartContainer: string;
}

interface IChartSeries {
    xAxis: string;
    yAxis: string;
    name: string;
    color: string,
    flags?: Array<IChartFormatFlag>
}

interface IChartRange {
    value: number;
    endValue: number;
    colour: string
}

interface IChartFormatFlag {
    text?: string,
    axis?: string,
    value?: any,
    fontSize?: number,
    horizontalCenter?: string,
    pole?: {
        color?: string,
        strokeWidth?: number,
        height?: number
    },
    background?: {
        waveLength?: number,
        color?: string,
        strokeWidth?: number,
        fillOpacity?: number
    }
}

interface IChartFormat {
    chart?: {
        height?: number,
        width?: number,
    };
    xAxis?: {
        axisType?: string,
        min?: number,
        max?: number
    };
    yAxis?: {
        axisType?: string,
        min?: number,
        max?: number
    };
    useCursor?: boolean;
    xAxisTitle?: {
        text?: string,
        fontSize?: any,
        fontWeight: number
    };
    yAxisTitle?: {
        text?: string,
        fontSize?: any,
        fontWeight: number
    };
    numberFormat?: string;
    inputDateFormat?: string;
    series?: {
        orientation?: am4core.PointerOrientation,
        roundedTopCorners?: boolean
    },
    legend?: {
        position?: string,
    },
    labels?: {
        hideValueAxisLabels?: boolean,
        fontSize: number
    }
}

export default class ColumnChart2 extends React.Component<IChartProps, IChartState> {
    constructor(props: IChartProps) {
        super(props);

        let chart = am4core.create(props.chartContainer, am4charts.XYChart);


        this.state =
        {
            chart: chart
        }

        //this.state =
        //{
        //    useCursor: true,
        //    hideValueAxisLabels: props.hideValueAxisLabels,
        //    orientation: props.orientation,
        //    chartData: props.chartData,
        //    chartContainer: props.chartContainer,
        //    chart: am4core.create(props.chartContainer, am4charts.XYChart),
        //    columnColour: props.columnColour,
        //    roundedTopCorners: props.roundedTopCorners,
        //    showGrid: props.showGrid,
        //    columnWidth: props.columnWidth,
        //}
    }


    public Create(data: Array<any>, seriesx: IChartSeries | Array<IChartSeries>, format?: IChartFormat, ranges?: Array<IChartRange>) {
        console.log("Create ColumnChart2");

        am4core.useTheme(am4themes_animated);
        am4core.addLicense("CH308822591");
        if (seriesx == null) throw new Error("Chart2 missing parameter 'series'");
        if (data == null) throw new Error("Chart2 missing parameter 'data'");


        //alert("CAN'T SET STATE LIKE THIS");
        // CAN'T SET STATE LIKE THIS
        /*
        this.state.data = data;
        this.state.format = format;

        if ("field" in seriesx) {
            let ss = new Array<IChartSeries>(seriesx);
            this.state.series = ss;
        } else if (Array.isArray(seriesx)) {
            this.state.series = seriesx;
        }
        */


        let chartXAxis, chartYAxis, strChartXAxis = "date", strChartYAxis = "value";

        if (format) {
            if (format.xAxis) {
                if (format.xAxis.axisType)
                    strChartXAxis = format.xAxis.axisType;
            }
            if (format.yAxis) {
                if (format.yAxis.axisType)
                    strChartYAxis = format.yAxis.axisType;
            }

        }

        switch (strChartXAxis) {
            case "value":
                chartXAxis = this.state.chart.xAxes.push(new am4charts.ValueAxis());
                if (format && format.xAxis) {
                    if (format.xAxis.min)
                        chartXAxis.min = format.xAxis.min;
                    if (format.xAxis.max)
                        chartXAxis.max = format.xAxis.max;
                }
                break;
            case "category":
                chartXAxis = this.state.chart.xAxes.push(new am4charts.CategoryAxis());
                break;
            default:
                chartXAxis = this.state.chart.xAxes.push(new am4charts.DateAxis());
                if (format && format.xAxis) {
                    if (format.xAxis.min)
                        chartXAxis.min = format.xAxis.min;
                    if (format.xAxis.max)
                        chartXAxis.max = format.xAxis.max;
                }
                break;
        }

        switch (strChartYAxis) {
            case "date":
                chartYAxis = this.state.chart.yAxes.push(new am4charts.DateAxis());
                if (format && format.yAxis) {
                    if (format.yAxis.min)
                        chartYAxis.min = format.yAxis.min;
                    if (format.yAxis.max)
                        chartYAxis.max = format.yAxis.max;
                }
                break;
            case "category":
                chartYAxis = this.state.chart.yAxes.push(new am4charts.CategoryAxis());
                break;
            default:
                chartYAxis = this.state.chart.yAxes.push(new am4charts.ValueAxis());
                if (format && format.yAxis) {
                    if (format.yAxis.min)
                        chartYAxis.min = format.yAxis.min;
                    if (format.yAxis.max)
                        chartYAxis.max = format.yAxis.max;
                }
                break;
        }
        if (format) {
            if (format.chart) {
                if (format.chart.height) {
                    this.state.chart.height = format.chart.height;
                    this.state.chart.svgContainer.htmlElement.style.height = "" + format.chart.height + "px";
                }
                if (format.chart.width)
                    this.state.chart.width = format.chart.width;
            }
            
            if (format.xAxisTitle) {
                if (format.xAxisTitle.text)
                    chartXAxis.title.text = format.xAxisTitle.text;
                if (format.xAxisTitle.text)
                    chartXAxis.title.fontSize = format.xAxisTitle.fontSize;
            }

            if (format.yAxisTitle) {
                if (format.yAxisTitle.text)
                    chartYAxis.title.text = format.yAxisTitle.text
                if (format.yAxisTitle.text)
                    chartYAxis.fontSize = format.yAxisTitle.fontSize;
            }
            
            if (format.useCursor) {
                // Make a panning cursor
                this.state.chart.cursor = new am4charts.XYCursor();
                this.state.chart.cursor.behavior = "panXY";
                this.state.chart.cursor.yAxis = chartYAxis;
            }

            if (format.legend) {
                this.state.chart.legend = new am4charts.Legend();
                if (format.legend)
                    this.state.chart.legend.position = format.legend.position as am4charts.LegendPosition;
            }
            if (format.labels) {

                if (format.labels.hideValueAxisLabels) {
                    //valueAxis.renderer.grid.template.disabled = true;
                    chartYAxis.renderer.baseGrid.disabled = true;
                    chartYAxis.renderer.labels.template.disabled = true;
                    chartYAxis.cursorTooltipEnabled = false;
                }
                if (format.labels.fontSize)
                    chartXAxis.renderer.labels.template.fontSize = format.labels.fontSize;
            }
        }

        //defaults
        chartYAxis.renderer.labels.template.fontSize = 9;
        chartYAxis.renderer.labels.template.fontSize = 9;

        this.state.chart.data = this.state.data;


        let first = true;
        for (let k in this.state.series) {
            let ser = this.state.series[k];
            if (first) {

                /*
                TypeScript error in C: /Dev/Aptiv / Aptiv Data Viz TSX / AptivDataViz / AptivDataViz.UI / ClientApp / src / components / Charts / ColumnChart2 / index.tsx(292, 43):
                Property 'category' does not exist on type 'IValueAxisDataFields | ICategoryAxisDataFields | IDateAxisDataFields'.
                    Property 'category' does not exist on type 'IValueAxisDataFields'.TS2339

// TODO: fix this in a way that the typescript compiler doesn't break
                if (strChartXAxis == "category")
                    chartXAxis.dataFields.category = ser.xAxis;   // changed category to categoryX > from chartXAxis.dataFields.category to chartXAxis.dataFields.categoryX
*/


                first = false;
            }
            let chartSeries = this.state.chart.series.push(new am4charts.ColumnSeries());

            //this.state.chart.cursor.snapToSeries = chartSeries;

            //Defaults
            /*chartSeries.tooltip.background.cornerRadius = 20;
            chartSeries.tooltip.background.strokeOpacity = 0;
            chartSeries.tooltip.label.minWidth = 40;
            chartSeries.tooltip.label.minHeight = 40;
            chartSeries.tooltip.label.textAlign = "middle";
            chartSeries.tooltip.label.textValign = "middle";*/

            chartSeries.name = ser.name;
            let orientation = '';

            if (format) {
                if (format.series) {
                    if (format.series.orientation)
                        orientation = format.series.orientation;

                    if (format.series.roundedTopCorners) {
                        chartSeries.columns.template.column.cornerRadiusTopRight = 5;
                        chartSeries.columns.template.column.cornerRadiusTopLeft = 5;
                    }
                }
            }

            switch (strChartXAxis) {
                case "value":
                    chartSeries.dataFields.valueX = ser.xAxis; //(orientation == 'horizontal' ? ser.yAxis : ser.xAxis);
                    break;
                case "category":
                    chartSeries.dataFields.categoryX = ser.xAxis; //(orientation == 'horizontal' ? ser.yAxis : ser.xAxis);
                    break;
                default:
                    chartSeries.dataFields.dateX = ser.xAxis; //(orientation == 'horizontal' ? ser.yAxis : ser.xAxis);
                    break;
            }
            switch (strChartYAxis) {
                case "value":
                    chartSeries.dataFields.valueY = ser.yAxis; //(orientation == 'horizontal' ? ser.xAxis : ser.yAxis);
                    break;
                case "category":
                    chartSeries.dataFields.categoryY = ser.yAxis; //(orientation == 'horizontal' ? ser.xAxis : ser.yAxis);
                    break;
                default:
                    chartSeries.dataFields.dateY = ser.yAxis; //(orientation == 'horizontal' ? ser.xAxis : ser.yAxis);
                    break;
            }

            chartSeries.fill = am4core.color(ser.color);
            chartSeries.stroke = am4core.color(ser.color);

            if (ser.flags && ser.flags.length > 0) {
                /*
                TypeScript error in C: /Dev/Aptiv / Aptiv Data Viz TSX / AptivDataViz / AptivDataViz.UI / ClientApp / src / components / Charts / ColumnChart2 / index.tsx(360, 35):
                Property 'axis' does not exist on type 'IChartFormatFlag[]'.TS2339
                let flagAxis;
                switch (ser.flags.axis) {
                    case "Y":
                    case "Y":
                        flagAxis = chartYAxis; //this.state.chart.yAxes.push(new am4charts.ValueAxis());
                        break;
                    default:
                        flagAxis = chartXAxis; //this.state.chart.xAxes.push(new am4charts.ValueAxis());
                        break;
                }
                for (let k in ser.flags) {
                    let flag = ser.flags[k];
                    this.createFlag(flag, flagAxis)
                }
                */
            }
        }
        //this.state.chart.data = this.state.data;
    }
    
    public createFlag(flagInfo: IChartFormatFlag, axis: any) {
        let flag = new am4plugins_bullets.FlagBullet();

        flag.label.text = flagInfo.text || "";
        //flag.label.horizontalCenter = flagInfo.horizontalCenter || "middle";
        flag.label.fontSize = flagInfo.fontSize || 20;
        if (flagInfo.pole) {
            if (flagInfo.pole.color)
                flag.pole.stroke = am4core.color(flagInfo.pole.color);
            if (flagInfo.pole.strokeWidth)
                flag.pole.strokeWidth = flagInfo.pole.strokeWidth;
            if (flagInfo.pole.height)
                flag.poleHeight = flagInfo.pole.height;
        }
        if (flagInfo.background) {
            flag.background.waveLength = flagInfo.background.waveLength || 0;
            if (flagInfo.background.color) {
                flag.background.fill = am4core.color(flagInfo.background.color);
                flag.background.stroke = am4core.color(flagInfo.background.color);
            }
            if (flagInfo.background.strokeWidth)
                flag.background.strokeWidth = flagInfo.background.strokeWidth;
            if (flagInfo.background.fillOpacity)
                flag.background.fillOpacity = flagInfo.background.fillOpacity;
        }

        let event = axis.axisRanges.create();

        console.log("createFlag", event);
        event.value = flagInfo.value;
        /*event.date = flagInfo.value;
        event.category = flagInfo.value;*/
        event.bullet = flag;
        event.grid.strokeWidth = 0;

    }

    public ReDraw(newdata: Array<{ dateTimeUtc: Date; value: number; }>) {
        this.state.chart.data = newdata;
    }

}
