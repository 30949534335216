import * as React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { isNullOrUndefined } from 'util';

interface IChartState {
    chartData: Array<{ name: string; metricName: string, value: number }>; //Array<{ category: string; positiveValue: number; negativeValue: number }>;
    chart: am4charts.XYChart;
    chartContainer: string;
    showLegend: boolean;
    orientation: am4core.PointerOrientation;
    colorPositiveValues: string;
    colorNegativeValues: string;
    hideValueAxisLabels: boolean;
    hideCategoryAxisLabels: boolean;
    hideGridLines: boolean;
    useCursor: boolean;
    title: string;
}

interface IChartProps {
    title: string;
    chartData: Array<{ name: string; metricName: string, value: number }>; //Array<{ category: string; positiveValue: number; negativeValue: number}>;
    chartContainer: string;
    showLegend: boolean;
    orientation: am4core.PointerOrientation;
    colorPositiveValues: string;
    colorNegativeValues: string;
    hideValueAxisLabels: boolean;
    hideCategoryAxisLabels: boolean;
    hideGridLines: boolean;
    useCursor: boolean;
    valueAxisTitle?: string;
    showSavedWasted?: boolean;
}

export default class DivergentStackBarChart extends React.Component<IChartProps, IChartState> {
    constructor(props: IChartProps) {
        super(props);
        this.state =
        {
            useCursor: props.useCursor,
            orientation: props.orientation,
            chartData: props.chartData,
            chartContainer: props.chartContainer,
            chart: am4core.create(props.chartContainer, am4charts.XYChart),
            showLegend: props.showLegend,
            colorPositiveValues: props.colorPositiveValues,
            colorNegativeValues: props.colorNegativeValues,
            hideValueAxisLabels: props.hideValueAxisLabels,
            hideCategoryAxisLabels: props.hideCategoryAxisLabels,
            hideGridLines: props.hideGridLines,
            title: props.title,
        }
    }

    public Create() {
        am4core.useTheme(am4themes_animated);
        am4core.addLicense("CH308822591");
        let chart = this.state.chart;

        // create a title and populate from props
        let title = chart.titles.push(new am4core.Label());
        title.text = this.props.title;
        title.fontWeight = "bold";
        title.fontSize = 16;
        title.marginBottom = 15;
        title.dx = 45;

        // get the minimum value
        let minimumValue = Math.min.apply(Math, this.state.chartData.map(function (o) { return o.value; }));
        let maximumValue = Math.max.apply(Math, this.state.chartData.map(function (o) { return o.value; }));

        //convert the data that was submitted as 'name|value' to 'category|postiveValue|negativeValue' to fit the divergentstackbar format
        let massagedData = this.state.chartData.map((data) => {
            return {
                category: data.name,
                url: data.metricName,
                positive: data.value > 0 ? data.value: null, //Rendering 0s on the chart that are hidden, but seen with mouseover
                negative: data.value <= 0 ? data.value: null
                
            }
        })
        //console.log(this.state.chartData);
        //console.log(massagedData);

        // set the data of the chart
        chart.data = massagedData;

        // Create category axes
        let categoryAxis = this.state.orientation === "horizontal" ? chart.xAxes.push(new am4charts.CategoryAxis()) : chart.yAxes.push(new am4charts.CategoryAxis());
        
        // let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.minGridDistance = 20;
        categoryAxis.renderer.axisFills.template.disabled = false;
        categoryAxis.renderer.axisFills.template.fillOpacity = 0.05;
        categoryAxis.renderer.labels.template.fontSize = 10;

        // Create value axes
        let valueAxis = this.state.orientation === "horizontal" ? chart.yAxes.push(new am4charts.ValueAxis()) : chart.xAxes.push(new am4charts.ValueAxis());
        //let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = minimumValue;
        valueAxis.max = maximumValue;

        valueAxis.renderer.minGridDistance = 25;
        valueAxis.renderer.ticks.template.length = 5;
        valueAxis.renderer.ticks.template.disabled = false;
        valueAxis.renderer.ticks.template.strokeOpacity = 0.4;
        valueAxis.renderer.labels.template.fontSize = 10;
        valueAxis.renderer.labels.template.adapter.add("text", function (text) {
            return text;
        })
        //console.log("hide value axis label" + this.state.hideValueAxisLabels);

        // show/hide the grid lines (and ticks)
        categoryAxis.renderer.grid.template.visible = !this.state.hideGridLines;
        valueAxis.renderer.grid.template.visible = !this.state.hideGridLines;
        valueAxis.renderer.ticks.template.visible = !this.state.hideGridLines;
        // show/hide the value axis labels
        valueAxis.renderer.labels.template.visible = !this.state.hideValueAxisLabels;

        if (this.props.valueAxisTitle) {
            valueAxis.title.text = this.props.valueAxisTitle;
            valueAxis.title.dx = -15; //Done quick but wont be very kind to responsiveness
        }

        //todo in future: add extra configurability for position, setting the text, etc
        if (!isNullOrUndefined(this.props.showSavedWasted) && this.props.showSavedWasted) {
            let topContainer = valueAxis.createChild(am4core.Container);
            topContainer.layout = "absolute";
            topContainer.toBack();
            //topContainer.paddingBottom = 15;
            topContainer.width = am4core.percent(100);

            let saved = topContainer.createChild(am4core.Label);
            saved.text = "Saved";
            saved.fontSize = 10;
            saved.align = "left";

            let wasted = topContainer.createChild(am4core.Label);
            wasted.text = "Wasted";
            wasted.fontSize = 10;
            wasted.align = "right";
        }

        // show/hide the category axis labels
        categoryAxis.renderer.labels.template.visible = !this.state.hideCategoryAxisLabels;
        categoryAxis.renderer.labels.template.propertyFields.url = "url";
        categoryAxis.renderer.labels.template.textDecoration = "underline";

        // Legend
        if (this.props.showLegend) {
            chart.legend = new am4charts.Legend();
            chart.legend.position = "right";
            chart.legend.setVisibility(this.props.showLegend);
        }
        
        // Use numbers with the - if negative
        chart.numberFormatter.numberFormat = "#,###.#";

        // Create series function
        function createSeries(field: string, name: string, color: am4core.Color, orientation: am4core.PointerOrientation) {

            let series = chart.series.push(new am4charts.ColumnSeries());
            let label = series.bullets.push(new am4charts.LabelBullet);

            if (orientation === "horizontal") {
                series.dataFields.valueY = field;      // valueY
                series.dataFields.categoryX = "category"; // dateX
                series.stacked = true;
                series.name = name;
                series.stroke = color;
                series.fill = color;
                
                label.label.text = "{valueX}";
                label.label.fill = am4core.color("#fff");
                label.label.strokeWidth = 0;
                label.label.truncate = false;
                label.label.hideOversized = true;
                label.locationX = 0.5;
                

            } else {
                series.dataFields.valueX = field;      // valueY
                series.dataFields.categoryY = "category"; // dateX
                series.stacked = true;
                series.name = name;
                series.stroke = color;
                series.fill = color;

                label.label.text = "{valueX}";
                label.label.fill = am4core.color("#fff");
                label.label.strokeWidth = 0;
                label.label.truncate = false;
                label.label.hideOversized = true;
                label.locationX = 0.5;
            }

            //series.columns.template.url = "https://www.google.com/"; //"{category.urlEncode()}";
            //series.columns.template.urlTarget = "_self";
            //label.label.html = '<a href="https://www.google.com/">{valueX}</a>'
            //label.label.interactionsEnabled = true;
            //label.propertyFields.url = "url";

            return series;
        }

        // set the colours for the negative and positive datasets
        let negativeColour = am4core.color(this.state.colorNegativeValues);
        let positiveColour = am4core.color(this.state.colorPositiveValues);

        // create the series for positive and negative values
        let negativeSeries = createSeries("negative", "Negative Factors", negativeColour, this.state.orientation);
        let positiveSeries = createSeries("positive", "Positive Factors", positiveColour, this.state.orientation);

        // set the cursors
        if (this.state.useCursor) {
            // Make a panning cursor
            this.state.chart.cursor = new am4charts.XYCursor();
            this.state.chart.cursor.behavior = "panXY";
            this.state.chart.cursor.xAxis = categoryAxis;
            this.state.chart.cursor.snapToSeries = [negativeSeries, positiveSeries];
        }

       

    }

    public ReDraw(newdata: Array<{ dateTimeUtc: Date; value: number; }>) {
        this.state.chart.data = newdata;
    }

}
