import * as React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

interface IChartState {
    data?: Array<any>;
    series?: Array<IChartSeries>;
    format?: IChartFormat;
    range?: Array<IChartRange>;
    chart: am4charts.XYChart;
    useCursor: boolean;
}

interface IChartProps {
    chartContainer: string;
    useCursor: boolean;
}

interface IChartSeries {
    xAxis: string;
    yAxis: string;
    name: string;
    color: string
}

interface IChartRange {
    value: number;
    endValue: number;
    colour: string
}

interface IChartFormat {
    numberFormat?: string;
    inputDateFormat?: string;
    min?: number;
    max?: number;
    series?: {
        tooltipText?: string,
        strokeWidth?: number,
        minBulletDistance?: number,
        tensionX?: number
    },
    legend?: {
        position?: string
    }
}


function CreateBulletLabels(series: am4charts.LineSeries) {
    // have the value show as a label on the first and last data points
    let labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{value}";
    labelBullet.disabled = true;
    labelBullet.propertyFields.disabled = "disabled";
    labelBullet.label.dy = -20;
    labelBullet.label.fontSize = "12";

}

export default class LineChart2 extends React.Component<IChartProps, IChartState> {

    constructor(props: IChartProps) {
        super(props);


        let chart = am4core.create(props.chartContainer, am4charts.XYChart);

        this.state =
        {
            useCursor: props.useCursor,
            data: null,
            series: null,
            format: null,
            range: null,
            chart: chart
        }
    }

    public Create(valueAxisTitle: string, data: Array<any>, seriesx: IChartSeries | Array<IChartSeries>, format?: IChartFormat, ranges?: Array<IChartRange>)
    {
        am4core.useTheme(am4themes_animated);
        const licence = "CH308822591";
        console.log(licence);
        am4core.addLicense(licence);

        if (seriesx == null) throw new Error("Chart2 missing parameter 'series'");
        if (data == null) throw new Error("Chart2 missing parameter 'data'");

        // this.state.chart.marginBottom = 0;
        // this.state.chart.marginLeft = 0;
        this.state.chart.width = new am4core.Percent(100);
        this.state.chart.x = 0;
        // this.state.chart.height = new am4core.Percent(100);

        // Create axes
        let dateAxis = this.state.chart.xAxes.push(new am4charts.DateAxis());
        let valueAxis = this.state.chart.yAxes.push(new am4charts.ValueAxis());

        /*if (min !== null)
            valueAxis.min = min;
        if (max !== null)
            valueAxis.max = max;*/
        if (valueAxisTitle !== '')
            valueAxis.title.text = valueAxisTitle;
        valueAxis.maxWidth = 25
        valueAxis.marginTop = 0
        valueAxis.marginBottom = 50
        valueAxis.marginLeft = -10
        valueAxis.marginRight = 0
        // valueAxis.visible = false;
        valueAxis.x = 0;
        // valueAxis.stroke = am4core.color("red");


        dateAxis.dateFormatter = new am4core.DateFormatter();
        dateAxis.dateFormatter.dateFormat = "MMM";

        dateAxis.marginBottom = 60
        dateAxis.renderer.minGridDistance = 50;


        dateAxis.renderer.labels.template.disabled = true;
        dateAxis.renderer.labels.template.fontSize = 12;
        
        var minRange = dateAxis.axisRanges.create();
        minRange.maxPosition = 1;
        minRange.minPosition = 0;
        minRange.label.horizontalCenter = "left"
        minRange.label.paddingLeft = 0;
        
        var maxRange = dateAxis.axisRanges.create();
        // this overrides minLabelPosition/maxLabelPosition so that the range labels would be visible
        maxRange.maxPosition = 1;
        maxRange.minPosition = 0;
        maxRange.label.horizontalCenter = "right";
        maxRange.label.paddingRight = 0;
        
        dateAxis.events.on("startendchanged", updateRangeLabels);
        dateAxis.events.on("extremeschanged", updateRangeLabels);
        
        function updateRangeLabels() {
            minRange.value = dateAxis.min + dateAxis.start * (dateAxis.max - dateAxis.min);
            minRange.label.text = dateAxis.dateFormatter.format(minRange.value, "MMM");
        
            maxRange.value = dateAxis.min + dateAxis.end * (dateAxis.max - dateAxis.min);
            maxRange.label.text = dateAxis.dateFormatter.format(maxRange.value, "MMM");
        }


        if (format) {
            if (format.legend) {
                this.state.chart.legend = new am4charts.Legend();
                if (format.legend.position)
                    this.state.chart.legend.position = format.legend.position as am4charts.LegendPosition;
            }
        }

        this.state.chart.legend = new am4charts.Legend();
        // console.log('legend', this.state.chart.legend);
        let legendContainer = am4core.create('chartLegendDiv', am4core.Container);
        legendContainer.marginLeft = 2;
        legendContainer.marginRight = 2;

        legendContainer.width = am4core.percent(100);
        legendContainer.height = 25;
        this.state.chart.legend.parent = legendContainer;


        for (let k in seriesx) {
            let ser = seriesx[k];

            let chartSeries = this.state.chart.series.push(new am4charts.LineSeries());
            chartSeries.legendSettings.labelText = "[font-size:11px]{name}";

            chartSeries.name = ser.name;
            chartSeries.dataFields.valueY = ser.yAxis;
            chartSeries.dataFields.dateX = ser.xAxis;
            chartSeries.tooltipText = "[bold]{name}[/]\n[font-size:11px]{valueY}";
            chartSeries.tooltip.getFillFromObject = false;
            chartSeries.tooltip.background.fill = am4core.color(ser.color);
            chartSeries.strokeWidth = 2;
            chartSeries.stroke = am4core.color(ser.color);
            //chartSeries.minBulletDistance = 15;

            if (format && format.series) {
                // console.log(format.series);

                if (format.series.tensionX)
                    chartSeries.tensionX = format.series.tensionX;
                if (format.series.tooltipText)
                    chartSeries.tooltipText = format.series.tooltipText;
                if (format.series.strokeWidth)
                    chartSeries.strokeWidth = format.series.strokeWidth;
                if (format.series.minBulletDistance)
                    chartSeries.minBulletDistance = format.series.minBulletDistance;
                if (format.series.tensionX)
                    chartSeries.tensionX = format.series.tensionX;
            }

            let seriesData = [];

            for (let kk in data) {
                let dataItem = data[kk];
                let dataRow = {};
                dataRow[ser.xAxis] = dataItem[ser.xAxis];
                dataRow[ser.yAxis] = dataItem[ser.yAxis];
                seriesData.push(dataRow);
            }

            chartSeries.data = seriesData;

            let bullet = chartSeries.bullets.push(new am4charts.CircleBullet());
            bullet.circle.opacity = 1;
            bullet.circle.fill = am4core.color(ser.color);
            bullet.circle.propertyFields.opacity = "opacity";
            bullet.circle.radius = 3;
            CreateBulletLabels(chartSeries);
        }

        // Set input format for the dates
        this.state.chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
        if (format != null) {
            if (format.numberFormat != null) this.state.chart.numberFormatter.numberFormat = '#' + format.numberFormat;
        }

        // Color set
        let colors = new am4core.ColorSet();
        let color = colors.getIndex(0);

        //this.state.chart.data = this.state.data;
        


        // console.log(this.state.chartData);


        /*// Create series
        let series = this.state.chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "value";
        series.dataFields.dateX = "dateTimeUtc";
        series.tooltipText = "[bold]{name}[/]\n[font-size:14px]{valueY}";
        series.strokeWidth = 2;
        series.minBulletDistance = 15;
        series.tensionX = 0.8;

        // render data points as bullets
        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.opacity = 1;
        bullet.circle.fill = color;
        bullet.circle.propertyFields.opacity = "opacity";
        bullet.circle.radius = 3;
        
        CreateBulletLabels(series);

       
        let label = this.state.chart.chartContainer.createChild(am4core.Label);
        label.text = labelValue;
        label.align = "center";

        // Drop-shaped tooltips
        series.tooltip.background.cornerRadius = 20;
        series.tooltip.background.strokeOpacity = 0;
        series.tooltip.pointerOrientation = "vertical";
        series.tooltip.label.minWidth = 40;
        series.tooltip.label.minHeight = 40;
        series.tooltip.label.textAlign = "middle";
        series.tooltip.label.textValign = "middle";*/

        // TODO: we need to find a way to make these dynamic and dependant on the metric. Nice and easy :D

        // Create ranges
        if (ranges !== null) {
            for (let key in ranges) {
                let range = valueAxis.axisRanges.create();
                range.value = ranges[key].value;
                range.endValue = ranges[key].endValue;
                range.axisFill.fill = am4core.color(ranges[key].colour);
                range.axisFill.fillOpacity = 0.15;
            }
        }


        
        //cursor also enables tooltip
        if (this.state.useCursor) {
            // Make a panning cursor
            this.state.chart.cursor = new am4charts.XYCursor();
            this.state.chart.cursor.behavior = "zoomX";
        }

    }


    
    public ReDraw(labelValue: string, valueAxisTitle: string, data: Array<any>, ranges: Array<IChartRange>, min: number, max: number) {

        this.state.chart.data = data;


        // TODO: figure out how to target the label more intelligently and not just by indeax
        if (this.state.chart.chartContainer.children.values.length > 3) {
            (this.state.chart.chartContainer.children.values[3] as any).text = labelValue;
        }

        if (this.state.chart.yAxes.values.length > 0) {
            (this.state.chart.yAxes.values[0] as any).min = min;
            (this.state.chart.yAxes.values[0] as any).max = max;
            (this.state.chart.yAxes.values[0] as any).title.text = valueAxisTitle;
        }

        // Create ranges
        if (ranges !== null) {
            for (var key in ranges) {
            }

        }


    }

}
