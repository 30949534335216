import * as React from 'react';
import { Row, Col, Card, CardDeck, CardTitle, CardText, CardBody,} from 'reactstrap';
import { DataTable } from '../../DataTable/FuelEconByDriverBehavior'
import { DropDownFilterBar } from '../../DropDownFilterBar'
import { Link } from 'react-router-dom';
import { FuelSubnav } from '../SubNav'

interface IDashboardProps {

}

interface IDashboardState {
    rowData: any,     // 'any' becauase the structure of the objects in teh array will vary
    columns: Array<{ columnName: string, columnHeader: string, width: number }>
}

export class FuelByVehicleAttribute extends React.Component<IDashboardProps, IDashboardState> {
    constructor(props: IDashboardProps) {
        super(props);
        this.state = {
            rowData: [],
            columns: []
        }

    }
    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        /*
        fetch('api/Fuel/FuelEconByBehaviourDataTable/2021-07-01/2021-07-14')  // Currently this enpoint returns an empty list. tempdata is being used
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                // console.log(data);
                this.populateFuelByWeight(data)
            })
            .catch((error: Error) => {
                throw error
            });
*/
        /*

            */
        const columns = [
            // { columnName: 'TotalConsumption' },
            { columnName: 'idleGallons', columnHeader: 'Idle Gallons (gal)', width: 300 },
            { columnName: 'acceleration', columnHeader: 'Vehicle Acceleration (+/- predicated gal)', width: 200 },
            { columnName: 'accelerationAvg', columnHeader: 'Average Accel. (g)', width: 100 },
            { columnName: 'highwaySpeed', columnHeader: 'Highway Speed (+/- predicated gal)', width: 200 },
            { columnName: 'highwaySpeedAvg', columnHeader: 'Average Hwy Speed (mph)', width: 100 },
        ];

        const rowData = [
            {
                vehicle: { name: "COMPANY", deviceId: 'b1553', isHeader:true },
                totalConsumption: { total:456321, delta:123 },
                idleGallons: { total: 200, actual: 10, maxValue: 311 },
                acceleration: { total: 15, actual: 0.21, maxValue: 38 },
                highwaySpeed: { total: -85, actual: 64.2, maxValue: -200 },
            },
            {
                vehicle: { name: "Fleet 1", deviceId: 'b1553', isHeader: false  },
                TotalConsumption: { total: 83156 },
                idleGallons: { total: -311, actual: 55, maxValue:-311 },
                acceleration: { total: -30, actual: 0.34, maxValue: -50 },
                highwaySpeed: { total: -200, actual: 89.3, maxValue: -200 },
            },
            {
                vehicle: { name: "Fleet 2", deviceId: 'b1553', isHeader: false },
                totalConsumption: { total: 63518},
                idleGallons: { total: -237, actual: 10, maxValue: -311  },
                acceleration: { total: 11, actual: 0.17, maxValue: 38 },
                highwaySpeed: { total: -100, actual: 86.8, maxValue: -200 },
            },
            {
                vehicle: { name: "Fleet 3", deviceId: 'b1553', isHeader: false },
                totalConsumption: { total: 95318},
                idleGallons: { total: 111, actual: 10, maxValue: 331  },
                acceleration: { total: -11, actual: 0.17, maxValue: -50 },
                highwaySpeed: { total: 100, actual: 95.7, maxValue: 203 },
            },
            {
                vehicle: { name: "Fleet 4", deviceId: 'b1553', isHeader: false },
                totalConsumption: { total: 83156 },
                idleGallons: { total: 200, actual: 10, maxValue: 331  },
                acceleration: { total: -22, actual: 0.17, maxValue: -50 },
                highwaySpeed: { total: -180, actual: 71.3, maxValue: -200 },
            },
            {
                vehicle: { name: "Fleet 5", deviceId: 'b1553', isHeader: false },
                totalConsumption: { total: 35497 },
                idleGallons: { total: 331, actual: 10, maxValue: 331  },
                acceleration: { total: -14, actual: 0.17, maxValue: -50 },
                highwaySpeed: { total: 203, actual: 80.5, maxValue: 203 },
            },
        ];


        this.setState(
            {
                rowData: rowData,
                columns: columns
            }, this.render);

    }

    //public populateFuelByWeight(data: any) {
    //    const columns = [
    //        // { columnName: 'TotalConsumption' },
    //        { columnName: 'idleGallons' },
    //        { columnName: 'acceleration' },
    //        { columnName: 'highwaySpeed' }
    //    ];


    //    this.setState(
    //        {
    //            rowData: data,
    //            columns: columns
    //        }, this.render);
    //}

    async populateData() {

    }

    /*
     * .ag-theme-alpine .ag-ltr .ag-cell {
    border-left: 1px solid silver !important;
}*/

    render() {
        let datatable;
        if (this.state.rowData && this.state.columns)
            datatable = <DataTable rowData={this.state.rowData} columns={this.state.columns} />

        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <nav className="dashboardTitle" aria-label="breadcrumb">
                            <div className="breadcrumb">
                                <Link className="breadcrumb-item" to="/fuel" >FUEL</Link>
                                <Link className="breadcrumb-item" to="/fuel/weight" >Fuel Efficiency</Link>
                                <a className="breadcrumb-item" >Fuel Efficiency By Driver Behavior</a>
                            </div>
                        </nav>
                    </Col>
                </Row>
                <DropDownFilterBar />
                <FuelSubnav pageTitle="Fuel Economy By Vehicle Attribute" />

                <Row>
                    <Col>
                        <CardDeck>
                            <Card className="col-5of5 card-secondary">
                                <CardBody>
                                    <CardText tag="div">
                                        {datatable}
                                    </CardText>
                                </CardBody>
                            </Card>
                        </CardDeck>
                    </Col>
                </Row>


            </React.Fragment>
        );
    }
}
