import * as React from 'react';
import { Row, Col, Spinner, Breadcrumb, BreadcrumbItem, Card, CardBody, CardTitle, CardText, CardDeck } from 'reactstrap';
import { DivergentStackBarChart } from '../../Charts';
import { DropDownFilterBar } from '../../DropDownFilterBar'
import { Link } from 'react-router-dom';
import { DataTable } from '../../DataTable/Telemetry'
import { FuelSubnav } from '../SubNav'
import { InovexDropDown } from '../../InovexDropDown';
import { FuelComparison } from './FuelComparison';

interface IDashboardProps {

}

interface IDashboardState {
    contributingFactorsChart: DivergentStackBarChart | null;
    totalFuelUsedInPeriod: string;
    totalExpectedFuelUsedInPeriod: string;
    totalFuelWasted: string;
    rowData: any,     // 'any' becauase the structure of the objects in teh array will vary
    columns: Array<{ columnName: string, columnHeader: string, width: number }>,
    chartRenderContent: any,//html
    charts: any[],// all charts
    selectedVehicles: any[],
}

export class FuelOverview extends React.Component<IDashboardProps, IDashboardState> {
    constructor(props: IDashboardProps) {
        super(props);
        this.state = {
            contributingFactorsChart: null,
            totalFuelUsedInPeriod: '',
            totalExpectedFuelUsedInPeriod: '',
            totalFuelWasted: '',
            rowData: [],
            columns: [],
            chartRenderContent: null,
            charts: null,
            selectedVehicles: null,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    public getFuelDataTableData() {
        fetch('api/Fuel/ContributingFactors')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {

                const columns = [
                    { columnName: 'totalMileage', columnHeader: 'Total Mileage', width: 150 },
                    { columnName: 'totalConsumption', columnHeader: 'Total Cons.', width: 150 },
                    { columnName: 'fuelIdleSum', columnHeader: 'Idle Cons.', width: 150 },
                    { columnName: 'avgFuelEcon', columnHeader: 'Avg. Fuel Econ', width: 150 },
                    { columnName: 'weight', columnHeader: 'Weight', width: 150 },
                    { columnName: 'driverBehavior', columnHeader: 'Driver Behavior', width: 150 },
                    { columnName: 'route', columnHeader: 'Route', width: 150 },
                    { columnName: 'vehicleAttribute', columnHeader: 'Vehicle Attribute', width: 150 }
                ];

                this.setState(
                    {
                        rowData: data,
                        columns: columns
                    }, this.render);


            })
            .catch((error: Error) => {
                throw error
            });

    }

    async fetchData() {
        this.getFuelDataTableData()
    }

    setSelectedVehicles(datas: any[]) {
        console.log('setSelectedVehicles', datas);
        this.setState({ selectedVehicles: datas });
    }


    render() {

        let datatable;
        if (this.state.rowData && this.state.columns)
            datatable = <DataTable rowData={this.state.rowData} columns={this.state.columns} pushPinnedData={(data) => { this.setSelectedVehicles(data); }} onRowSelected={(data) => { this.setSelectedVehicles(data); }} showCheckboxes={true} min={ 0 } max = { 3 } />
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <nav className="dashboardTitle" aria-label="breadcrumb">
                            <div className="breadcrumb">
                                <Link className="breadcrumb-item" to="/fuel" >FUEL</Link>
                                <Link className="breadcrumb-item" to="/fuel" >Fuel Economy</Link>
                                <a className="breadcrumb-item" >Analysis & Comparison</a>
                            </div>
                        </nav>
                    </Col>
                </Row>
                <DropDownFilterBar />
                <Row>
                    <Col>
                        <h2>Fuel Economy</h2>
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <CardDeck>
                            <Card className="card-highlight">
                                <CardBody>
                                    <CardTitle tag="h5" >Contributing Factors</CardTitle>
                                    <Row>
                                        {this.state.selectedVehicles && this.state.selectedVehicles.map((vehicleData) => (
                                            <FuelComparison key={vehicleData.vehicle.vin} data={vehicleData} />
                                        ))}
                                    </Row>
                                </CardBody>
                            </Card>
                        </CardDeck>
                    </Col>
                </Row>
                <Row className="pb-0 mb-0">
                    <Col xs="2" className="tile p-0 mb-0">
                        <div className="text-white pb-0 mb-0 pl-2 dropdownFleetHealthGroupByToggleLabel">
                            <div className="mt-0 pt-0 pb-0" style={{ float: "left", width: "33%", lineHeight: "30px" }}>
                                GROUP BY
                            </div>
                            <div className="mt-1 mb-1" style={{ display: "inline-block", width: "1px", backgroundColor: "white", height: "70%", float: "left" }} />
                            <div className="mt-0 pt-0" style={{ float: "left", width: "66%", height: "100%" }}>
                                <InovexDropDown dropDownId="dropDownGroupBy"
                                    dropDownToggleId="dropdownFleetHealthGroupByToggleID"
                                    initialValue="VIN"
                                    //onValueSelected={(trendValue: any) => this.changeTrendValue(trendValue)}
                                    dropDownStyle={{width: "100%" }}
                                    omitSelected={false} >
                                    <span>VIN</span>
                                    <span>Company</span>

                                </InovexDropDown>

                            </div>

                        </div>

                    </Col>


                </Row>
                <Row className="pt-0 mt-0">
                    <Col className='tile pr-0 pb-0 pt-0'>

                        {datatable}
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
