import * as React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4Bullets from "@amcharts/amcharts4/plugins/bullets";
import { isNullOrUndefined } from 'util';

interface IChartState {
    chartData: any;
    chart: am4charts.XYChart;
    // chartContainer: string;
}

interface IChartProps {
    chartData: any;
    chartContainer: string;
    chartProperties: IChartProperties;
}

interface IChartSeries {
    valueAxisName: string;
    label: string;
    color: string;
}

interface IChartFlag {
    labelText: string,
    XYaxis: string, // acceptable values are 'X' or 'Y'
    startValue?: any,
    endValue?: any,
    fontSize?: number,
    horizontalCenter?: string,
    pole?: {
        color?: string,
        strokeWidth?: number,
        height?: number,
        dash?: string
    },
    background?: {
        waveLength?: number,
        color?: string,
        strokeWidth?: number,
        fillOpacity?: number
    }
}

interface IChartProperties {
    chartTitle?: {
        text?: string,
        fontSize?: number,
        fontWeight?: string;
    }
    chartSubtitle?: {
        text?: string,
        fontSize?: number,
        fontWeight?: string;
    }
    valueAxisMinValue?: number,
    valueAxisMaxValue?: number,
    height?: number,
    width?: number,
    barThickness: number,
    hideLabels?: boolean,
    valueAxisRenderInside?: boolean,
    useCursor: boolean,
    useTooltip?: boolean,
    useBullets: boolean,
    categoryAxisName: string,
    orientation: string,
    labelTextTemplateSizeOverride?: string,
    labelTextTemplateOverride?: string,
    chartPadding?:number,
    stacked?: boolean,
    xAxisTitle?: {
        text?: string,
        fontSize?: any,
        fontWeight?: number
    };
    yAxisTitle?: {
        text?: string,
        fontSize?: any,
        fontWeight?: number
    };
    numberFormat?: string;
    series?: {
        orientation?: am4core.PointerOrientation,
        roundedTopCorners?: boolean
    },
    legend?: {
        legendContainer?: string,
        position: string,
    },
    labels?: {
        hideValueAxisLabels?: boolean,
        fontSize: number
    },
    rangeFill?: {
        startCategory: string,
        endCategory: string,
        color: string
    },
    flagProps?: IChartFlag,
    hoverState?: {
        isEnabled?: boolean,
        hoverColor?: string;
    }
}



export default class StackedColumnChart extends React.Component<IChartProps, IChartState> {
    constructor(props: IChartProps) {
        super(props);
        am4core.addLicense("CH308822591");
        // TODO: remove what we can from the state i.e. stuff that doesn't need to be kept in state
        this.state =
        {
            chartData: props.chartData,
            // chartData: tempData,
            // chartContainer: props.chartContainer,
            chart: am4core.create(props.chartContainer, am4charts.XYChart)
        }
    }


    public Create(series: Array<IChartSeries>) {
        // console.log('StackedColumnChart > Create');

        am4core.useTheme(am4themes_animated);
        this.state.chart.hiddenState.properties.opacity = 1; // zero  creates initial fade-in
        this.state.chart.data = this.state.chartData;
        this.state.chart.maskBullets = true;

        if (this.props.chartProperties.chartPadding) {
            this.state.chart.paddingLeft = this.props.chartProperties.chartPadding;
            this.state.chart.paddingRight = this.props.chartProperties.chartPadding;    ;

        }

        if (this.props.chartProperties.chartSubtitle) {
            let title = this.state.chart.titles.push(new am4core.Label());
            title.text = this.props.chartProperties.chartSubtitle.text;
            title.fontSize = 16;
            title.fontWeight = "normal";
        }

        if (this.props.chartProperties.chartTitle) {
            let title = this.state.chart.titles.push(new am4core.Label());
            title.text = this.props.chartProperties.chartTitle.text;
            title.fontSize = 16;
            title.fontWeight = "bold";
        }
        //console.log(this.state.chart.data);

        var isHorizontalOrientation = this.props.chartProperties.orientation === "horizontal";

        // Create axes
        var categoryAxis = (isHorizontalOrientation ? this.state.chart.yAxes.push(new am4charts.CategoryAxis()) : this.state.chart.xAxes.push(new am4charts.CategoryAxis()));
        categoryAxis.dataFields.category = this.props.chartProperties.categoryAxisName;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 10;


        // Hide category label
        var valueAxis = (isHorizontalOrientation ? this.state.chart.xAxes.push(new am4charts.ValueAxis()) : this.state.chart.yAxes.push(new am4charts.ValueAxis()));
        if (this.props.chartProperties.valueAxisRenderInside !== false)
            valueAxis.renderer.inside = true;


        if (this.props.chartProperties.yAxisTitle) {
            valueAxis.title.text = this.props.chartProperties.yAxisTitle.text;
        }

        if (this.props.chartProperties.xAxisTitle) {
            categoryAxis.title.text = this.props.chartProperties.xAxisTitle.text;
        }

        // check chartProperties configuration

        if (this.props.chartProperties.numberFormat)
            this.state.chart.numberFormatter.numberFormat = '#' + this.props.chartProperties.numberFormat;
        else
            this.state.chart.numberFormatter.numberFormat = "#,###.";

        if (this.props.chartProperties.valueAxisMaxValue !== null)
            valueAxis.max = this.props.chartProperties.valueAxisMaxValue;

        if (this.props.chartProperties.valueAxisMinValue !== null)
            valueAxis.min = this.props.chartProperties.valueAxisMinValue;
        else
            valueAxis.min = 0;

        // TODO: confirm these work as expected
        if (this.props.chartProperties.hideLabels) {
            categoryAxis.renderer.grid.template.disabled = true;
            categoryAxis.renderer.labels.template.disabled = true;
            valueAxis.renderer.grid.template.disabled = true;
            valueAxis.renderer.baseGrid.disabled = true;
            valueAxis.renderer.labels.template.disabled = true;
            valueAxis.cursorTooltipEnabled = false;
        }
        else {
            valueAxis.renderer.grid.template.disabled = false;
            valueAxis.renderer.baseGrid.disabled = false;
            valueAxis.renderer.labels.template.disabled = false;
            valueAxis.cursorTooltipEnabled = true;
        }

        if (this.props.chartProperties.rangeFill) {
            var rProps = this.props.chartProperties.rangeFill;

            let range = categoryAxis.axisRanges.create();
            range.category = rProps.startCategory;
            range.endCategory = rProps.endCategory;
            range.axisFill.fill = am4core.color(rProps.color);
            range.axisFill.fillOpacity = 0.3;
            range.label.disabled = true;
        }

        if (this.props.chartProperties.flagProps) {
            this.createFlag(this.props.chartProperties.flagProps, categoryAxis)
        }

        // We need to be able to supply a variable number of series
        if (series && series.length > 0) {
            for (let x = 0; x < series.length; x++) {
                this.createSeries(series[x].valueAxisName, series[x].label, am4core.color(series[x].color), categoryAxis, valueAxis);
            }
        }

        if (this.props.chartProperties.legend) {
            this.state.chart.legend = new am4charts.Legend();
            switch (this.props.chartProperties.legend.position) {
                case "top": this.state.chart.legend.position = "top"; break; // we can assign a string, but not a variable that is a string. weird
                case "left": this.state.chart.legend.position = "left"; break;
                case "bottom": this.state.chart.legend.position = "bottom"; break;
                case "right": this.state.chart.legend.position = "right"; break;
            }

            if (this.props.chartProperties.legend.legendContainer) {
                let legendContainer = am4core.create(this.props.chartProperties.legend.legendContainer, am4core.Container);
                legendContainer.marginLeft = 2;
                legendContainer.marginRight = 2;

                legendContainer.width = am4core.percent(100);
                legendContainer.height = am4core.percent(100);
                this.state.chart.legend.parent = legendContainer;
            }
        }
    }


    // Create series
    public createSeries(valueAxisName: string, seriesName: string, color: am4core.Color, categoryAxis: am4charts.CategoryAxis, valueAxis: am4charts.ValueAxis) {
        let labelTextTemplate = "";
        
        // Set up series
        let series = this.state.chart.series.push(new am4charts.ColumnSeries());
        series.name = seriesName;
        series.sequencedInterpolation = true;
        series.stacked = true; //Default stacked to true, since this is StackedColumnChart

        // If stacked is provided as a prop, set it to the prop
        if (!isNullOrUndefined(this.props.chartProperties.stacked)) {
            series.stacked = this.props.chartProperties.stacked;
        }

        //This keeps the labels in check for the current stackedcolumncharts
        if (series.stacked) {
            categoryAxis.renderer.cellStartLocation = 0.4; 
            categoryAxis.renderer.cellEndLocation = 1;
        }
        else {
            //For our vertical bar, we want it a bit different
            categoryAxis.renderer.cellStartLocation = 0; 
            categoryAxis.renderer.cellEndLocation = 1;
        }
       
        if (this.props.chartProperties.useCursor) {
            // Make a panning cursor
            this.state.chart.cursor = new am4charts.XYCursor();
            this.state.chart.cursor.behavior = "panXY";
            this.state.chart.cursor.xAxis = categoryAxis;
            this.state.chart.cursor.yAxis = valueAxis;
            this.state.chart.cursor.snapToSeries = series;

        }

        if (this.props.chartProperties.hoverState && this.props.chartProperties.hoverState.isEnabled) {
            let hoverState = series.columns.template.states.create("hover");
            hoverState.properties.fill = this.props.chartProperties.hoverState.hoverColor ? am4core.color(this.props.chartProperties.hoverState.hoverColor) : am4core.color("grey");
            hoverState.properties.fillOpacity = 0.8;
            series.tooltip.getFillFromObject = false;
            series.tooltip.background.fill = this.props.chartProperties.hoverState.hoverColor ? am4core.color(this.props.chartProperties.hoverState.hoverColor) : am4core.color("grey");
        }

        this.CreateBulletLabels(series);

        switch (this.props.chartProperties.orientation) {
            case 'horizontal':
                series.dataFields.valueX = valueAxisName;      // valueX
                series.dataFields.categoryY = this.props.chartProperties.categoryAxisName;    // categoryY
                series.columns.template.height = am4core.percent(this.props.chartProperties.barThickness);
                //series.columns.template.strokeWidth = this.props.chartProperties.barThickness;
                // console.log("Value X: ", series.dataFields.valueX);

                if (this.props.chartProperties.useTooltip) {
                    if (this.props.chartProperties.labelTextTemplateOverride)
                        series.columns.template.tooltipText = this.props.chartProperties.labelTextTemplateOverride;
                    else
                        series.columns.template.tooltipText = "{name}: {valueY}";

                    if (this.props.chartProperties.hoverState) {
                        series.tooltip.pointerOrientation = "left";
                        series.columns.template.tooltipX = am4core.percent(100);
                        series.columns.template.tooltipY = am4core.percent(50);
                    }
                }

                switch (this.props.chartProperties.labelTextTemplateSizeOverride) {
                    case 'xlarge': 
                        labelTextTemplate = "[bold " + color.hex + " font-size:16px]{valueX}%[/]\n[font-size:13px]{name}[/]"; //"{valueX}% {name}";
                        break;
                    case 'large': 
                        labelTextTemplate = "[bold " + color.hex + " font-size:14px]{valueX}%[/]\n[font-size:11px]{name}[/]"; //"{valueX}% {name}";
                        break;
                    case 'medium': 
                        labelTextTemplate = "[bold " + color.hex + " font-size:12px]{valueX}%[/]\n[font-size:10px]{name}[/]"; //"{valueX}% {name}";
                        break;

                    case null: // null is the default 
                    case undefined: 
                    case 'small':
                    default:
                        labelTextTemplate = "[bold " + color.hex + " font-size:10px]{valueX}%[/]\n[font-size:9px]{name}[/]"; //"{valueX}% {name}";
                        break;
                }



                // series.columns.template.tooltipText = "[bold]{valueX}%[font-size:14px] {name}[/]";
                break;
            case 'vertical':
            default: // vertical
                series.dataFields.valueY = valueAxisName;                                       // valueY
                // console.log("Vert Values:", valueAxisName);
                series.dataFields.categoryX = this.props.chartProperties.categoryAxisName;      // categoryX
                //series.columns.template.strokeWidth = this.props.chartProperties.barThickness;
                series.columns.template.width = am4core.percent(this.props.chartProperties.barThickness); //90% keeps a small gap between the bars - set to 100% for no gap
                // labelTextTemplate = "{valueY}";

                if (this.props.chartProperties.useTooltip) {
                    if (this.props.chartProperties.labelTextTemplateOverride) 
                        series.columns.template.tooltipText = this.props.chartProperties.labelTextTemplateOverride;
                    else
                        series.columns.template.tooltipText = "{name}: {valueY}";

                    if (this.props.chartProperties.hoverState) {
                        series.tooltip.pointerOrientation = "vertical";
                        series.columns.template.tooltipX = am4core.percent(50);
                        series.columns.template.tooltipY = am4core.percent(0);
                    }
                }
                
                switch (this.props.chartProperties.labelTextTemplateSizeOverride) {
                    case 'xlarge':
                        labelTextTemplate = "[bold " + color.hex + " font-size:16px]{valueY}%[/]\n[font-size:13px]{name}[/]"; //"{valueX}% {name}";
                        break;
                    case 'large':
                        labelTextTemplate = "[bold " + color.hex + " font-size:14px]{valueY}%[/]\n[font-size:11px]{name}[/]"; //"{valueX}% {name}";
                        break;
                    case 'medium':
                        labelTextTemplate = "[bold " + color.hex + " font-size:12px]{valueY}%[/]\n[font-size:10px]{name}[/]"; //"{valueX}% {name}";
                        break;

                    case null: // null is the default 
                    case undefined:
                    case 'small':
                    default:
                        labelTextTemplate = "[bold " + color.hex + " font-size:10px]{valueY}%[/]\n[font-size:9px]{name}[/]"; //"{valueX}% {name}";
                        break;
                }

                break;
        }


        // series.columns.template.column.fill = color;
        // series.columns.template.column.stroke = color;
        // console.log("Label Text Template:", labelTextTemplate);
        var columnTemplate = series.columns.template;
        columnTemplate.fillOpacity = .8;
        columnTemplate.strokeOpacity = 0;
        columnTemplate.fill = color;
        
        if (this.props.chartProperties.useBullets) {
            // Add label
            let labelBullet = series.bullets.push(new am4charts.LabelBullet());
            labelBullet.label.text = labelTextTemplate;

            labelBullet.locationX = 0.6;
            labelBullet.label.hideOversized = false;
            labelBullet.label.truncate = false;
            //labelBullet.label.fill = am4core.color("black");


            switch (this.props.chartProperties.labelTextTemplateSizeOverride) {
                case 'xlarge': // empty string is the default value
                case 'large': // empty string is the default value
                    labelBullet.label.dy = 50;
                    labelBullet.label.dx = 6;
                    break;
                case 'medium': // empty string is the default value
                    labelBullet.label.dy = 40;
                    labelBullet.label.dx = 6;
                    break;
                case 'small':
                case '': // empty string is the default value
                default:
                    labelBullet.label.dy = 25;
                    break;
            }



            labelBullet.verticalCenter = "top";
            labelBullet.horizontalCenter = "left";
            //labelBullet.label.fontSize = "11";
            //labelBullet.locationY = 0.5;
        }
        // return series;
    }

    public CreateBulletLabels(series: am4charts.ColumnSeries) {
        // have the value show as a label on the first and last data points
        let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{value}";
        labelBullet.disabled = true;
        labelBullet.propertyFields.disabled = "disabled";
        labelBullet.label.dy = -20;
        labelBullet.label.fontSize = "12";

    }


    public createFlag(chartFlag: IChartFlag, flagAxisOnTheChart: any) {
        let flag = new am4Bullets.FlagBullet();

        flag.label.text = chartFlag.labelText || "";
        flag.label.fontSize = chartFlag.fontSize || 20;

        

        if (chartFlag.pole) {
            if (chartFlag.pole.color)
                flag.pole.stroke = am4core.color(chartFlag.pole.color);
            if (chartFlag.pole.strokeWidth)
                flag.pole.strokeWidth = chartFlag.pole.strokeWidth;
            if (chartFlag.pole.height)
                flag.poleHeight = chartFlag.pole.height;
            if (chartFlag.pole.dash)
                flag.strokeDasharray = chartFlag.pole.dash;
        }

        if (chartFlag.background) {
            flag.background.waveLength = chartFlag.background.waveLength || 0;
            if (chartFlag.background.color) {
                flag.background.fill = am4core.color(chartFlag.background.color);
                flag.background.stroke = am4core.color(chartFlag.background.color);
            }
            if (chartFlag.background.strokeWidth)
                flag.background.strokeWidth = chartFlag.background.strokeWidth;
            if (chartFlag.background.fillOpacity)
                flag.background.fillOpacity = chartFlag.background.fillOpacity;
        }

        let event = flagAxisOnTheChart.axisRanges.create();

        //console.log("createFlag", event);

        //This will need to be modified to account for other types of charts other than category (such as dates)
        event.category = chartFlag.startValue;
        event.endCategory = chartFlag.endValue;
        event.label.disabled = true;
        
        /*event.date = chartFlag.value;
        event.category = chartFlag.value;*/
        event.bullet = flag;
        event.grid.strokeWidth = 0;

        //console.log("Flag Range", event.bullet.locationX);
    }



    public ReDraw(newdata: Array<{ dateTimeUtc: Date; value: number; }>) {
        this.state.chart.data = newdata;
    }

}
