import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Layout } from './components/Layout';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import { Overview } from './components/Overview';
import { UtilizationAnalysis } from './components/Utilization/Overview';
import { FetchData } from './components/FetchData';
import { DutyCycle, WeightAnalysis } from './components/DutyCycle';
import { Aftertreatment, BatteryHealth, GeneralHealth, VehicleHealth } from './components/Health'
import { FuelByWeight, FuelByDriverBehaviour, FuelOverview, FuelByRoute, FuelByVehicleAttribute, FuelTotalAndTractive } from './components/Fuel';


// import { AssetUtilization } from './components/Performance/AssetUtilization';
// import { DutyCycle } from './components/DutyCycle/DutyCycle';
// import { FuelEfficiency } from './components/FuelEfficiency/FuelEfficiency';

import './assets/custom.css'
import './assets/images/images.css'
import './assets/bootstrapoverride.css'

const LoginContext = React.createContext('Login');

export default class App extends React.Component {
    static displayName = App.name;


    /* TO DO: SAFETY, USAGE DUTY and CYCLE */
    /*
        <Route exact={true} path='/safety' component={Safety} parentPage="" pageTitle="Safety" />
        <Route exact={true} path='/usage' component={Safety} parentPage="" pageTitle="usage" />
        <Route exact={true} path='/duty-cycle' component={DutyCycle} parentPage="" pageTitle="Safety" />
        <Route exact={true} path='/duty-cycle/weight' component={WeightAnalysis} parentPage="" pageTitle="Safety" />
    */


    render() {
        return (
            <Layout>
                <Switch>
                    <Route path="/login" component={Login} />

                    <Route exact={true} path='/overview' component={Overview}  />

                    <Route exact={true} path='/duty-cycle' component={DutyCycle} />
                    <Route exact={true} path='/duty-cycle-weight' component={WeightAnalysis} />


                    <Route exact={true} path='/health-general' component={GeneralHealth}  />
                    <Route exact={true} path='/health-battery' component={BatteryHealth} />
                    <Route exact={true} path='/health-aftertreatment' component={Aftertreatment}  />
                    <Route exact={true} path='/health-vehicle-:vid' component={VehicleHealth} />

                    <Route exact={true} path='/fuel' component={FuelOverview}  />
                    <Route exact={true} path='/fuel-weight' component={FuelByWeight}  />
                    <Route exact={true} path='/fuel-behavior' component={FuelByDriverBehaviour}  />
                    <Route exact={true} path='/fuel-route' component={FuelByRoute} />
                    <Route exact={true} path='/fuel-totaltractive' component={FuelTotalAndTractive}  />
                    <Route exact={true} path='/fuel-attribute' component={FuelByVehicleAttribute} parentPage="" pageTitle="FuelByVehicleAttribute" />

                    <Route exact={true} path='/utilization' component={UtilizationAnalysis} />

                    {/*<Route exact={true} path='/FetchData' component={FetchData} />*/}

                    <Route path='/api' />

                    <Route path='/' component={Overview} />
                    <Redirect to='/'/>
                </Switch>
            </Layout>
        );
    }
}

