import * as React from 'react';
import { Link } from 'react-router-dom';
import '../../DataTable/Shared/styles.css'


export default (props) => {
    let text = '';
    let output;

   
    if (props.value !== undefined) {
        
        text = props.value.name

        if (props.value.isHeader) {
            output = <div className="pt-3" style={{ fontSize: "20px", textAlign: "center" }} ><strong>{text}</strong></div>
        } else {
            output = <div style={{ marginTop: '10px' }}>{text}</div>
        }


    }

    return (
        <div style={{ textAlign: "left" }} >{output}</div>
        
    );
};