import * as React from 'react';
import { Row, Col, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { DataTable } from '../../DataTable/Telemetry'
import { DropDownFilterBar } from '../../DropDownFilterBar'
import { Link } from 'react-router-dom';
import { SparkLineChart } from '../../Charts';

import { AiFillCheckCircle } from "react-icons/ai"; // good
import { MdCancel } from "react-icons/md"; //critical
import { RiErrorWarningFill } from "react-icons/ri"; //warning
import { InovexDropDown } from '../../InovexDropDown';
import { HealthSubnav } from '../SubNav'

interface IGeneralHealthProps {

}

interface IGeneralHealthState {
    rowData: any,     // 'any' becauase the structure of the objects in teh array will vary
    columns: Array<{ columnName: string, columnHeader: string, width: number }>
    healthChart: SparkLineChart | null;
    milLampCount: number,
    engineLampCount: number,
    stopLampCount: number,
    normalCount: number,
    warningCount: number,
    criticalCount: number,
    fleetHealthScore: number;
    filteredData: any,
    filteredExpression: string,
    isFiltered: boolean,
    healthChartData: Array<{ dateTimeUtc: Date; value: number; }>;
}


export class GeneralHealth extends React.Component<IGeneralHealthProps, IGeneralHealthState> {

    constructor(props: IGeneralHealthProps) {
        super(props);

        this.state =
        {
                rowData: [],
                columns: [],
                healthChart: null,
                milLampCount: 0,
                engineLampCount: 0,
                stopLampCount: 0,
                healthChartData: [],
                fleetHealthScore: 0,
                normalCount: 0,
                warningCount: 0,
                criticalCount: 0,
                filteredData: [],
                isFiltered: false,
                filteredExpression: ""
        };

        this.onFilterClickHandler = this.onFilterClickHandler.bind(this);
    };


    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        this.fetchFleetHealth();
        this.fetchFleetHealthScore();

    }

    public fetchFleetHealthScore() {
        fetch('api/Vehicle/FleetHealthScore')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {


                this.setState(
                    {
                        fleetHealthScore: data,
                    },
                    () => this.fetchFleetHealthScoreChart(data));
                ;


            })
            .catch((error: Error) => {
                throw error
            });
    }


    public fetchFleetHealthScoreChart(finalValue: number) {
        let start: number = finalValue - 1;
        let minimum: number = finalValue - 5;
        let maximum: number = finalValue + 5;

        fetch('/api/Vehicle/FakeData/10/' + minimum + '/' + maximum + '/Health/%25/3/3/1')  // 10: items, 3: weekly, 3: fluctuating, 1: integers
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                this.setState({ healthChartData: data }, () => this.populateHealtChart(data));

            })
            .catch((error: Error) => {
                throw error
            });
    }

    public populateHealtChart(json: any) {

        //const healthDataLength = json.length;

        console.log('populateHealtChart');
        const HealthValue = this.state.fleetHealthScore + '%';

        let initialDate: Date = new Date(json[0].dateTimeUtc);
        initialDate.setHours(initialDate.getHours() - 7 * 24)  // one week prior


        let finalDate: Date = new Date(json[json.length - 1].dateTimeUtc);
        finalDate.setHours(finalDate.getHours() + 7 * 24)      // and one week after

        let finalDatapoint: any = { name: 'Health', metricName: '%', value: this.state.fleetHealthScore, dateTimeUtc: finalDate };
        let initialDatapoint: any = { name: 'Health', metricName: '%', value: this.state.fleetHealthScore - 2, dateTimeUtc: initialDate };

        console.log(initialDatapoint);
        console.log(finalDatapoint);
        console.log(json);


        json.unshift(initialDatapoint);
        json.push(finalDatapoint);
        console.log(json);


        var chart = new SparkLineChart({ chartData: json, chartContainer: 'fleethealthchartdiv', strokeColor: '#3885BB', bulletColor: '#000000'});
        chart.Create('Health', true, true);
        this.setState({ healthChart: chart });
    }


    public fetchFleetHealth() {
        fetch('api/Vehicle/FleetHealth')
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                //console.log(data);
                const columnsWithLamps = [
                    { columnName: 'generalHealth', columnHeader: 'Total Health & Mileage', width: 150 },
                    { columnName: 'stopEngineLamp', columnHeader: 'Stop Engine', width: 100 },
                    { columnName: 'checkEngineLamp', columnHeader: 'Check Engine', width: 100 },
                    { columnName: 'milLamp', columnHeader: 'MIL Lamp', width: 100 },
                    { columnName: 'criticalDTCs', columnHeader: 'Critical DTCs', width: 80 },
                    { columnName: 'engCoolantLevel', columnHeader: 'Coolant Level', width: 110 },
                    { columnName: 'engCoolantTemp', columnHeader: 'Coolant Temp', width: 110 },
                    { columnName: 'fuelLevel', columnHeader: 'Fuel Level', width: 100 },
                    { columnName: 'oilTemp', columnHeader: 'Engine Oil Temp', width: 110 },
                    //{ columnName: 'defLevel', columnHeader: 'DEF Level', width: 110 },
                ];

                var result = data.reduce((a, c) => ({
                    checkEngineLamp: a.checkEngineLamp + c.checkEngineLamp,
                    stopEngineLamp: a.stopEngineLamp + c.stopEngineLamp,
                    milLamp: a.milLamp + c.milLamp
                }))

                var nCount = data.reduce((prev, cur) => cur.status === "normal" ? ++prev : prev, 0);
                var wCount = data.reduce((prev, cur) => cur.status === "warning" ? ++prev : prev, 0);
                var cCount = data.reduce((prev, cur) => cur.status === "critical" ? ++prev : prev, 0);

                this.setState(
                    {
                        rowData: data,
                        filteredData: data,
                        columns: columnsWithLamps,
                        engineLampCount: result.checkEngineLamp,
                        stopLampCount: result.stopEngineLamp,
                        milLampCount: result.milLamp,
                        normalCount: nCount,
                        warningCount: wCount,
                        criticalCount: cCount
                    }, this.render);
            })
            .catch((error: Error) => {
                throw error
            });
    }

    async populateData() {

    }

    onFilterClickHandler(filterVal, event) {
        var element = event.currentTarget;

        //Clear active from all the buttons
        document.querySelectorAll('.buttons button').forEach(function (el) {
            el.classList.remove('active');
        });

        if (this.state.filteredExpression === filterVal) { //if the same filter is clicked, reset the table
            element.classList.remove("active");
            var data = this.state.rowData;
            this.setState({ filteredData: data, isFiltered: false, filteredExpression: "" })
        }
        else { //if a different filter is clicked, filter the data
            element.classList.toggle("active");
            var data = this.state.rowData.filter((obj) => obj.status === filterVal);
            this.setState({ filteredData: data, isFiltered: true, filteredExpression: filterVal })
        }
    }

    render() {
        let datatable;
        if (this.state.rowData && this.state.columns)
            datatable = <DataTable rowData={this.state.filteredData} columns={this.state.columns} pinTopRow={false} />

        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <nav className="dashboardTitle" aria-label="breadcrumb">
                            <div className="breadcrumb">
                                <Link className="breadcrumb-item" to="/health-general" >HEALTH</Link>
                                <a className="breadcrumb-item" >Fleet Health</a>
                            </div>
                        </nav>
                    </Col>
                </Row>
                <DropDownFilterBar />

                <HealthSubnav pageTitle="Fleet Health" />

                <Row className="mb-0  ml-2 mr-2 ">
                    <Col className="pl-0 pr-2 mb-0">
                        <Card className="card-highlight">
                            <CardBody>
                                <CardTitle tag="h5">
                                    TOTAL FLEET HEALTH

                                    <div style={{ float: "right", marginRight: "30px" }}>
                                        <small>+5%</small>
                                    </div>

                                </CardTitle>

                                <CardText tag="div">
                                    <h2>{this.state.fleetHealthScore}% <small className="very-small">Average Total Fleet Health</small></h2> 
                                    
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="pl-0 pr-2 mb-0">
                        <Card className="card-highlight">
                            <CardBody>
                                <CardTitle tag="h5">
                                    FLEET HEALTH OVER PERIOD

                                </CardTitle>

                                <CardText tag="div">
                                    <div id="fleethealthchartdiv" style={{ height: "100px" }}></div>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="pl-0 pr-2 mb-0">
                        <Card className="card-highlight">
                            <CardBody>
                                <CardTitle tag="h5">
                                    DASHBOARD LIGHTS
                                </CardTitle>

                                <CardText tag="div">
                                    <Row className="mb-0">
                                        <Col xs="auto"></Col>
                                        <Col xs="7" className="border-bottom border-default ml-0 pl-0">
                                            STOP ENGINE LIGHTS
                                        </Col>
                                        <Col className="text-right border-bottom border-default">
                                            <strong>{this.state.stopLampCount}</strong>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                    <Row className="mb-0">
                                        <Col xs="auto"></Col>
                                        <Col xs="7" className="border-bottom border-default ml-0 pl-0">
                                            CHECK ENGINE LIGHTS
                                        </Col>
                                        <Col className="text-right border-bottom border-default">
                                            <strong>{this.state.engineLampCount}</strong>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                    <Row className="mb-0">
                                        <Col xs="auto"></Col>
                                        <Col xs="7" className="ml-0 pl-0">
                                            MIL LAMP
                                        </Col>
                                        <Col className="text-right">
                                            <strong>{this.state.milLampCount}</strong>
                                        </Col>
                                        <Col></Col>
                                    </Row>

                                    
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
                <Row className="pt-3 pb-3 align-items-center justify-content-center">
                    <div className="filterButtons round buttons">
                        <button style={{ width: "215px" }} onClick={(e) => this.onFilterClickHandler("critical", e)} >
                            <MdCancel className="MdCancel pr-1" fontSize="25" />
                            Critical 
                            <div className="box pr-5" style={{ float: "right", textAlign: "right" }}><strong>{this.state.criticalCount}</strong></div>
                        </button>
                        <button style={{ width: "215px" }} onClick={(e) => this.onFilterClickHandler("warning", e)}>
                            <RiErrorWarningFill className="RiErrorWarningFill pr-1" fontSize="25" />
                            Warning 
                            <div className="box pr-5" style={{ float: "right", textAlign: "right" }}><strong>{this.state.warningCount}</strong></div>
                        </button>
                        <button style={{ width: "215px" }} onClick={(e) => this.onFilterClickHandler("normal", e)}>
                            <AiFillCheckCircle className="AiFillCheckCircle pr-1" fontSize="25" />
                            Ready
                            <div className="box pr-5" style={{ float: "right", textAlign: "right" }}><strong>{this.state.normalCount}</strong></div>
                        </button>
                    </div>
                </Row>
                <Row className="pb-0 mb-0">
                    <Col xs="2" className="tile p-0 mb-0">
                        <div className="text-white pb-0 mb-0 pl-2 dropdownFleetHealthGroupByToggleLabel" >
                            <div className="mt-0 pt-0 pb-0" style={{ float: "left", width: "33%", lineHeight: "30px" }}>
                                GROUP BY
                            </div>
                            <div className="mt-1 mb-1" style={{ display: "inline-block", width: "1px", backgroundColor: "white", height:"70%", float: "left" }} />
                            <div className="mt-0 pt-0" style={{float: "left", width:"66%", height: "100%"}}>
                                <InovexDropDown dropDownId="dropDownGroupBy"
                                    dropDownToggleId="dropdownFleetHealthGroupByToggleID"
                                    initialValue="VIN"
                                    //onValueSelected={(trendValue: any) => this.changeTrendValue(trendValue)}
                                    dropDownStyle={{ width: "100%" }}
                                    omitSelected={false} >
                                    <span>VIN</span>
                                    <span>Stop Engine</span>    
                                    <span>Check Engine</span>
                                    <span>MIL Lamp</span>
                                </InovexDropDown>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="pt-0 mt-0">
                    <Col className='tile pr-0 pb-0 pt-0'>
                        {datatable}
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
