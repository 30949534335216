import * as React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { ScatterPlotChart } from '..';
import StackedColumnChart from '../StackedColumnChart';

interface IChartState {
    mainChartData: any;
    topChartData: any;
    rightChartData: any;
    mainChartContainer: string;
    topChartContainer: string;
    rightChartContainer: string;
}

interface IChartProps {
    mainChartData: any;
    topChartData: any;
    rightChartData: any;
    mainChartContainer: string;
    topChartContainer: string;
    rightChartContainer: string;
}

export default class ScatterPlotChartWithHistogramChart extends React.Component<IChartProps, IChartState> {
    constructor(props: IChartProps) {
        super(props);
        this.state =
        {
            mainChartData: props.mainChartData,
            topChartData: props.topChartData,
            rightChartData: props.rightChartData,

            mainChartContainer: props.mainChartContainer,
            topChartContainer: props.topChartContainer,
            rightChartContainer: props.rightChartContainer,
        }
    }


    public Create() {
        am4core.useTheme(am4themes_animated);
        am4core.addLicense("CH308822591");
        let mainChart = new ScatterPlotChart({
            chartData: this.state.mainChartData,
            chartContainer: this.state.mainChartContainer,
            yAxisTitleRotation: 0,
            bulletColor: "#19B7A7",
            alternatingGridColors: {
                isEnabled: true,
                alternateColor: "#edeced"
            }
        });

        mainChart.Create('Weight (lbs)', 'MPG on Trip', 'x', 'y', 'a', [], false);

        let topChartProperties = {
            chartTitle: {
                text: "FUEL ECONOMY BY WEIGHT BY TRIP"
            },
            chartSubtitle: {
                text: "Count of trips"
            },
            useCursor: false,
            useTooltip: true,
            useBullets: false,
            orientation: 'vertical',
            barThickness: 80,
            categoryAxisName: 'bName',
            labelTextTemplateOverride: '{categoryX} : {valueY}',
            numberFormat: '#,###.',
            valueAxisRenderInside: false,
            hideLabels: true,
            height: 600,
            stacked: false,
            // xAxisTitle: {
            //     text: "MPG",
            //     fontSize: "10px",
            //     fontWeight: 10
            // },
            // yAxisTitle: {
            //     text: "MPG",
            //     fontSize: "10px",
            //     fontWeight: 10
            // },
            hoverState: {
                isEnabled: true,
                hoverColor: "#FF8E07"
            }
        };

        let topData = this.state.topChartData;
        let topSeries = [];

        let topChart = new StackedColumnChart({ chartData: topData, chartContainer: this.state.topChartContainer, chartProperties: topChartProperties })       

        topSeries.push({ valueAxisName: "bVal", label: "{valueY}", color: "#aaa" })

        topChart.Create(topSeries);

        let rightChartProperties = {
            chartSubtitle: {
                text: "Count of trips"
            },
            useCursor: false,
            useTooltip: true,
            useBullets: false,
            orientation: 'horizontal',
            barThickness: 80,
            hideLabels: true,
            categoryAxisName: 'bName',
            labelTextTemplateOverride: '{categoryY} : {valueX}',
            numberFormat: '#,###.',
            valueAxisRenderInside: false,
            height: 600,
            stacked: false,

            hoverState: {
                isEnabled: true,
                hoverColor: "#FF8E07"
            }
        };

        let rightData = this.state.rightChartData;
        let rightSeries = [];

        let rightChart = new StackedColumnChart({ chartData: rightData, chartContainer: this.state.rightChartContainer, chartProperties: rightChartProperties });
        rightSeries.push({ valueAxisName: "bVal", label: "{valueX}", color: "#aaa" });
        rightChart.Create(rightSeries);

        //var rightChart = new ColumnChart2({
        //    chartContainer: this.state.rightChartContainer
        //});
        //rightChart.Create();

        //this.state.topChart.hiddenState.properties.opacity = 0; // this creates initial fade-in
        //this.state.mainChart.hiddenState.properties.opacity = 0; // this creates initial fade-in
        //this.state.rightChart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        
        //let interfaceColors = new am4core.InterfaceColorSet();

        // this.state.chart.legend = new am4charts.Legend();
        // this.state.chart.legend.position = "bottom";

        // scatter plot config begins

        // Create axes
        //let valueAxisX = this.state.mainChart.xAxes.push(new am4charts.ValueAxis());
        //valueAxisX.title.text = xAxisName;
        //valueAxisX.renderer.minGridDistance = 40;

        // Create value axis
        //let valueAxisY = this.state.mainChart.yAxes.push(new am4charts.ValueAxis());
        //valueAxisY.title.text = yAxisName;

        // Create series
        //let lineSeries = this.state.mainChart.series.push(new am4charts.LineSeries());
        //lineSeries.dataFields.valueY = "ay";
        //lineSeries.dataFields.valueX = "ax";
        //lineSeries.strokeOpacity = 0;

        // Add a bullet
        //let bullet = lineSeries.bullets.push(new am4charts.Bullet());

        // Add a triangle to act as am arrow
        //let arrow = bullet.createChild(am4core.Circle);
        //arrow.horizontalCenter = "middle";
        //arrow.verticalCenter = "middle";
        //arrow.strokeWidth = 0;
        //arrow.fill = this.state.mainChart.colors.getIndex(0);
        // arrow.direction = "top";
        //arrow.width = 12;
        //arrow.height = 12;

        /*
        // TODO: determine whether there is a second series by checking the data
        let lineSeries2 = this.state.mainChart.series.push(new am4charts.LineSeries());
        lineSeries2.dataFields.valueY = "by";
        lineSeries2.dataFields.valueX = "bx";
        lineSeries2.strokeOpacity = 0;



        // Add a bullet
        let bullet2 = lineSeries2.bullets.push(new am4charts.Bullet());
        
        // Add a triangle to act as an arrow
        let arrow2 = bullet2.createChild(am4core.Triangle);
        arrow2.horizontalCenter = "middle";
        arrow2.verticalCenter = "middle";
        arrow2.rotation = 180;
        arrow2.strokeWidth = 0;
        arrow2.fill = this.state.mainChart.colors.getIndex(3);
        arrow2.direction = "top";
        arrow2.width = 12;
        arrow2.height = 12;
        */


        //add the trendlines
        // for (let x = 0; x < trendlines.length; x++) {
        //     let trend = this.state.mainChart.series.push(new am4charts.LineSeries());
        //     trend.dataFields.valueY = trendlines[x].valueY;
        //     trend.dataFields.valueX = trendlines[x].valueX;
        //     trend.strokeWidth = 2
        //     trend.stroke = this.state.mainChart.colors.getIndex(0);
        //     trend.strokeOpacity = 0.7;
        //     trend.data = trendlines[x].data;
        // 
        // }

        // scatter plot config ends


        // right-hand axis begins

        //var sideCategoryAxis = this.state.rightChart.yAxes.push(new am4charts.CategoryAxis());
        //sideCategoryAxis.dataFields.category = "category";
        // sideCategoryAxis.renderer.grid.template.stroke = interfaceColors.getFor("background");
        // sideCategoryAxis.renderer.grid.template.strokeOpacity = 1;
        // sideCategoryAxis.renderer.grid.template.location = 1;
        //sideCategoryAxis.renderer.minGridDistance = 20;
        //sideCategoryAxis.renderer.grid.template.disabled = true;
        //sideCategoryAxis.renderer.baseGrid.disabled = true;
        //sideCategoryAxis.renderer.labels.template.disabled = true;
        //sideCategoryAxis.cursorTooltipEnabled = false;

        //let sideValueAxis = this.state.rightChart.xAxes.push(new am4charts.ValueAxis());
        //sideValueAxis.tooltip.disabled = true;
        //sideValueAxis.min = 0;
        //sideValueAxis.renderer.grid.template.disabled = true;
        //sideValueAxis.renderer.baseGrid.disabled = true;
        //sideValueAxis.renderer.labels.template.disabled = true;
        //sideValueAxis.cursorTooltipEnabled = false;

        // sideValueAxis.marginRight = 30;
        // sideValueAxis.renderer.gridContainer.background.fill = interfaceColors.getFor("alternativeBackground");
        // sideValueAxis.renderer.gridContainer.background.fillOpacity = 0.05;
        // sideValueAxis.renderer.grid.template.stroke = interfaceColors.getFor("background");
        // sideValueAxis.renderer.grid.template.strokeOpacity = 1;
        // sideValueAxis.title.text = "Side Axis";
         
        //let sideSeries = this.state.rightChart.series.push(new am4charts.ColumnSeries());
        //sideSeries.dataFields.categoryY = "category";
        //sideSeries.dataFields.valueX = "value";
        //sideSeries.xAxis = sideValueAxis;
        //sideSeries.name = "Side Series";
         
         // let sideValueAxisBullet = sideSeries.bullets.push(new am4charts.CircleBullet());
         // sideValueAxisBullet.fillOpacity = 0;
         // sideValueAxisBullet.strokeOpacity = 0;
         // sideValueAxisBullet.tooltipText = "{valueX.value}";
         // right-hand axis ends

        // top axis begins
        // this.state.topChart.leftAxesContainer.layout = "vertical";

        //var topCategoryAxis = this.state.topChart.xAxes.push(new am4charts.CategoryAxis());
        //topCategoryAxis.dataFields.category = "category";
        // topCategoryAxis.renderer.grid.template.stroke = interfaceColors.getFor("background");
        // topCategoryAxis.renderer.grid.template.strokeOpacity = 1;
        // topCategoryAxis.renderer.grid.template.location = 1;
        //topCategoryAxis.renderer.minGridDistance = 20;
        // topCategoryAxis.min = 0;
        //topCategoryAxis.renderer.grid.template.disabled = true;
        //topCategoryAxis.renderer.baseGrid.disabled = true;
        //topCategoryAxis.renderer.labels.template.disabled = true;
        //topCategoryAxis.cursorTooltipEnabled = false;

        //let topValueAxis = this.state.topChart.yAxes.push(new am4charts.ValueAxis());
        // topValueAxis.tooltip.disabled = true;
        // topValueAxis.renderer.baseGrid.disabled = true;
        // topValueAxis.marginRight = 30;
        // topValueAxis.renderer.gridContainer.background.fill = interfaceColors.getFor("alternativeBackground");
        // topValueAxis.renderer.gridContainer.background.fillOpacity = 0.05;
        // topValueAxis.renderer.grid.template.stroke = interfaceColors.getFor("background");
        // topValueAxis.renderer.grid.template.strokeOpacity = 1;
        // topValueAxis.title.text = "Top Axis";
        //topValueAxis.min = 0;
        //topValueAxis.renderer.grid.template.disabled = true;
        //topValueAxis.renderer.baseGrid.disabled = true;
        //topValueAxis.renderer.labels.template.disabled = true;
        //topValueAxis.cursorTooltipEnabled = false;

        //let topSeries = this.state.topChart.series.push(new am4charts.ColumnSeries());
        //topSeries.dataFields.categoryX = "category";
        //topSeries.dataFields.valueY = "value";
        //topSeries.yAxis = topValueAxis;
        //topSeries.name = "Top Series";
        
        // let topValueAxisBullet = topSeries.bullets.push(new am4charts.CircleBullet());
        // topValueAxisBullet.fillOpacity = 0;
        // topValueAxisBullet.strokeOpacity = 0;
        // topValueAxisBullet.tooltipText = "{valueX.value}";
        //  top axis ends

        /*

        if (schowScrollbars) {
            //scrollbars
            this.state.rightChart.scrollbarX = new am4core.Scrollbar();
            this.state.rightChart.scrollbarY = new am4core.Scrollbar();

        }
        */
    }


    public ReDraw(newdata: Array<{ dateTimeUtc: Date; value: number; }>) {
        // this.state.chart.data = newdata;
    }

}
