import * as React from 'react';

import {
    Row, Col, Card, CardBody, CardTitle, CardText,  CardDeck
} from 'reactstrap';

import { Link } from 'react-router-dom';
import { DropDownFilterBar } from '../../DropDownFilterBar'
import { InovexDropDown } from '../../InovexDropDown'
import '../Shared/images.css';

interface IDashboardProps {

}

interface IDashboardState {
}


export class UtilizationAnalysis extends React.Component<IDashboardProps, IDashboardState> {
    constructor(props: IDashboardProps) {
        super(props);


        this.state = {
        }
    }

    componentDidMount() {

    }

    async populateData() {
        // add any other methods that will populate data in the UI

    }




    async fetchData() {


    }




    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <nav className="dashboardTitle" aria-label="breadcrumb">
                            <div className="breadcrumb">
                                <Link className="breadcrumb-item" to="/duty-cycle" >Duty Cycle</Link>
                                <a className="breadcrumb-item" >Weight Analysis</a>
                            </div>
                        </nav>
                    </Col>
                </Row>
                <DropDownFilterBar />

                <Row>
                    <Col>
                        <h2>Asset Utilization & Days Out of Service</h2>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col>
                        <CardDeck>
                            <Card xs="12" className="card-highlight">
                                <CardBody>
                                    <CardTitle tag="h5">Asset Utilization & Days Out of Service</CardTitle>
                                    <CardText tag="div" id="weightAnalysisChartContainer" >
                                        <div id='utilization-charts' ></div>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </CardDeck>
                    </Col>
                </Row>
                <Row className="pb-0 mb-0">
                    <Col xs="2" className="tile p-0 mb-0">
                        <div className="text-white pb-0 mb-0 pl-2 dropdownFleetHealthGroupByToggleLabel">
                            <div className="mt-0 pt-0 pb-0" style={{ float: "left", width: "33%", lineHeight: "30px" }}>
                                GROUP BY
                            </div>
                            <div className="mt-1 mb-1" style={{ display: "inline-block", width: "1px", backgroundColor: "white", height: "70%", float: "left" }} />
                            <div className="mt-0 pt-0" style={{ float: "left", width: "66%", height: "100%" }}>
                                <InovexDropDown dropDownId="dropDownGroupBy"
                                    dropDownToggleId="dropdownFleetHealthGroupByToggleID"
                                    initialValue="Region"
                                    //onValueSelected={(trendValue: any) => this.changeTrendValue(trendValue)}
                                    dropDownStyle={{ width: "100%" }}
                                    omitSelected={false} >
                                    <span>VIN</span>
                                    <span>Region</span>
                                    <span>Total Mileage</span>
                                    <span>Engine Hours</span>
                                </InovexDropDown>

                            </div>

                        </div>

                    </Col>
                </Row>
                <Row className="pt-0 mt-0">
                    <Col className='tile pr-0 pl-0 pb-0 pt-0 mr-0'>
                        <div id='utilization-data-table' ></div>
                   </Col>
                </Row>
            </React.Fragment>
        );
    }
}
