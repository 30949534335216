import * as React from 'react';
import { Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup, Form, Input } from 'reactstrap';
import { Link, NavLink as RRNavLink, Redirect } from 'react-router-dom';
import '../Shared/styles.css';
import { FaChartArea, FaTools, FaMapMarked, FaTruckMoving, FaGasPump, FaStethoscope, FaCogs } from 'react-icons/fa';
import { BsBell, BsGear, BsSearch } from 'react-icons/bs';
import { InovexDropDown } from '../../InovexDropDown';
import { AlertBar } from '../../AlertBar';
import history from '../../../history';

interface INavTopProps {
    previousPage?: string;
}

interface INavTopState {
    isOpen: boolean;
    redirect: any | null;
}


export class NavTop extends React.Component<INavTopProps, INavTopState> {

    constructor(props: INavTopProps) {
        super(props);
        this.navigateTo = this.navigateTo.bind(this);
        this.fuelNav = this.fuelNav.bind(this);
        this.healthNav = this.healthNav.bind(this);
        this.dutyNav = this.dutyNav.bind(this);
        this.dropDownUpdate = this.dropDownUpdate.bind(this);
        this.state = {
            isOpen: false,
            redirect: null,
        };

    }

    dropDownUpdate() { //navbar in react strap used a custom componentShouldUpdate to be more efficient and not update on clicks. need click update to cycle dropdown css
        this.forceUpdate();
    }

    toggle = () => this.setState({ isOpen: !this.state.isOpen });

    navigateTo(to: string) {
        this.setState({ redirect: <Redirect to={to} /> }, () => { this.setState({ redirect: null }) });
        history.push(to);
    }

    //other styles located in custom.css under header "NavTop Styles"
    mainItemStyle: React.CSSProperties = { backgroundColor: "#444444", borderWidth: "0px", margin: "0px" };
    subItemStyle: React.CSSProperties = { backgroundColor: "#5b5e67", borderWidth: "0px", margin: "0px" };

    healthItemsStyles = [
        this.mainItemStyle,
        this.subItemStyle,
    ];

    healthNav(value: string) {
        let path = "";
        switch (value) {
            case "Overview":
                path = "/health-general";
                break;
            case "Aftertreatment":
                path = "/health-aftertreatment";
                break;
            default:
                path = "/health-general"
        }
        this.navigateTo(path);
    }

    fuelItemsStyles = [
        this.mainItemStyle,
        this.mainItemStyle,
        this.subItemStyle,
        this.subItemStyle,
        this.subItemStyle,

    ];

    fuelNav(value: string) {
        let path = "";
        console.log("fuelNav value");
        console.log(value);

        switch (value) {
            case "Overview":
                path = "/fuel";
                break;
            case "Fuel Economy Factors":
                path = "/fuel";
                break;
            case "Weight":
                path = "/fuel-weight";
                break;
            case "Driver Behaviors":
                path = "/fuel-behavior";
                break;
            case "Route":
                path = "/fuel-route";
                break;
            case "Vehicle Attributes":
                path = "/fuel-attribute";
                break;
            case "Total & Tractive Fuel":
                path = "/fuel-totaltractive";
                break;
            case "Sleeper Berth Gallons":
                path = "/fuel";
                break;
            default:
                path = "/fuel"
        }
        this.navigateTo(path);
    }

    dutyItemsStyles = [
        this.mainItemStyle,
        this.subItemStyle
    ];

    dutyNav(value: string) {
        let path = "";
        switch (value) {
            case "Overview":
                path = "/duty-cycle";
                break;
            case "Weight Analysis":
                path = "/duty-cycle-weight";
                break;
            default:
                path = "/duty-cycle"
        }
        this.navigateTo(path);
    }

    navItemSelectedStyle: React.CSSProperties = { borderTopColor: "#FF1D00", backgroundColor: "#5B5D66" }
    navItemUnSelectedStyle: React.CSSProperties = { borderTopColor: "#2E2E33", backgroundColor: "#2E2E33" }
    navDropDownSelectedStyle: React.CSSProperties = { backgroundColor: "#5B5D66" }
    navDropDownUnSelectedStyle: React.CSSProperties = { backgroundColor: "#2E2E33" }

    render() {
        return (
            <Navbar className='navbar-expand-sm navbar-toggleable-sm box-shadow mb-3' onClick={this.dropDownUpdate} style={{ backgroundColor: "#2E2E33", height: "67px" }}>
                <NavbarBrand tag={RRNavLink} to="/overview" style={{ marginRight:"0px" }}><div className="AptivLogo" /></NavbarBrand>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav navbar>
                        {this.state.redirect}
                        <NavItem className="navTopItem navTopOverview paddingTop20 pointer" style={((window.location.pathname.indexOf("/overview") !== -1 || window.location.pathname === "/") ? this.navItemSelectedStyle : this.navItemUnSelectedStyle)} onClick={() => { this.navigateTo("/overview") }}>
                            {/*<span>Overview</span>*/}
                            <NavLink tag={RRNavLink} to="/overview" style={{ padding: 0, margin: 0, border:0 }}>Overview</NavLink>
                        </NavItem>
                        <NavItem className="navTopItem navTopHealth" style={(window.location.pathname.indexOf("/health") !== -1 ? this.navItemSelectedStyle : this.navItemUnSelectedStyle)}>
                            <InovexDropDown dropDownId="healthTopNavDropDown" dropDownToggleId="healthTopNavDropDownToggle" dropDownToggleStyle={(window.location.pathname.indexOf("/health") !== -1 ? this.navDropDownSelectedStyle : this.navDropDownUnSelectedStyle)} staticTitle="HEALTH" initialValue="HEALTH" itemStyles={this.healthItemsStyles} activeEnabled={false} onValueSelected={(value: string) => { this.healthNav(value); }} oldDropDownFormat={true} hover={true} hideCaret={true} onToggleClicked={() => { this.healthNav("Overview") }}>
                                <span>Overview</span>
                                <span>Aftertreatment</span>
                            </InovexDropDown>
                        </NavItem>
                        <NavItem className="navTopItem navTopFuel" style={(window.location.pathname.indexOf("/fuel") !== -1 ? this.navItemSelectedStyle : this.navItemUnSelectedStyle)}>
                            <InovexDropDown dropDownId="fuelTopNavDropDown" dropDownToggleId="fuelTopNavDropDownToggle" dropDownToggleStyle={(window.location.pathname.indexOf("/fuel") !== -1 ? this.navDropDownSelectedStyle : this.navDropDownUnSelectedStyle)} staticTitle="FUEL" initialValue="FUEL" itemStyles={this.fuelItemsStyles} activeEnabled={false} onValueSelected={(value: string) => { this.fuelNav(value); }} oldDropDownFormat={true} hover={true} hideCaret={true} onToggleClicked={() => { this.fuelNav("Overview") }}>
                                <span>Overview</span>
                                <span>Fuel Economy Factors</span>
                                <span>Weight</span>
                                <span>Driver Behaviors</span>
                                <span>Route</span>
                                <span>Total & Tractive Fuel</span>
                            </InovexDropDown>
                        </NavItem>
                        <NavItem className="navTopItem navTopUtilization paddingTop20 pointer" style={(window.location.pathname.indexOf("/utilization") !== -1 ? this.navItemSelectedStyle : this.navItemUnSelectedStyle)} onClick={() => { this.navigateTo("/utilization") }}>
                            <span>Utilization</span>
                            {/*<NavLink activeClassName="active" tag={RRNavLink} title="Fleet Safety" to="/fleet-safety">Safety</NavLink>*/}
                        </NavItem>
                        {/*<NavItem>*/}
                        {/*    <NavLink activeClassName="active" tag={RRNavLink} title="Asset Utilization" to="/asset-utilization">Usage</NavLink>*/}
                        {/*</NavItem>*/}
                        <NavItem className="navTopItem navTopDuty" style={(window.location.pathname.indexOf("/duty-cycle") !== -1 ? this.navItemSelectedStyle : this.navItemUnSelectedStyle)}>
                            <InovexDropDown dropDownId="dutyTopNavDropDown" dropDownToggleId="dutyTopNavDropDownToggle" dropDownToggleStyle={(window.location.pathname.indexOf("/duty-cycle") !== -1 ? this.navDropDownSelectedStyle : this.navDropDownUnSelectedStyle)} staticTitle="DUTY CYCLE" initialValue="DUTY CYCLE" itemStyles={this.dutyItemsStyles} activeEnabled={false} onValueSelected={(value: string) => { this.dutyNav(value); }} oldDropDownFormat={true} hover={true} hideCaret={true} onToggleClicked={() => { this.dutyNav("Overview") }}>
                                <span>Overview</span>
                                <span>Weight Analysis</span>
                            </InovexDropDown>
                        </NavItem>
                    </Nav>
                </Collapse>
                <div className="iconGroupStyle d-flex justify-content-around align-items-center">
                    <div className="iconStyle">
                        <BsSearch size="25px" />
                    </div>
                    {/*<div className="iconStyle">*/}
                    {/*    <BsBell size="20px" />*/}
                    {/*</div>*/}
                    <AlertBar iconClassName="iconStyle pointer" />
                    <div className="iconStyle">
                        <BsGear size="25px" />
                    </div>
                </div>
            </Navbar>
        );
    }
}
