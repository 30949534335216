import * as React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

interface IChartState {
    chartData: Array<{ dateTimeUtc: Date; value: number; }>;
    chart: am4charts.XYChart;
    chartContainer: string;
    orientation: am4core.PointerOrientation;
    useCursor: boolean;
    hideValueAxisLabels: boolean;
    columnColour: string;
    roundedTopCorners: boolean;
    showGrid: boolean;
    columnWidth: number;
}

interface IChartProps {
    chartData: Array<{ dateTimeUtc: Date; value: number; }>;
    chartContainer: string;
    orientation: am4core.PointerOrientation;
    useCursor: boolean;
    hideValueAxisLabels: boolean;
    columnColour: string;
    roundedTopCorners: boolean;
    showGrid: boolean;
    columnWidth: number;
    toolTipFormat?: string;
    minGridDistance?: number; //this distance is in pixels, not values of charts
}

export default class ColumnChart extends React.Component<IChartProps, IChartState> {
    constructor(props: IChartProps) {
        super(props);
        this.state =
        {
            useCursor: true,
            hideValueAxisLabels: props.hideValueAxisLabels,
            orientation: props.orientation,
            chartData: props.chartData,
            chartContainer: props.chartContainer,
            chart: am4core.create(props.chartContainer, am4charts.XYChart),
            columnColour: props.columnColour,
            roundedTopCorners: props.roundedTopCorners,
            showGrid: props.showGrid,
            columnWidth: props.columnWidth,
        }
    }

    public Create() {
        console.log("Create ColumnChart");
        am4core.useTheme(am4themes_animated);
        am4core.addLicense("CH308822591");
        this.state.chart.data = this.state.chartData;
        this.state.chart.padding(15, 10, 0, 10);

        var dateAxis = this.state.chart.xAxes.push(new am4charts.DateAxis());
        var valueAxis = this.state.chart.yAxes.push(new am4charts.ValueAxis());

        let series = this.state.chart.series.push(new am4charts.ColumnSeries());

        switch (this.state.orientation) {
            case 'horizontal':
                series.dataFields.valueX = "value";      // valueX
                series.dataFields.dateY = "dateTimeUtc"; // dateY
                break;
            default: // vertical
                series.dataFields.valueY = "value";      // valueY
                series.dataFields.dateX = "dateTimeUtc"; // dateX
                break;
        }

        series.fill = am4core.color(this.props.columnColour);
        //This is for the outline fo the bars (May need to include a optional prop and defualt for this in the future)
        series.stroke = am4core.color("#ffffff");
        series.strokeWidth = 0.1;

        // if (dateAxis.renderer!== undefined)
            dateAxis.renderer.tooltip.disabled = true;
        
        //console.log("rounded cornders: " + this.props.roundedTopCorners)
        if (this.props.roundedTopCorners) {
            series.columns.template.column.cornerRadiusTopRight = 5;
            series.columns.template.column.cornerRadiusTopLeft = 5;
        }

        if (this.state.hideValueAxisLabels) {
            //valueAxis.renderer.grid.template.disabled = true;
            valueAxis.renderer.baseGrid.disabled = true;
            valueAxis.renderer.labels.template.disabled = true;
            valueAxis.cursorTooltipEnabled = false;
        }

        if (!this.props.showGrid)
            valueAxis.renderer.grid.template.strokeWidth = 0;

        if (this.props.minGridDistance)
            valueAxis.renderer.minGridDistance = this.props.minGridDistance;        
        
        dateAxis.renderer.labels.template.disabled = true;
        dateAxis.renderer.labels.template.fontSize = 15;
        dateAxis.renderer.labels.template.padding(5, 0, 0, 0);
        dateAxis.renderer.grid.template.strokeWidth = 0; //remove vertical grid lines

        var minRange = dateAxis.axisRanges.create();
        minRange.maxPosition = 1;
        minRange.minPosition = 0;
        minRange.label.horizontalCenter = "left"
        minRange.label.paddingLeft = 0;

        var maxRange = dateAxis.axisRanges.create();
        // this overrides minLabelPosition/maxLabelPosition so that the range labels would be visible
        maxRange.maxPosition = 1;
        maxRange.minPosition = 0;
        maxRange.label.horizontalCenter = "right"
        maxRange.label.paddingRight = 0;

        dateAxis.events.on("startendchanged", updateRangeLabels)
        dateAxis.events.on("extremeschanged", updateRangeLabels)

        function updateRangeLabels() {
            minRange.value = dateAxis.min + dateAxis.start * (dateAxis.max - dateAxis.min);
            minRange.label.text = dateAxis.dateFormatter.format(minRange.value, "MMM");

            maxRange.value = dateAxis.min + dateAxis.end * (dateAxis.max - dateAxis.min);
            maxRange.label.text = dateAxis.dateFormatter.format(maxRange.value, "MMM");
        }
        // series.name = "value#1";
        if (this.props.toolTipFormat == "X:Y") {
            series.tooltipText = "[font-size:14px]{dateX}: [bold]{valueY}[/]";
        }
        else {
            series.tooltipText = "[font-size:14px]{valueY}[/]"
        }

        // Drop-shaped tooltips
        
        series.tooltip.background.cornerRadius = 20;
        series.tooltip.background.strokeOpacity = 0;
        series.tooltip.pointerOrientation = this.state.orientation;
        series.tooltip.label.minWidth = 40;
        series.tooltip.label.minHeight = 40;
        series.tooltip.label.textAlign = "middle";
        series.tooltip.label.textValign = "middle";

        if (this.state.useCursor) {
            // Make a panning cursor
            this.state.chart.cursor = new am4charts.XYCursor();
            this.state.chart.cursor.behavior = "panXY";
            this.state.chart.cursor.xAxis = dateAxis;
            //this.state.chart.cursor.yAxis = dateAxis; // valueAxis;
            this.state.chart.cursor.snapToSeries = series;
            
        }

        // set the column width
        series.columns.template.width = am4core.percent(this.props.columnWidth);
    }

    public CreateBulletLabels(series: am4charts.LineSeries) {
        // have the value show as a label on the first and last data points
        let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{value}";
        labelBullet.disabled = true;
        labelBullet.propertyFields.disabled = "disabled";
        labelBullet.label.dy = -20;
        labelBullet.label.fontSize = "12";

    }



    public ReDraw(newdata: Array<{ dateTimeUtc: Date; value: number; }>) {
        this.state.chart.data = newdata;
    }
}
