import * as React from 'react';

import '../../DataTable/Shared/styles.css'

import { BsCircleHalf, BsCircleFill, BsCircle } from 'react-icons/bs';

export default (props) => {

    let statusIcon;
    let severity = "";
    
    if (props.value !== undefined) {
        switch (props.value) {
            case "High":
                statusIcon = <BsCircleFill className='severity-high' fontSize='18' />
                break;

            case "Low":

                statusIcon = <BsCircle className='severity-low' fontSize='18' />
                break;

            case "Medium":
                statusIcon = <BsCircleHalf className='severity-medium' fontSize='18' />
                break;
            default:
        }

        severity = props.value
        
    }
    return (
        <div>
            <div className="dt-vals-total">
                <div style={{ float: "left" }} className="mr-2 pr-2">{statusIcon}</div>
                <div style={{ float: "left" }}>{severity}</div>

                
            
            </div>
            
        </div>

    );
};


