import * as React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

interface IChartState {
    chartData: Array<{ dateTimeUtc: Date; value: number; }>;
    chart: am4charts.XYChart;
    chartContainer: string;
}

interface IChartProps {
    chartData: Array<{ dateTimeUtc: Date; value: number; }>;
    chartContainer: string;
    strokeColor?: string;
    bulletColor?: string;
    minGridDistance?: number; 
    // rangeValues: Array<{ value: number; endValue: number; colour: string;  }>;

    // range.value = 0;
    // range.endValue = 33;
    // range.axisFill.fill = am4core.color("red");
    // range.axisFill.fillOpacity = 0.15;

}

export default class SparkLineChart extends React.Component<IChartProps, IChartState> {

    constructor(props: IChartProps) {
        super(props);
        this.state =
        {
            chartData: props.chartData,
            chartContainer: props.chartContainer,
            chart: am4core.create(props.chartContainer, am4charts.XYChart)
        }
    }


    public Create(labelValue: string, hideGrid: boolean = false, hideLabels: boolean = false)
    {
        am4core.useTheme(am4themes_animated);
        am4core.addLicense("CH308822591");
        this.state.chart.layout = "grid";
        this.state.chart.fixedWidthGrid = false;
        this.state.chart.width = am4core.percent(100);
        this.state.chart.height = am4core.percent(100);
        this.state.chart.maskBullets = false;
        this.state.chart.padding(15, 10, 0, 10);

        // Color set
        let colors = new am4core.ColorSet();
        let color = colors.getIndex(0);
        let bulletColor = colors.getIndex(1);

        // Set input format for the dates
        this.state.chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
        this.state.chart.dateFormatter.outputFormat = "yyyy-MM-dd";
        this.state.chart.dateFormatter.dateFormat = "yyyy-MM-dd";
        //console.log("original data");
        //console.log(this.state.chartData);

        // get the minimum value
        //let minimumValue = Math.min.apply(Math, this.state.chartData.map(function (o) { return o.value; }));
        //let maximumValue = Math.max.apply(Math, this.state.chartData.map(function (o) { return o.value; }));

        // convert the data that was submitted as 'date|value' to 'date|value|disabled' to fit the sparkline format
        let massagedData = this.state.chartData.map((data) => {
            return {
                dateTimeUtc: data.dateTimeUtc,
                value: data.value,
                // disabled: data.value === minimumValue || data.value === maximumValue ? false: true,
                disabled: true
            }
        })
        if (massagedData.length > 0) {
            massagedData[0].disabled = false;
            massagedData[massagedData.length - 1].disabled = false;
        }

        //console.log("massaged data");
        //console.log(massagedData);

        this.state.chart.data = massagedData; //this.state.chartData;
        //console.log(this.state.chartData);
        // Create axes
        let dateAxis = this.state.chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.renderer.baseGrid.disabled = true;
        dateAxis.renderer.grid.template.strokeWidth = 0; //remove vertical grid lines

        if (hideLabels) {
            dateAxis.renderer.labels.template.disabled = true;
        }
        else {
            dateAxis.renderer.labels.template.disabled = true;
            dateAxis.startLocation = 0.5;
            dateAxis.endLocation = 0.5;
            dateAxis.cursorTooltipEnabled = false;
            dateAxis.dateFormatter = new am4core.DateFormatter();
            dateAxis.dateFormatter.dateFormat = "MMM";
            dateAxis.renderer.labels.template.fontSize = 15;
            dateAxis.renderer.labels.template.padding(5, 0, 0, 0);

            var minRange = dateAxis.axisRanges.create();
            minRange.maxPosition = 1;
            minRange.minPosition = 0;
            minRange.label.horizontalCenter = "left"
            minRange.label.paddingLeft = 0;

            var maxRange = dateAxis.axisRanges.create();
            // this overrides minLabelPosition/maxLabelPosition so that the range labels would be visible
            maxRange.maxPosition = 1;
            maxRange.minPosition = 0;
            maxRange.label.horizontalCenter = "right"
            maxRange.label.paddingRight = 0;

            dateAxis.events.on("startendchanged", updateRangeLabels)
            dateAxis.events.on("extremeschanged", updateRangeLabels)
        }

        

        function updateRangeLabels() {
            minRange.value = dateAxis.min + dateAxis.start * (dateAxis.max - dateAxis.min);
            minRange.label.text = dateAxis.dateFormatter.format(minRange.value, "MMM");

            maxRange.value = dateAxis.min + dateAxis.end * (dateAxis.max - dateAxis.min);
            maxRange.label.text = dateAxis.dateFormatter.format(maxRange.value, "MMM");
        }


        //dateAxis.renderer.labels.template.propertyFields.disabled = "disabled";

        let valueAxis = this.state.chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.renderer.grid.template.disabled = hideGrid;
        valueAxis.renderer.baseGrid.disabled = hideGrid;
        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.cursorTooltipEnabled = false;
        if (this.props.minGridDistance)
            valueAxis.renderer.minGridDistance = this.props.minGridDistance;
        

        // let label = this.state.chart.chartContainer.createChild(am4core.Label);
        // label.text = labelValue;
        // label.align = "center";

        // Create series
        let series = this.state.chart.series.push(new am4charts.LineSeries());
        series.tooltipText = "{date}: [bold]{value}";
        series.dataFields.valueY = "value";
        series.dataFields.dateX = "dateTimeUtc";
        series.tensionX = 0.8;
        series.strokeWidth = 2;

        if (this.props.strokeColor) {
            series.stroke = am4core.color(this.props.strokeColor)
            series.fill = am4core.color(this.props.strokeColor)
        }
        else
            series.stroke = color;
        
        // Drop-shaped tooltips
        series.tooltip.background.cornerRadius = 20;
        series.tooltip.background.strokeOpacity = 0;
        series.tooltip.pointerOrientation = "vertical";
        series.tooltip.label.minWidth = 40;
        series.tooltip.label.minHeight = 40;
        series.tooltip.label.textAlign = "middle";
        series.tooltip.label.textValign = "middle";

        // TODO: we need to find a way to make these dynamic and dependant on the metric. Nice and easy :D

        // render data points as bullets
        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.opacity = 1;
        bullet.circle.propertyFields.opacity = "opacity";
        bullet.circle.radius = 3;
        bullet.disabled = true;
        bullet.propertyFields.disabled = "disabled";
        bullet.dateFormatter = new am4core.DateFormatter();
        bullet.dateFormatter.dateFormat = "yyyy-MM-dd";
        //bullet.tooltipText = "{dateTimeUtc.formatDate('yyyy-MM-dd')}\n[bold]{value}[/]";
        bullet.tooltipText = "{value}";
        if (this.props.bulletColor) {
            bullet.circle.fill = am4core.color(this.props.bulletColor);//color taken from props
            bullet.circle.stroke = am4core.color(this.props.bulletColor);
        }
        else
            bullet.circle.fill = color;
        

        let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{value}";
        labelBullet.disabled = true;
        labelBullet.propertyFields.disabled = "disabled";
        labelBullet.label.dy = -10;
        labelBullet.label.fontSize = "9";
        
    }


}
