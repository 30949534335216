import * as React from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import VehicleCellRenderer from '../CellRenderers/vehicleFuelCellRenderer'
import BarCellRenderer from '../CellRenderers/barCellRenderer'
import VehicleSeverityCellRenderer from '../CellRenderers/vehicleHealthSeverityCellRenderer';

import { BsCircleHalf, BsCircleFill, BsCircle } from 'react-icons/bs';

import '../../DataTable/Shared/styles.css'
import '../../DataTable/Shared/ag-theme-alpine.css';
import '../../DataTable/Shared/ag-grid.css';

import { DiagnosticsSubnav } from '../../Health/DiagnosticsSubNav';



interface IDataTableProps {
    columns: Array<{ columnName: string }>
    rowData: any;
}

interface IDataTableState {
    loading: boolean;
    columns: Array<{ columnName: string }>;
    rowData: any;
    highCount: number;
    mediumCount: number;
    lowCount: number;
    gridApi: any;
    gridColumnApi: any;
    severity: string;
    status: string;

}

// All the docs are here: 
// https://www.ag-grid.com/react-data-grid

export class DataTable extends React.Component<IDataTableProps, IDataTableState> {
    constructor(props: IDataTableProps) {
        super(props);
        this.state =
        {
            loading: true,
            columns: [],
            rowData: null,
            highCount: 0,
            mediumCount: 0,
            lowCount: 0,
            gridApi: null,
            gridColumnApi: null,
            severity: 'all',
            status: 'all'

        };

    }

    componentDidMount() {

        // this.setState(
        //     {
        //         highCount: highCount,
        //         mediumCount: mediumCount,
        //         lowCount: lowCount
        //     }, this.render);
    }


    render() {
        // console.log(this.props.rowData);
        let highCount = this.props.rowData.filter(x => x.severity === 'High').length;
        let mediumCount = this.props.rowData.filter(x => x.severity === 'Medium').length;
        let lowCount = this.props.rowData.filter(x => x.severity === 'Low').length;

        const dateCellRenderer = params => {
            let returnValue = '';

            if (params.value !== undefined) {
                //console.log('dateCellRenderer');
                //console.log(params);

                var theDate = new Date(params.value);

                returnValue = theDate.toLocaleDateString("en-US");


            }

            return returnValue;
        };



        const onGridReady = (params) => {
            //console.log('onGridReady severity:' + this.state.severity);

            this.setState({
                gridApi: params.api,
                gridColumnApi: params.columnApi
            })


            const updateData = (data) => {
                // document.querySelector('#everyone').checked = true;
                // setRowData(data);
            };

            // fetch('https://www.ag-grid.com/example-assets/olympic-winners.json')
            //     .then((resp) => resp.json())
            //     .then((data) => updateData(data));
        };

        const dynamicCellStyle = params => {
            let style = { borderLeft: '0px !important', paddingTop: '4px', backgroundColor:'#ffffff'};
            switch (params.colDef.field) {
                // case 'vehicle': style.width = '10%'; break;
                // case 'totalConsumption': style.width = '15%'; break;
                // default: style.width = '25%'; break;
            }


            // if (params.rowIndex == 0) {
            //    style.height = '50px'
            // }
            return style;
        };


        const onFirstDataRendered = (params) => {
            sizeColumnsToFit(params);
        };

        const sizeColumnsToFit = (params) => {
            params.api.sizeColumnsToFit();
        };

        const getRowHeight = params => {
            // return params.node.rowIndex == 0 ? 50 : 40
            return 50;
        };

        const getRowClass = params => {
            // if (params.node.rowIndex === 0) {
            //    return 'borderBottomSilver';
            // }
            return '';
        };

        const severityFilterChanged = (button, newValue) => {
            //console.log('severityFilterChanged severity:' + this.state.severity);
            //console.log(button);

            // Remove the 'active' class from the other filter buttons.
            // Not crazy about toggling the class names like this. I'm sure there's a more React-ey way
            document.querySelectorAll('.buttons button').forEach(function (el) {
                el.classList.remove('active');
            });

            if (this.state.severity === newValue) {
                    newValue = 'all';
                    button.currentTarget.className = '';
            }
            else
                button.currentTarget.className = 'active'



            this.setState({ severity: newValue },
                () => this.state.gridApi.onFilterChanged()
            );
        };


        const statusFilterChanged = (newValue) => {
            console.log('statusFilterChanged severity:' + this.state.severity);
           // status = newValue;
            // console.log(this.state.gridApi);
            this.state.gridApi.onFilterChanged();
        };

        const isExternalFilterPresent = () => {
            return this.state.severity !== 'all'; // && status !== 'all';
        };

        const doesExternalFilterPass = (node) => {
            //console.log('doesExternalFilterPass severity:' + this.state.severity);
            //if (severity !== 'all')
            switch (this.state.severity) {
                    case 'High':
                        return node.data.severity === 'High';
                    case 'Medium':
                        return node.data.severity === 'Medium';
                    case 'Low':
                        return node.data.severity === 'Low';
                    default:
                        return true;
                }

           // if (status !== 'all')
           //     switch (status) {
           //         case 'Active':
           //             return node.data.status === 'Active';
           //         case 'Pending':
           //             return node.data.status === 'Pending';
           //         case 'Cleared':
           //             return node.data.status === 'Cleared';
           //         default:
           //             return true;
           //     }

        };


        return (
            <React.Fragment>
                <div className="filterButtons round">
                    <div className="title">Diagnostics Trouble Codes</div>
                    <div className="buttons">
                        <button onClick={(button) => severityFilterChanged(button, 'High')} ><BsCircleFill className="severity-high" />HIGH {highCount}</button>
                        <button onClick={(button) => severityFilterChanged(button, 'Medium')}><BsCircleHalf className="severity-medium" />Medium {mediumCount}</button>
                        <button onClick={(button) => severityFilterChanged(button, 'Low')}><BsCircle className="severity-low" />Low {lowCount}</button>
                    </div>
                </div>
                {/*<div>*/}
                {/*    <DiagnosticsSubnav pageTitle="Diagnostics" />*/}
                {/*</div>*/}
                <br />
                <div
                    id="myGrid"
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                    className="ag-theme-alpine cellsWithBottomBorder1px m-0"
                >
                    
                    <AgGridReact
                        frameworkComponents={{
                            vehicleSeverityCellRenderer: VehicleSeverityCellRenderer,
                        }}
                        onGridReady={onGridReady}
                        animateRows={true}
                        isExternalFilterPresent={isExternalFilterPresent}
                        doesExternalFilterPass={doesExternalFilterPass}
                        rowClass="datatable-row"
                        getRowHeight={getRowHeight}
                        getRowClass={getRowClass}
                        onFirstDataRendered={onFirstDataRendered}
                        onGridSizeChanged={sizeColumnsToFit}
                        rowData={this.props.rowData}
                        pagination={true}
                        paginationPageSize={10}

                    >


                        <AgGridColumn maxWidth={150} field='code' headerName='DTC OEM/ASPN/FMI' headerClass='FuelEconDataTableHeader' cellStyle={dynamicCellStyle} />
                        <AgGridColumn maxWidth={350} wrapText={true} field='description' headerName='Description' headerClass='FuelEconDataTableHeader' cellStyle={dynamicCellStyle} />
                        <AgGridColumn maxWidth={200} field='severity' headerName='Severity' headerClass='FuelEconDataTableHeader' filter="agTextColumnFilter" cellStyle={dynamicCellStyle} cellRenderer={"vehicleSeverityCellRenderer"}/>
                        {/*<AgGridColumn maxWidth={110} field='module' headerName='Module' headerClass='FuelEconDataTableHeader' cellStyle={dynamicCellStyle} />*/}
                        <AgGridColumn maxWidth={100} field='lampOn' headerName='Lamp On' headerClass='FuelEconDataTableHeader' cellStyle={dynamicCellStyle} />
                        <AgGridColumn maxWidth={100} field='lampOff' headerName='Lamp Off' headerClass='FuelEconDataTableHeader' cellStyle={dynamicCellStyle} />
                        <AgGridColumn maxWidth={120} field='first' headerName='First' cellRenderer={dateCellRenderer} headerClass='FuelEconDataTableHeader' cellStyle={dynamicCellStyle} />
                        <AgGridColumn maxWidth={120} field='last' headerName='Last' cellRenderer={dateCellRenderer} headerClass='FuelEconDataTableHeader' cellStyle={dynamicCellStyle} />

                    </AgGridReact>
                </div>

            </React.Fragment>
        )
    }


}
