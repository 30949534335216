import * as React from "react";
// import "../assets/Login.css";
import { Redirect } from "react-router-dom";


interface ILoginState {
    loginParams: {
        UserName: string,
        UserPassword: string
    }
    islogged: boolean;
}



class Login extends React.Component<{}, ILoginState> {
    constructor(props) {
        super(props);
        console.log('login constructor ' + localStorage.getItem("token"));
        this.state = {
            islogged: false,
            loginParams: {
                UserName: "",
                UserPassword: ""
            }
        };
    }
    handleFormChange = event => {
        let loginParamsNew = { ...this.state.loginParams };
        let val = event.target.value;
        loginParamsNew[event.target.name] = val;
        this.setState({
            loginParams: loginParamsNew
        });
    };

    login = event => {

        fetch('/api/Login/Login/' + this.state.loginParams.UserName + '/' + this.state.loginParams.UserPassword)
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                localStorage.setItem("token", data);
                this.setState({ islogged: data }, () => this.render());

            })
            .catch((error: Error) => {
                throw error
            });

        event.preventDefault();
    };
    render() {

        if (localStorage.getItem("token") == "true") {
            // console.log('redirecting to home' + localStorage.getItem("token"));
            return <Redirect to="/" />;
        }
        // console.log('login render ' + localStorage.getItem("token"));

        return (
            <div className="container">
	            <div className="d-flex justify-content-center h-100">
		            <div className="card">
			            <div className="card-header">
				            <h3>Sign In</h3>
			            </div>
			            <div className="card-body">
                            <form onSubmit={this.login} className="form-signin">
					            <div className="input-group form-group">
						            <div className="input-group-prepend">
							            <span className="input-group-text"><i className="fas fa-user"></i></span>
						            </div>
                                    <input type="text" name="UserName" className="form-control" placeholder="username" onChange={this.handleFormChange} />
						
					            </div>
					            <div className="input-group form-group">
						            <div className="input-group-prepend">
							            <span className="input-group-text"><i className="fas fa-key"></i></span>
						            </div>
                                    <input type="password" name="UserPassword" className="form-control" placeholder="password" onChange={this.handleFormChange} />
					            </div>

					            <div className="form-group">
						            <input type="submit" value="Login" className="btn float-right login_btn" />
					            </div>
				            </form>
			            </div>
			            <div className="card-footer">

			            </div>
		            </div>
	            </div>
















            </div>
        );
    }
}
export default Login;