import * as React from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import VehicleCellRenderer from '../CellRenderers/vehicleHealthCellRenderer';
import BarCellRenderer from '../CellRenderers/barCellRenderer';


import { LineChart } from '../../Charts';

import '../../DataTable/Shared/styles.css';
import '../../DataTable/Shared/ag-theme-alpine.css';
import '../../DataTable/Shared/ag-grid.css';
import { isNullOrUndefined } from 'util';


interface IDataTableProps {
    columns: Array<{ columnName: string, columnHeader: string, width: number, minBarPercentDisplay?: number; }>;
    rowData: any;
}

interface IDataTableState {
    loading: boolean;
    columns: Array<{ columnName: string, minBarPercentDisplay: number; }>;
    rowData: any;
    totalConsumptionLineChart: LineChart | null;
    idleGallonsLineChart: LineChart | null;
    vehicleAccelerationLineChart: LineChart | null;
    highwaySpeedLineChart: LineChart | null;
}

// All the docs are here:
// https://www.ag-grid.com/react-data-grid

export class DataTable extends React.Component<IDataTableProps, IDataTableState> {
    constructor(props: IDataTableProps) {
        super(props);
        this.state =
        {
            loading: true,
            columns: [],
            rowData: null,
            totalConsumptionLineChart: null,
            idleGallonsLineChart: null,
            vehicleAccelerationLineChart: null,
            highwaySpeedLineChart: null
        };

    }

    componentDidMount() {
        // fetch('/api/Fuel/FuelEconByBehaviour/2021-07-01/2021-07-31')
        //     .then(response => {
        //         if (!response.ok) {
        //             throw new Error(response.statusText)
        //         }
        //         return response.json()
        //     })
        //     .then(data => {
        //         this.populateCharts(data)
        //     })
        //     .catch((error: Error) => {
        //         throw error
        //     });

    }

    /*
    public populateCharts(json: any) {
        let totalConsumptionLineChart = new LineChart({ useCursor: true, chartData: json.totalConsumption, chartContainer: 'TotalConsumptionChart' });
        let idleGallonsLineChart = new LineChart({ useCursor: true, chartData: json.idleGallons, chartContainer: 'IdleGallonsChart' });
        let vehicleAccelerationLineChart = new LineChart({ useCursor: true, chartData: json.vehicleAcceleration, chartContainer: 'VehicleAccelerationChart' });
        let highwaySpeedLineChart = new LineChart({ useCursor: true, chartData: json.highwaySpeed, chartContainer: 'HighwaySpeedChart' });

        totalConsumptionLineChart.Create('', '', 'a', null, null);
        idleGallonsLineChart.Create('', 'idle gal', '', null, null);
        vehicleAccelerationLineChart.Create('', 'accel impact %', '', null, null);
        highwaySpeedLineChart.Create('', 'hgwy_frac_impact_mpg', '', null, null);

        this.setState(
            {
                totalConsumptionLineChart: totalConsumptionLineChart,
                idleGallonsLineChart: idleGallonsLineChart,
                vehicleAccelerationLineChart: vehicleAccelerationLineChart,
                highwaySpeedLineChart: highwaySpeedLineChart
        });

    }
    */
    public getRowData() {
        this.props.rowData.shift(); // drop the first element because it will be pinned
        return this.props.rowData as any[];
    };

    public getPinnedRowData() {
        return [this.props.rowData[0]];
    };

    render() {
        // console.log(this.props.rowData);

        const dynamicCellStyle = params => {
            let style = { width: '', height: '', paddingTop: '', textAlign: '', display: '' };

            if (params.node.rowPinned === 'top' || params.node.rowIndex === 0) {
                switch (params.colDef.field) {
                    case 'vehicle':
                        style.paddingTop = '0.3rem';
                        break;
                    case 'dutyCycle':
                        style.display = 'block';
                        style.paddingTop = '0.2rem';
                        break;
                    case 'fuelEconomy':
                    case 'highwaySpeed':
                    case 'citySpeed':
                    case 'weightImpactAvgMpg':
                        style.display = 'block';
                        break;
                    // case 'totalConsumption':
                    //     style.width = '15%';
                    //     break;
                    // case 'milesDriven':
                    // case 'totalConsumption':
                    // case 'averageGrossWeightInHauling':
                    // case 'gallonsSavedFromWeight':
                    //     style.width = '10%';
                    //     style.paddingTop = '4px';
                    //     break;
                    default:
                        style.paddingTop = '0.5rem';
                        style.textAlign = 'center';
                        break;
                }
            }
            else {
                switch (params.colDef.field) {
                    case 'vehicle':
                        style.paddingTop = '0.3rem';
                        break;
                    case 'weightImpactSum':
                    case 'overallFuelEconomyAvg':
                        style.display = 'block';
                        style.paddingTop = '0.3rem';
                        break;
                    case 'dutyCycle':
                        style.display = 'block';
                        style.paddingTop = '0rem';
                        break;
                    case 'fuelEconomy':
                    case 'highwaySpeed':
                    case 'citySpeed':
                    case 'weightImpactAvgMpg':
                        style.display = 'block';
                        break;
                    // case 'totalConsumption':
                    //     style.width = '15%';
                    //     break;
                    // case 'milesDriven':
                    // case 'totalConsumption':
                    // case 'averageGrossWeightInHauling':
                    // case 'gallonsSavedFromWeight':
                    //     style.width = '10%';
                    //     style.paddingTop = '4px';
                    //     break;
                    default:
                        style.textAlign = 'center';
                        style.paddingTop = '0rem';
                        break;
                }
            }
            return style;
        };


        const totalConsumptionCellRenderer = params => {
            let fontSize = params.node.rowPinned === "top" ? '16px' : '14px';
            let returnValue = '';
            if (params.value !== undefined) {
                console.log('totalConsumptionCellRenderer');
                console.log(params);
                returnValue = '<div style="line-height:20px; font-size:' + fontSize + ';">' + params.value.total;
                if (params.value.delta !== undefined) {
                    returnValue += '<br />(' + params.value.delta + ' gal)';
                }
            }

            return returnValue + '</div>';
        };

        const onFirstDataRendered = (params) => {
            sizeColumnsToFit(params);
            // console.log(params);
            let bodyWidth = params.columnApi.columnModel.bodyWidth;
            bodyWidth -= 300;
        };

        const sizeColumnsToFit = (params) => {
            params.api.sizeColumnsToFit();
        };

        const getRowHeight = params => {
            return params.node.rowPinned === "top" ? 60 : 50
        };

        const getRowClass = params => {
            if (params.node.rowPinned === "top") {
                return 'borderBottomSilver';
            }
        };

        const valueComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
            //console.log(valueA);
            if (isNullOrUndefined(valueA) || isNullOrUndefined(valueB)) return 0;

            if (!isNullOrUndefined(valueA.total) && !isNullOrUndefined(valueB.total)) {
                if (valueA.total == valueB.total) return 0;
                return (valueA.total > valueB.total) ? 1 : -1;
            }
            else {
                if (valueA == valueB) return 0;
                return (valueA > valueB) ? 1 : -1;
            }
        };

        const fleetNameCompare = (valueA, valueB, nodeA, nodeB, isInverted) => {
            if (isNullOrUndefined(valueA) || isNullOrUndefined(valueB)) return 0;
            if (valueA.name == valueB.name) return 0;
            return (valueA.name > valueB.name) ? 1 : -1;
        };

        return (
            <React.Fragment>
                <div
                    id="myGrid"
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                    className="ag-theme-alpine cellsWithLeftBorder"
                >
                    <AgGridReact
                        frameworkComponents={{
                            vehicleCellRenderer: VehicleCellRenderer,
                            barCellRenderer: BarCellRenderer,

                        }}
                        rowClass="datatable-row"
                        getRowHeight={getRowHeight}
                        getRowClass={getRowClass}
                        onFirstDataRendered={onFirstDataRendered}
                        onGridSizeChanged={sizeColumnsToFit}
                        onSortChanged={sizeColumnsToFit}
                        pinnedTopRowData={this.getPinnedRowData()}
                        rowData={this.getRowData()}
                        pagination={true}
                        paginationPageSize={10}
                        headerHeight={65}
                        defaultColDef={{ sortable: true, comparator: valueComparator }}
                    >
                        <AgGridColumn minWidth={240} field="vehicle" headerName="Fleet" cellRenderer="vehicleCellRenderer" headerClass='FuelEconDataTableHeader' cellStyle={dynamicCellStyle} comparator={fleetNameCompare} />
                        {this.props.columns.map(col =>
                            <AgGridColumn
                                cellRenderer="barCellRenderer"
                                headerName={col.columnHeader}
                                field={col.columnName}
                                headerClass='FuelEconDataTableHeader'
                                cellStyle={dynamicCellStyle}
                                width={col.width}
                                cellRendererParams={col.minBarPercentDisplay}
                                sortable={col.columnName === "dutyCycle" ? false : true}
                            />)}
                    </AgGridReact>
                </div>

            </React.Fragment>
        );
    }


}
